import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HibaService, UtilityService, GranitTableComponent } from '@granit/common-library';
import { FlottaService } from '../../../../services/flotta.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

@Component({
  selector: 'uj-flotta-b-ajanlatkeresek',
  templateUrl: './uj-flotta-b-ajanlatkeresek.component.html',
  styleUrls: ['./uj-flotta-b-ajanlatkeresek.component.scss']
})
export class UjFlottaBAjanlatkeresekComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private flottaService: FlottaService,
    public hibaService: HibaService,
    private utilityService: UtilityService) {

  }

  ujFlottaBAjanlatkeresekFormGroup: FormGroup;
  ujFlottaBAjanlatkeresekTablaOszlopok: any[] = [
    { columnDef: 'f_ugyszam', header: 'Ügyszám', class: 'col-lg-1' },
    { columnDef: 'f_kockezd', header: 'Kockázatviselés kezdete' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot' }
  ];
  @ViewChild('ujFlottaBAjanlatkeresekTabla', { static: false }) ujFlottaBAjanlatkeresekTabla: GranitTableComponent;

  ngOnInit(): void {
    this.ujFlottaBAjanlatkeresekLetrehozas();
    this.ujFlottaBAjanlatkeresekLekerese();
  }

  // Formgroup létrehozása
  ujFlottaBAjanlatkeresekLetrehozas() {
    this.ujFlottaBAjanlatkeresekFormGroup = this.formBuilder.group({
      f_szerzodo_nev: new FormControl(null),
      f_allapot: new FormControl(''),
      tableDataSource: this.formBuilder.array([])
    });
  }

  // Új B flotta ajánlatkérések lekérése
  ujFlottaBAjanlatkeresekLekerese() {

    // Model létrehozása
    let model = {
      ugyfelnev: this.ujFlottaBAjanlatkeresekFormGroup.controls['f_szerzodo_nev'].value,
      allapot: this.ujFlottaBAjanlatkeresekFormGroup.controls['f_allapot'].value,
      modozat: 'GFB'
    };

    // Kontroller hívás
    this.flottaService.ujFlottaBAjanlatkeresek(model).subscribe(
      data => {
        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.ujFlottaBAjanlatkeresekFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
        this.ujFlottaBAjanlatkeresekTabla.setDataSource();
      }
    );
  }

  // Szűrés gomb esemény
  onSzures() {
    this.ujFlottaBAjanlatkeresekLekerese();
  }

  // Táblázat letöltés gomb esemény
  onRowOperationClick(formGroup: FormGroup) {
    // Model létrehozása
    let model = {
      formGroupValue: formGroup.value,
      modozat: 'GFB',
      tipus: 'bflotta'
    };
    this.flottaService.ujFlottaAjanlatVagyDijkalkulacioLetoltes(model).subscribe(
      data => {
        /*var fileURL = URL.createObjectURL(data.body);
        window.open(fileURL, "_blank");*/

        let contentType = 'application/pdf';
        let blob = base64StringToBlob(data.file, contentType);
        saveAs(blob, data.filename);

      }
    );
  }
}
