import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HirekService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/hirek';

  // Hírek lekérése
  hirekLekerese(model: any) {
    return this.httpClient.post<any[]>(this.url + "/HirekLekerese", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  //Felugró ablak adatainak lekérése
  felugroAblakAdatok() {
    return this.httpClient.post(this.url + "/FelugroAblakAdatok", null, { responseType: 'text' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
