<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Flotta szaporítás'"
                                [subTitle]="'Meglévő flotta szaporítása, vagy díjnemfizetéssel megszűnt flotta szerződés újrakötése'"
                                [templateOutlet]="szaporitasok">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>
<ng-template #szaporitasok>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Flotta szaporítás / DNF újrakötés</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Flotta KGFB ajánlatok</mat-card-title>
              </mat-card-header>
              <mat-card-content class="mb-0">
                <div class="col-lg-12">
                  <form [formGroup]="flottaAjanlatokFormGroup">
                    <ng-container>
                      <div class="row">
                        <div class="col-lg-3">
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Alkusz név</mat-label>
                            <input matInput formControlName="alkuszNev" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Törzsszám</mat-label>
                            <input matInput formControlName="alkuszTorzsszam" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="toltoKepernyoKereses">
                      <div class="row">
                        <div class="col-lg-12 bg-white">
                          <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!toltoKepernyoKereses && !feltoltes">
                      <!--<div><h4>Alkusz adatai</h4></div>-->
                      <div class="row">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill" [style.width.%]="100">
                            <mat-label>Flotta azonosító</mat-label>
                            <input matInput formControlName="flottaAzonosito">
                            <mat-hint>Flotta azonosítóra történő szűréshez, adja meg a kívánt flotta azonosítót!</mat-hint>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill" [style.width.%]="100">
                            <mat-label>Szerződő név</mat-label>
                            <input matInput formControlName="szerzodoNev">
                            <mat-hint>Szerződő névre történő szűréshez, adja meg a szerződő nevének részletét!</mat-hint>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill" [style.width.%]="100">
                            <mat-label>Állapot</mat-label>
                            <mat-select formControlName="allapot">
                              <mat-option value="0">Minden ajánlat</mat-option>
                              <mat-option value="1">jóváhagyásra vár</mat-option>
                              <mat-option value="2">aláírásra kiadva</mat-option>
                              <mat-option value="3">ajánlat feltöltve</mat-option>
                              <mat-option value="4">ajánlat rögzítés alatt</mat-option>
                              <mat-option value="5">ajánlat lezárva, ajánlat rögzítve</mat-option>
                              <mat-option value="6">ajánlat törölve (határidő túllépés miatt)</mat-option>
                            </mat-select>
                            <mat-hint>Az ajánlat állapotára történő szűréshez, válassza ki a megfelelő állapot!</mat-hint>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3"><button mat-raised-button color="accent" (click)="szuresOnClick()">Szűrés</button></div>
                        <div class="col-lg-3"></div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4"></div>
                      </div>
                      <div class="row"></div>
                      <div class="row">
                        <div class="col-lg-12">
                          <!-- táblázat helye -->
                          <mat-table [dataSource]="dataSource">
                            <!--class="w100 mr-5 table_border"-->
                            <ng-container *ngFor="let column of szaporitasokTablaOszlopok" [matColumnDef]="column.columnDef">
                              <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border">
                                <div>
                                  <span [innerHTML]="column.header"></span>
                                </div>
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element">
                                <div *ngIf="column.adattipus != 'button'">
                                  <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                </div>
                                <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_letoltes' && (element.controls['f_allapot'].value == '2' || element.controls['f_allapot'].value == '3' || element.controls['f_allapot'].value == '4')">
                                  <button mat-raised-button color="primary" (click)="letoltesOnClick(element)" title="Ajánlat letöltés"><mat-icon>download</mat-icon></button>
                                </div>
                                <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_visszatoltes' && (element.controls['f_allapot'].value == '2' || element.controls['f_allapot'].value == '3' || element.controls['f_allapot'].value == '4')">
                                  <button mat-raised-button color="primary" (click)="feltoltesOnClick(element)" title="Aláírt ajánlat visszatöltés"><mat-icon>upload</mat-icon></button>
                                </div>
                                <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_torles' && (element.controls['f_allapot'].value == '1' || element.controls['f_allapot'].value == '2' || element.controls['f_allapot'].value == '3' || element.controls['f_allapot'].value == '4')">
                                  <button mat-raised-button color="primary" (click)="torlesOnClick(element)" title="Ajánlat törlés"><mat-icon>delete</mat-icon></button>
                                </div>
                                <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_fedezetigazolas' && (element.controls['f_kgfb_fedezet_igazolas_letoltheto'].value == 'I')">
                                  <button mat-raised-button color="primary" (click)="fedezetIgazolasOnClick(element)" title="Fedezet igazolás"><mat-icon>description</mat-icon></button>
                                </div>
                              </mat-cell>
                            </ng-container>

                            <!--Fejléc-->
                            <mat-header-row *matHeaderRowDef="getDisplayedSzaporitasColumns()"></mat-header-row>
                            <!--Sorok-->
                            <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedSzaporitasColumns();"></mat-row>

                            <div class="mat-row col-lg-12" *matNoDataRow>
                              <div class="mat-cell">Nincs megjeleníthető elem.</div>
                            </div>
                          </mat-table>
                          <mat-paginator #matPaginator [pageSizeOptions]="[5, 10, 20]"
                                         showFirstLastButtons
                                         aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                          </mat-paginator>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="feltoltes">
                      <div class="row">
                        <div class="col-lg-12">
                          <h2>Aláírt flottaszaporító ajánlat feltöltése</h2>
                          <p>A feltötlthető fájlok formátuma, biztonsági okok miatt, korlátozott az alábbiakra: JPEG, JPG, PDF, PNG.</p>
                        </div>
                      </div>
                      <!-- feltöltés -->
                      <div class="row">
                        <div class="col-lg-3">
                          <mat-form-field [style.width.%]="100">
                            <mat-label>Flotta azonosító</mat-label>
                            <input matInput formControlName="feltoltesFlotta" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field [style.width.%]="100">
                            <mat-label>Szerződő név</mat-label>
                            <input matInput formControlName="feltoltesSzerzodoNev" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                          <mat-form-field [style.width.%]="100">
                            <mat-label>Ügyszám</mat-label>
                            <input matInput formControlName="feltoltesUgyszam" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <granit-file-upload #fileUpload ngDefaultControl [formGroup]="flottaAjanlatokFormGroup" [formControlName]="'ajanlatFeltoltesDokumentumok'" [labelText]="'Kérjük válassza ki a feltölteni kívánt fájlt!'"
                                             [buttonText]="'Fájl kiválasztás'" [allowMultipleFiles]="true" [acceptedFileExtensions]="ajanlatFajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
                      </div>
                        <div class="row">
                          <div class="col-lg-3"><button mat-raised-button color="accent" (click)="feltoltescCancelOnClick()">Mégsem</button></div>
                          <div class="col-lg-3"></div>
                          <div class="col-lg-3"></div>
                          <div class="col-lg-3"><button mat-raised-button color="accent" (click)="feltoltesOkOnClick()">Feltöltés</button></div>
                        </div>
                    </ng-container>
                    <ng-container>
                      <div class="row">
                        <div class="col-lg-12">
                          <span [innerHTML]="hibaSzoveg"></span>
                        </div>
                      </div>
                    </ng-container>
                  </form>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
