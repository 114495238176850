import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonValidator, HibaService, TextTransformPipe, UtilityService } from '@granit/common-library';
import { DokumentumService } from '../../services/dokumentum.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MenuService } from '../../services/menu.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'dokumentum-igenyles',
  templateUrl: './dokumentum-igenyles.component.html',
  styleUrls: ['./dokumentum-igenyles.component.scss']
})
export class DokumentumIgenylesComponent implements OnInit {

  kivalasztottMenu: any;
  szamlaEvLista: any[] = [];

  dokumentumIgenylesFormGroup: FormGroup;
  dokumentumTipusLista: any[] = [];
  doktipus: any;
  infoSzoveg = "";
  title = "Dokumentum igénylés";
  pageIndex: number = 0;
  pageSize: number = 10;

  dokumentumSzerzodesekOszlopok: any[] = [
    { columnDef: 'sorszam', header: 'Sorszám', adattipus: 'sorszam' },
    { columnDef: 'szerzazon', header: 'Szerz.azon.' },
    { columnDef: 'rendszam', header: 'Rendszám' },
    { columnDef: 'szerzodoneve', header: 'Szerződő neve' },
    { columnDef: 'kockezd', header: 'Koc. kezdet', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'modozat', header: 'Módozat' },
    { columnDef: 'eredmeny', header: 'Eredmény' },
    { columnDef: 'valaszt', header: '', adattipus: 'check', class: 'col-lg-1' }
  ];
  getDisplayedSzerzodesekColumns(): string[] {
    let displayedColumns = this.dokumentumSzerzodesekOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }

  dokumentumSzerzodesek: MatTableDataSource<any>;
  @ViewChild("allCheck") allCheck: MatCheckbox;

  matSort: MatSort;
  @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
    if (sort) {
      this.matSort = sort;
      this.dokumentumSzerzodesek.sort = this.matSort;
    }
  }
  matSortDirection = 'asc'

  matPaginator: MatPaginator;
  @ViewChild('paginatorDokumentumSzerzodesek', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dokumentumSzerzodesek.paginator = this.matPaginator;
    }
  }

  kivalasztottDb: number = 0;
  osszesenDb: number = 0;
  allChecked: boolean;
  allIndeterminate: boolean;

  toltoKepernyo: boolean = false;
  toltoKepernyoKereses: boolean = false;

  keresesLathato: boolean = true;
  infoLathato: boolean = true;
  szerzodesekLathato: boolean = true;
  eredmenyLathato: boolean = true;
  eredmenyKotegeltLathato: boolean = true;

  dokumentumEredmenyOszlopok: any[] = [
    { columnDef: 'szerzazon', header: 'Szerz.azon.' },
    { columnDef: 'rendszam', header: 'Rendszám' },
    { columnDef: 'szerzodoneve', header: 'Szerződő neve' },
    { columnDef: 'kockezd', header: 'Koc. kezdet', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'modozat', header: 'Módozat' },
    { columnDef: 'eredmenyszoveg', header: 'Eredmény' }
  ];
  getDisplayedDokumentumEredmenyColumns(): string[] {
    let displayedColumns = this.dokumentumEredmenyOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  dokumentumEredmeny: MatTableDataSource<any>;
  dokumentumEredmenykMind: MatTableDataSource<any>;

  eredmenySzoveg: string = "";
  dokumentumLista: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public hibaService: HibaService,
    private dokumentumokService: DokumentumService,
    private utilityService: UtilityService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef  ) {
    this.route.queryParams.subscribe((params: Params) => {
      const _params = this.toLower(params);

      this.dokumentumIgenylesFormGroupInit();

      if (_params['doc']) {


        //this.kivalasztottMenu = this.menuService.kivalasztottMenupontValue;

        //this.dokumentumIgenylesFormGroupInit();
        //this.dokumentumIgenylesFormGroup.controls["f_dokumentum"].setValue(_params['doc']);
        if (_params['doc'] == "SZ") {
          this.title = "Számla igénylés";
          this.szamlaEvLista.push({ ev: (new Date()).getFullYear().toString() });
          this.szamlaEvLista.push({ ev: ((new Date()).getFullYear() - 1).toString() });
          this.dokumentumIgenylesFormGroup.controls["f_keresesi_mezo"].setValue("-1");
          this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].setValue(" ");
          this.dokumentumIgenylesFormGroup.controls["f_dokumentum"].setValue("SZ");

          this.infoSzoveg = "<p>Az alábbi kereső űrlapon lehetősége van a szerződésekhez kapcsolódó számla lekérésére. Jelenlegi és mínusz egy évre korlátozva. Ki kell választani az üzleti évet, és meg kell adni az újra megigényelt számla sorszámát. Ez a sorszám megtalálható az egyenlegközlőben, vagyis, ha nem ismert a számla sorszáma, akkor előbb az egyenlegközlőt kell letölteni és ott meg kell keresni a B oszlopban található számla sorszámot (csak az utolsó hat számot pl.: 123456). Beírását követően a kérés feladható. A számla Pdf. formátumban töltödik le, majd ezt lehet elmenteni felhasználás céljából. </p>";
          this.infoSzoveg += "<p>Szolgáltatásunkkal kapcsolatos kérdését, speciális igényét kérjük e-mailen jelezze a broker@granitbiztosito.hu címen.</p>";
        }
      }
      else {
        this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_ev"].setValue("-1");
        this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].setValue(" ");
        this.title = "Dokumentum igénylés";
        this.infoSzoveg = "<p>Az alábbi kereső űrlap kitöltésével lehetősége van a szerződésekhez kapcsolódó dokumentumok igénlésére.<br></p>";
        this.infoSzoveg += "<p>A keresési paraméterek megadását követően a legördülő mezőből választhatod ki a kért dokumentumot. Amennyiben flotta azonosítóval történik a keresés, akkor megjelenik egy „Kötegelt letöltés” check box. Ha ez be van pipálva, akkor a jobb oldali oszlop tetején lévő négyzet segítségével a teljes találati listából egyszerre kijelölhető minden olyan szerződés, ahol az „Eredmény” oszlopban a „Letölthető” olvasható. Kijelölés után az „Igénylés küldése” gombbal lehet a kérést indítani. Miután az igényelt dokumentumok elkészültek, a felhasználó egy e - mail értesítést kap a saját címére. A letöltendő fájlok a Listák menüpontban az Igényelt dokumentumok alatt érhetőek el 4 napig. Kötegelt letöltés esetén is hetente maximum 2 letöltésre van lehetőség szerződésenként.</p><br>";
        this.infoSzoveg += "<table>"
        this.infoSzoveg += "<tr>"
        this.infoSzoveg += "<td class=\"font - bold\">Dokumentum típusa</td>";
        this.infoSzoveg += "<td align = \"right\" class=\"font-bold\">Igényelhető mennyiség</td>";
        this.infoSzoveg += "</tr>"
        this.infoSzoveg += "<tr>"
        this.infoSzoveg += "<td colspan=\"2\">&nbsp;</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>Csekk, díjbekérő igénylése</td>";
        this.infoSzoveg += "<td align = \"right\">30 naponta 1 db – postai úton kiküldésre kerül az ügyfél részére</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>Díjigazolás kérése</td>";
        this.infoSzoveg += "<td align = \"right\">Hetente 2 db</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>Kötvény kérése</td>";
        this.infoSzoveg += "<td align = \"right\">Hetente 2 db – utasbiztosítás, szállítmánybiztosítás és CMR kötvény kivételével</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>Zöldkártya kérése</td>";
        this.infoSzoveg += "<td align = \"right\">Hetente 2 db</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>KÁF/KSZF kötvény fedezetigazolás</td>";
        this.infoSzoveg += "<td align = \"right\">Kötvényesítés után azonnal letölthető</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "<tr>";
        this.infoSzoveg += "<td>Kártörténeti igazolás(csak 1 évnél nem régebben törölt KGFB szerződés esetén)</td>";
        this.infoSzoveg += "<td align = \"right\">Hetente 1 db</td>";
        this.infoSzoveg += "</tr>";
        this.infoSzoveg += "</table><br>";
        this.infoSzoveg += "<p>Szolgáltatásunkkal kapcsolatos kérdésed, speciális igényed kérjük e-mailen jelezd a broker@granitbiztosito.hu, vagy az alkusztámogató e-mail címen.</p>";

        this.dokumentumJogosultsagLekeres();
      }
    });
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  ngOnInit(): void {
  }

  /*FormGroup inicializálása*/

  dokumentumIgenylesFormGroupInit() {
    this.dokumentumIgenylesFormGroup = this.formBuilder.group({
      f_keresesi_mezo: new FormControl("0", [CommonValidator.defaultEllenorzo("0")]),
      f_keresesi_adat: new FormControl(null, [Validators.required]),
      f_dokumentum: new FormControl("0", [CommonValidator.defaultEllenorzo("0")]),
      f_kotegelt: new FormControl(false),
      f_igenyelt: new FormControl(false),
      f_keresett: new FormControl(false),
      f_pdf: new FormControl(null),
      f_szamlaszam_ev: new FormControl("0", [CommonValidator.defaultEllenorzo("0")]),
      f_szamlaszam_szam: new FormControl(null),
      f_csak_letoltheto: new FormControl(false)
    });

    this.dokumentumSzerzodesek = new MatTableDataSource();
    this.dokumentumSzerzodesek.sortingDataAccessor = (item: FormGroup, property) => {

      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

  }

  dokumentumJogosultsagLekeres() {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_dokumentumigenyles_dokumentumJogosultsagLekeres').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.dokumentumLista = []          
          this.dokumentumokService.dokumentumJogosultsag().subscribe(
            data => {
              this.dokumentumLista = data
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt a jogosultságok lekérdezése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  keresesiAdatEllenor() {
    if (this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].value && this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].value != "") {

      this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].setErrors(null);
      let adat: string = this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].value.toString();

      switch (this.dokumentumIgenylesFormGroup.controls["f_keresesi_mezo"].value) {
        case "f_szerz_azon":
          if (adat.length != 8) {
            this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].setErrors({ szerzazonHosszHiba: true })
          }
          else {
            if (isNaN(Number(adat))) {
              this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].setErrors({ szerzazonSzamHiba: true })
            }
          }
          break;
        case "f_flotta_azon":
          if (isNaN(Number(adat))) {
            this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].setErrors({ flottaSzamHiba: true })
          }
          break;
      }
    }
  }

  szamlaEllenor() {
    if (this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].value != null && this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].value != "") {

      this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].setErrors(null);
      let adat: string = this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].value.toString();

      if (isNaN(Number(adat))) {
        this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].setErrors({ szamlaSzamHiba: true })
      }
    }
  }

  /*igényelhető dokumentumok keresése a megadott feltételek szerint*/

  onKereses() {

    this.toltoKepernyoKereses = true;
    this.dokumentumIgenylesFormGroup.controls["f_kotegelt"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["f_igenyelt"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["f_keresett"].setValue(false);
    this.dokumentumIgenylesFormGroup.controls["f_pdf"].setValue(null);
    this.dokumentumEredmeny = new MatTableDataSource();
    this.dokumentumEredmenykMind = new MatTableDataSource();
    this.eredmenySzoveg = "";

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_dokumentumigenyles_onKereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          if (this.dokumentumIgenylesFormGroup.controls["f_dokumentum"].value != "SZ") {
            let model = {
              f_kereses: this.dokumentumIgenylesFormGroup.value
            }

            this.dokumentumokService.dokumentumKereses(model).subscribe(
              data => {
                this.dokumentumIgenylesFormGroup.controls["f_keresett"].setValue(true);
                this.toltoKepernyoKereses = false;
                const fgs = data.map(item => {
                  return this.utilityService.formGroupObjektumbol(item);
                });
                this.dokumentumEredmenykMind.data = this.formBuilder.array(fgs).controls;
                this.onCsakLetoltheto();
                this.darabszamok();
                this.cdr.detectChanges();
              },
              errorResult => {
                this.toltoKepernyoKereses = false;
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba történt a keresés közben!",
                  confirmButtonText: 'Rendben'
                });
              }
            );
          }
          else {
            let model = {
              szamlaszamEv: this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_ev"].value.toString(),
              szamlaszamSzam: this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].value.toString()
            }

            this.dokumentumokService.szlaKeresesLetoltes(model).subscribe(
              data => {
                this.toltoKepernyoKereses = false;
                if (data.hiba == "") {
                  let contentType = 'application/pdf';
                  let blob = base64StringToBlob(data.dokumentum, contentType);
                  saveAs(blob, "szla_" + this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_ev"].value.toString() + "_" + this.dokumentumIgenylesFormGroup.controls["f_szamlaszam_szam"].value.toString() + ".pdf");
                }
                else {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hiba',
                    message: data.hiba,
                    confirmButtonText: 'Rendben'
                  });
                }
              },
              errorResult => {
                this.toltoKepernyoKereses = false;
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba történt a keresés közben!",
                  confirmButtonText: 'Rendben'
                });
              });
          }
        })
      });
  }

  onCsakLetoltheto() {
    if (this.dokumentumIgenylesFormGroup.controls["f_csak_letoltheto"].value) {
      this.dokumentumSzerzodesek.data = this.dokumentumEredmenykMind.data.filter(x => x.controls["eredmeny"].value == 'Letölthető');
    }
    else {
      this.dokumentumSzerzodesek.data = this.dokumentumEredmenykMind.data;
    }
  }

  /* táblában lévő összes választható és összes kijelölt sor darabszám lekérése*/

  darabszamok() {
    this.kivalasztottDb = this.dokumentumSzerzodesek.data.filter(row => row.controls["eredmeny"].value == 'Letölthető' && row.controls["valaszt"].value === true).length;
    this.osszesenDb = this.dokumentumSzerzodesek.data.filter(row => row.controls["eredmeny"].value == 'Letölthető').length;
  }

  /* az összes választható sor ki van e jelölve */
  mindKivalasztva() {
    this.darabszamok();
    return this.kivalasztottDb === this.osszesenDb;
  }

  /** tábal fejlécéban lévő választó gomb használata */
  onValasztMind() {
    this.allCheck.indeterminate = false;
    if (this.mindKivalasztva()) {
      this.dokumentumSzerzodesek.data.forEach(row => row.controls["valaszt"].setValue(false));
      this.allChecked = false;
    }
    else {
      this.dokumentumSzerzodesek.data.forEach(row => row.controls["valaszt"].setValue(row.controls["eredmeny"].value == 'Letölthető'));
      this.allChecked = true;
    }
    this.darabszamok();
  }

  /*táblában lévő választó gombok használata*/

  onValaszt() {
    if (this.allCheck != null) {
      if (!this.mindKivalasztva()) {
        if (this.kivalasztottDb = 0) {
          this.allCheck.indeterminate = false;
          this.allChecked = false;
        }
        else {
          this.allCheck.indeterminate = true;
        }
      }
      else {
        this.allChecked = true;
      }
    }
    this.darabszamok();
  }

  /*kötegelt választó gomb váltása*/

  onKotegeltCheck() {
    if (this.dokumentumIgenylesFormGroup.controls["f_kotegelt"].value) {

      if (this.mindKivalasztva()) {
        this.allChecked = true;
      }
      else {
        if (this.kivalasztottDb == 0) {
          this.allChecked = false;
        }
        else {
          this.allIndeterminate = true;
        }
      }
    }
    else {
      if (this.kivalasztottDb > 10) {
        this.dokumentumSzerzodesek.data.forEach(row => row.controls["valaszt"].setValue(false));
        this.allChecked = false;
        this.allCheck.indeterminate = false;
      }
    }
    this.darabszamok();
  }

  /*keresés ablak form mutatása/elrejtése*/
  onKeresesLathatoValt() {
    this.keresesLathato = !this.keresesLathato;
  }

  /*info ablak mutatása/elrejtése*/
  onInfoLathatoValt() {
    this.infoLathato = !this.infoLathato;
  }

  /*szerződések ablak mutatása/elrejtése*/
  onSzerzodesekLathatoValt() {
    this.szerzodesekLathato = !this.szerzodesekLathato;
  }

  /*eredmény ablak mutatása/elrejtése*/
  onEredmenyLathatoValt() {
    this.eredmenyLathato = !this.eredmenyLathato;
  }

  /*eredmény-kötegelt ablak mutatása/elrejtése*/
  onEredmenyKotegeltLathatoValt() {
    this.eredmenyKotegeltLathato = !this.eredmenyKotegeltLathato;
  }

  /*kiválasztott dokumentumok igénylése*/

  onIgenyKuldese() {

    this.toltoKepernyo = true;

    let valasztottak = this.dokumentumSzerzodesek.data.filter(row => row.controls["valaszt"].value == true);
    let szerzodesSzamok: number[] = [];
    valasztottak.forEach(adat => { Number(szerzodesSzamok.push(adat.controls["szerzazon"].value)) });

    let model = {
      f_dokumentum: this.dokumentumIgenylesFormGroup.controls["f_dokumentum"].value,
      f_szerzodesSzamok: szerzodesSzamok.toString(),
      f_kotegelt: this.dokumentumIgenylesFormGroup.controls["f_kotegelt"].value,
      f_flotta: this.dokumentumIgenylesFormGroup.controls["f_kotegelt"].value ? this.dokumentumIgenylesFormGroup.controls["f_keresesi_adat"].value : null
    }

    this.dokumentumokService.dokumentumIgenyles(model).subscribe(
      data => {

        this.toltoKepernyo = false;

        if (data.eredmeny_kod == 0) {

          this.dokumentumIgenylesFormGroup.controls["f_igenyelt"].setValue(true);

          if (!this.dokumentumIgenylesFormGroup.controls["f_kotegelt"].value) {

            const fgs = data.dokumentumEredmenyList.map(item => {
              return this.utilityService.formGroupObjektumbol(item);
            });
            this.dokumentumEredmeny.data = this.formBuilder.array(fgs).controls;

            this.dokumentumIgenylesFormGroup.controls["f_pdf"].setValue(data.dokumentum);

          }
          else {
            this.eredmenySzoveg = data.eredmeny_szoveg;
          }
        }
      },
      errorResult => {
        this.toltoKepernyo = false;
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt az igénylés közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  /*nem kötegelt igénylésnél a kért dokumentumok letöltése */

  onLetoltes() {

    let contentType = 'application/pdf';
    let blob = base64StringToBlob(this.dokumentumIgenylesFormGroup.controls["f_pdf"].value, contentType);
    saveAs(blob, 'dokumentum.pdf');

  }

  onPaginateChange(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

}
