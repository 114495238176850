import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HitelesitesGuard } from '@granit/portal-library';
import { BejelentkezesComponent } from './components/bejelentkezes/bejelentkezes.component';
import { ElfelejtettJelszoComponent } from './components/elfelejtett-jelszo/elfelejtett-jelszo.component';
import { FooldalComponent } from './components/fooldal/fooldal.component';
import { RegisztracioComponent } from './components/regisztracio/regisztracio.component';
import { ListakComponent } from './components/listak/listak.component';
import { IpidComponent } from './components/ipid/ipid.component';
import { KalkulatorHasznosinfoComponent } from './components/kalkulator-hasznosinfo/kalkulator-hasznosinfo.component';
import { DokumentumIgenylesComponent } from './components/dokumentum-igenyles/dokumentum-igenyles.component';
import { EgyenlegkozloComponent } from './components/egyenlegkozlo/egyenlegkozlo.component';
import { FuvarozoiComponent } from './components/fuvarozoi/fuvarozoi.component';
import { SzerzodesAdatokComponent } from './components/szerzodes-adatok/szerzodes-adatok.component';
import { OktatasComponent } from './components/oktatas/oktatas.component';
import { FlottaSzaporitasComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-szaporitas/flotta-szaporitas.component';
/*import { FlottaJovahagyasComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-jovahagyas/flotta-jovahagyas.component';*/
import { FlottaSzaporitasokComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-szaporitasok/flotta-szaporitasok.component';
import { FlottaFedigComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-fedig/flotta-fedig.component';
import { HirekComponent } from './components/hirek/hirek.component';
import { UjFlottaAjanlatVagyDijkalkulaciokeresComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlat-vagy-dijkalkulaciokeres/uj-flotta-ajanlat-vagy-dijkalkulaciokeres.component';
/*import { UjFlottaAlkusziJovahagyasComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-alkuszi-jovahagyas/uj-flotta-alkuszi-jovahagyas.component';*/
import { UjFlottaAlkusziHianypotlasComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-alkuszi-hianypotlas/uj-flotta-alkuszi-hianypotlas.component';
import { UjFlottaDijkalkulaciokComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-dijkalkulaciok/uj-flotta-dijkalkulaciok.component';
import { UjFlottaBAjanlatkeresekComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-b-ajanlatkeresek/uj-flotta-b-ajanlatkeresek.component';
import { UjFlottaAjanlatkeresekComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlatkeresek/uj-flotta-ajanlatkeresek.component';
/*import { UjFlottaAjanlatRogzitesEredmenyComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlat-rogzites-eredmeny/uj-flotta-ajanlat-rogzites-eredmeny.component';*/
/*import { UjFlottaElbiralasReszletezoComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-elbiralas-reszletezo/uj-flotta-elbiralas-reszletezo.component';*/
/*import { FlottaCascoJovahagyasComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-jovahagyas/flotta-casco-jovahagyas.component';*/
import { FlottaCascoSzaporitasComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-szaporitas/flotta-casco-szaporitas.component';
import { FlottaCascoAjanlatokComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-ajanlatok/flotta-casco-ajanlatok.component';
import { KafKszfDijszamoloBovitesEsetenComponent } from './components/kaf-kszf-dijszamolo-bovites-eseten/kaf-kszf-dijszamolo-bovites-eseten.component';

import { UjFlottaCascoAjanlatkeresComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlatkeres/uj-flotta-casco-ajanlatkeres.component'
import { UjFlottaCascoAjanlatkeresekComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlatkeresek/uj-flotta-casco-ajanlatkeresek.component'
/*import { UjFlottaCascoAlkusziJovahagyasComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-alkuszi-jovahagyas/uj-flotta-casco-alkuszi-jovahagyas.component'*/
import { UjFlottaCascoAlkusziHianypotlasComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-alkuszi-hianypotlas/uj-flotta-casco-alkuszi-hianypotlas.component'
/*import { UjFlottaCascoElbiralasReszletezoComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-elbiralas-reszletezo/uj-flotta-casco-elbiralas-reszletezo.component'*/
/*import { UjFlottaCascoAjanlatRogzitesEredmenyComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlat-rogzites-eredmeny/uj-flotta-casco-ajanlat-rogzites-eredmeny.component'*/


const routes: Routes = [
  { path: '', redirectTo: 'fooldal', pathMatch: 'full' },
  { path: 'fooldal', component: FooldalComponent, canActivate: [HitelesitesGuard] },
  { path: 'bejelentkezes', component: BejelentkezesComponent },
  { path: 'elfelejtett-jelszo', component: ElfelejtettJelszoComponent },
  { path: 'regisztracio-veglegesitese', component: RegisztracioComponent },
  { path: 'listak', component: ListakComponent, canActivate: [HitelesitesGuard] },
  { path: 'dokumentum-igenyles', component: DokumentumIgenylesComponent, canActivate: [HitelesitesGuard] },
  { path: 'egyenlegkozlo', component: EgyenlegkozloComponent, canActivate: [HitelesitesGuard] },
  { path: 'fuvarozoi', component: FuvarozoiComponent, canActivate: [HitelesitesGuard] },
  { path: 'szerzodes-adatok', component: SzerzodesAdatokComponent, canActivate: [HitelesitesGuard] },
  { path: 'oktatas', component: OktatasComponent, canActivate: [HitelesitesGuard] },
  { path: 'hirek', component: HirekComponent, canActivate: [HitelesitesGuard] },
  { path: 'archiv-hirek', component: HirekComponent, canActivate: [HitelesitesGuard] },

  { path: 'ipid', component: IpidComponent },
  { path: 'kalkulator-hasznosinfo', component: KalkulatorHasznosinfoComponent/*, canActivate: [HitelesitesGuard]*/ },
  { path: 'flotta-szaporitas', component: FlottaSzaporitasComponent, canActivate: [HitelesitesGuard] },
  /*{ path: 'flotta-jovahagyas', component: FlottaJovahagyasComponent, canActivate: [HitelesitesGuard] },*/
  { path: 'flotta-szaporitasok', component: FlottaSzaporitasokComponent, canActivate: [HitelesitesGuard] },
  { path: 'flotta-fedig', component: FlottaFedigComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-ajanlat-vagy-dijkalkulaciokeres', component: UjFlottaAjanlatVagyDijkalkulaciokeresComponent, canActivate: [HitelesitesGuard] },
  /*{ path: 'uj-flotta-alkuszi-jovahagyas', component: UjFlottaAlkusziJovahagyasComponent, canActivate: [HitelesitesGuard] },*/
  { path: 'uj-flotta-alkuszi-hianypotlas', component: UjFlottaAlkusziHianypotlasComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-dijkalkulaciok', component: UjFlottaDijkalkulaciokComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-b-ajanlatkeresek', component: UjFlottaBAjanlatkeresekComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-ajanlatkeresek', component: UjFlottaAjanlatkeresekComponent, canActivate: [HitelesitesGuard] },
  /*{ path: 'uj-flotta-ajanlat-rogzites-eredmeny', component: UjFlottaAjanlatRogzitesEredmenyComponent, canActivate: [HitelesitesGuard] },*/
  /*{ path: 'uj-flotta-elbiralas-reszletezo', component: UjFlottaElbiralasReszletezoComponent, canActivate: [HitelesitesGuard] },*/
  /*{ path: 'flotta-casco-jovahagyas', component: FlottaCascoJovahagyasComponent, canActivate: [HitelesitesGuard] },*/
  { path: 'flotta-casco-szaporitas', component: FlottaCascoSzaporitasComponent, canActivate: [HitelesitesGuard] },
  { path: 'flotta-casco-ajanlatok', component: FlottaCascoAjanlatokComponent, canActivate: [HitelesitesGuard] },
  { path: 'kaf-kszf-dijszamolo-bovites-eseten', component: KafKszfDijszamoloBovitesEsetenComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-casco-ajanlatkeres', component: UjFlottaCascoAjanlatkeresComponent, canActivate: [HitelesitesGuard] },
  { path: 'uj-flotta-casco-ajanlatkeresek', component: UjFlottaCascoAjanlatkeresekComponent, canActivate: [HitelesitesGuard] },
  /*{ path: 'uj-flotta-casco-alkuszi-jovahagyas', component: UjFlottaCascoAlkusziJovahagyasComponent, canActivate: [HitelesitesGuard] },*/
  { path: 'uj-flotta-casco-alkuszi-hianypotlas', component: UjFlottaCascoAlkusziHianypotlasComponent, canActivate: [HitelesitesGuard] },  
  /*{ path: 'uj-flotta-casco-elbiralas-reszletezo', component: UjFlottaCascoElbiralasReszletezoComponent, canActivate: [HitelesitesGuard] },*/
  /*{ path: 'uj-flotta-casco-ajanlat-rogzites-eredmeny', component: UjFlottaCascoAjanlatRogzitesEredmenyComponent, canActivate: [HitelesitesGuard] },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
