<div class="container-fluid pb-3">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Elfelejtett jelszó'"
                                [subTitle]="'Ha elfelejtette jelszavát, lehetősége van újat kérni.'"
                                [templateOutlet]="elfelejtettJelszo">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>


<ng-template #elfelejtettJelszo>
  <div class="row">
    <div class="col-lg-12">
      <portal-elfelejtett-jelszo [alkalmazasEnum]="1"></portal-elfelejtett-jelszo>
    </div>
  </div>
</ng-template>
