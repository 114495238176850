<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="title"
                                [subTitle]="''"
                                [templateOutlet]="dokumentumIgenyles"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="title"
                                [subTitle]="''"
                                [templateOutlet]="dokumentumIgenyles">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #dokumentumIgenyles>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">

        <form [formGroup]="dokumentumIgenylesFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onInfoLathatoValt()">
              <mat-card-header>
                <mat-card-title>Információ</mat-card-title>
                <button mat-icon-button *ngIf="infoLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!infoLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </mat-card-header>
            </div>
            <mat-card-content *ngIf="infoLathato">
              <div class="col-lg-12 mt-2 mb-3 pr-5 info" [innerHTML]="infoSzoveg">
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onKeresesLathatoValt()">
              <mat-card-header>
                <mat-card-title>Keresés</mat-card-title>
                <button mat-icon-button *ngIf="keresesLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!keresesLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </mat-card-header>
            </div>
            <mat-card-content *ngIf="keresesLathato">

              <ng-container *ngIf="toltoKepernyoKereses">
                <div class="row">
                  <div class="col-lg-12 bg-white">
                    <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!toltoKepernyoKereses">

                <div class="row pb-2 pt-3" *ngIf="dokumentumIgenylesFormGroup.get('f_dokumentum').value != 'SZ'">
                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Keresési mező</mat-label>
                      <mat-select matInput formControlName="f_keresesi_mezo" (focusout)="keresesiAdatEllenor()">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option value="f_ajanlat">Ajánlatszám</mat-option>
                        <mat-option value="f_flotta_azon">Flotta azonosító</mat-option>
                        <mat-option value="f_rendszam">Rendszám</mat-option>
                        <mat-option value="f_szerz_azon">Szerződésszám</mat-option>
                        <mat-option value="f_ugyfelnev">Szerződő neve</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_keresesi_mezo', 'Egyezik')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési mezőt!
                    </mat-error>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Keresési adat</mat-label>
                      <input type="text" matInput formControlName="f_keresesi_adat" (focusout)="keresesiAdatEllenor()" (keyup.enter)="onKereses()">
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_keresesi_adat', 'required')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési adatot!
                    </mat-error>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_keresesi_adat', 'szerzazonHosszHiba')">
                      <mat-icon>error</mat-icon>
                      A szerződésszámnak 8 számból kell állnia!
                    </mat-error>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_keresesi_adat', 'szerzazonSzamHiba')">
                      <mat-icon>error</mat-icon>
                      A szerződésszám csak számot tartalmazhat!
                    </mat-error>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_keresesi_adat', 'flottaSzamHiba')">
                      <mat-icon>error</mat-icon>
                      A flottaazonosító csak számot tartalmazhat!
                    </mat-error>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Dokumentum</mat-label>
                      <mat-select matInput formControlName="f_dokumentum">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let doksi of dokumentumLista" [value]="doksi.f_azonosito">{{doksi.f_megnevezes}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_dokumentum', 'Egyezik')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy dokumentumot!
                    </mat-error>
                  </div>

                </div>

                <div class="row pb-2 pt-3" *ngIf="dokumentumIgenylesFormGroup.get('f_dokumentum').value == 'SZ'">
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Év</mat-label>
                      <mat-select matInput formControlName="f_szamlaszam_ev" (focusout)="szamlaEllenor()">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let ev of szamlaEvLista" [value]="ev.ev">{{ev.ev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_szamlaszam_ev', 'Egyezik')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési mezőt!
                    </mat-error>
                    
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szám</mat-label>
                      <input matInput formControlName="f_szamlaszam_szam" (focusout)="szamlaEllenor()">
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_szamlaszam_szam', 'required')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési adatot!
                    </mat-error>
                    <mat-error *ngIf="hibaService.hiba(dokumentumIgenylesFormGroup, 'f_szamlaszam_szam', 'szamHiba')">
                      <mat-icon>error</mat-icon>
                      A flottaazonosító csak számot tartalmazhat!
                    </mat-error>
                  </div>
                </div>


                <div class="row pb-3">

                  <div class="col-lg-6">
                    <button mat-raised-button color="accent" [disabled]="!dokumentumIgenylesFormGroup.valid" (click)="onKereses()" type="submit">Keresés</button>
                  </div>

                </div>

              </ng-container>
            </mat-card-content>
          </mat-card>

          <div *ngIf="dokumentumIgenylesFormGroup.get('f_keresett').value">
            <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
              <div class="card-expand-div" (click)="onSzerzodesekLathatoValt()">
                <mat-card-header>
                  <mat-card-title>Szerződések</mat-card-title>
                  <button mat-icon-button *ngIf="szerzodesekLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!szerzodesekLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="szerzodesekLathato">

                <ng-container *ngIf="toltoKepernyo">
                  <div class="row">
                    <div class="col-lg-12 bg-white">
                      <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!toltoKepernyo">

                  <div class="row pb-0 pt-3 ml-2" >
                    <div class="col-lg-6" *ngIf="dokumentumSzerzodesek.data.length > 0 && dokumentumIgenylesFormGroup.get('f_keresesi_mezo').value == 'f_flotta_azon' && (dokumentumIgenylesFormGroup.get('f_dokumentum').value == 'KT' || dokumentumIgenylesFormGroup.get('f_dokumentum').value == 'ZK' || dokumentumIgenylesFormGroup.get('f_dokumentum').value == 'DI')">
                      <mat-checkbox class="tablazat_felett" formControlName="f_kotegelt" (change)="onKotegeltCheck()">Kötegelt letöltése (10-nél több szerződés esetében)</mat-checkbox>
                    </div>

                    <div class="col-lg-6">
                      <mat-checkbox class="tablazat_felett" formControlName="f_csak_letoltheto" (change)="onCsakLetoltheto()">Csak a letölthetők</mat-checkbox>
                    </div>
                  </div>

                  <div class="row pb-0 pt-2 ml-2">
                    <div class="col-lg-2">
                      <mat-label class="tablazat_felett">Letölthető db: {{osszesenDb}}</mat-label>
                    </div>
                    <div class="col-lg-2">
                      <mat-label class="tablazat_felett">Választott db: {{kivalasztottDb}}</mat-label>
                    </div>
                  </div>

                  <div class="row pb-3 pt-0">
                    <div class="col-lg-12">

                      <mat-table [dataSource]="dokumentumSzerzodesek" class="w100 mr-5 table_border" [matSortActive]="'szerzazon'" [matSortDirection]="matSortDirection" matSort>

                        <ng-container *ngFor="let column of dokumentumSzerzodesekOszlopok" [matColumnDef]="column.columnDef">

                          <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>

                            <div *ngIf="column.adattipus != 'check'">
                              <span [innerHTML]="column.header"></span>
                            </div>
                            <div *ngIf="dokumentumIgenylesFormGroup.get('f_kotegelt').value && column.adattipus == 'check'">
                              <mat-checkbox (change)="onValasztMind()" #allCheck [indeterminate]="allIndeterminate"></mat-checkbox>
                            </div>

                          </mat-header-cell>

                          <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                                    [class]=column.class>

                            <div *ngIf="column.adattipus == 'sorszam'">
                              {{(pageIndex*pageSize)+(i+1)}}
                            </div>

                            <div *ngIf="column.adattipus != 'check' && column.adattipus != 'sorszam'">
                              <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                            </div>

                            <div *ngIf="column.adattipus == 'check' && element.get('eredmeny').value == 'Letölthető' && (dokumentumIgenylesFormGroup.get('f_kotegelt').value || (!dokumentumIgenylesFormGroup.get('f_kotegelt').value && (kivalasztottDb < 10 || (kivalasztottDb >= 10 && element.get('valaszt').value == true)  )))">
                              <mat-checkbox formControlName="valaszt" (change)="onValaszt()"></mat-checkbox>
                            </div>

                          </mat-cell>

                        </ng-container>

                        <!--Fejléc-->
                        <mat-header-row *matHeaderRowDef="getDisplayedSzerzodesekColumns()"></mat-header-row>

                        <!--Sorok-->
                        <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedSzerzodesekColumns();"></mat-row>


                      </mat-table>
                      <mat-paginator #paginatorDokumentumSzerzodesek [pageSizeOptions]="[10, 50, 100, 150]" [pageSize]="pageSize" showFirstLastButtons (page)="onPaginateChange($event)"></mat-paginator>
                    </div>
                  </div>

                  <div class="row pb-3">
                    <div class="col-lg-6">
                      <button mat-raised-button color="accent" [disabled]="(dokumentumIgenylesFormGroup.get('f_kotegelt').value && kivalasztottDb < 11) ||  (!dokumentumIgenylesFormGroup.get('f_kotegelt').value && kivalasztottDb == 0)" (click)="onIgenyKuldese()">Igény küldése</button>
                    </div>
                  </div>
                </ng-container>

              </mat-card-content>
            </mat-card>
          </div>

          <div *ngIf="!dokumentumIgenylesFormGroup.get('f_kotegelt').value && dokumentumEredmeny && dokumentumEredmeny.data.length > 0">
            <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
              <div class="card-expand-div" (click)="onEredmenyLathatoValt()">
                <mat-card-header>
                  <mat-card-title>Eredmény</mat-card-title>
                  <button mat-icon-button *ngIf="eredmenyLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!eredmenyLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="eredmenyLathato">


                <mat-table [dataSource]="dokumentumEredmeny" class="w100 mr-5 table_border">

                  <ng-container *ngFor="let column of dokumentumEredmenyOszlopok" [matColumnDef]="column.columnDef">

                    <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class>{{ column.header }}</mat-header-cell>

                    <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                              [class]=column.class>

                      <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>

                    </mat-cell>

                  </ng-container>

                  <!-- Fejléc -->
                  <mat-header-row *matHeaderRowDef="getDisplayedDokumentumEredmenyColumns()"></mat-header-row>

                  <!-- Sorok -->
                  <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedDokumentumEredmenyColumns();"></mat-row>

                </mat-table>

                <div class="row pb-3 pt-4">
                  <div class="col-lg-6">
                    <button mat-raised-button color="accent" [disabled]="!dokumentumIgenylesFormGroup.get('f_pdf').value" (click)="onLetoltes()">Dokumentum letöltése</button>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>

          <div *ngIf="dokumentumIgenylesFormGroup.get('f_kotegelt').value && dokumentumIgenylesFormGroup.get('f_igenyelt').value">
            <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
              <div class="card-expand-div" (click)="onEredmenyKotegeltLathatoValt()">
                <mat-card-header>
                  <mat-card-title>Eredmény</mat-card-title>
                  <button mat-icon-button *ngIf="eredmenyKotegeltLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!eredmenyKotegeltLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="eredmenyKotegeltLathato">
                <div class=" pb-3 pt-3">
                  <span class="pb-3 pt-3" [innerHTML]="eredmenySzoveg"></span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </form>

      </div>
    </div>
  </div>
</ng-template>


