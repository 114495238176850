import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { CommonValidator, HibaService, UtilityService, GranitFileUploadComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import * as moment from 'moment';
import { FlottaService } from '../../../../services/flotta.service';

@Component({
  selector: 'uj-flotta-casco-ajanlatkeres',
  templateUrl: './uj-flotta-casco-ajanlatkeres.component.html',
  styleUrls: ['./uj-flotta-casco-ajanlatkeres.component.scss']
})
export class UjFlottaCascoAjanlatkeresComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private felhasznaloService: FelhasznaloService,
    private flottaService: FlottaService,
    private utilityService: UtilityService,
    public hibaService: HibaService) { }


  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  adatok: UjFlottaFejlecAdatok = new UjFlottaFejlecAdatok('CASCO');
  //formAllapotEnum = FormAllapotEnum.AlkuszAdatokMegadasa;
  alkuszAdataiFormGroup: FormGroup;
  alkuszElerhetosegeiFormGroup: FormGroup;
  szerzodoKereseseFormGroup: FormGroup;
  szerzodoAdataiFormGroup: FormGroup;
  alkusziMegbizasFeltoltesFormGroup: FormGroup;
  dijfizetesiAdatokMegadasaFormGroup: FormGroup;
  szerzodoElerhetosegeinekMegadasaFormGroup: FormGroup;
  excelFajlFeltolteseFormGroup: FormGroup;
  szerzodoKereseseToltoKepernyo: boolean = false;

  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('fileUploadExcel', { static: false }) fileUploadExcel: GranitFileUploadComponent ;

  ngOnInit(): void {
    this.alkuszAdataiFormGroupLetrehozasa();
    this.alkuszElerhetosegeiFormGroupLetrehozasa();
    this.szerzodoKereseseFormGroupLetrehozasa();
    this.szerzodoAdataiFormGroupLetrehozasa();
    this.alkusziMegbizasFeltoltesFormGroupLetrehozasa();
    this.dijfizetesiAdatokMegadasaFormGroupLetrehozasa();
    this.szerzodoElerhetosegeinekMegadasaFormGroupLetrehozasa();
    this.excelFajlFeltolteseFormGroupLetrehozasa();
  }

  // Alkusz adatai formgroup létrehozása
  alkuszAdataiFormGroupLetrehozasa() {
    this.alkuszAdataiFormGroup = this.formBuilder.group({
      f_alkusz_neve_kodja: new FormControl(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev + ' - ' + this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam),
      f_alkusz_regisztralt_email: new FormControl(this.aktualisFelhasznalo.f_email)
    });
  }

  // Alkusz elérhetőségei formgroup létrehozása
  alkuszElerhetosegeiFormGroupLetrehozasa() {
    this.alkuszElerhetosegeiFormGroup = this.formBuilder.group({
      f_regisztralt_email_hasznalata: new FormControl('I'),
      f_alkusz_email: new FormControl(null),
      f_alkusz_email_ismet: new FormControl(null),
      f_alkusz_telefon: new FormControl(null),
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_alkusz_email', 'f_alkusz_email_ismet')]
      }
    );

    this.alkuszElerhetosegeiFormGroup.controls['f_regisztralt_email_hasznalata'].valueChanges.subscribe(
      value => {
        if (value == 'N') {
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].setValidators([Validators.required, Validators.email]);
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email_ismet'].setValidators([Validators.required, Validators.email]);
        }
        else {
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].reset();
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email_ismet'].reset();
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].setValidators([Validators.email]);
          this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email_ismet'].setValidators([Validators.email]);
        }

        this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].updateValueAndValidity();
        this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email_ismet'].updateValueAndValidity();
      }
    );
  }

  // Szerződő keresése formgroup létrehozása
  szerzodoKereseseFormGroupLetrehozasa() {
    this.szerzodoKereseseFormGroup = this.formBuilder.group({
      f_szerzodo_adoszam: new FormControl(null, [Validators.required])
    });
  }

  // Szerződő adatai formgroup létrehozása
  szerzodoAdataiFormGroupLetrehozasa() {
    this.szerzodoAdataiFormGroup = this.formBuilder.group({
      f_szerzodo_nev: new FormControl(null),
      f_szerzodo_cime: new FormControl(null),
      f_cegjegyzekszam: new FormControl(null),
      f_teaor: new FormControl(null)
    });
  }

  // Alkuszi megbízás feltöltése formgroup létrehozása
  alkusziMegbizasFeltoltesFormGroupLetrehozasa() {
    this.alkusziMegbizasFeltoltesFormGroup = this.formBuilder.group({
      f_fajl: new FormControl(null, [Validators.required]),
    });
  }

  // Díjfizetési adatok megadása formgroup létrehozása
  dijfizetesiAdatokMegadasaFormGroupLetrehozasa() {
    this.dijfizetesiAdatokMegadasaFormGroup = this.formBuilder.group({
      f_dijfizmod: new FormControl('B'),
      f_dijfizgyak: new FormControl('N'),
      f_szamlaszam: new FormControl(null)
    });
  }

  // Szerződő elérhetőségeinek megadása formgroup létrehozása
  szerzodoElerhetosegeinekMegadasaFormGroupLetrehozasa() {
    this.szerzodoElerhetosegeinekMegadasaFormGroup = this.formBuilder.group({
      f_eltero_levcim: new FormControl('N'),
      f_lev_irsz: new FormControl(null),
      f_lev_telepules: new FormControl(null),
      f_lev_kt_nev: new FormControl(null),
      f_lev_kt_tipus: new FormControl(null),
      f_lev_hrsz: new FormControl(null),
      f_lev_hazszam: new FormControl(null),
      f_lev_hazszam2: new FormControl(null),
      f_lev_epulet: new FormControl(null),
      f_lev_lepcsohaz: new FormControl(null),
      f_lev_emelet: new FormControl(null),
      f_lev_ajto: new FormControl(null),
      f_email_ekom: new FormControl('I'),
      f_email: new FormControl(null, [Validators.required, Validators.email]),
      f_email_ismet: new FormControl(null, [Validators.required, Validators.email])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_email', 'f_email_ismet')]
      }
    );

    this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_eltero_levcim'].valueChanges.subscribe(
      value => {
        if (value == 'I') {
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].setValidators([Validators.required]);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].setValidators([Validators.required]);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].setValidators([Validators.required]);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].setValidators([Validators.required]);
        }
        else {

          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].reset();
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].reset();
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].reset();
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].reset();

          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].setValidators(null);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].setValidators(null);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].setValidators(null);
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].setValidators(null);
        }
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].updateValueAndValidity();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].updateValueAndValidity();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].updateValueAndValidity();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].updateValueAndValidity();
      }
    );

    this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].valueChanges.subscribe(value => {
      if (value && (value as string).length == 4) {
        this.telepulesLekerdezese();
      }
      else {
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_hazszam'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_hazszam2'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_epulet'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_lepcsohaz'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_emelet'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_ajto'].reset();
        this.telepulesLista = [];
        this.utcanevLista = [];
        this.uttipusLista = [];
      }
    });

    // Település módosulás esetén lekérdezzük az utcaneveket
    this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].valueChanges.subscribe(value => {
      if (value) {
        this.utcanevekLekerdezese();
      }
    });
    // Utcanév módosulás esetén lekérdezzük az úttípusokat
    this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].valueChanges.subscribe(value => {
      if (value) {
        this.uttipusokLekerdezese();
      }
    });

    this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ekom'].valueChanges.subscribe(value => {
      if (value == 'I') {
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email'].setValidators([Validators.required, Validators.email]);
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ismet'].setValidators([Validators.required, Validators.email]);
      }
      else {
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ismet'].reset();
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email'].setValidators([Validators.email]);
        this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ismet'].setValidators([Validators.email]);
      }

      this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email'].updateValueAndValidity();
      this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ismet'].updateValueAndValidity();
    });

  }

  // Excel fájl feltöltése formgroup létrehozása
  excelFajlFeltolteseFormGroupLetrehozasa() {
    this.excelFajlFeltolteseFormGroup = this.formBuilder.group({
      f_fajl: new FormControl(null, [Validators.required]),
    });
  }

  // Alkusz adatai tovább gomb esemény
  onAlkuszElerhetosegeiClick(stepper: MatStepper) {

    // Adatok feltöltése az alkusz adatai form alapján
    this.adatok.f_regisztralt_email_hasznalata = this.alkuszElerhetosegeiFormGroup.controls['f_regisztralt_email_hasznalata'].value;
    if (this.adatok.f_regisztralt_email_hasznalata == 'I') {
      // Ha regisztrált email címet használ
      this.adatok.f_alkusz_email = this.aktualisFelhasznalo.f_email;
      this.adatok.f_alkusz_email2 = this.aktualisFelhasznalo.f_email;
    }
    else {
      // Ha nem, akkor a formon megadott email címet vesszük
      this.adatok.f_alkusz_email = this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].value;
      this.adatok.f_alkusz_email2 = this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_email'].value;
    }
    this.adatok.f_alkusz_telefon = this.alkuszElerhetosegeiFormGroup.controls['f_alkusz_telefon'].value;

    // Tovább léptetjük a steppert a következő formra
    stepper.selected.completed = true;
    stepper.next();
  }

  // Szerződő keresése form gomb esemény
  onSzerzodoKeresesClick(stepper: MatStepper, uj_kereses: boolean) {

    this.szerzodoKereseseToltoKepernyo = true;

    // Feltöltjük az ügyfél adószámot
    this.adatok.f_ugyfel_adatok = new UjFlottaUgyfelAdatok();
    this.adatok.f_ugyfel_adatok.f_adoszam = this.szerzodoKereseseFormGroup.controls['f_szerzodo_adoszam'].value;

    // Lekérdezzük a további ügyfél adatokat az adószám alapján

    let model = {
      f_adoszam: this.szerzodoKereseseFormGroup.controls['f_szerzodo_adoszam'].value,
      uj_kereses: uj_kereses
    }

    this.flottaService.cascoSzerzodoKeresesEllenorzessel(model).subscribe(
      data => {

        if (data["marSzerzodo"] == "I") {

          this.utilityService.dialogMegnyitasa({
            info: true,
            title: 'Adószám keresése',
            message: "Tisztelt Felhasználó! A Szerződőnek már van élő casco flottaszerződése, kérjük az ajánlatkérést a flotta casco szaporításban újra elindítani!",
            confirmButtonText: 'Rendben'
          });
        }

        if (data["marSzerzodo"] == "F") {
          this.utilityService.dialogMegnyitasa({
            info: true,
            title: 'Adószám keresése',
            message: "Tisztelt Felhasználó! A Szerződő rendelkezett, illetve rendelkezik flottacasco szerződéssel Társaságunknál, amely a biztosító által felmondásra került, illetve arra felmondás van előjegyezve. Egyeztetés céljából kérjük vegye fel a kapcsolatot az alkusztámogatójával!",
            confirmButtonText: 'Rendben'
          });
        }

        if (data["marSzerzodo"] == "N") {
          if (data["adatok"]) {

            // További ügyfél adatok feltöltése
            this.szerzodoAdataiFormGroup.controls['f_szerzodo_nev'].setValue(data["adatok"].f_cegnev);
            this.szerzodoAdataiFormGroup.controls['f_szerzodo_cime'].setValue(data["adatok"].f_telephely);
            this.szerzodoAdataiFormGroup.controls['f_cegjegyzekszam'].setValue(data["adatok"].f_cegjegyzekszam);
            this.szerzodoAdataiFormGroup.controls['f_teaor'].setValue(data["adatok"].f_teaor);
            this.adatok.f_ugyfel_adatok = data["adatok"];

            if (!uj_kereses) {
              // Tovább léptetjük a steppert a következő formra
              stepper.selected.completed = true;
              stepper.next();
            }
          }
          else {
            this.utilityService.dialogMegnyitasa({
              info: true,
              title: 'Adószám keresése',
              message: 'Az Ön által megadott adószámhoz tartozó céget nem találtunk. Kérjük ellenőrizze, hogy helyesen adta-e meg az adószámot.',
              confirmButtonText: 'Rendben'
            });
          }
        }
        this.szerzodoKereseseToltoKepernyo = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Adószám keresése',
          message: 'Adatbázisunk jelenleg egy technikai hiba miatt nem érhető el. Kérjük térjen vissza később!',
          confirmButtonText: 'Rendben'
        });
        this.szerzodoKereseseToltoKepernyo = false;
      }
    );
  }

  // Szerződő adatai form új keresés gomb esemény
  onSzerzodoAdataiUjKeresesClick() {

    this.alkuszAdataiFormGroupLetrehozasa();
    this.alkuszElerhetosegeiFormGroupLetrehozasa();
    this.szerzodoKereseseFormGroupLetrehozasa();
    this.szerzodoAdataiFormGroupLetrehozasa();


    this.stepper.reset();
    this.stepper.steps.toArray()[0].completed = true;
    this.stepper.selectedIndex = 1;

  }

  // Szerződő adatai form tovább gomb esemény
  onSzerzodoAdataiTovabbClick(stepper: MatStepper) {

    // Átadjuk a controllernek az aktuális felhasználót és a form adatokat
    let model = {
      adatok: this.adatok
    };

    // Meghatározzuk, hogy szükséges-e alkuszi megbízás feltöltése
    this.flottaService.alkusziFeltoltesSzukseges(model).subscribe(
      data => {
        // Feltöltjük az alkuszi megbízás szükséges propertyt
        this.adatok.f_alkuszi_szukseges = data ? 'I' : 'N'

        // Ha kell alkuszi megbízás feltöltés, akkor csak simán tovább léptetjük a következkező stepre
        if (this.adatok.f_alkuszi_szukseges == 'I') {
          stepper.selected.completed = true;
          stepper.next();
        }
        else {
          // Ha nem, akkor kimarad az alkuszi megbízás feltöltése lépés és sikeres mentés esetén tovább lépünk az ügytípus választásra
          let model = {
            adatok: this.adatok
          }
          this.flottaService.alkusziMegbizasFeldolgozas(model).subscribe(
            data => {
              this.adatok.f_ugyszam = data;
              stepper.selected.completed = true;
              stepper.next();
            }
          );
        }
      }
    );
  }

  // Alkuszi megbízás feltöltése gomb esemény
  onAlkusziFeltoltesClick(stepper: MatStepper) {

    // Átadjuk a controllernek az aktuális felhasználót és a form adatokat és az alkuszi megbízás feltöltés formgroup value-t
    let model = {
      adatok: this.adatok,
      alkusziMegbizasFeltoltesFormGroupValue: this.alkusziMegbizasFeltoltesFormGroup.value
    }

    this.flottaService.alkusziMegbizasFeldolgozas(model).subscribe(
      data => {
        this.adatok.f_ugyszam = data;
        const dialogRef = this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres feltöltés',
          message: 'A feltöltött alkuszi megbízást a rendszerünk fogadta. Feldolgozását kollégáink 1 munkanapon belül megkezdik.',
          confirmButtonText: 'Rendben'
        });
        // Csak a dialog ablak bezárása után lépünk a következő stepp-re
        dialogRef.afterClosed().subscribe(
          () => {
            stepper.selected.completed = true;
            stepper.next();
          }
        );
      }
    );
  }

  // Díjfizetesi adatok megadása form tovább gomb esemény
  onDijfizetesiAdatokMegadasaTovabbClick(stepper: MatStepper) {
    this.adatok.f_dijfizmod = this.dijfizetesiAdatokMegadasaFormGroup.controls['f_dijfizmod'].value;
    this.adatok.f_dijfizgyak = this.dijfizetesiAdatokMegadasaFormGroup.controls['f_dijfizgyak'].value;
    this.adatok.f_szamlaszam = this.dijfizetesiAdatokMegadasaFormGroup.controls['f_szamlaszam'].value;
    stepper.selected.completed = true;
    stepper.next();
  }

  // Szerződő elérhetőségeinek megadása form tovább gomb esemény
  onSzerzodoElerhetosegeinekMegadasaTovabbClick(stepper: MatStepper) {

    this.adatok.f_eltero_levcim = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_eltero_levcim'].value;

    // Eltérő levelezési cím esetén mentjük a formon megadott levelezési cím adatokat
    if (this.adatok.f_eltero_levcim == 'I') {
      this.adatok.f_lev_irsz = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].value;
      this.adatok.f_lev_telepules = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].value;
      this.adatok.f_lev_kt_nev = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].value;
      this.adatok.f_lev_kt_tipus = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].value;
      this.adatok.f_lev_hrsz = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_hrsz'].value;
      this.adatok.f_lev_hazszam1 = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_hazszam'].value;
      this.adatok.f_lev_hazszam2 = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_hazszam2'].value;
      this.adatok.f_lev_epulet = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_epulet'].value;
      this.adatok.f_lev_lepcsohaz = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_lepcsohaz'].value;
      this.adatok.f_lev_emelet = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_emelet'].value;
      this.adatok.f_lev_ajto = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_ajto'].value;
    }
    this.adatok.f_email_ekom = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ekom'].value;
    // Ha be van állítva elektromos kommunikáció
    if (this.adatok.f_email_ekom == 'I') {
      this.adatok.f_email = this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email'].value;
    }
    stepper.selected.completed = true;
    stepper.next();
  }

  // Excel fájl feltöltése
  onExcelFajlFeltolteseTovabbClick(stepper: MatStepper) {

    // Átadjuk a controllernek az aktuális felhasználót és a form adatokat és az alkuszi megbízás feltöltés formgroup value-t
    let model = {
      adatok: this.adatok,
      excelFajlFeltolteseFormGroupValue: this.excelFajlFeltolteseFormGroup.value
    }

    this.flottaService.ujFlottaAjanlatVagyDijkalkulaciokeres(model).subscribe(
      data => {
        // Ha van hiba, akkor kiírjuk és nem lépünk tovább
        if (data.hibak && data.hibak.length > 0) {
          let hibaString = '<div style="\overflow: auto; max-height: 300px;"\>';
          for (var i = 0; i < data.hibak.length; i++) {
            hibaString += '<p>' + data.hibak[i] + '</p>';
          }
          hibaString += '</div>'
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Excel feldolgozás',
            message: hibaString,
            confirmButtonText: 'Rendben'
          });
          this.fileUploadExcel.clearInputElement();
          this.fileUploadExcel.clearFilesList();
          this.excelFajlFeltolteseFormGroupLetrehozasa();
        }
        else {
          // Ha nincs hiba, tovább lépünk az eredmény lépésre
          stepper.selected.completed = true;
          stepper.next();
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az excel feldolgozása közben",
          confirmButtonText: 'Rendben'
        });
        this.fileUploadExcel.clearInputElement();
        this.fileUploadExcel.clearFilesList();
        this.excelFajlFeltolteseFormGroupLetrehozasa();
      }
    );
  }

  // Település lekérdezése irányítószám alapján
  telepulesLekerdezese() {
    this.utilityService.telepulesek(this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].value).subscribe(
      data => {
        this.telepulesLista = data;
        // Ha egy darab település van, akkor automatikusan kiválasztjuk
        if (this.telepulesLista.length == 1) {
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].setValue(this.telepulesLista[0].f_helynev);
        }
      }
    );
  }

  // Utca nevek lekérdezése irányítószám és település alapján
  utcanevekLekerdezese() {
    this.utilityService.utcanevek(this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].value, this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].value).subscribe(
      data => {
        this.utcanevLista = data;
      }
    );
  }

  // Utca nevek lekérdezése irányítószám, település és utcanév alapján
  uttipusokLekerdezese() {
    this.utilityService.uttipusok(this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_irsz'].value, this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_telepules'].value, this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_nev'].value).subscribe(
      data => {
        this.uttipusLista = data;
        // Ha egy darab úttípus van, akkor automatikusan kiválasztjuk
        if (this.uttipusLista.length == 1) {
          this.szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_lev_kt_tipus'].setValue(this.uttipusLista[0].f_uttipus);
        }
      }
    );
  }

  // Sablon letöltése
  onSablonLetoltes() {
    window.location.href = 'assets/documents/casco_flotta_szaporito.xlsx';
  }

  // FileUpload kontrol (changeClick) output eseménye, lekezeljük, ha van hiba
  onFajlHozzaadas(hibaLista: string[], kontrolId: string) {
    // Ha a fájl hozzáadása közben történt hiba
    if (hibaLista && hibaLista.length > 0) {
      if (kontrolId == 'fileUploadAlkuszi') {
        this.alkusziMegbizasFeltoltesFormGroup.controls['f_fajl'].reset();
      }
      else if (kontrolId == 'fileUploadExcel') {
        this.excelFajlFeltolteseFormGroup.controls['f_fajl'].reset();
      }
    }
  }
}

export class UjFlottaFejlecAdatok {
  constructor(modozat: string) {
    this.f_modozat = modozat;
  }
  f_regisztralt_email_hasznalata: string; // regisztrációs emailcím használata I/N
  f_alkusz_email: string;
  f_alkusz_email2: string;
  f_alkusz_telefon: string;
  f_alkuszi_szukseges: string; // kell e alkuszit feltöltenie az alkusznak, vagy sem I/N
  f_ugyszam: number;
  f_ugytipus: string = 'A';
  f_nyilatkozott_darabszam: number;
  f_dijfizmod: string;
  f_dijfizgyak: string;
  f_szamlaszam: string;
  f_eltero_levcim: string;
  f_lev_irsz: string;
  f_lev_telepules: string;
  f_lev_kt_nev: string;
  f_lev_kt_tipus: string;
  f_lev_hrsz: string;
  f_lev_hazszam1: string;
  f_lev_hazszam2: string;
  f_lev_epulet: string;
  f_lev_lepcsohaz: string;
  f_lev_emelet: string;
  f_lev_ajto: string;
  f_email_ekom: string;
  f_email: string;
  f_kockezd: string;
  f_ugyfel_adatok: UjFlottaUgyfelAdatok;
  f_modozat: string;
}


export class UjFlottaUgyfelAdatok {
  f_adoszam: string;
  f_cegnev: string;
  f_tipus: string;
  f_cegjegyzekszam: string;
  f_teaor: string;
  f_telephely_irsz: string;
  f_telephely_telepules: string;
  f_telephely_kt_nev: string;
  f_telephely_kt_tipus: string;
  f_telephely_hrsz: string;
  f_telephely_hazszam1: string;
  f_telephely_hazszam2: string;
  f_telephely_epulet: string;
  f_telephely_lepcsohaz: string;
  f_telephely_emelet: string;
  f_telephely_ajto: string;
  f_cegalapitas: any;
}

//enum FormAllapotEnum {
//  AlkuszAdatokMegadasa,
//  SzerzodoAdoszamMegadasa,
//  SzerzodoKeresesEredmeny,
//  AlkusziMegbizasFeltoltes,
//  SzolgaltatasValasztas,
//  ExcelFeltoltes,
//  Vege
//}



// Adatok feltöltése
  //adatokFeltoltese(data? : any) {

  //  // Ha alkusz adatok megadása form
  //  if (this.formAllapotEnum == FormAllapotEnum.AlkuszAdatokMegadasa) {
  //    this.adatok.f_regisztralt_email_hasznalata = this.alkuszAdataiFormGroup.controls['f_regisztralt_email_hasznalata'].value;
  //    if (this.adatok.f_regisztralt_email_hasznalata == 'I') {
  //      // Ha regisztrált email címet használ
  //      this.adatok.f_alkusz_email = this.aktualisFelhasznalo.f_email;
  //      this.adatok.f_alkusz_email2 = this.aktualisFelhasznalo.f_email;
  //    }
  //    else {
  //      // Ha nem, akkor a formon megadott email címet vesszük
  //      this.adatok.f_alkusz_email = this.alkuszAdataiFormGroup.controls['f_alkusz_email'].value;
  //      this.adatok.f_alkusz_email2 = this.alkuszAdataiFormGroup.controls['f_alkusz_email'].value;
  //    }
  //    this.adatok.f_alkusz_telefon = this.alkuszAdataiFormGroup.controls['f_alkusz_telefon'].value;
  //  }

  //  // Szerződő adószám megadása
  //  if (this.formAllapotEnum == FormAllapotEnum.SzerzodoAdoszamMegadasa) {
  //    this.adatok.f_ugyfel_adatok = new UjFlottaUgyfelAdatok();
  //    this.adatok.f_ugyfel_adatok.f_adoszam = this.szerzodoKereseseFormGroup.controls['f_szerzodo_adoszam'].value;
  //  }

  //  // Ügyfél adatok feltöltése
  //  if (this.formAllapotEnum == FormAllapotEnum.SzerzodoKeresesEredmeny) {
  //    this.szerzodoAdataiFormGroup.controls['f_szerzodo_nev'].setValue(data.f_cegnev);      
  //    this.szerzodoAdataiFormGroup.controls['f_szerzodo_cime'].setValue(data.f_telephely);
  //    this.szerzodoAdataiFormGroup.controls['f_cegjegyzekszam'].setValue(data.f_cegjegyzekszam);
  //    this.szerzodoAdataiFormGroup.controls['f_teaor'].setValue(data.f_teaor);
  //    this.adatok.f_ugyfel_adatok = data;
  //  }
  //}
