<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Új flotta casco ajánlatkérés'"
                                [subTitle]="''"
                                [templateOutlet]="ujFlottaCascoAjanlatkeres">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #ujFlottaCascoAjanlatkeres>
  <mat-card>
    <mat-card-content class="mb-0">

      <mat-vertical-stepper linear #stepper disableRipple="true" selectedIndex="1">

        <!-- Alkusz adatai - ez a step mindig nyitva van -->
        <mat-step completed="true" editable="false">
          <ng-template matStepLabel>Alkusz adatai</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="information-wrapper mb-4">
                Kérjük, hogy a helyes működéshez a legújabb excel sablont használja.<br /><br />                
                <button mat-raised-button type="button" color="primary" (click)="onSablonLetoltes()"><mat-icon class="mr-1">download</mat-icon>Új flotta casco ajánlat sablon letöltése</button>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="alkuszAdataiFormGroup" class="form-group mt-0">
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Alkusz neve és kódja</mat-label>
                      <input matInput formControlName="f_alkusz_neve_kodja" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Alkusz regisztrált email címe</mat-label>
                      <input matInput formControlName="f_alkusz_regisztralt_email" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Alkusz elérhetőségei -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Alkusz elérhetőségei</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="information-wrapper mb-4">
                Kérjük, itt adja meg azt az e-mail címet, amelyre a szaporítással kapcsolatos értesítéseket küldhetjük, illetve amelyen keresztül probléma esetén elérhetjük.<br /><br />
                Kérjük, hogy olyan telefonszámot adjon meg, amelyen az adott flottával kapcsolatos esetleges kérdések, problémák esetén kollégáink elérhetik Önt. Megadása nem kötelező.
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="alkuszElerhetosegeiFormGroup" class="form-group mt-0">
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>A regisztrációs email cím használata kommunikációra</mat-label>
                      <mat-select formControlName="f_regisztralt_email_hasznalata">
                        <mat-option value="I">Igen</mat-option>
                        <mat-option value="N">Nem</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--Az email cím és megerősítése csak akkor látszik, ha nem a regisztrációs email címet használja kommunikációra-->
                <ng-container *ngIf="alkuszElerhetosegeiFormGroup.controls['f_regisztralt_email_hasznalata'].value == 'N'">
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-form-field appearance="fill">
                        <mat-label>Email cím</mat-label>
                        <input matInput formControlName="f_alkusz_email" required>
                        <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegeiFormGroup, 'f_alkusz_email', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az e-mail címet!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegeiFormGroup, 'f_alkusz_email', 'email')">
                          <mat-icon>error</mat-icon>
                          A megadott e-mail cím nem megfelelő!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-form-field appearance="fill">
                        <mat-label>Email cím megerősítése</mat-label>
                        <input matInput formControlName="f_alkusz_email_ismet" required>
                        <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegeiFormGroup, 'f_alkusz_email_ismet', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az e-mail címét ismét!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegeiFormGroup, 'f_alkusz_email_ismet', 'email')">
                          <mat-icon>error</mat-icon>
                          A megadott e-mail cím nem megfelelő!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegeiFormGroup, 'f_alkusz_email_ismet', 'nemEgyezik')">
                          <mat-icon>error</mat-icon>
                          Kérjük, helyesen ismételje meg az e-mail címét!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Telefon</mat-label>
                      <input matInput formControlName="f_alkusz_telefon">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="!alkuszElerhetosegeiFormGroup.valid" color="accent" (click)="onAlkuszElerhetosegeiClick(stepper)">Tovább</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Szerződő keresése adószám alapján -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Szerződő keresése</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="szerzodoKereseseFormGroup" class="form-group mt-0">
                <ng-container *ngIf="szerzodoKereseseToltoKepernyo">
                  <div class="row">
                    <div class="col-lg-12 bg-white">
                      <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!szerzodoKereseseToltoKepernyo">
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-form-field appearance="fill">
                        <mat-label>Adószám</mat-label>
                        <input matInput formControlName="f_szerzodo_adoszam" required>
                        <mat-error *ngIf="hibaService.hiba(szerzodoKereseseFormGroup, 'f_szerzodo_adoszam', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az adószámot!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="(szerzodoKereseseFormGroup && !szerzodoKereseseFormGroup.valid) || szerzodoKereseseToltoKepernyo" color="accent" (click)="onSzerzodoKeresesClick(stepper, false)">Keresés</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Szerződő adatainak kiírása -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Szerződő adatai</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="szerzodoAdataiFormGroup" class="form-group mt-0">
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő neve</mat-label>
                      <input matInput formControlName="f_szerzodo_nev" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő címe</mat-label>
                      <input matInput formControlName="f_szerzodo_cime" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Cégjegyzékszám</mat-label>
                      <input matInput formControlName="f_cegjegyzekszam" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Főtevékenység TEÁOR kód</mat-label>
                      <input matInput formControlName="f_teaor" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" color="accent" class="mr-5" (click)="onSzerzodoKeresesClick(stepper, true)">Szerződő adatainak frissítése</button>
                  </div>                  
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    Figyelem! A szerződő adatait a Biztosító tölti fel adatbázisából. Amennyiben az ügyfél által megadott szerződő adataiban és a feltöltés adataiban eltérés van, pl. adószám, székhely címe stb., akkor kérünk, hogy nyomj rá a „Szerződő adatainak frissítésé” gombra!
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" color="accent" class="mr-5" (click)="onSzerzodoAdataiUjKeresesClick()">Új keresés</button>
                    <button mat-raised-button type="button" [disabled]="szerzodoKereseseFormGroup && !szerzodoKereseseFormGroup.valid" color="accent" (click)="onSzerzodoAdataiTovabbClick(stepper)">Tovább</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Alkuszi megbízás feltöltése -->
        <mat-step *ngIf="adatok && (!adatok.f_alkuszi_szukseges || adatok.f_alkuszi_szukseges == 'I')" completed="false" editable="false">
          <ng-template matStepLabel>Alkuszi megbízás feltöltése</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="information-wrapper mb-4">
                Kérjük töltse fel alkuszi megbízását, hogy a kért ajánlatot el tudjuk készíteni. Minden esetben törekedjen a legfrissebb aláírt megbízás feltöltésére.<br />
                Engedélyezett fájl kiterjesztések: .pdf, .jpg, .jpeg, .png
                <br /><br />
                Amennyiben a feltöltés megtörtént, a "Tovább" gombra kattintva tudja elkészíteni ajánlatkérését.
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="alkusziMegbizasFeltoltesFormGroup" class="form-group mt-0">
                <granit-file-upload #fileUploadAlkuszi ngDefaultControl
                                     [formGroup]="alkusziMegbizasFeltoltesFormGroup"
                                     [formControlName]="'f_fajl'"
                                     [labelText]="'Kérjük töltse fel aláírt alkuszi megbízását!'"
                                     [buttonText]="'Kiválasztás'"
                                     (changeClick)="onFajlHozzaadas($event, 'fileUploadAlkuszi')"
                                     [acceptedFileExtensions]="['.pdf', '.jpg', '.jpeg', '.png']">
                </granit-file-upload>
                <div class="row pt-2">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="!alkusziMegbizasFeltoltesFormGroup.valid" color="accent" (click)="onAlkusziFeltoltesClick(stepper)">Tovább</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>        

        <!-- Díjfizetési adatok megadása-->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Díjfizetési adatok megadása</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="dijfizetesiAdatokMegadasaFormGroup" class="form-group mt-0">
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Díjfizetés módja</mat-label>
                      <mat-select formControlName="f_dijfizmod">
                        <mat-option value="B">Banki átutalás</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Díjfizetés gyakorisága</mat-label>
                      <mat-select formControlName="f_dijfizgyak">
                        <mat-option value="E">Éves</mat-option>
                        <mat-option value="F">Féléves</mat-option>
                        <mat-option value="N">Negyedéves</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Számlaszám</mat-label>
                      <input matInput formControlName="f_szamlaszam">
                      <mat-error *ngIf="hibaService.hiba(dijfizetesiAdatokMegadasaFormGroup, 'f_szamlaszam', 'required')">
                        <mat-icon>error</mat-icon>
                        Kérjük, adja meg a számlaszámot!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="!dijfizetesiAdatokMegadasaFormGroup.valid" color="accent" (click)="onDijfizetesiAdatokMegadasaTovabbClick(stepper)">Tovább</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Szerződő elérhetőségeinek megadása -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Szerződő elérhetőségének megadása</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="information-wrapper mb-4">
                E-mail cím megadása esetén minden nyomtatványt elektronikus formában fog megkapni az ügyfél az itt megadott címre.
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="szerzodoElerhetosegeinekMegadasaFormGroup" class="form-group mt-0">
                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Eltérő szerződő levelezési cím</mat-label>
                      <mat-select formControlName="f_eltero_levcim">
                        <mat-option value="I">Igen</mat-option>
                        <mat-option value="N">Nem</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!-- Az email cím és megerősítése csak akkor látszik, ha nem a regisztrációs email címet használja kommunikációra  -->
                <ng-container *ngIf="szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_eltero_levcim'].value == 'I'">
                  <div class="row">
                    <div class="col-lg-5">
                      <mat-form-field appearance="fill">
                        <mat-label>Irányítószám</mat-label>
                        <input matInput formControlName="f_lev_irsz" mask="0000" required autocomplete="off">
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_lev_irsz', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az irányítószámot!
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-7">
                      <mat-form-field appearance="fill">
                        <mat-label>Település</mat-label>
                        <mat-select formControlName="f_lev_telepules" required>
                          <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_lev_telepules', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg a települést!
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-7">
                      <mat-form-field appearance="fill">
                        <mat-label>Utcanév</mat-label>
                        <mat-select formControlName="f_lev_kt_nev" required>
                          <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_lev_kt_nev', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az utcanevet!
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-5">
                      <mat-form-field appearance="fill">
                        <mat-label>Úttípus</mat-label>
                        <mat-select formControlName="f_lev_kt_tipus" required>
                          <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_lev_kt_tipus', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az úttípust!
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Helyrajzi szám</mat-label>
                        <input matInput formControlName="f_lev_hrsz" autocomplete="off">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Házszám</mat-label>
                        <input matInput formControlName="f_lev_hazszam" autocomplete="off">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Házszám 2</mat-label>
                        <input matInput formControlName="f_lev_hazszam2" autocomplete="off">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Épület</mat-label>
                        <input matInput formControlName="f_lev_epulet" autocomplete="off">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Lépcsőház</mat-label>
                        <input matInput formControlName="f_lev_lepcsohaz" autocomplete="off">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Emelet</mat-label>
                        <mat-select formControlName="f_lev_emelet">
                          <mat-option value="FSZ">Földszint</mat-option>
                          <mat-option value="MFSZ">Magasföldszint</mat-option>
                          <mat-option value="FÉLEM">Félemelet</mat-option>
                          <mat-option value="TTÉR">Tetőtér</mat-option>
                          <mat-option value="SZUT">Szuterén</mat-option>
                          <mat-option value="GRZS">Garázs</mat-option>
                          <mat-option value="GARSOR">Garázssor</mat-option>
                          <mat-option value="ALGSOR">Alagsor</mat-option>
                          <mat-option value="ÜZLSOR">Üzletsor</mat-option>
                          <mat-option value="1. EM">1. Emelet</mat-option>
                          <mat-option value="2. EM">2. Emelet</mat-option>
                          <mat-option value="3. EM">3. Emelet</mat-option>
                          <mat-option value="4. EM">4. Emelet</mat-option>
                          <mat-option value="5. EM">5. Emelet</mat-option>
                          <mat-option value="6. EM">6. Emelet</mat-option>
                          <mat-option value="7. EM">7. Emelet</mat-option>
                          <mat-option value="8. EM">8. Emelet</mat-option>
                          <mat-option value="9. EM">9. Emelet</mat-option>
                          <mat-option value="10. EM">10. Emelet</mat-option>
                          <mat-option value="11. EM">11. Emelet</mat-option>
                          <mat-option value="12. EM">12. Emelet</mat-option>
                          <mat-option value="13. EM">13. Emelet</mat-option>
                          <mat-option value="14. EM">14. Emelet</mat-option>
                          <mat-option value="15. EM">15. Emelet</mat-option>
                          <mat-option value="16. EM">16. Emelet</mat-option>
                          <mat-option value="17. EM">17. Emelet</mat-option>
                          <mat-option value="18. EM">18. Emelet</mat-option>
                          <mat-option value="19. EM">19. Emelet</mat-option>
                          <mat-option value="20. EM">20. Emelet</mat-option>
                          <mat-option value="POSTALÁDA">Postaláda</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Ajtó</mat-label>
                        <input matInput formControlName="f_lev_ajto" autocomplete="off">
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>

                <div class="row">
                  <div class="col-lg-12">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő email címének megadása elektronikus kommunikációhoz</mat-label>
                      <mat-select formControlName="f_email_ekom">
                        <mat-option value="I">Igen</mat-option>
                        <mat-option value="N">Nem</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Elektronikus kommunikáció esetén email cím megadása -->
                <ng-container *ngIf="szerzodoElerhetosegeinekMegadasaFormGroup.controls['f_email_ekom'].value == 'I'">
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-form-field appearance="fill">
                        <mat-label>E-mail cím</mat-label>
                        <input matInput formControlName="f_email">
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_email', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az e-mail címet!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_email', 'email')">
                          <mat-icon>error</mat-icon>
                          A megadott e-mail cím nem megfelelő!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <mat-form-field appearance="fill">
                        <mat-label>E-mail cím ismét</mat-label>
                        <input matInput formControlName="f_email_ismet">
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_email_ismet', 'required')">
                          <mat-icon>error</mat-icon>
                          Kérjük, adja meg az e-mail címét ismét!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_email_ismet', 'email')">
                          <mat-icon>error</mat-icon>
                          A megadott e-mail cím nem megfelelő!
                        </mat-error>
                        <mat-error *ngIf="hibaService.hiba(szerzodoElerhetosegeinekMegadasaFormGroup, 'f_email_ismet', 'nemEgyezik')">
                          <mat-icon>error</mat-icon>
                          Kérjük, helyesen ismételje meg az e-mail címét!
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>


                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="!szerzodoElerhetosegeinekMegadasaFormGroup.valid" color="accent" (click)="onSzerzodoElerhetosegeinekMegadasaTovabbClick(stepper)">Tovább</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </mat-step>

        <!-- Excel fájl feltöltés -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Excel fájl feltöltése</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <form [formGroup]="excelFajlFeltolteseFormGroup" class="form-group mt-0">
                <granit-file-upload #fileUploadExcel ngDefaultControl
                                     [formGroup]="excelFajlFeltolteseFormGroup"
                                     [formControlName]="'f_fajl'"
                                     [labelText]="'Kérjük töltse fel a kitöltött új flotta casco ajánlat sablont!'"
                                     [buttonText]="'Kiválasztás'"
                                     [acceptedFileExtensions]="['.xlsx']"
                                     (changeClick)="onFajlHozzaadas($event, 'fileUploadExcel')">
                </granit-file-upload>
                <div class="row">
                  <div class="col-lg-12">
                    <button mat-raised-button type="button" [disabled]="!excelFajlFeltolteseFormGroup.valid" color="accent" (click)="onExcelFajlFeltolteseTovabbClick(stepper)">Tovább</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>

        <!-- Eredmény -->
        <mat-step completed="false" editable="false">
          <ng-template matStepLabel>Eredmény</ng-template>
          <div class="row justify-content-end flex-row-reverse">
            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
              <p>Új flotta CASCO ajánlatkérését rendszerünk, fogadta.</p>
              <p>Ügyszám: <b>{{adatok.f_ugyszam}}</b></p>
            </div>
          </div>
        </mat-step>

      </mat-vertical-stepper>

    </mat-card-content>
  </mat-card>
</ng-template>


