<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Flotta szaporítás'"
                                [subTitle]="'Meglévő flotta szaporítása, vagy díjnemfizetéssel megszűnt flotta szerződés újrakötése'"
                                [templateOutlet]="szaporitas">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #szaporitas>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Flottaszaporítás / DNF újrakötés</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Alkusz adatai</mat-card-title>
              </mat-card-header>
              <mat-card-content class="mb-0">
                <div class="row justify-content-end flex-row-reverse">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="information-wrapper mb-4">
                      Kérjük, hogy a helyes működéshez a legújabb excel sablont használja.<br /><br />
                      <a mat-raised-button color="primary" href="assets/documents/flotta_ajanlat.xlsx"><mat-icon>download</mat-icon>Flottaszaporitó Excel sablon letöltése</a>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <form [formGroup]="alkuszFormGroup" class="form-group mt-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Törzsszám</mat-label>
                            <input matInput formControlName="alkuszTorzsszam" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Név</mat-label>
                            <input matInput formControlName="alkuszNev" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>E-mail címe</mat-label>
                            <input matInput formControlName="alkuszEmail" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <!--<div class="col-lg-12">
                <div>
                  <form [formGroup]="alkuszFormGroup">-->
                <!--<div><h4>Alkusz adatai</h4></div>-->
                <!--<div class="row">
                        <div class="col-lg-12 text-right">
                          <a mat-raised-button color="primary" href="assets/documents/flotta_ajanlat.xlsx"><mat-icon>download</mat-icon>Flottaszaporitó Excel sablon letöltése</a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Törzsszám</mat-label>
                            <input matInput formControlName="alkuszTorzsszam" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>Név</mat-label>
                            <input matInput formControlName="alkuszNev" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <mat-form-field appearance="legacy" [style.width.%]="100">
                            <mat-label>E-mail címe</mat-label>
                            <input matInput formControlName="alkuszEmail" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>-->

              </mat-card-content>
              <div *ngIf="szerzodoFormGroup.controls['szerzodoNev'].value">
                <mat-card-header>
                  <mat-card-title>Szerződő adatai</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mb-0">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                        {{szerzodoFormGroup.controls['szerzodoNev'].value}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        {{szerzodoFormGroup.controls['szerzodoCimEgyben'].value}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        {{currentStep}}
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>
              <mat-card-content class="mb-0">
                <div class="col-lg-12">
                  <mat-vertical-stepper linear #stepper disableRipple="true" >
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <!-- step 0-->
                      <mat-step [stepControl]="alkuszElerhetosegFormGroup" editable="false" completed="false">
                        <div>
                          <form [formGroup]="alkuszElerhetosegFormGroup">
                            <ng-template matStepLabel>Alkusz elérhetősége</ng-template>
                            <div class="row">
                              <section>
                                <mat-checkbox formControlName="chkElteroEmail">Eltérő email cím</mat-checkbox>
                              </section>
                            </div>
                            <div *ngIf="alkuszElerhetosegFormGroup.get('chkElteroEmail').value" >
                              <div class="row">
                                <div class="col-lg-6">
                                  <mat-form-field appearance="fill" [style.width.%]="100">
                                    <mat-label>Alkusz e-mail címe</mat-label>
                                    <input matInput placeholder="pl.: minta@valami.hu" formControlName="alkuszElteroEmail" required>
                                    <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmail', 'required')">
                                      <mat-icon>error</mat-icon>
                                      Kérjük, adja meg az e-mail címet!
                                    </mat-error>
                                    <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmail', 'email')">
                                      <mat-icon>error</mat-icon>
                                      A megadott e-mail cím nem megfelelő!
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-6">
                                  <mat-form-field appearance="fill" [style.width.%]="100">
                                    <mat-label>Alkusz e-mail megerősítése</mat-label>
                                    <input matInput placeholder="pl.: minta@valami.hu" formControlName="alkuszElteroEmailIsmet" required onpaste="return false">
                                    <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmailIsmet', 'required')">
                                      <mat-icon>error</mat-icon>
                                      Kérjük, adja meg az e-mail címét ismét!
                                    </mat-error>
                                    <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmailIsmet', 'email')">
                                      <mat-icon>error</mat-icon>
                                      A megadott e-mail cím nem megfelelő!
                                    </mat-error>
                                    <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmailIsmet', 'nemEgyezik')">
                                      <mat-icon>error</mat-icon>
                                      Kérjük, helyesen ismételje meg az e-mail címét!
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill">
                                  <mat-label>Telefonszám</mat-label>
                                  <input matInput placeholder="pl.: 06-1-999-9999" formControlName="alkuszTelefon">
                                </mat-form-field>
                              </div>
                            </div>
                            <div>
                              <button mat-raised-button color="accent" [disabled]="!alkuszElerhetosegFormGroup.valid" matStepperNext>Tovább</button>
                            </div>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 1 -->
                      <mat-step [stepControl]="szerzodoFormGroup" editable="false" completed="false">
                        <div>
                          <form [formGroup]="szerzodoFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Szerződő adatai</ng-template>
                              <div class="row">
                                <div class="col-lg-6">
                                  <mat-form-field appearance="fill" [style.width.%]="100">
                                    <mat-label>Flotta azonosító</mat-label>
                                    <input matInput placeholder="pl.: 123" formControlName="flottaAzonosito" (focusout)="szerzodoAdatokTorles()">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-6">
                                  <mat-form-field appearance="fill" [style.width.%]="100">
                                    <mat-label>Szerződő adószáma</mat-label>
                                    <input matInput placeholder="pl.: 123465678-12-1" formControlName="szerzodoAdoszam" (focusout)="szerzodoAdatokTorles()">
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-6">
                                  <mat-form-field appearance="fill" [style.width.%]="100">
                                    <mat-label>A kötés oka</mat-label>
                                    <mat-select matInput formControlName="kotesOka">
                                      <mat-option value="01">Flotta szaporítás</mat-option>
                                      <mat-option value="06">Díjnemfizetéssel megszűnt szerződés újrakötése</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div *ngIf="szerzodoFormGroup.get('flottaAzonosito').value && szerzodoFormGroup.get('szerzodoAdoszam').value && szerzodoFormGroup.get('kotesOka').value" class="row">
                                <button mat-stroked-button (click)="szerzodoKereses()">Szerződő keresése</button>
                              </div>
                              <div class="row">
                                <div [innerHtml]="szerzodoFormGroup.get('hibaSzoveg').value"></div>
                              </div>
                              <div *ngIf="szerzodoFormGroup.get('szerzodoNev').value">
                                <div>
                                  <div class="row"><h2><br />Keresés eredménye:</h2></div>
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <mat-form-field appearance="fill" [style.width.%]="100">
                                        <mat-label>Szerződő neve</mat-label>
                                        <input matInput placeholder="" formControlName="szerzodoNev" [disabled]="disabled">
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <mat-form-field appearance="fill" [style.width.%]="100">
                                        <mat-label>Szerződő címe</mat-label>
                                        <input matInput placeholder="" formControlName="szerzodoCimEgyben" [disabled]="disabled">
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <button mat-raised-button color="accent" matStepperNext>Tovább</button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 2 -->
                      <mat-step [stepControl]="alkusziFeltoltesFormGroup" editable="false" completed="false" *ngIf="alkusziSzukseges">
                        <div>
                          <form [formGroup]="alkusziFeltoltesFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Alkuszi megbízás feltöltése</ng-template>
                              <div class="row" *ngIf="!folyamatVege">
                                <div>
                                  <p>&nbsp;</p>
                                  <p><strong>Tisztelt Partnerünk,</strong></p>
                                  <p>mivel a szaporítani kívánt flottát más partnerünk kezeli, ezért kérjük, hogy alkuszi megbízását itt a felületen feltölteni szíveskedjék. Amennyiben a feltöltés megtörtént, 1 munkanapon belül jelezni fogjuk, hogy mikor folytathatja a flotta szaporítását. A gyorsabb ügyintézés érdekében a megadott e-mail címen, vagy telefonszámon fogjuk keresni.</p>
                                  <p>Kérjük szíves türelmét!</p>
                                  <p>üdvözlettel: Gránit Biztosító</p>
                                  <p>&nbsp;</p>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                  <div class="mt-4">
                                    <div class="row mt-5">
                                      <div class="col-lg-1">
                                        <mat-icon class="ml-3 info-icon" [matTooltip]="'Biztonsági okok miatt, csak jpg, jpeg, pdf kiterjesztés file-okat tud kárbejelentéséhez csatolni. Kérjük szíves megértését.'">info</mat-icon>
                                      </div>
                                      <div class="col-lg-4">
                                        <granit-file-upload #fileUpload ngDefaultControl [formGroup]="alkusziFeltoltesFormGroup" [formControlName]="'alkusziFeltoltesDokumentum'" [labelText]="'Kérjük válassza ki a feltölteni kívánt fájlt!'"
                                                             [buttonText]="'Fájl kiválasztás'" allowMultipleFiles="false" [acceptedFileExtensions]="alkusziFajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div [innerHTML]="alkusziFeltoltesFormGroup.get('hibaSzoveg').value"></div>
                              </div>
                              <div class="row">
                                <div>
                                  <button mat-raised-button color="accent" (click)="resetForm()" *ngIf="folyamatVege">Új szaporítás</button>
                                  <button mat-raised-button color="accent" (click)="alkusziFeldolgozas()" *ngIf="!folyamatVege && alkusziFeltoltesFormGroup.get('alkusziFeltoltesDokumentum').value">Feldolgozás</button>
                                </div>
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 3 -->
                      <mat-step [stepControl]="szaporitoFeltoltesFormGroup" editable="false" completed="false" *ngIf="!alkusziSzukseges">
                        <div>
                          <form [formGroup]="szaporitoFeltoltesFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Flottaszaporító feltöltés</ng-template>
                              <div class="row" *ngIf="!folyamatVege">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                  <div class="mt-4">
                                    <div class="row mt-1">
                                      <p>Kérjük, hogy az előzmény kötvényszámot és előzmény biztosítót minden esetben pontosan adják meg, az esetleges fedezetlenségi díj számítása miatt.</p>
                                      <p><span>Amennyiben a kockázat kezdete mai nap, akkor kérjük az időpontot is adja meg az alábbi formátumban:<br />yyyy.mm.dd óó:pp</span></p>
                                      <p></p>
                                    </div>
                                    <div class="row mt-5">
                                      <div class="col-lg-4">
                                        <granit-file-upload #fileUpload ngDefaultControl [formGroup]="szaporitoFeltoltesFormGroup" [formControlName]="'szaporitoFeltoltesDokumentum'" [labelText]="'Kérjük válassza ki a feltölteni kívánt Excel fájlt!'"
                                                             [buttonText]="'Fájl kiválasztás'" allowMultipleFiles="false" [acceptedFileExtensions]="szaporitoFajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div [innerHTML]="szaporitoFeltoltesFormGroup.get('hibaSzoveg').value"></div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4">
                                  <button mat-raised-button color="accent" (click)="resetForm()" *ngIf="folyamatVege">Új szaporítás</button>
                                  <button mat-raised-button color="accent" (click)="szaporitoFeldolgozas()" *ngIf="!folyamatVege" [disabled]="!szaporitoFeltoltesFormGroup.controls['szaporitoFeltoltesDokumentum'].value">Feldolgozás</button>
                                </div>
                                <div class="col-lg-4" *ngIf="allapot == '2'">
                                  <button *ngIf="folyamatVege && szaporitoFeltoltesFormGroup.controls['ugyszam'].value != null" mat-raised-button color="accent" (click)="letoltesOnClick()">Ajánlat letöltés</button>
                                </div>
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                    </div>
                  </mat-vertical-stepper>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
