<mat-menu #alMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let menupont of menupontok">

    <ng-container *ngIf="menupont.f_menupontok && menupont.f_menupontok.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.alMenu">
        <span>{{menupont.f_megnevezes}}</span>
      </button>
      <portal-almenu #menu [menupontok]="menupont.f_menupontok"></portal-almenu>
    </ng-container>

    <ng-container *ngIf="!menupont.f_menupontok || menupont.f_menupontok.length === 0">
      <button mat-menu-item (click)="onMenupontClick(menupont)">
        <span>{{menupont.f_megnevezes}}</span>
      </button>
    </ng-container>

  </span>
</mat-menu>
