<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Oktatás'"
                                [subTitle]="''"
                                [templateOutlet]="oktatas"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Oktatás'"
                                [subTitle]="''"
                                [templateOutlet]="oktatas">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #oktatas>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">
        <form class="form">
          <ng-container>
            <div class="row">
              <div class="col-lg-3">

                <granit-alkalmazas-fajlok #alkFajlok
                                           [alkalmazasEnumInput]="1"
                                           [oldalInput]="'oktatas'"
                                           [oldalCsoportInput]="'oktatas'"
                                           [aktualisInput]="'I'">
                </granit-alkalmazas-fajlok>

              </div>

              <div class="col-lg-9">
                <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-12">
                  <div class="card-expand-div" (click)="onInfoLathatoValt()">
                    <mat-card-header>
                      <mat-card-title>Információ</mat-card-title>
                      <button mat-icon-button *ngIf="infoLathato">
                        <mat-icon>expand_less</mat-icon>
                      </button>
                      <button mat-icon-button *ngIf="!infoLathato">
                        <mat-icon>expand_more</mat-icon>
                      </button>
                    </mat-card-header>
                  </div>
                  <mat-card-content *ngIf="infoLathato">

                    <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                      <span>
                        Kedves Partnerünk!
                      </span>
                    </div>
                    <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                      <span>
                        A felhasználói segédlet megismerését követően, kérjük válaszolj helyesen a feltett kérdésekre! Amennyiben jól válaszoltál, jogosultságot kapsz és azonnal használhatod az alkalmazást.
                      </span>
                    </div>
                    <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                      <span>
                        Az aktuális tesztek között csak azok jelennek meg, amihez még nincs jogosultságod.
                      </span>
                    </div>
                    <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                      <span>
                        Köszönjük az együttműködést!
                      </span>
                    </div>

                  </mat-card-content>
                </mat-card>
                <mat-card class="form-content-2 col-lg-12" *ngIf="vegeUzenet != ''">
                  <div class="col-lg-12" [innerHtml]="vegeUzenet">
                  </div>
                  <div class="col-lg-12 text-right">
                    <button mat-raised-button color="accent" (click)="onRendben()">Rendben</button>
                  </div>
                </mat-card>
                <mat-card class="form-content-2 mb-3 pt-2 col-lg-12" *ngIf="tesztekList.length > 0" [class.disabledDiv]="vegeUzenet != ''">
                  <mat-card class="form-content-2 mb-3 pt-2" [class.disabledDiv]="tesztIndit">
                    <div class="row">
                      <div class="col-lg-12">
                        <mat-label class="teszt-kerdes">Aktuális tesztek</mat-label>
                      </div>
                      <div class="col-lg-12 pt-4">
                        <mat-radio-group>
                          <mat-radio-button class="col-lg-3 pl-3" *ngFor="let teszt of tesztekList" [value]="teszt.f_teszt_azonosito" (change)="onTesztValasztas(teszt)">
                            {{teszt.f_teszt}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div class="col-lg-12 pt-4">
                        <div class="row">
                          <div class="col-lg-2">
                            <button mat-raised-button color="accent" [disabled]="this.tesztFejFormGroup.get('f_teszt_azonosito').value == -1" (click)="aktualisAdatokLekerese()">Teszt indítása</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                  <mat-card class="form-content-2 mb-3 pt-2" *ngIf="tesztIndit">
                    <div class="row">
                      <div class="col-lg-12 pt-5">
                        <mat-list>
                          <mat-list-item *ngFor="let kerdes of kerdesekList; let i = index" class="teszt-list-item">
                            <mat-card-content>
                              <div class="row" [class.helyesValasz]="kerdes.f_helyes == 'I'">
                                <div class="col-lg-12 pt-3 pl-5">
                                  <mat-label class="teszt-kerdes">{{i + 1}}. {{kerdes.f_kerdes}}</mat-label>
                                </div>
                                <div class="col-lg-12 pt-2 pl-4 pr-3">
                                  <mat-radio-group class="teszt-radio-group pr-4">
                                    <mat-radio-button class="pt-3 pl-5 pr-3" *ngFor="let valasz of kerdes.f_valaszok" [value]="valasz.f_valasz_azonosito" (change)="onValaszMent(valasz)" [checked]="valasz.f_valasztva == 'I'">
                                      {{valasz.f_valasz}}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-list-item>
                        </mat-list>
                      </div>
                    </div>
                    <div class="eredmenyBox">
                      <mat-card class="form-content-2 col-lg-4">
                        <div class="row">
                          <div class="col-lg-12">
                            <mat-list>
                              <mat-list-item>Összes kérdés: {{osszKerdes}}</mat-list-item>
                              <mat-list-item>Megválaszolt: {{osszValasz}}</mat-list-item>
                            </mat-list>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 text-right">
                            <button mat-raised-button color="accent" [disabled]="osszKerdes != osszValasz" (click)="onValaszokBekuldese()">Válaszok beküldése</button>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </mat-card>
                </mat-card>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</ng-template>
