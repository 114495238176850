<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Alkuszi megbízással kapcsolatos hiánypótlás feltöltése'"
                                [subTitle]="'Ezen az oldalon, a megjelenített ügyhöz kapcsolódóan lehetősége van, a kapott értesítés alapján, hiánypótlásra.'"
                                [templateOutlet]="ujFlottaAlkusziHianypotlas">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #ujFlottaAlkusziHianypotlas>
  <mat-card>
    <mat-card-content class="mb-0">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Ügyszám</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_ugyszam ? adatok.ujFlottaFejlecAdatok.f_ugyszam : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Ügytípus</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_tipus_szoveg ? adatok.ujFlottaFejlecAdatok.f_tipus_szoveg : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Beérkezés dátuma</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_datum ? (adatok.ujFlottaFejlecAdatok.f_datum | dateFormat: 'YYYY.MM.DD. HH:mm') : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Alkusz neve és kódja</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_alkusz_nev ? (adatok.ujFlottaFejlecAdatok.f_alkusz_nev + ' - ' + adatok.ujFlottaFejlecAdatok.f_torzsszam) : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Szerződő cégjegyzékszáma</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_cegjegyzekszam ? adatok.ujFlottaFejlecAdatok.f_cegjegyzekszam : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Szerződő neve</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_ugyfelnev ? adatok.ujFlottaFejlecAdatok.f_ugyfelnev : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Szerződő címe</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_cim ? adatok.ujFlottaFejlecAdatok.f_cim : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Adószám</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_adoszam ? adatok.ujFlottaFejlecAdatok.f_adoszam : '-'}}</label>
            </ng-container>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="control-group">
            <ng-container *ngIf="toltoKepernyo">
              <div class="row">
                <div class="col-4">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '25px', 'margin': '0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyo">
              <label class="control-header-label">Aktuális állapot</label>
              <label class="control-data-label">{{adatok.ujFlottaFejlecAdatok.f_allapot_szoveg ? adatok.ujFlottaFejlecAdatok.f_allapot_szoveg : '-'}}</label>
            </ng-container>
          </div>
        </div>
      </div>
      <form *ngIf="adatok && (adatok.ujFlottaFejlecAdatok.f_allapot == 0 || adatok.ujFlottaFejlecAdatok.f_allapot == 130 || adatok.ujFlottaFejlecAdatok.f_allapot == 230 || adatok.ujFlottaFejlecAdatok.f_allapot == 330)" [formGroup]="alkuszMegbizasHianypotlasFormGroup" class="form-group mt-4">
        <granit-file-upload #fileUpload ngDefaultControl
                             [formGroup]="alkuszMegbizasHianypotlasFormGroup"
                             [formControlName]="'f_fajl'"
                             [labelText]="'Hiánypótlás feltöltése'"
                             [buttonText]="'Kiválasztás'"
                             [acceptedFileExtensions]="fajlKiterjesztesek">
        </granit-file-upload>
        <div class="row">
          <div class="col-lg-8 col-md-6 col-sm-6">
            <mat-form-field appearance="fill">
              <mat-label>Egyéni megjegyzés</mat-label>
              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="f_megjegyzes" required autocomplete="off"></textarea>
              <mat-error *ngIf="hibaService.hiba(alkuszMegbizasHianypotlasFormGroup, 'f_megjegyzes', 'required')">
                <mat-icon>error</mat-icon>
                Kérjük, adjon meg egyéni megjegyzést!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <button mat-raised-button type="button" [disabled]="!alkuszMegbizasHianypotlasFormGroup.valid || toltoKepernyo" color="accent" (click)="onTovabb()">Hiánypótlás elküldése</button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
