import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlottaSzaporitasService {
  private url: string = "/api/flottaszaporitas";
  constructor(
    private httpClient: HttpClient)
  { }

  /* flottaSzerzodoKereses */
  flottaSzerzodoKereses(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzerzodoKereses", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* flottaAlkusziAdatok */
  /*
  flottaAlkusziAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaAlkusziAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */

  /* flottaSzaporitoPdfFej */
  flottaSzaporitoPdfFej(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitoPdfFej", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitoPdfFejUid */
  /*
  flottaSzaporitoPdfFejUid(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitoPdfFejUid", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
*/
  /* flottaSzaporitoPdfGj */
  flottaSzaporitoPdfGj(model: any) {
    return this.httpClient.post<any[]>(this.url + "/FlottaSzaporitoPdfGj", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitoPdfGj */
  /*
  flottaSzaporitoPdfGjUid(model: any) {
    return this.httpClient.post<any[]>(this.url + "/FlottaSzaporitoPdfGjUid", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* flottaSzaporitoPdfMelleklet */
  flottaSzaporitoPdfMelleklet(model: any) {
    return this.httpClient.post<any[]>(this.url + "/FlottaSzaporitoPdfMelleklet", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitoPdfMellekletUid */
  flottaSzaporitoPdfMellekletUid(model: any) {
    return this.httpClient.post<any[]>(this.url + "/FlottaSzaporitoPdfMellekletUid", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Flottaszaporító feldolgozas */
  flottaSzaporitoExcelFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitoExcelFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Alkuszi megbízás feltöltés feldolgozása */
  flottaAlkusziFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaAlkusziFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaAlkusziMegbizasJovahagyasFeldolgozas */
  /*
  flottaAlkusziMegbizasJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post(this.url + "/FlottaAlkusziMegbizasJovahagyasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* flottaAjanlatJovahagyasFeldolgozas */
  /*
  flottaAjanlatJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post(this.url + "/FlottaAjanlatJovahagyasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* flottaAjanlatLezarasFeldolgozas */
  /*
  flottaAjanlatLezarasFeldolgozas(model: any) {
    return this.httpClient.post(this.url + "/FlottaAjanlatLezarasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* flottaSzaporitoAlkuszLista */
  flottaSzaporitoAlkuszLista(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitoAlkuszLista", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitoAlkuszLista */
  flottaSzaporitoAlkuszListaCasco(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitoAlkuszListaCasco", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasTorles */
  flottaSzaporitasTorles(model: any) {
    return this.httpClient.post(this.url + "/FlottaSzaporitasTorles", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasAjanlatPdf */
  flottaSzaporitasAjanlatPdf(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasAjanlatPdf", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasFedezetIgazolasPdf */
  flottaSzaporitasFedezetIgazolasPdf(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasFedezetIgazolasPdf", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
  /* flottaSzaporitasElozetesFedezetIgazolasPdf */
  flottaSzaporitasElozetesFedezetIgazolasPdf(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasElozetesFedezetIgazolasPdf", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasElozetesFedezetIgazolasLista */
  flottaSzaporitasElozetesFedezetIgazolasLista(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasElozetesFedezetIgazolasLista", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasAjanlatFeltoltes */
  flottaSzaporitasAjanlatFeltoltes(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasAjanlatFeltoltes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaSzaporitasAjanlatFeltoltes Casco*/
  flottaSzaporitasAjanlatFeltoltesCasco(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasAjanlatFeltoltesCasco", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flotta Casco SzerzodoKereses */
  flottaCascoSzerzodoKereses(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzerzodoKereses", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Flotta Casco szaporító feldolgozás */
  flottaCascoSzaporitoExcelFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzaporitoExcelFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* flottaCascoAjanlatJovahagyasFeldolgozas */
  /*
  flottaCascoAjanlatJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoAjanlatJovahagyasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* Flotta szaporítás állapotkódok */
  flottaSzaporitasAllapotKodok() {
    return this.httpClient.post<any>(this.url + "/FlottaSzaporitasAllapotKodok", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Flotta szaporítás adatok */
  /*
  flottaCascoSzaporitoAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzaporitoAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* Flotta szaporítás adatok rögzítéshez */
  flottaCascoSzaporitoAdatokRogziteshez(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzaporitoAdatokRogziteshez", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Excel - Flotta szaporítás beküldött adatok */
  /*
  flottaCascoGepjarmuAdatokExcelbe(model: any) {
    return this.httpClient.post(this.url + "/FlottaCascoGepjarmuAdatokExcelbe", model, { responseType: 'blob', observe: 'response'}).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* Excel Flotta szaporítás BRUNO gépjárművek */
  /*
  flottaCascoBrunoGepjarmuvekExcelbe(model: any) {
    return this.httpClient.post(this.url + "/FlottaCascoBrunoGepjarmuvekExcelbe", model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* Excel Flotta szaporítás DNF gépjárművek */
  /*
  flottaCascoDnfGepjarmuvekExcelbe(model: any) {
    return this.httpClient.post(this.url + "/FlottaCascoDnfGepjarmuvekExcelbe", model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /*  flottaCascoAjanlatLezarasFeldolgozas*/
  /*
  flottaCascoAjanlatLezarasFeldolgozas(model: any) {
    return this.httpClient.post(this.url + "/FlottaCascoAjanlatLezarasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* Társbiztosítotti adatok lekérése */
  flottaCascoTarsbiztositottiAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoTarsbiztositottiAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Társbiztosítotti adatok lekérése */
  flottaCascoTarsbiztositottiAdatokMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoTarsbiztositottiAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Flotta CASCO szaporítás esetében ha va szemleköteles gépjármű, kapcsolattartó adatainak mentése */
  flottaCascoSzemleAdatokMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzemleAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Flotta CASCO szaporítás esetében ha va szemleköteles gépjármű, kapcsolattartó adatainak lekérése */
  flottaCascoSzemleAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzemleAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
