  <p>Hasznos információkkal szeretnénk segíteni a munkád annak érdekében, hogy a flottakezeléssel kapcsolatos hibák, javítási kérések gyorsabban kerüljenek rögzítésre. Amennyiben ezekre figyelsz, akkor jelentősen tudunk javítani a feldolgozás gyorsaságán! Kérjük, figyelj arra, hogy az olvasható ajánlat minden lapját tartalmazó aláírt ajánlat legyen feltöltve, mert ez alapján történik a rögzítés! (új és szaporítás esetén is!)</p>
  <blockquote>
    <ol>
      <li>
        <p>Új flotta ajánlat:</p>
        <ul class="materials">
          <li>Új flottaszerződést csak olyan kockázatviselési kezdettel indíts, melynél teljesül, hogy minimum 5 db jármű forgalomban van és valamennyi jármű átírásra került a szerződő nevére. Amennyiben az új flotta 5-nél kevesebb ilyen járművet tartalmaz, a kockázatviselés kezdetét módosítanunk kell az 5. jármű forgalomba helyezésének/átírásának dátumára, ezért a többi járműre fedezetlenségi díjat számolunk.</li>
          <li>Kérjük a közös megegyezésről szóló kérelmet és a Társaságunk által megküldött engedélyező dokumentumot (pl.: e-mail az alkusz támogatódtól) is töltsd fel, hogy a szerződés megszüntethető legyen!</li>
        </ul>
      </li>
      <li>
        <p>Flotta átkötése:</p>
        <p>Flotta gfb átkötés esetén, a meglévő Gránit Biztosító-s flottaszerződést közös megegyezéssel megszüntetjük és egy új flottaszerződést indítunk, akkor kérjük az alábbiak betartását:</p>
        <ul>
          <li>Kérjük, ellenőrizd a flotta díjrendezettségét, mert a közös megegyezéssel megszűnő szerződéseknek (előzmény) nem lehet díjhátraléka! Ehhez segítséget nyújt a portálon található egyenlegközlő! </li>
          <li>Kérjük a közös megegyezésről szóló kérelmet és a Társaságunk által megküldött engedélyező dokumentumot is tölts fel, hogy a szerződés megszüntethető legyen!</li>
        </ul>
      </li>
      <li>
        <p>Flottaszaporítás:</p>
        <p>Amennyiben már van meglévő, rögzített flottája az ügyfelednek, akkor az Online flottaszaporítóban kérd az adott járműre az ajánlatot!</p>
        <ul>
          <li>Ha az Ügyfeled mégsem írja alá az ajánlatot vagy javítás miatt készült új ajánlat, a flotta szaporítás törlése menüben tudod törölni a nem kívánt szaporítást!</li>
          <li>Ha szükséged van előzetes fedezet igazolásra, az előzetes fedezetigazolás menüben letöltheted!</li>
          <li>Ha a kötés dátuma megegyezik a kockázat kezdettel, minden esetben töltsd ki az óra percet, egyéb esetben sajnos csak a következő nappal tudjuk fedezetbe vonni a szerződéseket, ezáltal fedezetlenségi díj keletkezik a szerződésen.</li>
          <li>Ha a feltöltött aláírt ajánlat nem olvasható, vagy van hiányzó része, ezeket sajnos nem tudjuk feldolgozni, így ezek visszaküldésre kerülnek hozzád aláírásra, hiánypótlásra!</li>
        </ul>
      </li>
    </ol>
  </blockquote>
  <p>A flotta ajánlatokat elegendő a portálon keresztül visszatölteni azt más helyre nem kell beküldeni! </p>
  <p>&nbsp;</p>
