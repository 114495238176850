export * from './felhasznalo.service';
export * from './listak.service';
export * from './menu.service';
export * from './dokumentum.service';
export * from './egyenlegkozlo.service';
export * from './fuvarozoi.service';
export * from './szerzodesAdatok.service';
export * from './oktatas.service';
export * from './hirek.service';
export * from './kafKszfDijszamolo.service';

