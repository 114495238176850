import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlottaService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/flotta';

  // Szerződő keresés
  szerzodoKereses(model: any) {
    return this.httpClient.post<any>(this.url + '/SzerzodoKereses', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Alkuszi megbízás szükségesség ellenőrzés
  alkusziFeltoltesSzukseges(model: any) {
    return this.httpClient.post<any>(this.url + '/AlkusziFeltoltesSzukseges', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Alkuszi megbízás feldolgozása
  alkusziMegbizasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + '/AlkusziMegbizasFeldolgozas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta ajánlat vagy díjkalkulációkérés
  ujFlottaAjanlatVagyDijkalkulaciokeres(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatVagyDijkalkulaciokeres', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta alkuszi jóváhagyás feldolgozás
  /*
  ujFlottaAlkusziJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAlkusziJovahagyasFeldolgozas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  // Új flotta alkuszi megbízás hiánypótlás feldolgozás
  ujFlottaAlkusziHianypotlasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAlkusziHianypotlasFeldolgozas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta dijkalkulációk lekérése
  ujFlottaDijkalkulaciok(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaDijkalkulaciok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új B flotta ajánlakérések lekérése
  ujFlottaBAjanlatkeresek(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaBAjanlatkeresek', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta ajánlakérések lekérése
  ujFlottaAjanlatkeresek(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatkeresek', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta ajánlakérések letöltés
  ujFlottaAjanlatVagyDijkalkulacioLetoltes(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatVagyDijkalkulacioLetoltes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta ajánlakérések letöltés
  flottaFedezetIgazolasPdf(model: any) {
    return this.httpClient.post(this.url + '/FlottaFedezetIgazolasPdf', model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta ajánlakérés gépjárművek lekérése
  //ujFlottaAjanlatkeresGepjarmuvek(model: any) {
  //  return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatkeresGepjarmuvek', model).pipe(
  //    map(data => {
  //      return data;
  //    }),
  //    catchError(error => {
  //      return throwError(error);
  //    })
  //  );
  //}

  // Új flotta ajánlat mellékletek visszatöltés
  ujFlottaAjanlatMellekletekVisszatoltese(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatMellekletekVisszatoltese', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta adatok lekérése
  
  ujFlottaAdatokLekerese(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAdatokLekerese', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
  // Új flotta ajánlat lezárás
  /*
  ujFlottaAjanlatLezaras(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaAjanlatLezaras', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */

  //UjFlottaElbiralasAdatok
  /*
  ujFlottaElbiralasAdatok(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaElbiralasAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /*
  //UjFlottaElozmenyAdatok
  ujFlottaElozmenyAdatok(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaElozmenyAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */

  // UjFlottaDijkalk
  /*
  ujFlottaDijkalk(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaDijkalk', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /*
  // UjFlottaJovahagyasFeldolgozas
  ujFlottaJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + '/UjFlottaJovahagyasFeldolgozas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */

  /* UjFlottaCascoAjanlatJovahagyasFeldolgozas */
  /*
  ujFlottaCascoAjanlatJovahagyasFeldolgozas(model: any) {
    return this.httpClient.post<any>(this.url + "/UjFlottaCascoAjanlatJovahagyasFeldolgozas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  /* UjFlottaCascoAdatok */
  /*
  ujFlottaCascoAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/UjFlottaCascoAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  // Új flotta casco gépjármű adatok excelbe
  /*
  ujFlottaCascoGepjarmuAdatokExcelbe(model: any) {
    return this.httpClient.post(this.url + "/UjFlottaCascoGepjarmuAdatokExcelbe", model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  */
  // Új flotta casco ajánlat üzletkötői törlése
  ujFlottaAjanlatTorles(model: any) {
    return this.httpClient.post(this.url + "/UjFlottaAjanlatTorles", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Új flotta kockázat viselés ellenörzés
  ujFlottaKockazatviselesEllenorzes(model: any) {
    return this.httpClient.post<any>(this.url + "/UjFlottaKockazatviselesEllenorzes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Társbiztosítotti adatok lekérése */
  flottaCascoUjTarsbiztositottiAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoUjTarsbiztositottiAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Társbiztosítotti adatok lekérése */
  flottaCascoUjTarsbiztositottiAdatokMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoUjTarsbiztositottiAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Új flotta CASCO esetében szerződő vizsgálata, hogy van e már a brunoban casco flottája */
  cascoSzerzodoKeresesEllenorzessel(model: any) {
    return this.httpClient.post<any>(this.url + "/CascoSzerzodoKeresesEllenorzessel", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Új flotta CASCO esetében ha va szemleköteles gépjármű, kapcsolattartó adatainak mentése */
  flottaCascoSzemleAdatokMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzemleAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Új flotta CASCO esetében ha va szemleköteles gépjármű, kapcsolattartó adatainak lekérése */
  flottaCascoSzemleAdatok(model: any) {
    return this.httpClient.post<any>(this.url + "/FlottaCascoSzemleAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
