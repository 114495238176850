import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { ListakService, MenuService } from '../../services/_index';
import { saveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { base64StringToBlob } from 'blob-util';
import * as moment from 'moment';

@Component({
  selector: 'listak',
  templateUrl: './listak.component.html',
  styleUrls: ['./listak.component.scss']
})
export class ListakComponent implements OnInit {


  kivalasztottMenu: any;

  listatipus: any;

  listakOszlopok: any[] = [
    { columnDef: 'f_FAZON', header: 'AZONOSÍTÓ', class: 'col-lg-1' },
    { columnDef: 'f_FNEV', header: 'FÁJL NÉV', class: 'col-lg-5' },
    { columnDef: 'f_FELTOLTVE', header: 'FELTÖLTVE', dateFormat: 'YYYY.MM.DD. HH.mm', adattipus: 'date', class: 'col-lg-2' },
    { columnDef: 'f_letoltheto', header: 'LETÖLTHETŐ', class: 'col-lg-2' },
    { columnDef: 'f_egyeb_muvelet_gomb', header: '', class: 'col-lg-2', buttonText: "Letöltés", buttonIcon: "file_download" },
  ];
  getDisplayedListakColumns(): string[] {
    let displayedColumns = this.listakOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  listakFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([]),
  });
  listakTabla: MatTableDataSource<any>;

  vonalkodOszlopok: any[] = [
    { columnDef: 'tól', header: 'TÓL', class: 'col-lg-6' },
    { columnDef: 'ig', header: 'IG', class: 'col-lg-6' }
  ];
  getDisplayedVonalkodColumns(): string[] {
    let displayedColumns = this.vonalkodOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  vonalkodFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([]),
  });
  vonalkodTabla: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorVonalkod: MatPaginator;

  listaUjraOszlopok: any[] = [
    { columnDef: 'f_FAZON', header: '', visible: false },
    { columnDef: 'f_FNEV', header: 'FÁJL NÉV', class: 'col-lg-11' },
    { columnDef: 'f_valaszt', header: '', adattipus: 'check', class: 'col-lg-1' }
  ];
  getDisplayedUjraColumns(): string[] {
    let displayedColumns = this.listaUjraOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  listaUjraFormGroup: FormGroup;
  listaUjraTabla: MatTableDataSource<any>;
  ujraTipusLista: any[] = [];
  ujraEvLista: any[] = [];
  ujraHonapLista: any[] = [];


  mnbOszlopok: any[] = [
    { columnDef: 'f_EV', header: 'ÉV' },
    { columnDef: 'f_IDOSZAK', header: 'IDŐSZAK' },
    { columnDef: 'f_MODOZAT', header: 'MÓDOZAT' },
    { columnDef: 'f_DIJFIZETES', header: 'DÍJFIZETÉS' },
    { columnDef: 'f_EVES_DB', header: 'IDŐSZAK DB', adattipus: 'number' },
    { columnDef: 'f_EVES_ALLOMANYDIJ', header: 'IDŐSZAK ÁLLOMÁNYDÍJ', adattipus: 'number' },
    { columnDef: 'f_ZARO_DB', header: 'ZÁRÓ DB', adattipus: 'number' },
    { columnDef: 'f_ZARO_ALLOMANYDIJ', header: 'ZÁRÓ ÁLLOMÁNYDÍJ', adattipus: 'number' },
    { columnDef: 'f_KOZV_SZERZ_DB', header: 'KÖZVETÍTETT SZERZ. DB', adattipus: 'number' },
    { columnDef: 'f_KOZV_SZERZ_ALLOMANYDIJ', header: 'KÖZVETÍTETT SZERZ. ÁLLOMÁNYDÍJ', adattipus: 'number' },
    { columnDef: 'f_SZTORNO_DB', header: 'SZTORNÓ DB', adattipus: 'number' },
    { columnDef: 'f_SZTORNO_ALLOMANYDIJ', header: 'SZTORNÓ ÁLLOMÁNYDÍJ', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_SZERZESI_KIFIZET', header: 'SZERZÉSI JUTALÉK KIFIZETÉS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_FENNTARTASI_KIFIZET', header: 'FENNTARTÁSI JUTALÉK KIFIZETÉS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_BONUSZ_KIFIZET', header: 'BONUSZ JUTALÉK KIFIZETÉS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_KIFIZET', header: 'JUTALÉK KIFIZETÉS ÖSSZESEN', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_SZERZESI_VISSZAIR', header: 'SZERZÉSI JUTALÉK VISSZAÍRÁS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_FENNTARTASI_VISSZAIR', header: 'FENNTARTÁSI JUTALÉK VISSZAÍRÁS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_BONUSZ_VISSZAIR', header: 'BONUSZ JUTALÉK VISSZAÍRÁS', adattipus: 'number' },
    { columnDef: 'f_JUTALEK_VISSZAIR', header: 'JUTALÉK VISSZAÍRÁS ÖSSZESEN', adattipus: 'number' }
  ];
  getDisplayedMnbColumns(): string[] {
    let displayedColumns = this.mnbOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  mnbFormGroup: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([]),
  });
  mnbTabla: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorMnb: MatPaginator;

  jutalekElszamolasFilenev: string = "";
  jutalekElszamolasEgyenleg: any = null;
  jutalekElszamolasJovahagyva: string = "";


  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public hibaService: HibaService,
    private utilityService: UtilityService,
    private listakService: ListakService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private menuService: MenuService  ) {
    this.route.queryParams.subscribe((params: Params) => {
      const _params = this.toLower(params);
      this.listatipus = _params['lista'];

      this.kivalasztottMenu = this.menuService.kivalasztottMenupontValue;

      if (this.listatipus != "-1") {
        this.listaLekeres();
      }
      else {
        this.listaUjraTabla = new MatTableDataSource();
        this.listaUjraFormGroupInit();
      }

    });
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  ngOnInit(): void {    
    
  }

  listaLekeres() {

    let model = {
      listaTipus: this.listatipus
    };

    this.listakService.listaAdatok(model).subscribe(
      data => {
        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        if (this.listatipus != "0" && this.listatipus != "6") {
          this.listakTabla = new MatTableDataSource();
          this.listakFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
          this.listakTabla.data = (this.listakFormGroup.get('tableDataSource') as FormArray).controls;
          if (this.listatipus == "12") {
            this.jutalekElszamolasFilenev = "";
            this.jutalekElszamolasEgyenleg = null;
            this.jutalekElszamolasJovahagyva = "";
            this.listakService.brokerJutElszamJovahagy().subscribe(
              data => {
                if (data != null) {
                  this.jutalekElszamolasFilenev = data["f_FNEV"];
                  this.jutalekElszamolasEgyenleg = data["f_egyenleg"];
                  this.jutalekElszamolasJovahagyva = data["f_jovahagyva"];
                }
              },
              errorResult => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba a jutalék elszámolás lekérdezése közben",
                  confirmButtonText: 'Rendben'
                });
              });
          }
        }
        if (this.listatipus == "0") {
          this.vonalkodTabla = new MatTableDataSource();
          this.vonalkodFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
          this.vonalkodTabla.data = (this.vonalkodFormGroup.get('tableDataSource') as FormArray).controls;
          this.vonalkodTabla.paginator = this.paginatorVonalkod;

        }
        if (this.listatipus == "6") {
          this.mnbTabla = new MatTableDataSource();
          this.mnbFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
          this.mnbTabla.data = (this.mnbFormGroup.get('tableDataSource') as FormArray).controls;
          this.mnbTabla.paginator = this.paginatorMnb;
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a listák lekérdezése közben",
          confirmButtonText: 'Rendben'
        });
      });
  }

  onRowOperationClick(event: any) {

    let model = {
      fileAzonosito: event.controls["f_FAZON"].value
    };

    this.listakService.listaLetoltese(model).subscribe(
      data => {
        const filename = event.controls["f_FNEV"].value;
        saveAs(data.body, decodeURIComponent(filename));
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a letöltés közben",
          confirmButtonText: 'Rendben'
        });
      });
  }

  listaUjraFormGroupInit() {
    this.listaUjraFormGroup = this.formBuilder.group({
      f_lista_tipus: new FormControl("0"),
      f_ev: new FormControl("0"),
      f_honap: new FormControl("0"),
      tableDataSource: this.formBuilder.array([])
    });
    this.listaUjraTipus();
  }

  listaUjraTipus() {

    this.listakService.listaUjraTipus().subscribe(
      data => {
        if (data.length > 0) {
          this.ujraTipusLista = data;
        }
      },
      errorResult => {        
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lista típusok lekérdezése közben",
          confirmButtonText: 'Rendben'
        });
      });
  }

  onUjraTipusValasztas() {
    if (this.listaUjraFormGroup.controls["f_lista_tipus"].value == "0") {
      this.listaUjraFormGroup.controls["f_ev"].setValue("0");
      this.listaUjraFormGroup.controls["f_ho"].setValue("0");
    }
    else {

      let model = {
        listaTipus: this.listaUjraFormGroup.controls["f_lista_tipus"].value.toString()
      };

      this.listakService.listaUjraEv(model).subscribe(
        data => {
          if (data.length > 0) {
            this.ujraEvLista = data;
          }
        },
        errorResult => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az évek lekérdezése közben",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  onUjraEvValasztas() {
    if (this.listaUjraFormGroup.controls["f_lista_tipus"].value == "0") {
      this.listaUjraFormGroup.controls["f_ho"].setValue("0");
    }
    else {

      let model = {
        listaTipus: this.listaUjraFormGroup.controls["f_lista_tipus"].value.toString(),
        ev: this.listaUjraFormGroup.controls["f_ev"].value.toString()
      };

      this.listakService.listaUjraHo(model).subscribe(
        data => {
          if (data.length > 0) {
            this.ujraHonapLista = data;
          }
        },
        errorResult => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a hónapok lekérdezése közben",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  onKereses() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_listak').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            listaTipus: this.listaUjraFormGroup.controls["f_lista_tipus"].value,
            ev: this.listaUjraFormGroup.controls["f_ev"].value.toString(),
            honap: this.listaUjraFormGroup.controls["f_honap"].value.toString()
          };

          this.listakService.listaUjraLista(model).subscribe(
            data => {
              const fgs = data.map(item => {
                return this.utilityService.formGroupObjektumbol(item);
              });
              this.listaUjraFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
              this.listaUjraTabla.data = (this.listaUjraFormGroup.get('tableDataSource') as FormArray).controls;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba az adatok lekérdezése közben",
                confirmButtonText: 'Rendben'
              });
            });
        })
      });
  }

  onUjraIgenyles() {
    let ujra: any[] = null;

    ujra = this.listaUjraFormGroup.value.tableDataSource.filter(x => x.f_valaszt === true);

    if (ujra.length > 0) {
      ujra.forEach(adat => {

        let model = {
          fileAzonosito: adat.f_FAZON.toString()
        };

        this.listakService.listaUjraMentes(model).subscribe(
          () => {
            this.utilityService.dialogMegnyitasa({
              success: true,
              title: 'Siker',
              message: "Sikeres mentés!",
              confirmButtonText: 'Rendben'
            });
            this.onKereses();
          },
          errorResult => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a mentés közben",
              confirmButtonText: 'Rendben'
            });
          });
      });
    }
    else {
      this.utilityService.dialogMegnyitasa({
        error: true,
        title: 'Hiba',
        message: "Nincs kijelölve egy fájl sem",
        confirmButtonText: 'Rendben'
      });
    }
  }

  onJutalekElszamolasElfogadasa() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_listak').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.listakService.brokerJutElszamJovahagyva().subscribe(
            () => {
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Siker',
                message: "Sikeres mentés!",
                confirmButtonText: 'Rendben'
              });
              this.jutalekElszamolasFilenev = "";
              this.jutalekElszamolasEgyenleg = null;
              this.jutalekElszamolasJovahagyva = "";
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a mentés közben",
                confirmButtonText: 'Rendben'
              });
            });
        })
      });
  }

  fajlbaMentes() {

    let mnbAdatok: any[] = [];
    this.mnbTabla.data.forEach(tabla => {
      mnbAdatok.push(tabla.value);
    })

    let model = {
      adatok: mnbAdatok
    }

    this.listakService.mnbFajlba(model).subscribe(
      data => {

        let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let blob = base64StringToBlob(data, contentType);
        saveAs(blob, "granit_mnb_" + moment(new Date()).format("YYYYMMDD") + ".xlsx");       
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt a mentés közben!",
          confirmButtonText: 'Rendben'
        });
      }
    );

  }

}
