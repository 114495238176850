<mat-toolbar [class.fejlec-teszt]="teszt" [class.fejlec]="!teszt">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center" style="vertical-align:middle;">
        <button *ngIf="menuGombLathatosag" mat-icon-button color="accent" (click)="onMenuToggleClick()" style="font-weight: bold;"><mat-icon *ngIf="!menu_latszik">chevron_left</mat-icon>MENÜ<mat-icon *ngIf="menu_latszik">chevron_right</mat-icon>
        </button>
      </div>
      <div class="col-xxl-8 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
        <div class="row">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 background-color-primary">
            <div>
              <a routerLink="/fooldal">
                <span>
                  <img class="logo" src="assets/images/logo.png" />
                </span>
              </a>
              <button *ngIf="fooldalGombLathatosag" mat-button color="accent" routerLink="/fooldal">
                <mat-icon style="margin-bottom: 3px;">chevron_left</mat-icon>
                <span>Főoldal</span>
              </button>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 background-color-primary d-flex align-items-center">
            <span *ngIf="aktualisFelhasznalo && aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam != null" class="alkusz_szoveg">{{aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam}} - {{aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev}}</span>
          </div>
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" [class.fejlec-jobb-teszt]="teszt" [class.fejlec-jobb]="!teszt">
            <div class="h-100 d-flex align-items-center justify-content-end">
              <div *ngIf="teszt">
                <span class="szoveg_teszt">TESZT</span>
              </div>
              <div *ngIf="aktualisFelhasznalo" class="h-100 d-flex align-items-center justify-content-end">
                <button mat-button [class.font-color-teszt]="teszt" [class.font-color]="!teszt" (click)="onKijelentkezesClick()">
                  <mat-icon>logout</mat-icon>
                  Kijelentkezés
                </button>
                <countdown class="d-none" #countdownComponent [config]="{ leftTime: 1800, format: 'mm:ss', notify: [300] }" (event)="handleEvent($event)"></countdown>
                <countdown class="d-none" #countdownComponent2 [config]="{ leftTime: 30, format: 'mm:ss' }" (event)="handleEvent2($event)"></countdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
