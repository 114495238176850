import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private httpClient: HttpClient) {
    this.kivalasztottMenupontSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('kivalasztottMenupont')));
    this.kivalasztottMenupont = this.kivalasztottMenupontSubject.asObservable();
  }

  public kivalasztottMenupontSubject: BehaviorSubject<any>;
  public kivalasztottMenupont: Observable<any>;

  public get kivalasztottMenupontValue(): any {
    return this.kivalasztottMenupontSubject.value;
  }

  private url: string = '/api/menu';
  private portalMenuSideNav: MatSidenav;

  public setportalMenuSideNav(portalMenuSideNav: MatSidenav) {
    this.portalMenuSideNav = portalMenuSideNav;
  }

  public portalMenuToggle() {
    this.portalMenuSideNav.toggle();
  }

  /* Menüpontok lekérése */
  oldalonBeluliJogosultsag(model: any) {
    return this.httpClient.post<any[]>(this.url + "/OldalonBeluliJogosultsag", model).pipe(
      map(contracts => {
        return contracts;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
