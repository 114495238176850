import { ChangeDetectorRef, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HibaService, UtilityService, GranitTableComponent, GranitFileUploadComponent, Dokumentum } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { FlottaSzaporitasService } from '../../../../services/flotta-szaporitas.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DokumentumService } from '../../../../services/dokumentum.service';

@Component({
  selector: 'flotta-szaporitasok',
  templateUrl: './flotta-szaporitasok.component.html',
  styleUrls: ['./flotta-szaporitasok.component.scss']
})

export class FlottaSzaporitasokComponent implements OnInit {
  flottaAjanlatokFormGroup: FormGroup;
  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  hibaSzoveg: string = "";

  dataSource: MatTableDataSource<any>;
  matPaginator: MatPaginator;
  @ViewChild('matPaginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dataSource.paginator = this.matPaginator;
    }
  }

  getDisplayedSzaporitasColumns(): string[] {
    let displayedColumns = this.szaporitasokTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  szaporitasokTablaOszlopok: any[] = [

    //ügyszám, beérkezés dátuma, flotta, szerződő, állapot, ajánlatszámok

    { columnDef: 'f_ugyszam', header: 'Ügyszám' },
    { columnDef: 'f_datum', header: 'Beérkezés dátuma', adattipus: 'date', dateFormat: 'YYYY.MM.DD' },
    { columnDef: 'f_flotta_azon', header: 'Flotta' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot', header: 'Áll.', visible: false },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot'},
    { columnDef: 'f_vonalkod', header: 'Ajánlatszámok' },
    { columnDef: 'f_uid', header: 'Uid', visible: false },
    { columnDef: 'f_gj_azon', header: 'gjazon', visible: false },
    { columnDef: 'f_kgfb_fedezet_igazolas_letoltheto', header: 'fedig', visible: false },
    // Extra oszlopok
    { columnDef: 'f_letoltes', header: 'Ajánlat letöltés', adattipus: 'button' },
    { columnDef: 'f_visszatoltes', header: 'Ajánlat visszatöltés', adattipus: 'button' },
    { columnDef: 'f_torles', header: 'Ajánlat törlése', adattipus: 'button' },
    { columnDef: 'f_fedezetigazolas', header: 'Fedezet igazolás', adattipus: 'button' }
  ];

  ajanlatFajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];
  @ViewChild('fileUpload', { static: true }) fileUpload: GranitFileUploadComponent;

  toltoKepernyoKereses: boolean = false;
  feltoltes: boolean = false;
  fajlFeltoltesEredmeny: string = "";

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private flottaSzaporitasService: FlottaSzaporitasService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    private dokumentumokService: DokumentumService) {
  }

  ngOnInit(): void {
    this.flottaAjanlatokFormGroup = this.formBuilder.group({
      alkuszNev: new FormControl(''),
      alkuszTorzsszam: new FormControl(''),
      flottaAzonosito: new FormControl(''),
      szerzodoNev: new FormControl(''),
      feltoltesFlotta: new FormControl(''),
      feltoltesSzerzodoNev: new FormControl(''),
      feltoltesUgyszam: new FormControl(''),
      allapot: new FormControl('0', Validators.required),
      ajanlatFeltoltesDokumentumok: new FormControl(''),
    });
    if (this.aktualisFelhasznalo != null) {
      this.flottaAjanlatokFormGroup.controls["alkuszNev"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev);
      this.flottaAjanlatokFormGroup.controls["alkuszTorzsszam"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
    }

    this.dataSource = new MatTableDataSource();
    this.szuresOnClick();
  }

  letoltesOnClick(row) {
    let model = {
      f_ugyszam: row.controls["f_ugyszam"].value,
      f_modozat: "GFB"
    };
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasAjanlatPdf(model).subscribe(
      data => {
        if (data != null) {
          if (data.eredmenykod == 0) {
            let contentType = 'application/pdf';
            let blob = base64StringToBlob(data.file, contentType);
            saveAs(blob, data.filename);
          }
          else {
            this.hibaSzoveg = "<p>Hibás kérés</p>";
          }
        }
        else {
          this.hibaSzoveg = "<p>Hibás kérés</p>";
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.hibaSzoveg = "<p>Hibás kérés</p>";
        this.toltoKepernyoKereses = false;
      });
  }

  fedezetIgazolasOnClick(row) {
    let model = {
      f_dokumentum: "F",
      f_szerzodesSzamok: "",
      f_kotegelt: false,
      f_flotta: null,
      f_ugyszam: row.controls["f_ugyszam"].value,
      f_ugy_modozat: "GFB",
      f_ugy_tipus: "szaporítás"
    }

    this.toltoKepernyoKereses = true;
    this.dokumentumokService.dokumentumIgenyles(model).subscribe(
      data => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres igénylés',
          message: data.eredmeny_szoveg,
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      });
  }

  feltoltesOnClick(row) {
    this.feltoltes = true;
    if (row.f_dokumentum == null || row.f_dokumentum.length > 0) {
      this.flottaAjanlatokFormGroup.controls["feltoltesFlotta"].setValue(row.controls["f_flotta_azon"].value);
      this.flottaAjanlatokFormGroup.controls["feltoltesSzerzodoNev"].setValue(row.controls["f_ugyfelnev"].value);
      this.flottaAjanlatokFormGroup.controls["feltoltesUgyszam"].setValue(row.controls["f_ugyszam"].value);
    }
  }

  feltoltesOkOnClick() {
    let model = {
      f_ugyszam: this.flottaAjanlatokFormGroup.controls["feltoltesUgyszam"].value,
      f_dokumentumok: this.flottaAjanlatokFormGroup.controls["ajanlatFeltoltesDokumentumok"].value
    };
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasAjanlatFeltoltes(model).subscribe(
      data => {
        this.szuresOnClick();
        this.utilityService.dialogMegnyitasa({
          succes: true,
          title: 'Siker',
          message: "A feltöltés sikeresen megtörtént",
          confirmButtonText: 'Rendben'
        });
      },
      errorResult => {
        this.hibaSzoveg = "<p>Hiba történt az ajánlat feltöltésekor.</p>";        
      }
    );
    this.toltoKepernyoKereses = false;
    this.feltoltes = false;
  }

  feltoltescCancelOnClick() {
    this.feltoltes = false;
}


  torlesOnClick(row) {
    // Megerősítő dialog ablak
    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Törlés megerősítés',
      message: 'Biztosan törölni kívánja az ajánlatot?',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.toltoKepernyoKereses = true;
        // Model, amit a controllerbe küldünk
        let model = {
          f_ugyszam: row.controls["f_ugyszam"].value
        };

        this.flottaSzaporitasService.flottaSzaporitasTorles(model).subscribe(
          data => {
            this.szuresOnClick();
            this.toltoKepernyoKereses = false;
            this.utilityService.dialogMegnyitasa({
              succes: true,
              title: 'Siker',
              message: "A törlés sikeresen megtörént",
              confirmButtonText: 'Rendben'
            });
            
          },
          errorResult => {
            this.hibaSzoveg = "<p>Hibás kérés</p>";
            this.toltoKepernyoKereses = false;
          }
        );

        //this.szerzodesService.valtozasBejelentesElkuldese(model).subscribe(
        //  () => {
        //    const dialogRef = this.utilityService.dialogMegnyitasa({
        //      info: true,
        //      title: 'Változás bejelentés sikeres',
        //      message: 'A váltotás bejelentése sikeresen megtörtént, az Ön által megadott módosításokat ügyfélszolgálatunk megkapta.',
        //      confirmButtonText: 'Rendben'
        //    });
        //    dialogRef.afterClosed().subscribe((result: boolean) => {
        //      if (result) {
        //        // Csak akkor nullázuk, ha módosítás volt
        //        if (this.valtozasBejelentesTipus.f_tipus == 'modositas') {
        //          this.valtozasBejelentesTipus = null;
        //        }
        //        this.valtozasBejelentesFormGroupLetrehozasa();
        //      }
        //    });
        //  },
        //  errorResult => {
        //    // Hiba
        //  }
        //);
      }
    });
  }

  szuresOnClick() {
    let allapot = null;
    if (this.flottaAjanlatokFormGroup.controls["allapot"].value == '0') {
      allapot = null;
    }
    let model = {
      f_torzsszam: this.flottaAjanlatokFormGroup.controls["alkuszTorzsszam"].value,
      f_flotta: this.flottaAjanlatokFormGroup.controls["flottaAzonosito"].value,
      f_szerzodo_nev: this.flottaAjanlatokFormGroup.controls["szerzodoNev"].value,
      f_allapot: this.flottaAjanlatokFormGroup.controls["allapot"].value,
      f_modozat: "GFB"
    }
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitoAlkuszLista(model).subscribe(
      data => {
        if (data != null) {
          const fgs = data.map(item => {
            return this.utilityService.formGroupObjektumbol(item);
          });
          this.dataSource.data = this.formBuilder.array(fgs).controls;
        }
        else {
          this.hibaSzoveg = "<p>Hibás kérés</p>";
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.hibaSzoveg = "<p>Hibás kérés</p>";
        this.toltoKepernyoKereses = false;
      }
    );
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny += element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }

}
