import { Component, OnInit, ViewChild } from '@angular/core';
import { GranitAlkalmazasFajlokComponent } from '@granit/common-library';

@Component({
  selector: 'ipid',
  templateUrl: './ipid.component.html',
  styleUrls: ['./ipid.component.scss']
})
export class IpidComponent implements OnInit {

  @ViewChild('alkFajlok', { static: false }) alkFajlok: GranitAlkalmazasFajlokComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
