import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HibaService, TextTransformPipe, UtilityService, GranitTableComponent } from '@granit/common-library';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject } from 'rxjs';
import { SzerzodesAdatokService } from '../../services/_index';

@Component({
  selector: 'szerzodes-adatok',
  templateUrl: './szerzodes-adatok.component.html',
  styleUrls: ['./szerzodes-adatok.component.scss']
})
export class SzerzodesAdatokComponent implements OnInit {

  toltoKepernyo: boolean = false;
  szerzodesAdatokFormGroup: FormGroup;
  uzenet: string;

  kockkezdttolMinDate: string = moment(new Date("2008.01.01")).format('YYYY-MM-DD');
  kockkezdtigMinDate: string = moment(new Date("2008.01.01")).format('YYYY-MM-DD');

  modozatokLista: any[] = [];
  keresesLathato: boolean = true;
  infoLathato: boolean = true;
  eredmenyLathato: boolean = true;
  adatokLathato: boolean = true;

  azonosito = new BehaviorSubject<string>('');

  szerzodesekOszlopok: any[] = [
    { columnDef: 'f_szerz_azon', header: 'Szerz.azon.' },
    { columnDef: 'f_ajanlat', header: 'Ajánlatszám' },
    { columnDef: 'f_flotta_azon', header: 'Flotta' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő neve' },
    { columnDef: 'f_kockezd', header: 'Koc. kezdet', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_modrnev', header: 'Módozat' },
    { columnDef: 'f_allapot', header: 'Állapot' },
    { columnDef: 'f_statusz', header: 'Státusz' }
  ];
  getDisplayedSzerzodesekOszlopok(): string[] {
    let displayedColumns = this.szerzodesekOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  szerzodesekTabla: MatTableDataSource<any>;
  matSort: MatSort;
  @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
    if (sort) {
      this.matSort = sort;
      this.szerzodesekTabla.sort = this.matSort;
    }
  }
  matSortDirection = 'asc'
  matPaginator: MatPaginator;
  @ViewChild('paginatorSzerzodesek', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.szerzodesekTabla.paginator = this.matPaginator;
    }
  }
  //@ViewChild('szerzodesekTabla', { static: false }) szerzodesekTabla: GranitTableComponent;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private utilityService: UtilityService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private szerzodesAdatokService: SzerzodesAdatokService) { }

  ngOnInit(): void {
    this.szezodesAdatokFormGroupInit();    
  }

  /*FormGroup inicializálása*/

  szezodesAdatokFormGroupInit() {
    this.szerzodesAdatokFormGroup = this.formBuilder.group({
      f_szerzazon: new FormControl(null),
      f_flotta: new FormControl(null),
      f_rendszam: new FormControl(null),
      f_ajanlat: new FormControl(null),
      f_kockkezdtol: new FormControl(null),
      f_kockkezdig: new FormControl(null),
      f_nev: new FormControl(null),
      f_modozat: new FormControl("ÖSSZES"),
      f_allapot: new FormControl(["É"]),
      f_eredmeny_van: new FormControl(false),
      f_valasztva: new FormControl(false)
    });

    this.szerzodesAdatokService.modozatok().subscribe(
      data => {
        this.modozatokLista = data
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a lekérdezés közben",
          confirmButtonText: 'Rendben'
        });
      });

    this.szerzodesekTabla = new MatTableDataSource();
    this.szerzodesekTabla.sortingDataAccessor = (item: FormGroup, property) => {

      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

  }

  kockkezdtolValtozas() {
    if (this.szerzodesAdatokFormGroup.controls["f_kockkezdtol"].errors == null) {
      this.kockkezdtigMinDate = this.szerzodesAdatokFormGroup.controls["f_kockkezdtol"].value;
    }
  }

  szerzazonEllenor() {
    this.szerzodesAdatokFormGroup.controls["f_szerzazon"].setErrors(null);
    let szerzsazon: string = this.szerzodesAdatokFormGroup.controls["f_szerzazon"].value.toString();
    if (szerzsazon && szerzsazon.length > 0) {
      if (szerzsazon.length != 8) {
        this.szerzodesAdatokFormGroup.controls["f_szerzazon"].setErrors({ hosszHiba: true })
      }
      else {
        if (isNaN(Number(szerzsazon))) {
          this.szerzodesAdatokFormGroup.controls["f_szerzazon"].setErrors({ szamHiba: true })
        }
      }
    }
  }

  flottaEllenor() {
    this.szerzodesAdatokFormGroup.controls["f_flotta"].setErrors(null);
    let flotta: string = this.szerzodesAdatokFormGroup.controls["f_flotta"].value.toString();
    if (flotta && flotta.length > 0) {
      if (isNaN(Number(flotta))) {
        this.szerzodesAdatokFormGroup.controls["f_flotta"].setErrors({ szamHiba: true })
      }
    }
  }

  rendszamEllenor() {
    this.szerzodesAdatokFormGroup.controls["f_rendszam"].setErrors(null);
    let rendszam: string = this.szerzodesAdatokFormGroup.controls["f_rendszam"].value.toString();
    if (rendszam && rendszam.length > 0) {
      if (rendszam.length != 6 && rendszam.length != 7) {
        this.szerzodesAdatokFormGroup.controls["f_rendszam"].setErrors({ hosszHiba: true })
      }
    }
  }

  onKereses() {

    this.toltoKepernyo = true;
    this.szerzodesAdatokFormGroup.controls["f_eredmeny_van"].setValue(false);
    this.szerzodesAdatokFormGroup.controls["f_valasztva"].setValue(false);
    this.eredmenyLathato = true;

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_szerzodes_adatok_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {            
            adatok: this.szerzodesAdatokFormGroup.value
          }

          this.szerzodesAdatokService.szerzodesekKeresese(model).subscribe(
            data => {
              const fgs = data.map(item => {
                return this.utilityService.formGroupObjektumbol(item);
              });
              this.szerzodesekTabla.data = this.formBuilder.array(fgs).controls;

              this.szerzodesAdatokFormGroup.controls['f_eredmeny_van'].setValue(true);                           
              this.toltoKepernyo = false;
              this.keresesLathato = false;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a keresés közben",
                confirmButtonText: 'Rendben'
              });
              this.toltoKepernyo = false;
            });
        })
      });
  }

  /*szerződés adatok megjelenítse*/
  onAdatokClick(formGroup: FormGroup) {
    this.eredmenyLathato = false;
    this.szerzodesAdatokFormGroup.controls['f_valasztva'].setValue(true);

    this.azonosito.next(formGroup.controls['f_szerz_azon'].value.toString());

    //this.azonosito = formGroup.controls['f_szerz_azon'].value.toString();
    //localStorage.setItem('azonosito', this.azonosito.value);
    
  }

  /*keresés ablak form mutatása/elrejtése*/
  onKeresesLathatoValt() {
    this.keresesLathato = !this.keresesLathato;
  }

  /*info ablak mutatása/elrejtése*/
  onInfoLathatoValt() {
    this.infoLathato = !this.infoLathato;
  }

  /*eredmény ablak mutatása/elrejtése*/
  onEredmenyLathatoValt() {
    this.eredmenyLathato = !this.eredmenyLathato;
  }

  /*adatok ablak mutatása/elrejtése*/
  onAdatokLathatoValt() {
    this.adatokLathato = !this.adatokLathato;
  }

}
