import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HibaService, TextTransformPipe, UtilityService, GranitFileUploadComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { base64StringToBlob } from 'blob-util';
import { DokumentumService } from '../../../../services/dokumentum.service';
import { FlottaSzaporitasService } from '../../../../services/flotta-szaporitas.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'flotta-casco-ajanlatok',
  templateUrl: './flotta-casco-ajanlatok.component.html',
  styleUrls: ['./flotta-casco-ajanlatok.component.scss']
})
export class FlottaCascoAjanlatokComponent implements OnInit {
  flottaAjanlatokFormGroup: FormGroup;
  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  dataSource: MatTableDataSource<any>;
  matPaginator: MatPaginator;
  @ViewChild('matPaginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dataSource.paginator = this.matPaginator;
    }
  }
  getDisplayedSzaporitasColumns(): string[] {
    let displayedColumns = this.szaporitasokTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  szaporitasokTablaOszlopok: any[] = [

    //ügyszám, beérkezés dátuma, flotta, szerződő, állapot, ajánlatszámok

    { columnDef: 'f_ugyszam', header: 'Ügyszám' },
    { columnDef: 'f_datum', header: 'Beérkezés dátuma', adattipus: 'date', dateFormat: 'YYYY.MM.DD' },
    { columnDef: 'f_flotta_azon', header: 'Flotta' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot', header: 'Áll.', visible: false },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot' },
    { columnDef: 'f_vonalkod', header: 'Ajánlatszámok' },
    { columnDef: 'f_uid', header: '', visible: false },
    { columnDef: 'f_alkusz_telefon', header: '', visible: false },
    { columnDef: 'f_alkusz_email', header: '', visible: false },
    { columnDef: 'f_flotta_nev', header: '', visible: false },
    { columnDef: 'f_szemle', header: '', visible: false },
    { columnDef: 'f_egyeni_megjegyzes', header: '', visible: false }
  ];


  /* Szaporításhoz tartozó gépjármű azonosítók*/
  cascoBovitesGepjarmuvekOszlopok: any[] = [
    { columnDef: 'f_gj_azon', header: 'XXX' }
  ];
  getDisplayedCascoBovitesGepjarmuvekColumns(): string[] {
    let displayedColumns = this.cascoBovitesGepjarmuvekOszlopok.map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceCascoBovitesGepjarmuvek: MatTableDataSource<any>;


  ajanlatFajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];
  @ViewChild('fileUpload', { static: true }) fileUpload: GranitFileUploadComponent;

  toltoKepernyoKereses: boolean = false;
  feltoltes: boolean = false;
  allapotKodLista: any = [];
  highlightedRow: any = [];
  fedezetIgazolas: boolean = false;
  fajlFeltoltesEredmeny: string = "";

  constructor(private formBuilder: FormBuilder,   
    public hibaService: HibaService,
    private flottaSzaporitasService: FlottaSzaporitasService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    private dokumentumokService: DokumentumService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    this.flottaAjanlatokFormGroup = this.formBuilder.group({
      alkuszNev: new FormControl(''),
      alkuszTorzsszam: new FormControl(''),
      flottaAzonosito: new FormControl(''),
      szerzodoNev: new FormControl(''),
      feltoltesFlotta: new FormControl(''),
      feltoltesSzerzodoNev: new FormControl(''),
      feltoltesUgyszam: new FormControl(''),
      allapot: new FormControl('-1', Validators.required),
      ajanlatFeltoltesDokumentumok: new FormControl(''),
      feltoltesAlkuszTelefon: new FormControl(''),
      feltoltesAlkuszEmail: new FormControl(''),
      feltoltesFlottaNev: new FormControl(''),
      feltoltesVonalkodok: new FormControl(''),
      feltoltesUid: new FormControl(''),
      f_allapot_szoveg: new FormControl(''),
      f_kapcsolattarto_neve: new FormControl(''),
      f_kapcsolattarto_telszam: new FormControl(''),
      f_kapcsolattarto_email: new FormControl('', [Validators.pattern("^[-_a-z0-9][-_.a-z0-9]*@[-_.a-z0-9]+(\.[-_.a-z0-9]+)*\.([a-z]{2,10})$")]),
      f_kapcsolattarto_fenykep: new FormControl('')
    },
      {
        validators: [AdatValidator.kapcsolattartoNevEllenor(), AdatValidator.kapcsolattartoTelszamEllenor(), AdatValidator.kapcsolattartoEmailEllenor(), AdatValidator.kapcsolattartoFenykepEllenor()]
      });

    if (this.aktualisFelhasznalo != null) {
      this.flottaAjanlatokFormGroup.controls["alkuszNev"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev);
      this.flottaAjanlatokFormGroup.controls["alkuszTorzsszam"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
    }

    this.dataSource = new MatTableDataSource();
    this.szuresOnClick();
    this.allapotok();

    this.dataSourceCascoBovitesGepjarmuvek = new MatTableDataSource();
  }

  highlighted(row) {
    this.highlightedRow = [];
    this.highlightedRow.push(row);
    this.feltoltes = false;
    this.flottaAjanlatokFormGroup.controls["f_allapot_szoveg"].setValue(this.highlightedRow[0].controls["f_allapot_szoveg"].value);
    if (this.highlightedRow[0].controls["f_szemle"].value == "I") {
      this.szemleAdatok();
    }
    this.tarsbiztositottiAdatok();
    this.scrollToElement('divReszletesAdatok');
  }

  allapotok() {
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasAllapotKodok().subscribe(
      data => {
        this.allapotKodLista = data.filter(x => x.f_allapotkod != 0 && x.f_allapotkod != 7);
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az állapotkódok lekérésnél",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      });
  }

  letoltesOnClick() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_modozat: "CASCO"
    };
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasAjanlatPdf(model).subscribe(
      data => {
        if (data != null) {
          if (data.eredmenykod == 0) {
            let contentType = 'application/pdf';
            let blob = base64StringToBlob(data.file, contentType);
            saveAs(blob, data.filename);
          }
          else {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hibás kérés",
              confirmButtonText: 'Rendben'
            });
          }
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás kérés",
            confirmButtonText: 'Rendben'
          });
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      });
  }

  feltoltesOnClick() {
    this.feltoltes = true;
    this.flottaAjanlatokFormGroup.controls["feltoltesFlotta"].setValue(this.highlightedRow[0].controls["f_flotta_azon"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesSzerzodoNev"].setValue(this.highlightedRow[0].controls["f_ugyfelnev"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesUgyszam"].setValue(this.highlightedRow[0].controls["f_ugyszam"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesAlkuszTelefon"].setValue(this.highlightedRow[0].controls["f_alkusz_telefon"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesAlkuszEmail"].setValue(this.highlightedRow[0].controls["f_alkusz_email"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesFlottaNev"].setValue(this.highlightedRow[0].controls["f_ugyfelnev"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesVonalkodok"].setValue(this.highlightedRow[0].controls["f_vonalkod"].value);
    this.flottaAjanlatokFormGroup.controls["feltoltesUid"].setValue(this.highlightedRow[0].controls["f_uid"].value);
  }

  fedezetIgazolasOnClick() {

    let model = {
      f_dokumentum: "F",
      f_szerzodesSzamok: "",
      f_kotegelt: false,
      f_flotta: null,
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_ugy_modozat: "CASCO",
      f_ugy_tipus: "szaporítás"
    }

    this.toltoKepernyoKereses = true;
    this.dokumentumokService.dokumentumIgenyles(model).subscribe(
      data => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres igénylés',
          message: data.eredmeny_szoveg,
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      });
  }

  feltoltesOkOnClick() {
    this.toltoKepernyoKereses = true;

    let model = {
      formgroup: this.flottaAjanlatokFormGroup.value,
      allapot: this.highlightedRow[0].controls["f_allapot"].value
    }

    this.flottaSzaporitasService.flottaSzaporitasAjanlatFeltoltesCasco(model).subscribe(
      data => {
        this.szuresOnClick();
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: "A feltöltés sikeresen megtörtént.",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hibás mentés',
          message: "Hiba történt az ajánlat feltöltésekor.",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      }
    );
    this.feltoltes = false;
  }

  feltoltescCancelOnClick() {
    this.feltoltes = false;
  }

  torlesOnClick() {
    // Megerősítő dialog ablak
    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Törlés megerősítés',
      message: 'Biztosan törölni kívánja az ajánlatot?',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.toltoKepernyoKereses = true;
        // Model, amit a controllerbe küldünk
        let model = {
          f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value
        };

        this.flottaSzaporitasService.flottaSzaporitasTorles(model).subscribe(
          data => {
            this.szuresOnClick();
            this.toltoKepernyoKereses = false;
            this.utilityService.dialogMegnyitasa({
              success: true,
              title: 'Sikeres törlés',
              message: "A törlés sikeresen megtörént.",
              confirmButtonText: 'Rendben'
            });

          },
          errorResult => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hibás kérés",
              confirmButtonText: 'Rendben'
            });
            this.toltoKepernyoKereses = false;
          }
        );
      }
    });
  }

  szuresOnClick() {
    let allapot = null;
    if (this.flottaAjanlatokFormGroup.controls["allapot"].value == '0') {
      allapot = null;
    }

    let ugyszam: number = null;
    if (this.highlightedRow && this.highlightedRow.length > 0) {
      ugyszam = this.highlightedRow[0].controls["f_ugyszam"].value;
    }

    let model = {
      //f_torzsszam: this.flottaAjanlatokFormGroup.controls["alkuszTorzsszam"].value,
      f_flotta: this.flottaAjanlatokFormGroup.controls["flottaAzonosito"].value,
      f_szerzodo_nev: this.flottaAjanlatokFormGroup.controls["szerzodoNev"].value,
      f_allapot: this.flottaAjanlatokFormGroup.controls["allapot"].value,
      f_modozat: "CASCO"
    }
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitoAlkuszListaCasco(model).subscribe(
      data => {
        if (data != null) {
          const fgs = data.map(item => {
            return this.utilityService.formGroupObjektumbol(item);
          });
          this.dataSource.data = this.formBuilder.array(fgs).controls;
          //this.dataSource.paginator = this.paginator;

          if (ugyszam != null) {
            
            setTimeout(() => {
              let sor: number = 0;
              let ok: boolean = false;

              while (!ok && sor < this.dataSource.data.length) {

                let row: FormGroup = this.dataSource.data[sor];
                if (Number(row.controls["f_ugyszam"].value.toString()) == ugyszam) {
                  ok = true;
                  this.highlightedRow = [];
                  this.highlightedRow.push(row);
                }
                else {
                  sor++;
                }
              }

              let pageindex: number = Math.trunc(sor / this.dataSource.paginator.pageSize);
              this.dataSource.paginator.pageIndex = pageindex;
              this.dataSource.paginator.page.emit({
                length: this.dataSource.paginator.getNumberOfPages(),
                pageIndex: pageindex,
                pageSize: this.dataSource.paginator.pageSize
              });
            }, 0);

          }

        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás kérés",
            confirmButtonText: 'Rendben'
          });
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      }
    );
  }

  tarsbiztositottiAdatok() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value
    }
    this.flottaSzaporitasService.flottaCascoTarsbiztositottiAdatok(model).subscribe(
      data => {
        if (data != null) {
          const fgs = data.map(item => {
            return this.utilityService.formGroupObjektumbol(item);
          });
          this.fedezetIgazolas = false;
          this.dataSourceCascoBovitesGepjarmuvek.data = this.formBuilder.array(fgs).controls;
          this.dataSourceCascoBovitesGepjarmuvek.data.forEach(adat => {
            if (adat.controls["f_tarsbiztositotti_szukseges"].value == "I" &&
              adat.controls["f_hitelintezet_neve"].value != "" &&
              adat.controls["f_hitelintezet_cime"].value != "" &&
              adat.controls["f_hitelszerzodes_azonosito"].value != "" &&
              adat.controls["f_szerzodes_tipusa"].value != "" &&
              adat.controls["f_szerzodes_kezdete"].value != null &&
              adat.controls["f_szerzodes_osszege"].value != null &&
              adat.controls["f_szerzodes_devizanem"].value != "" &&
              adat.controls["f_szerzodes_lejarata"].value != null) {
              this.fedezetIgazolas = true;
            }
          });
        }        
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  tarsbiztositottiMentes(row) {

    this.flottaSzaporitasService.flottaCascoTarsbiztositottiAdatokMentes(row.value).subscribe(
      data => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: "A társbiztosítotti adatokat sikeresen elmentettük",
          confirmButtonText: 'Rendben'
        });
        this.fedezetIgazolas = true;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a mentés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  onBezaras() {
    this.highlightedRow = [];
  }

  szemleAdatok() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_ugytipus: 'S'
    }
    this.flottaSzaporitasService.flottaCascoSzemleAdatok(model).subscribe(
      data => {
        if (data != null) {

          this.flottaAjanlatokFormGroup.controls["f_kapcsolattarto_neve"].setValue(data["f_nev"]);
          this.flottaAjanlatokFormGroup.controls["f_kapcsolattarto_telszam"].setValue(data["f_telefon"]);
          this.flottaAjanlatokFormGroup.controls["f_kapcsolattarto_email"].setValue(data["f_email"]);
          this.flottaAjanlatokFormGroup.controls["f_kapcsolattarto_fenykep"].setValue(data["f_fenykep"]);

        }     
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  compareObjects(object1, object2, key/*, direction*/) {
    const obj1 = object1.controls[key].value.toString().toUpperCase();
    const obj2 = object2.controls[key].value.toString().toUpperCase();

    if (obj1 < obj2) {
      return /*direction == "asc" ?*/ -1/* : 1 */
    }
    if (obj1 > obj2) {
      return /*direction == "asc" ?*/ 1/*: -1*/
    }
    return 0
  }

  szemleAdatokMentes() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_ugytipus: 'S',
      f_formgroup: this.flottaAjanlatokFormGroup.value
    }

    this.flottaSzaporitasService.flottaCascoSzemleAdatokMentes(model).subscribe(
      data => {
        this.szuresOnClick();

        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: "A kapocsolttartó adatainak mentése sikeresen megtörtént",
          confirmButtonText: 'Rendben'
        });
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  scrollToElement(elementName): void {
    var element = document.getElementById(elementName);
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny += element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }

}

export class AdatValidator extends Validators {


  static kapcsolattartoNevEllenor() {

    let validator = (formGroup: FormGroup) => {

      const control2 = formGroup.controls['f_allapot_szoveg'];
      const control1 = formGroup.controls['f_kapcsolattarto_neve'];
      control1.setErrors(null);

      if (control2 && control2.value == "ajánlat szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoNevlHiany: true });
        }

      }
    }
    return validator;
  }

  static kapcsolattartoTelszamEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_telszam'];
      const control2 = formGroup.controls['f_allapot_szoveg'];
      control1.setErrors(null);

      if (control2 && control2.value == "ajánlat szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoTelszamlHiany: true });
        }

      }
    }
    return validator;
  }

  static kapcsolattartoEmailEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_email'];
      const control2 = formGroup.controls['f_allapot_szoveg'];

      if (!control1.errors || (control1.errors && !control1.errors.pattern)) {
        control1.setErrors(null);

        if (control2 && control2.value == "ajánlat szemleköteles") {

          if (!control1.value || control1.value.toString().length == 0) {
            control1.setErrors({ kapcsolattartoEmailHiany: true });
          }

        }
      }
    }
    return validator;
  }

  static kapcsolattartoFenykepEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_fenykep'];
      const control2 = formGroup.controls['f_allapot_szoveg'];
      control1.setErrors(null);

      if (control2 && control2.value == "ajánlat szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoFenykepHiany: true });
        }

      }
    }
    return validator;
  }  
}
