import { Component, HostListener, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilityService } from '@granit/common-library';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private utilityService: UtilityService,
    private menuService: MenuService) { }

  title = 'ClientApp';
  @ViewChild('portalmenuSideNav', { static: true }) public portalmenuSideNav: MatSidenav;

  ngOnInit(): void {
    this.menuService.setportalMenuSideNav(this.portalmenuSideNav);
  }
}
