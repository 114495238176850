<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Szerződés adatok'"
                                [subTitle]="''"
                                [templateOutlet]="szerzodesadatok"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Szerződés adatok'"
                                [subTitle]="''"
                                [templateOutlet]="szerzodesadatok">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #szerzodesadatok>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">

        <form [formGroup]="szerzodesAdatokFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onInfoLathatoValt()">
              <mat-card-header>
                <mat-card-title>Információ</mat-card-title>
                <button mat-icon-button *ngIf="infoLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!infoLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>

              </mat-card-header>
            </div>
            <mat-card-content *ngIf="infoLathato">

              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <!--
                <span>
                  Az alkalmazás használatához a <asp:Image ImageUrl="~/Style/Images/help.png" runat="server" ID="Image1"/> ablakban talál hasznos információkat.
                </span>
                -->
              </div>
              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <span>
                  Bármilyen észrevétel, vagy kérdés esetén legyen szíves írásban forduljon bizalommal alkusz összekötőjéhez, javaslataikat igyekszünk lehetőség szerint figyelembe venni és megvalósítani.
                </span>
              </div>

            </mat-card-content>
          </mat-card>

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onKeresesLathatoValt()">
              <mat-card-header>
                <mat-card-title>Keresés</mat-card-title>
                <button mat-icon-button *ngIf="keresesLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!keresesLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </mat-card-header>
            </div>
            <mat-card-content *ngIf="keresesLathato">

              <ng-container *ngIf="toltoKepernyo">
                <div class="row">
                  <div class="col-lg-12 bg-white">
                    <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!toltoKepernyo">

                <div class="row pt-1">

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződésszám</mat-label>
                      <input type="number" matInput formControlName="f_szerzazon" (focusout)="szerzazonEllenor()">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_szerzazon').errors?.hosszHiba">
                        <mat-icon>error</mat-icon>
                        A szerződésszámnak 8 számból kell állnia!
                      </mat-error>
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_szerzazon').errors?.szamHiba">
                        <mat-icon>error</mat-icon>
                        A szerződésszám csak számot tartalmazhat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Flotta azonosító</mat-label>
                      <input type="number" matInput formControlName="f_flotta" (focusout)="flottaEllenor()">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_szerzazon').errors?.szamHiba">
                        <mat-icon>error</mat-icon>
                        A flotta azonosító csak számot tartalmazhat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Rendszám</mat-label>
                      <input matInput formControlName="f_rendszam" (focusout)="rendszamEllenor()">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_rendszam').errors?.hosszHiba">
                        <mat-icon>error</mat-icon>
                        A rendszámnak 6 vagy 7 karakterből kell állnia!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Ajánlatszám</mat-label>
                      <input type="number" matInput formControlName="f_ajanlat">
                    </mat-form-field>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Kock. kezdettől</mat-label>
                      <input matInput [matDatepicker]="kockkezdtolPicker" formControlName="f_kockkezdtol" [min]="kockkezdttolMinDate" (dateChange)="kockkezdtolValtozas()">
                      <mat-datepicker-toggle matSuffix [for]="kockkezdtolPicker"></mat-datepicker-toggle>
                      <mat-datepicker #kockkezdtolPicker></mat-datepicker>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_kockkezdtol').errors?.matDatepickerParse">
                        A kock. kezdettől nem valós dátum!
                      </mat-error>
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_kockkezdtol').errors?.matDatepickerMin">
                        A kock. kezdettől nem lehet kisebb 2008.01.01-nél!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Kock. kezdetig</mat-label>
                      <input matInput [matDatepicker]="kockkezdigPicker" formControlName="f_kockkezdig" [min]="kockkezdtigMinDate">
                      <mat-datepicker-toggle matSuffix [for]="kockkezdigPicker"></mat-datepicker-toggle>
                      <mat-datepicker #kockkezdigPicker></mat-datepicker>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="szerzodesAdatokFormGroup.get('f_kockkezdig').errors?.matDatepickerParse">
                        A kock. kezdetig nem valós dátum!
                      </mat-error>
                    </div>

                  </div>

                  <div class="col-lg-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő neve</mat-label>
                      <input matInput formControlName="f_nev">
                    </mat-form-field>
                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Módozat</mat-label>
                      <mat-select matInput formControlName="f_modozat">
                        <mat-option *ngFor="let modozat of modozatokLista" [value]="modozat.f_MODRNEV">{{modozat.f_MODNEV}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Állapot</mat-label>
                      <mat-select matInput formControlName="f_allapot" multiple="true">
                        <mat-option value="É">Élő</mat-option>
                        <mat-option value="SZ">Szüneteltetett</mat-option>
                        <mat-option value="TD">Törölt DNF</mat-option>
                        <mat-option value="TE">Törölt egyéb</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row pb-3">
                  <div class="col-lg-3">
                    <button mat-raised-button color="accent" [disabled]="!szerzodesAdatokFormGroup.valid" (click)="onKereses()">Keresés</button>
                  </div>
                </div>


              </ng-container>
            </mat-card-content>
          </mat-card>

          <div [hidden]="!szerzodesAdatokFormGroup.get('f_eredmeny_van').value">
            <mat-card class="form-content-2 mb-3 pt-2 pb-3 col-lg-10">
              <div class="card-expand-div" (click)="onEredmenyLathatoValt()">
                <mat-card-header>
                  <mat-card-title>Eredmény</mat-card-title>

                  <button mat-icon-button *ngIf="eredmenyLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!eredmenyLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>

                </mat-card-header>
              </div>
              <mat-card-content [hidden]="!eredmenyLathato">
                <mat-table [dataSource]="szerzodesekTabla" class="w100 mr-5 table_border" [matSortActive]="'f_szerz_azon'" [matSortDirection]="matSortDirection" matSort>
                  <ng-container *ngFor="let column of szerzodesekOszlopok" [matColumnDef]="column.columnDef">
                    <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>
                      <span [innerHTML]="column.header"></span>                                       
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                              [class]=column.class>                      
                        <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>                                            
                    </mat-cell>
                  </ng-container>
                  <!--Fejléc-->
                  <mat-header-row *matHeaderRowDef="getDisplayedSzerzodesekOszlopok()"></mat-header-row>
                  <!--Sorok-->
                  <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedSzerzodesekOszlopok();" (click)="onAdatokClick(row)"></mat-row>
                </mat-table>
                <mat-paginator #paginatorSzerzodesek [pageSizeOptions]="[10, 50, 100, 150]" showFirstLastButtons></mat-paginator>
              </mat-card-content>
            </mat-card>
          </div>

          <div *ngIf="szerzodesAdatokFormGroup.get('f_valasztva').value">
            <mat-card class="form-content-2 mb-3 pt-2 pb-3 col-lg-10">
              <div class="card-expand-div" (click)="onAdatokLathatoValt()">
                <mat-card-header>
                  <mat-card-title>Adatok</mat-card-title>

                  <button mat-icon-button *ngIf="adatokLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!adatokLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>

                </mat-card-header>
              </div>
              <mat-card-content>
                <portal-szerzodes-adatok *ngIf="azonosito && azonosito != ''" [alkalmazasEnum]="1" [azonositoInput]="azonosito"></portal-szerzodes-adatok>
              </mat-card-content>
            </mat-card>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>
