import { Component, OnInit, Output, EventEmitter,  ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CommonValidator, HibaService, UtilityService, GranitTableComponent, GranitFileUploadComponent, Dokumentum } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { FlottaSzaporitasService } from '../../../../services/flotta-szaporitas.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

@Component({
  selector: 'flotta-szaporitas',
  templateUrl: './flotta-szaporitas.component.html',
  styleUrls: ['./flotta-szaporitas.component.scss']
})

export class FlottaSzaporitasComponent implements OnInit {
  //isLinear = false;
  excelUrl: string = "assets/documents/flotta_ajanlat.xlsx";
  alkusziSzukseges: boolean = false;
  alkuszFormGroup: FormGroup;
  alkuszElerhetosegFormGroup: FormGroup;
  szerzodoFormGroup: FormGroup;
  alkusziFeltoltesFormGroup: FormGroup;
  szaporitoFeltoltesFormGroup: FormGroup;

  allapot: string = "";

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  alkusziFajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];
  szaporitoFajlKiterjesztesek: string[] = [".xlsx"];

  @ViewChild('fileUpload', { static: true }) fileUpload: GranitFileUploadComponent;
  @ViewChild('stepper') stepper: MatStepper;

  currentFormGroup: FormGroup;

  folyamatVege: boolean = false;
  toltoKepernyo: boolean = false;
  fajlFeltoltesEredmeny: string = "";

  constructor(private formBuilder: FormBuilder,    
    public hibaService: HibaService,
    private flottaSzaporitasService: FlottaSzaporitasService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService) {
  }

  ngOnInit(): void {

    //this.aktualisFelhasznalo = new Felhasznalo();
    //this.aktualisFelhasznalo.f_email = "boros.tibor@granitbiztosito.hu";
    //this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev = "Dijkonroll kft.";
    //this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam = "0000000390";

    this.createFormGroups();
  }

  createFormGroups() {
    this.alkuszFormGroup = this.formBuilder.group({
      alkuszNev: new FormControl(''),
      alkuszTorzsszam: new FormControl(''),
      alkuszEmail: new FormControl('')
    });
    if (this.aktualisFelhasznalo != null) {
      this.alkuszFormGroup.controls["alkuszNev"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev);
      this.alkuszFormGroup.controls["alkuszTorzsszam"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
      this.alkuszFormGroup.controls["alkuszEmail"].setValue(this.aktualisFelhasznalo.f_email);
    }
    this.currentFormGroup = this.alkuszFormGroup;

    this.alkuszElerhetosegFormGroup = this.formBuilder.group({
      chkElteroEmail: new FormControl(''),
      alkuszElteroEmail: new FormControl(''),
      alkuszElteroEmailIsmet: new FormControl(''),
      alkuszTelefon: new FormControl(''),
      hibaSzoveg: new FormControl(''),
    },
      {
        validators: [CommonValidator.osszehasonlitas('alkuszElteroEmail', 'alkuszElteroEmailIsmet')]
      }
    );

    this.alkuszElerhetosegFormGroup.controls['chkElteroEmail'].valueChanges.subscribe(
      value => {
        if (value == 'true') {
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmail'].setValidators([Validators.required, Validators.email]);
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmailIsmet'].setValidators([Validators.required, Validators.email]);
        }
        else {
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmail'].reset();
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmailIsmet'].reset();
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmail'].setValidators([Validators.email]);
          this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmailIsmet'].setValidators([Validators.email]);
        }

        this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmail'].updateValueAndValidity();
        this.alkuszElerhetosegFormGroup.controls['alkuszElteroEmailIsmet'].updateValueAndValidity();
      }
    );

    this.szerzodoFormGroup = this.formBuilder.group({
      flottaAzonosito: new FormControl('', Validators.required),
      szerzodoAdoszam: new FormControl('', Validators.required),
      kotesOka: new FormControl('01', Validators.required),
      szerzodoNev: new FormControl('', Validators.required),
      szerzodoCimEgyben: new FormControl('', Validators.required),
      hibaSzoveg: new FormControl('')
    });

    this.alkusziFeltoltesFormGroup = this.formBuilder.group({
      alkusziFeltoltesDokumentum: new FormControl(''),
      hibaSzoveg: new FormControl('')
    });
    this.szaporitoFeltoltesFormGroup = this.formBuilder.group({
      szaporitoFeltoltesDokumentum: new FormControl(''),
      hibaSzoveg: new FormControl(''),
      ugyszam: new FormControl(null)
    });
  }

  szerzodoKereses() {
    this.toltoKepernyo = true;
    this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(null);
    let model = {
      adoszam: this.szerzodoFormGroup.controls["szerzodoAdoszam"].value,
      flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      kotes_oka: this.szerzodoFormGroup.controls["kotesOka"].value
    };
    this.flottaSzaporitasService.flottaSzerzodoKereses(model).subscribe(
      data => {
        if (data != null) {
          this.szerzodoFormGroup.controls["szerzodoNev"].setValue(data.ugyfelnev);
          this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue(data.cimegyben);
          this.alkusziSzukseges = (data.alkuszi_szukseges == "I");
          if (data.ugyfelnev == null || data.ugyfelnev == '') {
            this.szerzodoFormGroup.controls["hibaSzoveg"].setValue("<p>A megadott adatokkal nem található szerződő.</p>");
          }
          if (data.info != null && data.info != '') {
            this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(data.info);
          }
        }
        else {
          this.szerzodoFormGroup.controls["hibaSzoveg"].setValue("<p>A megadott adatokkal nem található szerződő.</p>");
        }
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.szerzodoFormGroup.controls["szerzodoNev"].setValue("");
        this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue("");
        this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(errorResult);
        this.toltoKepernyo = false;
      });
  }

  alkusziFeldolgozas() {
    this.toltoKepernyo = true;

    let email = this.alkuszFormGroup.controls["alkuszEmail"].value;
    if (this.alkuszElerhetosegFormGroup.controls["chkElteroEmail"].value && this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value != null && this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value != '') {
      email = this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value;
    }

    let model = {
      f_alkusz_telefon: this.alkuszElerhetosegFormGroup.controls["alkuszTelefon"].value,
      f_alkusz_email: email,
      f_flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      f_flotta_nev: this.szerzodoFormGroup.controls["szerzodoNev"].value,
      f_szerzodo_cim: this.szerzodoFormGroup.controls["szerzodoCimEgyben"].value,
      f_dokumentum: this.alkusziFeltoltesFormGroup.controls["alkusziFeltoltesDokumentum"].value,
      f_modozat: "GFB"
    }
    this.flottaSzaporitasService.flottaAlkusziFeldolgozas(model).subscribe(
      () => {
        this.folyamatVege = true;
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Siker',
          message: "A fájl feltöltése sikeres",
          confirmButtonText: 'Rendben'
        });
        this.alkusziFeltoltesFormGroup.controls["hibaSzoveg"].setValue("Az feltöltött alkuszi megbízást a rendszerünk fogadta. A feldolgozását kollégáink 24 órán belül megkezdik.");
        this.toltoKepernyo = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt az alkuszi megbízás feldolgozása közben!",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyo = false;
      });
}

  szaporitoFeldolgozas() {
    this.toltoKepernyo = true;
    let email = this.alkuszFormGroup.controls["alkuszEmail"].value;

    if (this.alkuszElerhetosegFormGroup.controls["chkElteroEmail"].value && this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value != null && this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value != '') {
      email = this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value;
    }

    let model = {
      f_alkusz_torzsszam: this.alkuszFormGroup.controls["alkuszTorzsszam"].value,
      f_alkusz_telefon: this.alkuszElerhetosegFormGroup.controls["alkuszTelefon"].value,
      f_alkusz_email: email,
      f_flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      f_adoszam: this.szerzodoFormGroup.controls["szerzodoAdoszam"].value,
      f_kotes_oka: this.szerzodoFormGroup.controls["kotesOka"].value,
      f_dokumentum: this.szaporitoFeltoltesFormGroup.controls["szaporitoFeltoltesDokumentum"].value
    }

    this.allapot = '';
    this.flottaSzaporitasService.flottaSzaporitoExcelFeldolgozas(model).subscribe(
      data => {
        if (data["azonosito"] != null && data["azonosito"] != '') {
          this.folyamatVege = true;
          this.szaporitoFeltoltesFormGroup.controls["hibaSzoveg"].setValue("Sikeres szaporitás. (" + data["azonosito"] + ")");
          this.szaporitoFeltoltesFormGroup.controls["ugyszam"].setValue(data["azonosito"]);
          this.allapot = data["allapot"];
          this.utilityService.dialogMegnyitasa({
            success: true,
            title: 'Siker',
            message: "Az excel feltöltése sikeres",
            confirmButtonText: 'Rendben'
          });
          
        }
        else {
          let hibaszoveg: string = '';
          data["hibak"].forEach(hiba => {
            hibaszoveg = hibaszoveg + "<li>" + hiba + "</li>";
          });
          if (hibaszoveg.length > 0) {
            hibaszoveg = "<ul>" + hibaszoveg + "</ul>";
          }
          this.szaporitoFeltoltesFormGroup.controls["hibaSzoveg"].setValue(hibaszoveg);
        }
        this.toltoKepernyo = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt a szaporítás feldolgozása közben!",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyo = false;
      });
  }

  resetForm() {
    /*
    this.stepper.reset();
    this.createFormGroups();
    */
    window.location.reload();
  }

  szerzodoAdatokTorles() {
    this.szerzodoFormGroup.controls["szerzodoNev"].setValue("");
    this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue("");
  }


  public onStepChange(event: any) {
    let hiba = false;
    switch (event.previouslySelectedStep.stepControl) {
      case this.alkuszFormGroup:
        this.currentFormGroup = event.selectedStep.stepControl;
        break;
      case this.szerzodoFormGroup:
        this.currentFormGroup = event.selectedStep.stepControl;
        break;

    }
  }

  letoltesOnClick(row) {
    let model = {
      f_ugyszam: this.szaporitoFeltoltesFormGroup.controls["ugyszam"].value,
      f_modozat: "GFB"
    };
    this.toltoKepernyo = true;
    this.flottaSzaporitasService.flottaSzaporitasAjanlatPdf(model).subscribe(
      data => {
        if (data != null) {
          if (data.eredmenykod == 0) {
            let contentType = 'application/pdf';
            let blob = base64StringToBlob(data.file, contentType);
            saveAs(blob, data.filename);
          }
          else {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hibás kérés",
              confirmButtonText: 'Rendben'
            });
          }
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás kérés",
            confirmButtonText: 'Rendben'
          });
        }
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyo = false;
      });
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny += element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }
}
