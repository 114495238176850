import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EgyenlegkozloService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/egyenlegkozlo';

  
  // Egyenlegközlő
  egyenlegkozlo(model: any) {
          
    return this.httpClient.post<any>(this.url + '/Egyenlegkozlo', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
