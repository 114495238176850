<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Regisztráció véglegesítése'"
                                [subTitle]="'Kérjük adja meg a kért adatokat, és pár percen belül hozzáférhet a fiókjához.'"
                                [templateOutlet]="regisztracio">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #regisztracio>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Töltse ki a kért adatokat</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card>
            <mat-card-content class="mb-0">
              <ng-container *ngIf="toltoKepernyo">
                <div class="col-lg-11 ml-auto mr-auto bg-white pt-3 pr-3 pl-3">
                  <ngx-skeleton-loader count="3" [theme]="{'border-radius': '0', height: '50px' }">
                  </ngx-skeleton-loader>
                </div>
              </ng-container>
              <ng-container *ngIf="!toltoKepernyo">
                <div class="row justify-content-end flex-row-reverse">
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                    <div class="information-wrapper mb-4">
                      <div>
                        A regisztráció véglegesítéséhez adja meg a profilhoz kapcsolódó adatokat.<br /><br />
                        A felhasználó névnek egyedinek kell lennie és minimum 6 karakterből kell állnia. A regisztráció után a felhasználó név módosítására nincs lehetőség.<br /><br />
                        A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot.
                        Kérjük kerülje az ékezetes karakterek használatát.
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                    <form [formGroup]="profilAdatokFormGroup" class="form-group mt-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Felhasználó név</mat-label>
                            <input id="username" matInput formControlName="f_felhasznalo_nev" required autocomplete="username">
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adjon meg egy felhasználó nevet!
                            </mat-error>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'minlength')">
                              <mat-icon>error</mat-icon>
                              A felhasználó névnek minimum 6 karakterből kell állnia.
                            </mat-error>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_felhasznalo_nev', 'felhasznaloNevFoglalt')">
                              <mat-icon>error</mat-icon>
                              A felhasználónév már foglalt! Kérjük, adjon meg másikat.
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>                      
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Jelszó</mat-label>
                            <input id="new-password" matInput formControlName="f_jelszo" [type]="jelszoElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                            <button type="button" mat-icon-button matSuffix (click)="jelszoElrejtese = !jelszoElrejtese">
                              <mat-icon>{{jelszoElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adjon meg egy jelszót!
                            </mat-error>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo', 'hibasJelszo')">
                              <mat-icon>error</mat-icon>
                              A jelszónak minimum 12 karakterből kell állnia és tartalmaznia kell legalább egy nagybetűt, egy kis betűt és egy számot. Kérjük kerülje az ékezetes karakterek használatát.
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Jelszó ismét</mat-label>
                            <input id="new-password-ismet" matInput formControlName="f_jelszo_ismet" [type]="jelszoIsmetElrejtese ? 'password' : 'text'" required autocomplete="new-password">
                            <button type="button" mat-icon-button matSuffix (click)="jelszoIsmetElrejtese = !jelszoIsmetElrejtese">
                              <mat-icon>{{jelszoIsmetElrejtese ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo_ismet', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adja meg az új jelszavát ismét!
                            </mat-error>
                            <mat-error *ngIf="hibaService.hiba(profilAdatokFormGroup, 'f_jelszo_ismet', 'nemEgyezik')">
                              <mat-icon>error</mat-icon>
                              Kérjük, helyesen ismételje meg jelszavát!
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <button mat-raised-button type="button" [disabled]="!profilAdatokFormGroup.valid" color="accent" (click)="onRegisztracioVeglegesitesClick()">Regisztráció véglegesítése</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>


