import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'bejelentkezes',
  templateUrl: './bejelentkezes.component.html',
  styleUrls: ['./bejelentkezes.component.scss']
})
export class BejelentkezesComponent implements OnInit { 

  karbantartas: string = "I";

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {      
  }

  olvasas(event: string) {
    this.karbantartas = event;
    this.cdr.detectChanges();
  }

}
