import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HibaService, UtilityService, GranitFileUploadComponent } from '@granit/common-library';
import { FlottaService } from '../../../../services/flotta.service';

@Component({
  selector: 'uj-flotta-alkuszi-hianypotlas',
  templateUrl: './uj-flotta-alkuszi-hianypotlas.component.html',
  styleUrls: ['./uj-flotta-alkuszi-hianypotlas.component.scss']
})
export class UjFlottaAlkusziHianypotlasComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private flottaService: FlottaService,
    public hibaService: HibaService,
    private utilityService: UtilityService,
    private router: Router) {

    // uid querystring kiolvasása
    this.route.queryParams.subscribe(params => {
      // Ha az uid querystringnek van értéke
      if (params['uid']) {
        this.uid = (params['uid'] as string);
      }
    });
  }

  uid: string;
  adatok: any;
  alkuszMegbizasHianypotlasFormGroup: FormGroup;
  toltoKepernyo: boolean = false;
  fajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];

  @ViewChild('fileUpload', { static: false }) fileUpload: GranitFileUploadComponent;

  ngOnInit(): void {
    this.alkuszMegbizasHianypotlasFormGroupLetrehozasa();
    this.formAdatokLekerese();
  }


  // Alkuszi megbízás hiánypótlás formgroup létrehozása
  alkuszMegbizasHianypotlasFormGroupLetrehozasa() {
    this.alkuszMegbizasHianypotlasFormGroup = this.formBuilder.group({
      f_fajl: new FormControl(null, [Validators.required]),
      f_megjegyzes: new FormControl(null)
    });
  }

  // Új flotta alkuszi jóváhagyás form adatok lekérése
  formAdatokLekerese() {
    this.toltoKepernyo = true;
    // Kontrollernek model
    let model = {
      uid: this.uid,
      fejlecAdatokSzukseges: true,
      alkusziMegbizasAdatokSzukseges: true
    };
    // Kontroller hívás
    this.flottaService.ujFlottaAdatokLekerese(model).subscribe(
      data => {
        this.adatok = data;
        // Ha nem KezdoAllapot, UjFlottaElbiralasAlatt, DijkalkElbiralasAlatt, BFlottaElbiralasAlatt állapotban van, akkor hiba és vissza a főoldalra
        if (
          this.adatok.ujFlottaFejlecAdatok.f_allapot != 0 &&
          this.adatok.ujFlottaFejlecAdatok.f_allapot != 130 &&
          this.adatok.ujFlottaFejlecAdatok.f_allapot != 230 &&
          this.adatok.ujFlottaFejlecAdatok.f_allapot != 330) {

          const dialogRef = this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'A hivatkozás elavult',
            message: '<p>Észrevételét, hiánypótlását a <a href=\"mailto:flotta@granitbiztosito.hu\">flotta@granitbiztosito.hu</a> e-mail címre küldheti!</p>',
            confirmButtonText: 'Rendben'
          });
          // Csak a dialog ablak bezárása után lépünk a következő stepp-re
          dialogRef.afterClosed().subscribe(
            () => {
              this.router.navigateByUrl('/fooldal');
            }
          );
        }
        else {
          this.toltoKepernyo = false;
        }
      }
    );
  }

  // Tovább gomb esemény
  onTovabb() {
    // Kontrollernek model
    let model = {
      ugyszam: this.adatok.ujFlottaFejlecAdatok.f_ugyszam,
      formGroupValue: this.alkuszMegbizasHianypotlasFormGroup.value
    };
    // Kontroller hívás
    this.flottaService.ujFlottaAlkusziHianypotlasFeldolgozas(model).subscribe(
      () => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: 'A kiválasztott fájl feldogozása megkezdődőtt.',
          confirmButtonText: 'Rendben'
        });

        this.fileUpload.clearInputElement();
        this.fileUpload.clearFilesList();
        this.alkuszMegbizasHianypotlasFormGroupLetrehozasa();
        this.formAdatokLekerese();
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt a feldolgozás során",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

}
