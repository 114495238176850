import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListakService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/listak';

  
  // Lista adatainak lekérése
  listaAdatok(model: any) {
          
    return this.httpClient.post<any[]>(this.url + '/ListaAdatok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Lista megnevezése
  listaLetoltese(model: any) {

    return this.httpClient.post(this.url + '/ListaLetoltese', model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Lista újra - tipus */
  listaUjraTipus() {

    return this.httpClient.post<any[]>(this.url + '/ListaUjraTipus', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Lista újra - év */
  listaUjraEv(model: any) {

    return this.httpClient.post<any[]>(this.url + '/ListaUjraEv', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Lista újra - hónap */
  listaUjraHo(model: any) {

    return this.httpClient.post<any[]>(this.url + '/ListaUjraHo', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Lista újra - lista */
  listaUjraLista(model: any) {

    return this.httpClient.post<any[]>(this.url + '/ListaUjraLista', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Lista újra - mentés */
  listaUjraMentes(model: any) {
    
    return this.httpClient.post(this.url + '/ListaUjraMentes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Üzletkötő még nem jóváhagyott jutalékelszámolása */
  brokerJutElszamJovahagy() {

    return this.httpClient.post<any>(this.url + '/BrokerJutElszamJovahagy', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Üzletkötő még nem jóváhagyott jutalékelszámolásának jóváhagyása */
  brokerJutElszamJovahagyva() {

    return this.httpClient.post(this.url + '/BrokerJutElszamJovahagyva', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MNB adatok fájlba
  mnbFajlba(model: any) {

    return this.httpClient.post<any>(this.url + '/MnbFajlba', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
