import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HibaService, UtilityService, GranitAlkalmazasFajlokComponent } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { OktatasService } from '../../services/oktatas.service';

@Component({
  selector: 'oktatas',
  templateUrl: './oktatas.component.html',
  styleUrls: ['./oktatas.component.scss']
})
export class OktatasComponent implements OnInit {

  tesztFejFormGroup: FormGroup;
  kerdesekList: any = [];
  tesztekList: any = [];
  valaszokList: any = [];
  helyesValaszokList: any = [];
  fajlokLista: any[] = [];

  tesztIndit: boolean = false;
  infoLathato: boolean = true;
  osszKerdes: number = 0;
  osszValasz: number = 0;
  vegeUzenet: string = "";

  @ViewChild('alkFajlok', { static: false }) alkFajlok: GranitAlkalmazasFajlokComponent;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private utilityService: UtilityService,
    private oktatasService: OktatasService) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.tesztekList = [];
    this.kerdesekList = [];
    this.valaszokList = [];
    this.helyesValaszokList = [];
    this.tesztIndit = false;
    this.osszKerdes = 0;
    this.osszValasz = 0;
    this.vegeUzenet = "";
    this.tesztFejFormGroupInit();
    this.aktualisTesztek();
  }

  tesztFejFormGroupInit() {
    this.tesztFejFormGroup = this.formBuilder.group({
      f_teszt_azonosito: new FormControl("0"),
      f_teszt: new FormControl(""),
      f_menupont: new FormControl(null),
      f_menupont_megnevezes: new FormControl(null),
      f_szerepkor: new FormControl(null),
      f_min_helyes_valasz: new FormControl(null)
    });    
  }

  aktualisTesztek() {
    this.recaptchaV3Service.execute('parterportal_oktatas_tesztek').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {          
          this.oktatasService.oktatasTesztek().subscribe(
            data => {              
              this.tesztekList = data;              
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a tesztek lekérése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  onTesztValasztas(teszt: any) {    
    this.tesztFejFormGroup.controls["f_teszt_azonosito"].setValue(teszt.f_teszt_azonosito);
    this.tesztFejFormGroup.controls["f_teszt"].setValue(teszt.f_teszt);
    this.tesztFejFormGroup.controls["f_menupont"].setValue(teszt.f_menupont);
    this.tesztFejFormGroup.controls["f_menupont_megnevezes"].setValue(teszt.f_menupont_megnevezes);
    this.tesztFejFormGroup.controls["f_szerepkor"].setValue(teszt.f_szerepkor);
    this.tesztFejFormGroup.controls["f_min_helyes_valasz"].setValue(teszt.f_min_helyes_valasz);
  }

  aktualisAdatokLekerese() {


    this.kerdesekList = [];

    this.recaptchaV3Service.execute('parterportal_oktatas_tesztadatok').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            tesztAzonosito: this.tesztFejFormGroup.controls["f_teszt_azonosito"].value
          }

          this.oktatasService.oktatasAdatok(model).subscribe(
            data => {

              this.helyesValaszokList.forEach(valasz => {
                data["valaszok"].find(x => x.f_valasz_azonosito == valasz.f_valasz_azonosito)["f_valasztva"] = "I";
              });

              let bekuldendoValaszok = "";
              this.osszValasz = this.helyesValaszokList.length;

              if (this.osszValasz >= this.tesztFejFormGroup.controls["f_min_helyes_valasz"].value) {
                this.valaszokList.sort((a, b) => (a.f_kerdes_azonosito > b.f_kerdes_azonosito) ? 1 : -1).forEach(vv => {
                  if (bekuldendoValaszok != "") {
                    bekuldendoValaszok += ","
                  }
                  bekuldendoValaszok += vv.f_valasz_azonosito.toString();
                })
              }              

              data["kerdesek"].sort(k => k.f_kerdes_azonosito).forEach(element => {
                let indexHelyes: number = this.helyesValaszokList.findIndex(x => x.f_kerdes_azonosito == element.f_kerdes_azonosito);                
                let kerdes = {
                  f_kerdes_azonosito: element.f_kerdes_azonosito,
                  f_kerdes: element.f_kerdes,
                  f_valaszok: data["valaszok"].filter(x => x.f_kerdes_azonosito == element.f_kerdes_azonosito).sort(v => v.f_valasz_azonosito),
                  f_helyes: indexHelyes != -1 ? "I" : "N"
                }

                this.kerdesekList.push(kerdes);

                if (indexHelyes == -1) {
                  let indexValasz = this.valaszokList.findIndex(x => x.f_kerdes_azonosito == element.f_kerdes_azonosito);
                  if (indexValasz > -1) {
                    this.valaszokList.splice(indexValasz, 1);
                  }
                }
                

              })
              this.tesztIndit = true;
              this.osszKerdes = this.kerdesekList.length;
              
              if (this.osszValasz >= this.tesztFejFormGroup.controls["f_min_helyes_valasz"].value) {
                
                let model = {
                  tesztAzonosito: this.tesztFejFormGroup.controls["f_teszt_azonosito"].value,
                  valaszok: bekuldendoValaszok
                }
                this.oktatasService.oktatasTesztBekuldottValaszok(model).subscribe(
                  () => {
                    this.vegeUzenet = "<p><strong>Kedves Partnerünk!</strong></p>";
                    this.vegeUzenet += "<p>Gratulálunk, a feltett kérdésekre helyesen válaszoltál.<br/>";
                    if (this.tesztFejFormGroup.controls["f_szerepkor"].value && this.tesztFejFormGroup.controls["f_szerepkor"].value != "") {
                      this.vegeUzenet += "Jogosultságod kiterjed a " + this.tesztFejFormGroup.controls["f_szerepkor"].value + " szerepkör használatára.<br/>";
                    }                    
                    this.vegeUzenet += "<p>További sikeres munkát kíván a Gránit Biztosító Zrt.</p>";                    
                  },
                  errorResult => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a tesztre adott válaszok mentése közben",
                      confirmButtonText: 'Rendben'
                    });
                  }                                  
                );
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a teszt adatainak lekérése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  onValaszMent(valasz: any) {
    let valasztottValasz = {
      f_kerdes_azonosito: valasz.f_kerdes_azonosito,
      f_valasz_azonosito: valasz.f_valasz_azonosito,
      f_valasz_helyes: valasz.f_valasz_helyes
    }

    let index: number = -1;
    index = this.valaszokList.findIndex(elem => elem.f_kerdes_azonosito == valasz.f_kerdes_azonosito);
    index != -1 ? this.valaszokList[index] = valasztottValasz : this.valaszokList.push(valasztottValasz);

    this.osszValasz = this.valaszokList.length;

  }

  onValaszokBekuldese() {

    this.helyesValaszokList = [];

    this.valaszokList.forEach(valasz => {

      if (valasz.f_valasz_helyes == "I") {
        this.helyesValaszokList.push(valasz);
      }

    });

    this.aktualisAdatokLekerese();
  }
  
  /*info ablak mutatása/elrejtése*/
  onInfoLathatoValt() {
    this.infoLathato = !this.infoLathato;
  }

  onRendben() {
    this.init();
  }

}
