import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HibaService, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { KafKszfDijszamoloService } from '../../services/_index';


@Component({
  selector: 'kaf-kszf-dijszamolo-bovites-eseten',
  templateUrl: './kaf-kszf-dijszamolo-bovites-eseten.component.html',
  styleUrls: ['./kaf-kszf-dijszamolo-bovites-eseten.component.scss']
})
export class KafKszfDijszamoloBovitesEsetenComponent implements OnInit {  

  toltoKepernyo: boolean = false;
  kafKszfDijszamoloFormGroup: FormGroup;
  uzenet: string;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private utilityService: UtilityService,
    private kafKszfDijszamoloService: KafKszfDijszamoloService,
    private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.kafKszfDijszamoloFormGroupInit();
  }

  /*FormGroup inicializálása*/

  kafKszfDijszamoloFormGroupInit() {
    this.kafKszfDijszamoloFormGroup = this.formBuilder.group({
      f_szerzazon: new FormControl(null, [Validators.required]),
      f_darab: new FormControl(null, [Validators.required]),
      f_hiba: new FormControl(false),
      f_termek: new FormControl(null),
      f_dij: new FormControl(null),
      f_ossz_darab: new FormControl(null)
    });

  }

  onSzamolas() {
    this.toltoKepernyo = true;
    this.uzenet = "";
    this.kafKszfDijszamoloFormGroup.controls["f_hiba"].setValue(false);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_kafkszfdijszamolo').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzazon: this.kafKszfDijszamoloFormGroup.controls["f_szerzazon"].value.toString(),
            db: this.kafKszfDijszamoloFormGroup.controls["f_darab"].value.toString(),
          }

          this.kafKszfDijszamoloService.kafKszfDijszamolo(model).subscribe(
            data => {
              this.toltoKepernyo = false;
              if (data) {

                if (data.eredmeny != "Siker") {
                  this.uzenet = data.eredmeny;
                  this.kafKszfDijszamoloFormGroup.controls["f_hiba"].setValue(true);
                }
                else {
                  this.kafKszfDijszamoloFormGroup.controls["f_termek"].setValue(data.kafKszfDijszamolo["termek"]);
                  this.kafKszfDijszamoloFormGroup.controls["f_dij"].setValue(data.kafKszfDijszamolo["uj_dij"]);
                  this.kafKszfDijszamoloFormGroup.controls["f_ossz_darab"].setValue(data.kafKszfDijszamolo["darab_most"]);
                  this.uzenet = "A szaporítani kívánt <b>" + this.kafKszfDijszamoloFormGroup.controls['f_termek'].value + "</b> szerződés díja <b>" + this.kafKszfDijszamoloFormGroup.controls['f_dij'].value + "</b> lesz. A szaporítás után a flottában <b>" + this.kafKszfDijszamoloFormGroup.controls['f_ossz_darab'].value + "</b> gépjármű lesz található.";
                }
              }
              else {
                this.kafKszfDijszamoloFormGroup.controls["f_hiba"].setValue(true);
                this.uzenet = "Hiba történt a keresés közben!";
              }
            },
            errorResult => {
              this.toltoKepernyo = false;
              this.kafKszfDijszamoloFormGroup.controls["f_hiba"].setValue(true);
              this.uzenet = "Hiba történt a keresés közben!";

            }
          );
        });
      });
  }

}
