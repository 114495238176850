import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HibaService, UtilityService, GranitTableComponent, GranitFileUploadComponent, Dokumentum } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { FlottaSzaporitasService } from '../../../../services/flotta-szaporitas.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'flotta-fedig',
  templateUrl: './flotta-fedig.component.html',
  styleUrls: ['./flotta-fedig.component.scss']
})
export class FlottaFedigComponent implements OnInit {
  flottaFedigFormGroup: FormGroup;

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;
  alkusziFajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];

  dataSource: MatTableDataSource<any>;

  

  matPaginator: MatPaginator;
  @ViewChild('paginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dataSource.paginator = this.matPaginator;
    }
  }

  hibaSzoveg: string = "";

  getDisplayedFedigColumns(): string[] {
    let displayedColumns = this.fedigTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }

  fedigTablaOszlopok: any[] = [
    { columnDef: 'f_gj_azon', header: 'Gépjármű azonosító', visible: false },
    { columnDef: 'f_ugyszam', header: 'Ügyszám' },
    { columnDef: 'f_flotta_azon', header: 'Flotta' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_jelleg_szoveg', header: 'Jelleg'},
    { columnDef: 'f_vonalkod', header: 'Ajánlatszám' },
    { columnDef: 'f_letoltes', header: 'Fedezetigazolás letöltés', adattipus: 'button' },
  ];

  toltoKepernyoKereses: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private flottaSzaporitasService: FlottaSzaporitasService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitized: DomSanitizer,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.aktualisFelhasznalo != null) {
      this.flottaFedigFormGroup.controls["alkuszNev"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev);
      this.flottaFedigFormGroup.controls["alkuszTorzsszam"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
    }

    this.dataSource = new MatTableDataSource();
    this.kereses();

  }

  initFormGroup() {
    this.flottaFedigFormGroup = this.formBuilder.group({
      alkuszTorzsszam: new FormControl(''),
      alkuszNev: new FormControl(''),
      ugyszam: new FormControl('', Validators.pattern("^[0-9]*$")),
      flottaAzonosito: new FormControl('', Validators.pattern("^[0-9]*$")),
      szerzodoNev: new FormControl(''),
      ajanlatszam: new FormControl('', Validators.pattern("^[0-9]*$")),
      rendszam: new FormControl(''),
    });
  }

  kereses() {
    let model = {
      f_torzsszam: this.flottaFedigFormGroup.controls["alkuszTorzsszam"].value,
      f_ugyszam: this.flottaFedigFormGroup.controls["ugyszam"].value,
      f_flotta: this.flottaFedigFormGroup.controls["flottaAzonosito"].value,
      f_szerzodo_nev: this.flottaFedigFormGroup.controls["szerzodoNev"].value,
      f_vonalkod: this.flottaFedigFormGroup.controls["ajanlatszam"].value,
      f_rendszam: this.flottaFedigFormGroup.controls["rendszam"].value
    }
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasElozetesFedezetIgazolasLista(model).subscribe(
      data => {
        if (data != null) {
          const fgs = data.map(item => {
            return this.utilityService.formGroupObjektumbol(item);
          });
          this.dataSource.data = this.formBuilder.array(fgs).controls;
        }
        else {
          this.hibaSzoveg = "<p>Hibás kérés</p>";
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.hibaSzoveg = "<p>Hibás kérés</p>";
        this.toltoKepernyoKereses = false;
      }
    );
  }


  letoltes(row) {
    let model = {
      f_gj_azon: row.controls["f_gj_azon"].value
    };
    this.toltoKepernyoKereses = true;
    this.flottaSzaporitasService.flottaSzaporitasElozetesFedezetIgazolasPdf(model).subscribe(
      data => {
        if (data != null) {
          if (data.eredmenykod == 0) {
            let contentType = 'application/pdf';
            let blob = base64StringToBlob(data.file, contentType);
            saveAs(blob, data.filename);
          }
          else {
            this.hibaSzoveg = "<p>Hibás kérés</p>";
          }
        }
        else {
          this.hibaSzoveg = "<p>Hibás kérés</p>";
        }
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.hibaSzoveg = "<p>Hibás kérés</p>";
        this.toltoKepernyoKereses = false;
      });
  }

}
