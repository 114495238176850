<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Új flotta díjkalkulációs kérések'"
                                [subTitle]="'Ezen az oldalon láthatja új flotta díjkalkulációs kéréseit'"
                                [templateOutlet]="ujFlottaDijkalkulaciok">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #ujFlottaDijkalkulaciok>

  <form [formGroup]="ujFlottaDijkalkulaciokFormGroup" class="form-group">


    <ng-container *ngIf="toltoKepernyoKereses">
      <div class="row">
        <div class="col-lg-12 bg-white">
          <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!toltoKepernyoKereses">

      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Szűrés</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card>
            <mat-card-content class="mb-0">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerződő neve</mat-label>
                    <input matInput formControlName="f_szerzodo_nev">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Állapot</mat-label>
                    <mat-select formControlName="f_allapot">
                      <mat-option value="">Összes</mat-option>
                      <mat-option value="0">Alkuszi megbízás feldolgozása folyamatban</mat-option>
                      <mat-option value="230">Díjkalkuláció kérés feldolgozása folyamatban</mat-option>
                      <mat-option value="250">Díjkalkulációs lap letölthető</mat-option>
                      <mat-option value="296">Díjkalkuláció kérés elusítva (érvényes akuszi megbízás hiánya)</mat-option>
                      <mat-option value="299">Díjkalkuláció lezárva</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <button mat-raised-button type="button" color="accent" (click)="onSzures()">Szűrés</button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
      <div class="row mt-5">
        <div class="col-lg-12">
          <granit-table #ujFlottaDijkalkulaciokTabla
                         [columns]="ujFlottaDijkalkulaciokTablaOszlopok"
                         [formGroup]="ujFlottaDijkalkulaciokFormGroup"
                         [isEditable]="true"
                         [addNewMode]="''"
                         [operationButtonText]="'Letöltés'"
                         [operationButtonIcon]="'file_download'"
                         (rowOperationClick)="onRowOperationClick($event)">
          </granit-table>
        </div>
      </div>

    </ng-container>
  </form>
</ng-template>
