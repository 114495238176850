import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { HirekService } from '../../services/_index';

@Component({
  selector: 'hirek',
  templateUrl: './hirek.component.html',
  styleUrls: ['./hirek.component.scss']
})
export class HirekComponent implements OnInit {

  constructor(private hirekService: HirekService,
    private hibaService: HibaService,
    private router: Router,
    private utilityService: UtilityService,
    private recaptchaV3Service: ReCaptchaV3Service  ) {

    this.routerUrl = this.router.url.split('?')[0].substring(1);
    this.tipus = this.router.url.split('?')[0].substring(1) == "archiv-hirek" ? "archiv" : "aktuális";
  }

  hirekList: any[] = [];
  hirTeljes: number = null;
  info: string = "";
  tipus: string = "";
  routerUrl: string = '';

  ngOnInit(): void {
    this.info = "<b>Köszöntünk a Gránit Biztosító megújult Partner Portálján!</b><br><br>Új portálunk elsősorban formailag újult meg, de a közeljövőben számos új tartalmi elemmel is bővíteni szeretnénk az oldalt, annak érdekében hogy a munkátokat megkönnyítse és gyorsabbá tegye!Reméljük, hogy új portálunk elnyeri a tetszéseteket és bízunk benne, hogy nagy hasznát veszitek a munkátok során! Észrevételeitekkel, javaslataitokkal forduljatok az alkusztámogatóitokhoz!<br><br>Jó munkát kíván a Gránit Biztosító!";
    this.hirekLekerese();
    this.felugroAblak();
  }

  felugroAblak() {
    this.recaptchaV3Service.execute('partnerportal_fooldal').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.hirekService.felugroAblakAdatok().subscribe(
            data => {
              if (data && data.length > 0) {
                this.utilityService.dialogMegnyitasa({
                  info: true,
                  title: 'Információ',
                  message: data,
                  confirmButtonText: 'Rendben'
                }, "500px"
                );
              }
            },
            errorResult => {
            }
          );
        })
      });
  }

  hirekLekerese() {

    let model = {
      f_tipus: this.tipus
    }

    this.hirekService.hirekLekerese(model).subscribe(
      data => {
        this.hirekList = data.sort((x, y) => x.f_hatalytol < y.f_hatalytol ? 1 : -1);
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a hírek lekérése közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  onTargyClick(id: number) {
    this.hirTeljes = this.hirTeljes != id ? id : null;
  }

}
