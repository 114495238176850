<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!-->portal-content-wrapper [title]="'Listák'"
                                [subTitle]="kivalasztottMenu.f_megnevezes"
                                [templateOutlet]="dokumentumIgenyles"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Listák'"
                                [subTitle]="kivalasztottMenu.f_megnevezes"
                                [templateOutlet]="dokumentumIgenyles">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #dokumentumIgenyles>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">


        <!-- Letölthető listák -->

        <div *ngIf="listatipus != '0' && listatipus != '6' && listatipus != '-1'">
          <div *ngIf="listatipus == '12'">
            <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-6" *ngIf="jutalekElszamolasFilenev != '' && jutalekElszamolasJovahagyva == ''">
              <mat-card-content>
                <div class="row pb-3 pt-3">
                  <div class="col-lg-9">
                    <mat-label>Utolsó jutalék elszámolás</mat-label>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-lg-9">
                    <mat-label>{{jutalekElszamolasFilenev}}</mat-label>
                  </div>
                  <div class="col-lg-3 text-right">
                    <button mat-button (click)="onJutalekElszamolasElfogadasa()">
                      <span>Elfogadás</span>
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-6" *ngIf="jutalekElszamolasFilenev != '' && jutalekElszamolasJovahagyva != ''">
              <mat-card-content>
                <div class="row pt-3">
                  <div class="col-lg-6">
                    <mat-label>Utolsó jutalék elszámolás</mat-label>
                  </div>
                  <div class="col-lg-6 text-right">
                    <mat-label>Jóváhagyva</mat-label>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-lg-6">
                    <mat-label>{{jutalekElszamolasFilenev}}</mat-label>
                  </div>
                  <div class="col-lg-6 text-right">
                    <mat-label>{{jutalekElszamolasJovahagyva}}</mat-label>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <mat-table [dataSource]="listakTabla" class="w100 mr-5 table_border">

            <ng-container *ngFor="let column of listakOszlopok" [matColumnDef]="column.columnDef">

              <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class>{{ column.header }}</mat-header-cell>

              <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                        [class]=column.class>

                <span *ngIf="column.columnDef != 'f_egyeb_muvelet_gomb'" [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>

                <button mat-icon-button *ngIf="column.columnDef == 'f_egyeb_muvelet_gomb'" (click)="onRowOperationClick(element);" [matTooltip]="column.buttonText">
                  <mat-icon class="material-icons-outlined">{{column.buttonIcon}}</mat-icon>
                </button>

              </mat-cell>

            </ng-container>

            <!-- Fejléc -->
            <mat-header-row *matHeaderRowDef="getDisplayedListakColumns()"></mat-header-row>

            <!-- Sorok -->
            <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedListakColumns();"></mat-row>

          </mat-table>

        </div>

        <!-- Kiadott vonalkódok -->

        <div *ngIf="listatipus == '0'">
          <div class="mat-elevation-z3">
            <mat-table [dataSource]="vonalkodTabla" class="w100 mr-5 table_border col-lg-4">

              <ng-container *ngFor="let column of vonalkodOszlopok" [matColumnDef]="column.columnDef">

                <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class>{{ column.header }}</mat-header-cell>

                <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                          [class]=column.class>

                  <span>{{element.controls[column.columnDef].value}}</span>

                </mat-cell>

              </ng-container>

              <!-- Fejléc -->
              <mat-header-row *matHeaderRowDef="getDisplayedVonalkodColumns()"></mat-header-row>

              <!-- Sorok -->
              <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedVonalkodColumns();"></mat-row>

            </mat-table>
            <mat-paginator #paginatorVonalkod [pageSizeOptions]="[10, 50, 100, 150]" showFirstLastButtons></mat-paginator>
          </div>
        </div>

        <!-- MNB adatszolgáltatás -->

        <div *ngIf="listatipus == '6'">
          <mat-label>Figyelem! Az adatszolgáltatónak saját magának kell az adatgyűjtését, analitikáját, nyilvántartását megszervezni és gondoskodni arról, hogy minden egyes táblába csak bizonylattal alátámasztott, és az adatszolgáltató nyilvántartásaiban rögzített gazdasági eseményekről kerüljön be adat. Kérjük a biztosító által közzétett adatokat ellenőrizni!</mat-label>
          <div class="row mt-4">
            <div class="col-lg-12">
              <button mat-raised-button color="accent" (click)="fajlbaMentes()">Fájlba mentés</button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="mat-elevation-z3">

                <mat-table [dataSource]="mnbTabla" class="table-wrapper">

                  <ng-container *ngFor="let column of mnbOszlopok" [matColumnDef]="column.columnDef">

                    <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>

                    <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element">

                      <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>

                    </mat-cell>

                  </ng-container>

                  <!-- Fejléc -->
                  <mat-header-row *matHeaderRowDef="getDisplayedMnbColumns(); sticky: true"></mat-header-row>

                  <!-- Sorok -->
                  <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedMnbColumns();"></mat-row>

                </mat-table>
                <mat-paginator #paginatorMnb [pageSizeOptions]="[10, 50, 100, 150]" showFirstLastButtons></mat-paginator>

              </div>
            </div>
            </div>
          </div>

        <!-- Levett listák újraigénylése -->

        <form [formGroup]="listaUjraFormGroup" class="form" *ngIf="listatipus == '-1'">
          <mat-label>A sikeresen visszaemelt listák megtalálhatóak a szokásos, eredeti helyükön</mat-label>
          <div class="mt-4">

            <div class="col-lg-4">
              <mat-form-field appearance="fill">
                <mat-label>Lista tipus</mat-label>
                <mat-select matInput formControlName="f_lista_tipus" (selectionChange)="onUjraTipusValasztas()">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let ujraTipus of ujraTipusLista" [value]="ujraTipus.f_FTIPAZON">{{ujraTipus.f_megnevezes}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <div class="col-lg-4" *ngIf="listaUjraFormGroup.get('f_lista_tipus').value != '0'">
              <mat-form-field appearance="fill">
                <mat-label>Év</mat-label>
                <mat-select matInput formControlName="f_ev" (selectionChange)="onUjraEvValasztas()">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let ujraEv of ujraEvLista" [value]="ujraEv.f_ev">{{ujraEv.f_ev}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <div class="col-lg-4" *ngIf="listaUjraFormGroup.get('f_ev').value != '0'">
              <mat-form-field appearance="fill">
                <mat-label>Hónap</mat-label>
                <mat-select matInput formControlName="f_honap">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let ujraHonap of ujraHonapLista" [value]="ujraHonap.f_ho">{{ujraHonap.f_ho}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-4" *ngIf="listaUjraFormGroup.get('f_honap').value != '0'">
              <button mat-raised-button color="accent" (click)="onKereses()">Keresés</button>
            </div>

          </div>
          <div class="mt-4 col-lg-6" *ngIf="listaUjraFormGroup.get('tableDataSource').length > 0">

            <mat-table [dataSource]="listaUjraTabla" class="w100 mr-5 table_border">

              <ng-container *ngFor="let column of listaUjraOszlopok" [matColumnDef]="column.columnDef">

                <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class>{{ column.header }}</mat-header-cell>

                <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                          [class]=column.class>

                  <div *ngIf="column.adattipus != 'check'">
                    <span>{{element.controls[column.columnDef].value}}</span>
                  </div>

                  <div *ngIf="column.adattipus == 'check'">
                    <mat-checkbox formControlName="f_valaszt"></mat-checkbox>
                  </div>

                </mat-cell>

              </ng-container>

              <!-- Fejléc -->
              <mat-header-row *matHeaderRowDef="getDisplayedUjraColumns()"></mat-header-row>

              <!-- Sorok -->
              <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedUjraColumns();"></mat-row>

            </mat-table>

            <div class="col-lg-4 mt-4">
              <button mat-raised-button color="accent" (click)="onUjraIgenyles()">Kiválasztottak újra kérése</button>
            </div>

          </div>
        </form>

      </div>
    </div>
  </div>
</ng-template>
