import { NgModule } from '@angular/core';

import { CommonLibraryModule } from '@granit/common-library';
import { PortalLibraryModule } from '@granit/portal-library';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskModule } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalFejlecComponent } from './components/portal-fejlec/portal-fejlec.component';
import { PortalLablecComponent } from './components/portal-lablec/portal-lablec.component';
import { FooldalComponent } from './components/fooldal/fooldal.component';
import { BejelentkezesComponent } from './components/bejelentkezes/bejelentkezes.component';
import { RegisztracioComponent } from './components/regisztracio/regisztracio.component';
import { ListakComponent } from './components/listak/listak.component';
import { IpidComponent } from './components/ipid/ipid.component';
import { KalkulatorHasznosinfoComponent } from './components/kalkulator-hasznosinfo/kalkulator-hasznosinfo.component';
import { ElfelejtettJelszoComponent } from './components/elfelejtett-jelszo/elfelejtett-jelszo.component';
import { PortalMenuComponent } from './components/portal-menu/portal-menu.component';
import { PortalAlmenuComponent } from './components/portal-menu/portal-almenu/portal-almenu.component';
import { DokumentumIgenylesComponent } from './components/dokumentum-igenyles/dokumentum-igenyles.component';
import { EgyenlegkozloComponent } from './components/egyenlegkozlo/egyenlegkozlo.component';
import { DatePipe } from '@angular/common';
import { FuvarozoiComponent } from './components/fuvarozoi/fuvarozoi.component';
import { SzerzodesAdatokComponent } from './components/szerzodes-adatok/szerzodes-adatok.component';
import { FlottaSzaporitasComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-szaporitas/flotta-szaporitas.component';
/*import { FlottaJovahagyasComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-jovahagyas/flotta-jovahagyas.component';*/
import { FlottaSzaporitasokComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-szaporitasok/flotta-szaporitasok.component';
import { FlottaFedigComponent } from './components/flotta-kgfb/flotta-szaporitas/flotta-fedig/flotta-fedig.component';
import { OktatasComponent } from './components/oktatas/oktatas.component';
import { HirekComponent } from './components/hirek/hirek.component';
import { UjFlottaAjanlatVagyDijkalkulaciokeresComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlat-vagy-dijkalkulaciokeres/uj-flotta-ajanlat-vagy-dijkalkulaciokeres.component';
/*import { UjFlottaAlkusziJovahagyasComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-alkuszi-jovahagyas/uj-flotta-alkuszi-jovahagyas.component';*/
import { UjFlottaAlkusziHianypotlasComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-alkuszi-hianypotlas/uj-flotta-alkuszi-hianypotlas.component';
import { UjFlottaDijkalkulaciokComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-dijkalkulaciok/uj-flotta-dijkalkulaciok.component';
import { UjFlottaBAjanlatkeresekComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-b-ajanlatkeresek/uj-flotta-b-ajanlatkeresek.component';
import { UjFlottaAjanlatkeresekComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlatkeresek/uj-flotta-ajanlatkeresek.component';
/*import { UjFlottaAjanlatRogzitesEredmenyComponent } from './components/flotta-kgfb/uj-flotta/uj-flotta-ajanlat-rogzites-eredmeny/uj-flotta-ajanlat-rogzites-eredmeny.component';*/
/*import { UjFlottaElbiralasReszletezoComponent, UjFlottaElbiralasElozmenyDialog } from './components/flotta-kgfb/uj-flotta/uj-flotta-elbiralas-reszletezo/uj-flotta-elbiralas-reszletezo.component';*/
/*import { FlottaCascoJovahagyasComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-jovahagyas/flotta-casco-jovahagyas.component';*/
import { FlottaCascoSzaporitasComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-szaporitas/flotta-casco-szaporitas.component';
import { FlottaCascoAjanlatokComponent } from './components/flotta-casco/flotta-szaporitas/flotta-casco-ajanlatok/flotta-casco-ajanlatok.component';
import { KafKszfDijszamoloBovitesEsetenComponent } from './components/kaf-kszf-dijszamolo-bovites-eseten/kaf-kszf-dijszamolo-bovites-eseten.component';

import { UjFlottaCascoAjanlatkeresComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlatkeres/uj-flotta-casco-ajanlatkeres.component'
import { UjFlottaCascoAjanlatkeresekComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlatkeresek/uj-flotta-casco-ajanlatkeresek.component'
/*import { UjFlottaCascoAlkusziJovahagyasComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-alkuszi-jovahagyas/uj-flotta-casco-alkuszi-jovahagyas.component'*/
import { UjFlottaCascoAlkusziHianypotlasComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-alkuszi-hianypotlas/uj-flotta-casco-alkuszi-hianypotlas.component';
/*import { UjFlottaCascoElbiralasReszletezoComponent } from './components/flotta-casco/uj-flotta/uj-flotta-casco-elbiralas-reszletezo/uj-flotta-casco-elbiralas-reszletezo.component'*/
/*import { UjFlottaCascoAjanlatRogzitesEredmenyComponent  } from './components/flotta-casco/uj-flotta/uj-flotta-casco-ajanlat-rogzites-eredmeny/uj-flotta-casco-ajanlat-rogzites-eredmeny.component'*/

@NgModule({
  declarations: [
    AppComponent,
    PortalFejlecComponent,
    PortalLablecComponent,
    FooldalComponent,
    BejelentkezesComponent,
    RegisztracioComponent,
    ListakComponent,
    IpidComponent,
    KalkulatorHasznosinfoComponent,
    ElfelejtettJelszoComponent,
    PortalMenuComponent,
    PortalAlmenuComponent,
    DokumentumIgenylesComponent,
    EgyenlegkozloComponent,
    FuvarozoiComponent,
    OktatasComponent,
    SzerzodesAdatokComponent,
    FlottaSzaporitasComponent,
    /*FlottaJovahagyasComponent,*/
    FlottaSzaporitasokComponent,
    FlottaFedigComponent,
    HirekComponent,
    UjFlottaAjanlatVagyDijkalkulaciokeresComponent,
    /*UjFlottaAlkusziJovahagyasComponent,*/
    UjFlottaAlkusziHianypotlasComponent,
    UjFlottaDijkalkulaciokComponent,
    UjFlottaBAjanlatkeresekComponent,
    UjFlottaAjanlatkeresekComponent,
    /*UjFlottaAjanlatRogzitesEredmenyComponent,*/
    /*UjFlottaElbiralasReszletezoComponent,*/
    /*UjFlottaElbiralasElozmenyDialog,*/
    /*FlottaCascoJovahagyasComponent,*/
    FlottaCascoSzaporitasComponent,
    FlottaCascoAjanlatokComponent,
    KafKszfDijszamoloBovitesEsetenComponent,
    UjFlottaCascoAjanlatkeresComponent,
    UjFlottaCascoAjanlatkeresekComponent,
    /*UjFlottaCascoAlkusziJovahagyasComponent,*/
    UjFlottaCascoAlkusziHianypotlasComponent,
    /*UjFlottaCascoElbiralasReszletezoComponent,*/
    /*UjFlottaCascoAjanlatRogzitesEredmenyComponent*/
  ],
  imports: [
    AppRoutingModule,
    CommonLibraryModule.forRoot(),
    PortalLibraryModule.forRoot(),    
    NgxSkeletonLoaderModule,
    NgxMaskModule.forRoot()
  ],
  entryComponents: [
    /*UjFlottaElbiralasElozmenyDialog*/
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
