<div class="container-fluid">
    <div class="section">
        <div class="row">
            <div class="col-lg-12">
                <portal-content-wrapper [title]="formEnum == 0 ? 'Új flotta ajánlatkérések' : 'Aláírt flotta ajánlat feltöltése'"
                                        [subTitle]="'Ezen az oldalon láthatja új flotta ajánlatkéréseit'"
                                        [templateOutlet]="ujFlottaAjanlatkeresek">
                </portal-content-wrapper>
            </div>
        </div>
    </div>
</div>

<ng-template #ujFlottaAjanlatkeresek>

    <ng-container *ngIf="formEnum == 0">
        <form [formGroup]="ujFlottaAjanlatkeresekFormGroup" class="form-group">

            <ng-container *ngIf="toltoKepernyoKereses">
                <div class="row">
                    <div class="col-lg-12 bg-white">
                        <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyoKereses">
                <mat-card class="form-content">
                    <mat-card-header>
                        <mat-card-title class="content-title">Szűrés</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-card>
                            <mat-card-content class="mb-0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Szerződő neve</mat-label>
                                            <input matInput formControlName="f_szerzodo_nev">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Állapot</mat-label>
                                            <mat-select formControlName="f_allapot">
                                                <mat-option value="">Összes</mat-option>
                                                <mat-option value="0">Alkuszi megbízás feldolgozása folyamatban</mat-option>
                                                <mat-option value="130">Ajánlatkérés feldolgozása folyamatban</mat-option>
                                                <mat-option value="150">Ajánlat letölthető</mat-option>
                                                <mat-option value="160">Ajánlat letöltve</mat-option>
                                                <mat-option value="170">Ajánlat visszatöltve</mat-option>
                                                <mat-option value="196">Ajánlatkérés elusítva (érvényes akuszi megbízás hiánya)</mat-option>
                                                <mat-option value="197">Ajánlatkérés visszavonva</mat-option>
                                                <mat-option value="198">Ajánlatkérés elusítva (visszatöltés hiánya)</mat-option>
                                                <mat-option value="199">Ajánlat lezárva</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <button mat-raised-button type="button" color="accent" (click)="onSzures()">Szűrés</button>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-card-content>
                </mat-card>
                <div class="row mt-4">
                    <div class="col-lg-12">

                        <!-- táblázat helye -->
                        <mat-table [dataSource]="dataSource">
                            <!--class="w100 mr-5 table_border"-->
                            <ng-container *ngFor="let column of ujFlottaAjanlatkeresekTablaOszlopok" [matColumnDef]="column.columnDef">
                                <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]="column.class">
                                    <div>
                                        <span [innerHTML]="column.header"></span>
                                    </div>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class">

                                    <div *ngIf="column.adattipus != 'button'">
                                        <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                    </div>
                                    <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_letoltes' && element.controls['f_letoltes'].value">
                                        <button mat-raised-button color="primary" (click)="letoltesOnClick(element)" title="Ajánlat letöltés"><mat-icon>download</mat-icon></button>
                                    </div>
                                    <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_visszatoltes' && element.controls['f_visszatoltes'].value">
                                        <button mat-raised-button color="primary" (click)="feltoltesOnClick(element)" title="Aláírt ajánlat visszatöltés"><mat-icon>upload</mat-icon></button>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <!--Fejléc-->
                            <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
                            <!--Sorok-->
                            <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedColumns();"></mat-row>

                            <div class="mat-row col-lg-12" *matNoDataRow>
                                <div class="mat-cell">Nincs megjeleníthető elem.</div>
                            </div>
                        </mat-table>
                        <mat-paginator #matPaginator [pageSizeOptions]="[5, 10, 20]"
                                       showFirstLastButtons
                                       aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                        </mat-paginator>

                    </div>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <ng-container *ngIf="formEnum == 1">
        <form [formGroup]="ujFlottaAjanlatVisszatoltesFormGroup" class="form-group">

            <ng-container *ngIf="toltoKepernyoKereses">
                <div class="row">
                    <div class="col-lg-12 bg-white">
                        <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyoKereses">
                <mat-card>
                    <mat-card-content class="mb-0">
                        <div *ngIf="ujFlottaKivalasztottAjanlatAdatok" class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Ügyszám</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_ugyszam ? ujFlottaKivalasztottAjanlatAdatok.f_ugyszam : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Beérkezés dátuma</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_datum ? (ujFlottaKivalasztottAjanlatAdatok.f_datum | dateFormat : 'YYYY. MMMM DD. HH:mm') : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Aktuális állapot</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_allapot_szoveg ? ujFlottaKivalasztottAjanlatAdatok.f_allapot_szoveg : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Szerződő neve</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_ugyfelnev ? ujFlottaKivalasztottAjanlatAdatok.f_ugyfelnev : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Szerződő címe</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_cim ? ujFlottaKivalasztottAjanlatAdatok.f_cim : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Adószám</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_adoszam ? ujFlottaKivalasztottAjanlatAdatok.f_adoszam : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Pénznem</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_penznem ? ujFlottaKivalasztottAjanlatAdatok.f_penznem : '-'}}</label>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div class="control-group">
                                    <label class="control-header-label">Évforduló</label>
                                    <label class="control-data-label">{{ujFlottaKivalasztottAjanlatAdatok.f_evfordulo ? ujFlottaKivalasztottAjanlatAdatok.f_evfordulo : '-'}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end flex-row-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="information-wrapper mb-4">
                                    <div>
                                        A feltötlthető fájlok formátuma korlátozott az alábbiakra: DOC, DOCX, XLS, XLSX, JPG, TIF, PDF, PNG.
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <granit-file-upload #fileUpload ngDefaultControl
                                                             [formGroup]="ujFlottaAjanlatVisszatoltesFormGroup"
                                                             [formControlName]="'f_fajlok'"
                                                             [labelText]="'Aláírt flotta ajánlat feltöltése'"
                                                             [buttonText]="'Kiválasztás'"
                                                             [allowMultipleFiles]="true"
                                                             [acceptedFileExtensions]="['.doc','.docx','.xls','.xlsx','.jpg','.tif','.pdf','.png']"
                                                             (changeClick)="fajlEllenorzes($event)">
                                        </granit-file-upload>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <mat-label>
                                            Feltöltés eredménye: {{fajlFeltoltesEredmeny}}
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row mt-3 pb-3">
                                    <div class="col-lg-12">
                                        <button mat-raised-button type="button" [disabled]="fajlFeltoltesEredmeny != 'Sikeres' || !ujFlottaAjanlatVisszatoltesFormGroup.valid" color="accent" (click)="onFajlokFeltolteseClick()">Fájl(ok) feltöltése</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="form-content mt-4">
                    <mat-card-header>
                        <mat-card-title class="content-title">Új flotta ajánlat gépjárművek</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-xl-12 col-lg-4 col-md-6 col-sm-6">
                                <granit-table #ujFlottaAjanlatkeresGepjarmuTabla
                                               [columns]="ujFlottaAjanlatkeresGepjarmuTablaOszlopok"
                                               [formGroup]="ujFlottaAjanlatVisszatoltesFormGroup">
                                </granit-table>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>

        </form>

    </ng-container>


</ng-template>
