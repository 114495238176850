import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as CommonLibrary from '@granit/common-library';
import * as PortalLibrary from '@granit/portal-library';
import { Felhasznalo } from '@granit/portal-library';
import { MenuService, FelhasznaloService } from '../../services/_index';

@Component({
  selector: 'portal-menu',
  templateUrl: './portal-menu.component.html',
  styleUrls: ['./portal-menu.component.scss']
})
export class PortalMenuComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private felhasznaloServiceLibrary: PortalLibrary.FelhasznaloService,
    private menuService: MenuService,
    private menuServiceLibrary: CommonLibrary.MenuService,
    private utilityService: CommonLibrary.UtilityService,
    private felhasznaloService: FelhasznaloService,
    private router: Router,    
    public hibaService: CommonLibrary.HibaService) { }

  aktualisFelhasznalo: PortalLibrary.Felhasznalo;
  menupontokLista: any[] = [];
  uzletkotokLista: any[] = [];
  felhasznalokLista: any[] = [];
  megtekintesMasNevebenFormGroup: FormGroup;
  //objectComparisonFunctionPartner = function (option, value): boolean {
  //  return value ? option.f_torzsszam === value : false;
  //}
  objectComparisonFunctionFelhasznalo = function (option, value): boolean {
    return value ? option.f_felhasznalo_id === value : false;
  }

  ngOnInit(): void {
    // Feliratkozunk az aktuális felhasználó módosulásaira
    this.felhasznaloServiceLibrary.aktualisFelhasznalo.subscribe(
      felhasznalo => {
        this.aktualisFelhasznalo = felhasznalo;

        // Ha nincs bejelentkezett felhasználó, 
        if (this.aktualisFelhasznalo == null) {
          // Ha volt inicializálva a megtekintése más nevében form group, akkor kinullázuk
          if (this.megtekintesMasNevebenFormGroup) {
            this.megtekintesMasNevebenFormGroup = null;
          }
          // Kiürítjük a listákat is
          this.uzletkotokLista = [];
          this.felhasznalokLista = [];
        }

        // Ha van bejelentkezett felhasználó
        if (this.aktualisFelhasznalo != null) {

          // Jogosultság ellenőrzés
          this.felhasznaloOldalJogosultsagEllenorzese();

          // Admin funkciók beállítása, csak akkor, ha a flehasználó admin
          if (this.aktualisFelhasznalo.f_torzsszam == '0000000001') {

            // Csak akkor hozzuk létre a formgroupot, ha még nem volt
            if (!this.megtekintesMasNevebenFormGroup) {
              // Létrehozzuk a formgroupot
              this.megtekintesMasNevebenFormGroupLetrehozasa();
            }

            // Beállítjuk a formgroup controlok értékeit
            this.megtekintesMasNevebenFormGroupBeallítas();

            // Csak akkor kérjük le a partnerek, ha üres a lista
            if (this.uzletkotokLista.length == 0) {
              // Partnerek lekérése
              this.parterekLekerese();
            }
            // Felhasználók lekérése
            this.felhasznalokLekerese();
          }
        }
        // Menüpontok lekérése
        this.menupontokLekerese();
      }
    );
  }

  // Megtekintés más nevében form group - Admin 
  megtekintesMasNevebenFormGroupLetrehozasa() {
    this.megtekintesMasNevebenFormGroup = this.formBuilder.group({
      f_partner: new FormControl(null),
      f_felhasznalo: new FormControl(null)
    });
  }

  // Beállítjuk a formgroup controlok értékeit
  megtekintesMasNevebenFormGroupBeallítas() {
    if (this.aktualisFelhasznalo != null && this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam != null) {
      this.megtekintesMasNevebenFormGroup.controls['f_partner'].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
    }
    if (this.aktualisFelhasznalo != null && this.aktualisFelhasznalo.f_valasztott_felhasznalo_id != null) {
      this.megtekintesMasNevebenFormGroup.controls['f_felhasznalo'].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_id);
    }
  }

  felhasznaloOldalJogosultsagEllenorzese() {
    let modelOldal = {
      oldal: window.location.pathname
    };
    this.felhasznaloService.felhasznaloOldalJogosultsag(modelOldal).subscribe(
      data => {
        if (data != null) {
          if ((this.aktualisFelhasznalo.f_valasztott_felhasznalo_nev == null || this.aktualisFelhasznalo.f_valasztott_felhasznalo_nev == "") && data.f_admin_oldal == "N") {
            this.router.navigate(['fooldal']);
          }
          else {
            if (data.f_jogosultsag_kell == "I" && data.f_jogosultsag_van == "N") {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Bejelentkezés',
                message: "Nincs jogosultsaga ehhez az oldalhoz (" + window.location.pathname + ")",
                confirmButtonText: 'Rendben'
              });
              this.router.navigate(['fooldal']);
            }
          }
        }
        else {
          this.router.navigate(['fooldal']);
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a keresés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  // Partner kiválasztása esemény
  onPartnerSelectionChange() {
    // Kiürítjük a felhasználó listát
    this.felhasznalokLista = [];

    // Nullázuk az értékeket
    this.aktualisFelhasznalo.f_valasztott_felhasznalo_id = null;
    this.aktualisFelhasznalo.f_valasztott_felhasznalo_nev = null;
    this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev = null;
    this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam = null;
    this.felhasznaloServiceLibrary.aktualisFelhasznaloSubject.next(this.aktualisFelhasznalo);
  }

  // Admin - Felhasználó módosítás
  onFelhasznaloSelectionChange() {
    this.megtekintesMasNeveben();
  }

  // Menüpontok lekérése
  menupontokLekerese() {
    let model = {
      alkalmazasEnum: 1 // A Granit.Common.Models.Enums.AlkalmazasEnum szerint a 1 a PartnerPortál
    };
    this.menuServiceLibrary.menupontokLekerese(model).subscribe(
      data => {
        this.menupontokLista = data;
      }
    )
  }

  // Partnerek lekérése
  parterekLekerese() {
    this.utilityService.uzletkotok('I').subscribe(
      data => {
        this.uzletkotokLista = data;
      }
    );
  }

  // Partnerhez tartozó felhasználók lekérése
  felhasznalokLekerese() {
    // Átadjuk a kiválasztott partner törzsszámát
    let model = {
      torzsszam: this.megtekintesMasNevebenFormGroup.controls['f_partner'].value
    };
    this.felhasznaloService.felhasznalok(model).subscribe(
      data => {
        this.felhasznalokLista = data;
      }
    );
  }

  // Menü toggle
  onMenuToggleClick() {
    this.menuService.portalMenuToggle();
  }

  // Menüpont gomb esemény
  onMenupontClick(menupont: any) {
    if (menupont.f_kulso_link) {
      window.open(decodeURIComponent(menupont.f_route), '_blank');
    }
    else {
      // A menü route-ban lehetnek query paraméterek
      let route = menupont.f_route.split('?')[0];
      let queryString = menupont.f_route.split('?')[1];
      let queryParamsObject = {};
      // Ha van query paraméter
      if (queryString) {
        // Ha több querystring paraméter van, akkor feldolgozzuk
        let queryParamsArray: any[] = queryString.split('&');
        for (var i = 0; i < queryParamsArray.length; i++) {
          queryParamsObject[queryParamsArray[i].split('=')[0]] = queryParamsArray[i].split('=')[1];
        }
      }
      this.router.navigate([route], { queryParams: queryParamsObject });
    }

    sessionStorage.setItem('kivalasztottMenupont', JSON.stringify(menupont));
    this.menuService.kivalasztottMenupontSubject.next(menupont);
    //this.menuService.portalMenuToggle();
  }

  // Megtekintés másik felhasználó nevében
  megtekintesMasNeveben() {

    let kivalasztottFelhasznalo = this.megtekintesMasNevebenFormGroup.controls['f_felhasznalo'].value;

    if (kivalasztottFelhasznalo == null || this.aktualisFelhasznalo.f_valasztott_felhasznalo_id != kivalasztottFelhasznalo.f_valasztott_felhasznalo_id) {
      this.router.navigate(['fooldal']);
    }

    // A modelbe átadjuk az aktuális felhasználót és a kiválasztott felhasználót is
    let model = {
      kivalasztottFelhasznalo: kivalasztottFelhasznalo
    };

    // Megtekintés másik felhasználó nevében beállítás
    this.felhasznaloService.megtekintesMasNeveben(model).subscribe(
      data => {
        // A visszatérési értékkel frissítjük a sessionStorage-t
        sessionStorage.setItem('aktualisFelhasznalo', JSON.stringify(data));
        this.felhasznaloServiceLibrary.aktualisFelhasznaloSubject.next(data);
      }
    );
  }
}
