<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'KÁF-KSZF díjszámoló bővítés esetén'"
                                [subTitle]="''"
                                [templateOutlet]="dijszamolo"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'KÁF-KSZF díjszámoló bővítés esetén'"
                                [subTitle]="''"
                                [templateOutlet]="dijszamolo">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #dijszamolo>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">

        <form [formGroup]="kafKszfDijszamoloFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <mat-card-header>
              <mat-card-title>Információ</mat-card-title>
            </mat-card-header>
            <mat-card-content>

              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <span style="font-weight:bold">
                  Tisztelt Partnerünk!
                </span>
              </div>
              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <span>
                  Amennyiben meglévő KÁF/KSZF szerződését szeretné szaporítani, kérjük adja meg a szerződés azonosítót és a szaporítandó gépjárművek számát (3,5t feletti, nem pótkocsi).<br />
                  A kitöltött ajánlatott kérjük aláíratni (vagy aláírni) és a broker@granitbiztosito.hu email címre beküldeni.
                </span>
              </div>
              <!--
  <div class="col-lg-12 mt-2 mb-3 pr-5 info">
    <span>
      <a href="docs/kaf_kszf_bovites_dijszamolo_2021.pdf" style="color: blue;" target="_blank">KÁF-KSZF bővítés díjszámoló 2021</a>
    </span>
  </div>
    -->

            </mat-card-content>
          </mat-card>

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <mat-card-header>
              <mat-card-title>Adatok</mat-card-title>
            </mat-card-header>
            <mat-card-content>

              <div class="row pb-2 pt-3">

                <div class="col-lg-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerződés azonosító</mat-label>
                    <input type="number" matInput formControlName="f_szerzazon">
                  </mat-form-field>
                  <mat-error *ngIf="hibaService.hiba(kafKszfDijszamoloFormGroup, 'f_szerzazon', 'required')">
                    <mat-icon>error</mat-icon>
                    Kérjük, adja meg a szerződés azonosító!
                  </mat-error>

                </div>

                <div class="col-lg-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Szaporítandó gépjárművek száma</mat-label>
                    <input type="number" matInput formControlName="f_darab">
                  </mat-form-field>
                  <mat-error *ngIf="hibaService.hiba(kafKszfDijszamoloFormGroup, 'f_darab', 'required')">
                    <mat-icon>error</mat-icon>
                    Kérjük, adja meg a szaporítandó gépjárművek száma!
                  </mat-error>

                </div>

              </div>

              <div class="row pb-3">

                <div class="col-lg-3">
                  <button mat-raised-button color="accent" [disabled]="!kafKszfDijszamoloFormGroup.valid" (click)="onSzamolas()">Számolás</button>
                </div>

              </div>

            </mat-card-content>
          </mat-card>

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10" *ngIf="uzenet && uzenet != ''">
            <mat-card-header>
              <mat-card-title>Eredmény</mat-card-title>
            </mat-card-header>
            <mat-card-content>

              <div class="col-lg-12 mt-2 mb-3 pr-5">
                <div *ngIf="!kafKszfDijszamoloFormGroup.controls['f_hiba'].value">
                  <span [innerHTML]="uzenet">
                  </span>
                </div>

                <div class="hibauzenet" *ngIf="kafKszfDijszamoloFormGroup.controls['f_hiba'].value">
                  <span>
                    {{uzenet}}
                  </span>
                </div>

              </div>

            </mat-card-content>
          </mat-card>



        </form>
      </div>
    </div>
  </div>
</ng-template>
