import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { HibaService, UtilityService, GranitFileUploadComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { FlottaSzaporitasService } from '../../../../services/flotta-szaporitas.service';
import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'flotta-casco-szaporitas',
  templateUrl: './flotta-casco-szaporitas.component.html',
  styleUrls: ['./flotta-casco-szaporitas.component.scss']
})
export class FlottaCascoSzaporitasComponent implements OnInit {

  excelUrl: string = "assets/documents/flotta_ajanlat.xlsx";
  alkusziSzukseges: boolean = false;
  alkuszFormGroup: FormGroup;
  alkuszElerhetosegFormGroup: FormGroup;
  szerzodoFormGroup: FormGroup;
  alkusziFeltoltesFormGroup: FormGroup;
  szaporitoFeltoltesFormGroup: FormGroup;


  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  alkusziFajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".png", ".pdf"];
  szaporitoFajlKiterjesztesek: string[] = [".xlsx"];
  fajlFeltoltesEredmeny: string = "";

  @ViewChild('fileUpload', { static: true }) fileUpload: GranitFileUploadComponent;
  @ViewChild('stepper') stepper: MatStepper;

  currentFormGroup: FormGroup;

  folyamatVege: boolean = false;
  toltoKepernyo: boolean = false;

  dnfTablaOszlopok: any[] = [
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_jelleg', header: 'Jelleg' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_onresz', header: 'Önrész' },
    { columnDef: 'f_valasztva', header: '', adattipus: 'check' }];

  getDisplayedDnfColumns(): string[] {
    let displayedColumns = this.dnfTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }

  dnfDataSource: MatTableDataSource<any> = null;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private flottaSzaporitasService: FlottaSzaporitasService,
    private utilityService: UtilityService,
    private felhasznaloService: FelhasznaloService) {
  }

  ngOnInit(): void {
    this.createFormGroups();
  }

  createFormGroups() {
    this.alkuszFormGroup = this.formBuilder.group({
      alkuszNev: new FormControl(''),
      alkuszTorzsszam: new FormControl(''),
      alkuszEmail: new FormControl(''),
    });
    if (this.aktualisFelhasznalo != null) {
      this.alkuszFormGroup.controls["alkuszNev"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev);
      this.alkuszFormGroup.controls["alkuszTorzsszam"].setValue(this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam);
      this.alkuszFormGroup.controls["alkuszEmail"].setValue(this.aktualisFelhasznalo.f_email);
    }
    this.currentFormGroup = this.alkuszFormGroup;

    this.alkuszElerhetosegFormGroup = this.formBuilder.group({
      chkElteroEmail: new FormControl(''),
      alkuszElteroEmail: new FormControl('', [Validators.pattern("^[-_a-z0-9][-_.a-z0-9]*@[-_.a-z0-9]+(\.[-_.a-z0-9]+)*\.([a-z]{2,10})$")]),
      alkuszTelefon: new FormControl(''),
      hibaSzoveg: new FormControl('')
    });

    this.szerzodoFormGroup = this.formBuilder.group({
      flottaAzonosito: new FormControl('', Validators.required),
      szerzodoAdoszam: new FormControl('', Validators.required),
      kotesOka: new FormControl('01', Validators.required),
      szerzodoNev: new FormControl('', Validators.required),
      szerzodoCimEgyben: new FormControl('', Validators.required),
      hibaSzoveg: new FormControl('')
    });

    this.alkusziFeltoltesFormGroup = this.formBuilder.group({
      alkusziFeltoltesDokumentum: new FormControl(''),
      hibaSzoveg: new FormControl('')
    });
    this.szaporitoFeltoltesFormGroup = this.formBuilder.group({
      szaporitoFeltoltesDokumentum: new FormControl('', Validators.required),
      hibaSzoveg: new FormControl(''),
      ugyszam: new FormControl('')
    });

    this.dnfDataSource = new MatTableDataSource();
  }

  szerzodoKereses() {
    this.toltoKepernyo = true;
    this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(null);
    let model = {
      adoszam: this.szerzodoFormGroup.controls["szerzodoAdoszam"].value,
      flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      //torzsszam: this.alkuszFormGroup.controls["alkuszTorzsszam"].value,
      kotes_oka: this.szerzodoFormGroup.controls["kotesOka"].value
    };
    this.flottaSzaporitasService.flottaCascoSzerzodoKereses(model).subscribe(
      data => {
        if (data != null) {
          this.szerzodoFormGroup.controls["szerzodoNev"].setValue(data.ugyfelnev);
          this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue(data.cimegyben);
          this.alkusziSzukseges = (data.alkuszi_szukseges == "I");
          if (data.ugyfelnev == null || data.ugyfelnev == '') {
            this.szerzodoFormGroup.controls["hibaSzoveg"].setValue("<p>A megadott adatokkal nem található szerződő.</p>");
          }
          if (data.info == null || data.info == '') {
            this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(data.info);
          }
        }
        else {
          this.szerzodoFormGroup.controls["hibaSzoveg"].setValue("<p>Nem található a megadott paramétereknek megfelelő szerződés.<br>Ellenőrizze, hogy jól adta-e meg az adószámot, illetve hogy élő flottát kíván-e szaporítani.<br>Amennyiben a probléma továbbra is fennáll, kérjük keresse alkusztámogatóját</p>");
        }
        this.toltoKepernyo = false;
      },
      errorResult => {
        this.szerzodoFormGroup.controls["szerzodoNev"].setValue("");
        this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue("");
        this.szerzodoFormGroup.controls["hibaSzoveg"].setValue(errorResult);
        this.toltoKepernyo = false;
      });
  }

  alkusziFeldolgozas() {
    this.toltoKepernyo = true;
    let model = {
      //f_alkusz_torzsszam: this.alkuszFormGroup.controls["alkuszTorzsszam"].value,
      //f_alkusz_nev: this.alkuszFormGroup.controls["alkuszNev"].value,
      f_alkusz_telefon: this.alkuszElerhetosegFormGroup.controls["alkuszTelefon"].value,
      f_alkusz_email: this.alkuszElerhetosegFormGroup.controls["chkElteroEmail"].value ? this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value : this.alkuszFormGroup.controls["alkuszEmail"].value,
      f_flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      f_flotta_nev: this.szerzodoFormGroup.controls["szerzodoNev"].value,
      f_szerzodo_cim: this.szerzodoFormGroup.controls["szerzodoCimEgyben"].value,
      f_dokumentum: this.alkusziFeltoltesFormGroup.controls["alkusziFeltoltesDokumentum"].value,
      f_modozat: "CASCO"
    }
    this.flottaSzaporitasService.flottaAlkusziFeldolgozas(model).subscribe(
      () => {
        this.folyamatVege = true;

        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: "A fájl feltöltése sikeres.",
          confirmButtonText: 'Rendben'
        });

        this.alkusziFeltoltesFormGroup.controls["hibaSzoveg"].setValue("Az feltöltött alkuszi megbízást a rendszerünk fogadta. A feldolgozását kollégáink 24 órán belül megkezdik.");
        this.toltoKepernyo = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hibás mentés',
          message: "Hiba történt az alkuszi megbízás feldolgozása közben!",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyo = false;
      });
  }   

  szaporitoFeldolgozas() {
    this.szaporitoFeltoltesFormGroup.controls["hibaSzoveg"].setValue(null);
    let model = {
      //f_alkusz_torzsszam: this.alkuszFormGroup.controls["alkuszTorzsszam"].value,
      //f_alkusz_nev: this.alkuszFormGroup.controls["alkuszNev"].value,
      f_alkusz_telefon: this.alkuszElerhetosegFormGroup.controls["alkuszTelefon"].value,
      f_alkusz_email: this.alkuszElerhetosegFormGroup.controls["chkElteroEmail"].value ? this.alkuszElerhetosegFormGroup.controls["alkuszElteroEmail"].value : this.alkuszFormGroup.controls["alkuszEmail"].value,
      f_flotta: this.szerzodoFormGroup.controls["flottaAzonosito"].value,
      f_adoszam: this.szerzodoFormGroup.controls["szerzodoAdoszam"].value,
      f_szerzodo_nev: this.szerzodoFormGroup.controls["szerzodoNev"].value,
      f_dokumentum: this.szaporitoFeltoltesFormGroup.controls["szaporitoFeltoltesDokumentum"].value,
      f_kotes_oka: this.szerzodoFormGroup.controls["kotesOka"].value,
      dnfGepjarmuvek: this.dnfDataSource.data
    }
    this.toltoKepernyo = true;
    this.flottaSzaporitasService.flottaCascoSzaporitoExcelFeldolgozas(model).subscribe(
      data => {
        if (data["azonosito"] != null && data["azonosito"] != '') {
          this.folyamatVege = true;
          this.szaporitoFeltoltesFormGroup.controls["hibaSzoveg"].setValue("Sikeres szaporitás. (" + data["azonosito"] + ")");
          this.szaporitoFeltoltesFormGroup.controls["ugyszam"].setValue(data["azonosito"]);

          this.utilityService.dialogMegnyitasa({
            success: true,
            title: 'Sikeres mentés',
            message: "Az excel feltöltése sikeres.",
            confirmButtonText: 'Rendben'
          });

        }
        else {
          let hibaszoveg: string = "";
          data["hibak"].forEach(hiba => {
            hibaszoveg = hibaszoveg + "<li>" + hiba + "</li>";
          });
          if (hibaszoveg.length > 0) {
            hibaszoveg = "<ul>" + hibaszoveg + "</ul>";
          }
          this.szaporitoFeltoltesFormGroup.controls["hibaSzoveg"].setValue(hibaszoveg);
        }
        this.toltoKepernyo = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hibás mentés',
          message: "Hiba történt a szaporítás feldolgozása közben",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyo = false;
      });
  }

  resetForm() {
    this.stepper.reset();
    this.createFormGroups();
  }

  szerzodoAdatokTorles() {
    this.szerzodoFormGroup.controls["szerzodoNev"].setValue("");
    this.szerzodoFormGroup.controls["szerzodoCimEgyben"].setValue("");
  }


  public onStepChange(event: any) {
    let hiba = false;
    switch (event.previouslySelectedStep.stepControl) {
      case this.alkuszFormGroup:
        this.currentFormGroup = event.selectedStep.stepControl;
        break;
      case this.szerzodoFormGroup:
        this.currentFormGroup = event.selectedStep.stepControl;
        break;

    }
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny += element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }
}
