import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../services/_index';

@Component({
  selector: 'portal-almenu',
  templateUrl: './portal-almenu.component.html',
  styleUrls: ['./portal-almenu.component.scss']
})
export class PortalAlmenuComponent implements OnInit {

  constructor(
    private menuService: MenuService,
    private router: Router) { }

  @Input() menupontok: any[];
  @ViewChild('alMenu', { static: true }) public alMenu;

  ngOnInit(): void {
  }

  onMenupontClick(menupont: any) {
    if (menupont.f_kulso_link) {
      window.open(decodeURIComponent(menupont.f_route), '_blank');
    }
    else {
      // A menü route-ban lehetnek query paraméterek
      let route = menupont.f_route.split('?')[0];
      let queryString = menupont.f_route.split('?')[1];
      let queryParamsObject = {};
      // Ha van query paraméter
      if (queryString) {
        // Ha több querystring paraméter van, akkor feldolgozzuk
        let queryParamsArray: any[] = queryString.split('&');
        for (var i = 0; i < queryParamsArray.length; i++) {
          queryParamsObject[queryParamsArray[i].split('=')[0]] = queryParamsArray[i].split('=')[1];
        }
      }
      this.router.navigate([route], { queryParams: queryParamsObject });
    }

    sessionStorage.setItem('kivalasztottMenupont', JSON.stringify(menupont));
    this.menuService.kivalasztottMenupontSubject.next(menupont);
    //this.menuService.portalMenuToggle();
  }

}
