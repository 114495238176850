<!--<portal-fejlec></portal-fejlec>
<div class="router-wrapper">
  <router-outlet></router-outlet>
</div>
<portal-lablec></portal-lablec>-->

<portal-fejlec></portal-fejlec>

<mat-sidenav-container id="portalmenuSideNavContainer" hasBackdrop="false">
  <mat-sidenav #portalmenuSideNav id="portalmenuSideNav" mode="side">
    <portal-menu></portal-menu>
  </mat-sidenav>
  <mat-sidenav-content>    

    <div class="router-wrapper">
      <router-outlet></router-outlet>
    </div>

    <portal-lablec></portal-lablec>

  </mat-sidenav-content>
</mat-sidenav-container>




