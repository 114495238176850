<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="formEnum == 0 ? 'Új flotta ajánlatkérések' : 'Aláírt flotta ajánlat feltöltése'"
                                [subTitle]="'Ezen az oldalon láthatja új flotta ajánlatkéréseit'"
                                [templateOutlet]="ujFlottaAjanlatkeresek">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #ujFlottaAjanlatkeresek>

  <ng-container *ngIf="formEnum == 0">
    <form [formGroup]="ujFlottaAjanlatkeresekFormGroup" class="form-group">
      <ng-container *ngIf="toltoKepernyoKereses">
        <div class="row mt-4">
          <div class="col-lg-12 bg-white">
            <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!toltoKepernyoKereses">
        <mat-card class="form-content">
          <mat-card-header>
            <mat-card-title class="content-title">Szűrés</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-card>
              <mat-card-content class="mb-0">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő neve</mat-label>
                      <input matInput formControlName="f_szerzodo_nev">
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Állapot</mat-label>
                      <mat-select formControlName="f_allapot">
                        <mat-option value="">Összes</mat-option>
                        <mat-option value="0">Alkuszi megbízás feldolgozása folyamatban</mat-option>
                        <mat-option value="130">Ajánlatkérés feldolgozása folyamatban</mat-option>
                        <mat-option value="135">Ajánlat szemleköteles</mat-option>
                        <mat-option value="150">Ajánlat letölthető</mat-option>
                        <mat-option value="160">Ajánlat letöltve</mat-option>
                        <mat-option value="170">Ajánlat visszatöltve</mat-option>
                        <mat-option value="196">Ajánlatkérés elusítva (érvényes akuszi megbízás hiánya)</mat-option>
                        <mat-option value="197">Ajánlatkérés visszavonva</mat-option>
                        <mat-option value="198">Ajánlatkérés elusítva (visszatöltés hiánya)</mat-option>
                        <mat-option value="199">Ajánlat lezárva</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <button mat-raised-button type="button" color="accent" (click)="onSzures()">Szűrés</button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-card-content>
        </mat-card>

        <div class="row mt-4">
          <div class="col-lg-12">

            <!-- táblázat helye -->
            <mat-table [dataSource]="dataSource">
              <!--class="w100 mr-5 table_border"-->
              <ng-container *ngFor="let column of ujFlottaAjanlatkeresekTablaOszlopok" [matColumnDef]="column.columnDef">
                <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]="column.class">
                  <div>
                    <span [innerHTML]="column.header"></span>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class">
                  <div>
                    <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                  </div>
                </mat-cell>
              </ng-container>

              <!--Fejléc-->
              <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
              <!--Sorok-->
              <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedColumns();" (click)="highlighted(row)" [style.background]="highlightedRow && highlightedRow.length > 0 && highlightedRow.indexOf(row) != -1 ? 'lightblue' : ''"></mat-row>

              <div class="mat-row col-lg-12" *matNoDataRow>
                <div class="mat-cell">Nincs megjeleníthető elem.</div>
              </div>
            </mat-table>
            <mat-paginator #matPaginator [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons
                           aria-label="Válassza ki az oldalon megjelenő találatok számát.">
            </mat-paginator>

          </div>
        </div>

        <div class="row" id="divReszletesAdatok">
          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-12" *ngIf="highlightedRow && highlightedRow.length > 0">
            <mat-card-content>
              <div class="row pt-3 pb-3">
                <div class="col-lg-6">
                  <button mat-raised-button [disabled]="!(highlightedRow[0].controls['f_allapot'].value >= '150' && highlightedRow[0].controls['f_allapot'].value <= '170')" color="accent" (click)="letoltesOnClick()">Ajánlat letöltése</button>
                </div>
                <div class="col-lg-6 text-right">
                  <button mat-raised-button color="accent" (click)="onBezaras()">Bezárás</button>
                </div>
              </div>
              <div class="row pt-3 pb-3">
                <div class="col-lg-4">
                  <button mat-raised-button [disabled]="!(highlightedRow[0].controls['f_allapot'].value >= '160' && highlightedRow[0].controls['f_allapot'].value <= '170')" color="accent" (click)="feltoltesOnClick()">Aláírt ajánlat visszatöltés</button>
                </div>
                <div class="col-lg-4 text-center">
                  <button mat-raised-button [disabled]="!(highlightedRow[0].controls['f_allapot'].value >= '150' && highlightedRow[0].controls['f_allapot'].value <= '170') || !fedezetIgazolas" color="accent" (click)="fedezetIgazolasOnClick()">Fedezetigazolás</button>
                </div>
                <div class="col-lg-4 text-right">
                  <button mat-raised-button [disabled]="highlightedRow[0].controls['f_allapot'].value >= '170'" color="accent" (click)="torlesOnClick()">Ajánlat törlés</button>
                </div>
              </div>

              <div *ngIf="!feltoltes">
                <div class="row mt-5">
                  <div class="col-lg-12">

                    <div class="row mb-4 elvalasztas" *ngIf="highlightedRow[0].controls['f_egyeni_megjegyzes'].value && highlightedRow[0].controls['f_egyeni_megjegyzes'].value != ''">

                      <div class="col-lg-12 cim mb-4">
                        Aktuális állapothoz tarozó megjegyzés
                      </div>

                      <div class="col-lg-12 mb-3 pl-4">
                        <label>{{highlightedRow[0].controls['f_egyeni_megjegyzes'].value}}</label>
                      </div>
                    </div>

                    <div class="row mb-4 elvalasztas" *ngIf="highlightedRow[0].controls['f_szemle'].value == 'I' && highlightedRow[0].controls['f_allapot'].value == '135'">
                      <div class="row justify-content-end flex-row-reverse pl-4">
                        <div class="col-lg-4">
                          <div class="information-wrapper mb-4">
                            Tisztelt Felhasználó!<br />A casco szemléhez kapcsolódó információkat kérjük olvasd el!<br />Amennyiben a megadott kapcsolattartóban változás állna be a szemle elvégzése előtt, kérjük a <b>flottacasco@granitbiztosito.hu</b> címre írd meg a rendszám/ajánlatszám feltüntetésével.<br />Köszönjük a segítséget!<br />Gránit Biztosító<br /><br />
                            <a mat-raised-button color="primary" target="_blank" href="assets/documents/casco_szemle_tajekoztato.pdf"><mat-icon>download</mat-icon>Flotta CASCO szemle tájékoztató letöltése</a>
                          </div>
                        </div>

                        <div class="col-lg-8">

                          <div class="col-lg-12 mb-3 pl-4">

                            <div class="row">
                              <div class="col-lg-12 cim">
                                Szemle kapcsolattartó adatok
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 mb-3 pl-4">

                            <div class="row">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill" [style.width.%]="100">
                                  <mat-label>Neve</mat-label>
                                  <input matInput formControlName='f_kapcsolattarto_neve' required>
                                  <mat-error *ngIf="hibaService.hiba(ujFlottaAjanlatkeresekFormGroup, 'f_kapcsolattarto_neve', 'kapcsolattartoNevlHiany')">
                                    <mat-icon>error</mat-icon>
                                    Hiányzó adat!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill" [style.width.%]="100">
                                  <mat-label>Telefonszám</mat-label>
                                  <input matInput formControlName='f_kapcsolattarto_telszam' required>
                                  <mat-error *ngIf="hibaService.hiba(ujFlottaAjanlatkeresekFormGroup, 'f_kapcsolattarto_telszam', 'kapcsolattartoTelszamlHiany')">
                                    <mat-icon>error</mat-icon>
                                    Hiányzó adat!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill" [style.width.%]="100">
                                  <mat-label>E-mail</mat-label>
                                  <input matInput formControlName='f_kapcsolattarto_email' required (input)="ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_email'].setValue(ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_email'].value.toLowerCase())">
                                  <mat-error *ngIf="hibaService.hiba(ujFlottaAjanlatkeresekFormGroup, 'f_kapcsolattarto_email', 'kapcsolattartoEmailHiany')">
                                    <mat-icon>error</mat-icon>
                                    Hiányzó adat!
                                  </mat-error>
                                  <mat-error *ngIf="hibaService.hiba(ujFlottaAjanlatkeresekFormGroup, 'f_kapcsolattarto_email', 'pattern')">
                                    <mat-icon>error</mat-icon>
                                    Nem valós e-mail cím!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill" [style.width.%]="100">
                                  <mat-label>Fényképeket tud küldeni?</mat-label>
                                  <mat-select formControlName="f_kapcsolattarto_fenykep" required>
                                    <mat-option value="I">Igen</mat-option>
                                    <mat-option value="N">Nem</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(ujFlottaAjanlatkeresekFormGroup, 'f_kapcsolattarto_fenykep', 'kapcsolattartoFenykepHiany')">
                                    <mat-icon>error</mat-icon>
                                    Hiányzó adat!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-3">
                                <button mat-raised-button color="accent" [disabled]="ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_neve'].errors || ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_telszam'].errors || ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_email'].errors || ujFlottaAjanlatkeresekFormGroup.controls['f_kapcsolattarto_fenykep'].errors" (click)="szemleAdatokMentes()">Mentés</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 cim">
                        Társbiztosítotti adatok
                      </div>

                      <div class="col-lg-12">

                        <mat-table [dataSource]="dataSourceCascoGepjarmuvek.data">
                          <ng-container matColumnDef="f_gj_azon" class="col-lg-12">
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" class="pl-0 pr-0">

                              <div class="col-lg-12 mt-3 mb-3">
                                <mat-card>
                                  <mat-card-header>
                                    <mat-card-title>{{element.get('f_rendszam_alvazsszam').value}}</mat-card-title>
                                  </mat-card-header>
                                  <mat-card-content>

                                    <div class="row">
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Hitelintézet neve</mat-label>
                                          <input matInput formControlName='f_hitelintezet_neve' required>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_hitelintezet_neve', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Hitelintézet székhelye</mat-label>
                                          <input matInput formControlName='f_hitelintezet_cime' required>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_hitelintezet_cime', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Szerződés azonosító</mat-label>
                                          <input matInput formControlName='f_hitelszerzodes_azonosito' required>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_hitelszerzodes_azonosito', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Szerződés tipus</mat-label>
                                          <input matInput formControlName='f_szerzodes_tipusa' required>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_szerzodes_tipusa', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Szerződés kezdete</mat-label>
                                          <input [matDatepicker]="szerzKezdPicker" matInput formControlName='f_szerzodes_kezdete' required>
                                          <mat-datepicker-toggle matSuffix [for]="szerzKezdPicker"></mat-datepicker-toggle>
                                          <mat-datepicker #szerzKezdPicker></mat-datepicker>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_szerzodes_kezdete', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Szerződés lejárata</mat-label>
                                          <input [matDatepicker]="szerzLejarPicker" matInput formControlName='f_szerzodes_lejarata' required>
                                          <mat-datepicker-toggle matSuffix [for]="szerzLejarPicker"></mat-datepicker-toggle>
                                          <mat-datepicker #szerzLejarPicker></mat-datepicker>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_szerzodes_lejarata', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Összeg</mat-label>
                                          <input matInput formControlName='f_szerzodes_osszege' required>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_szerzodes_osszege', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-lg-3">
                                        <mat-form-field appearance="fill" [style.width.%]="100">
                                          <mat-label>Devizanem</mat-label>
                                          <mat-select formControlName="f_szerzodes_devizanem" required>
                                            <mat-option value="HUF">Forint</mat-option>
                                            <mat-option value="EUR">Euro</mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="hibaService.hiba(element, 'f_szerzodes_devizanem', 'required')">
                                            <mat-icon>error</mat-icon>
                                            Hiányzó adat!
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col-lg-3">
                                        <button mat-raised-button color="accent" [disabled]="!element.valid" (click)="tarsbiztositottiMentes(element)">Mentés</button>
                                      </div>
                                    </div>

                                  </mat-card-content>
                                </mat-card>
                              </div>

                            </mat-cell>

                          </ng-container>
                          <mat-header-row *matHeaderRowDef="getDisplayedCascoGepjarmuvekColumns()"></mat-header-row>

                          <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedCascoGepjarmuvekColumns();"></mat-row>


                          <div class="mat-row col-lg-12" *matNoDataRow>
                            <div class="mat-cell">Nincs megjeleníthető elem.</div>
                          </div>


                        </mat-table>

                      </div>

                    </div>

                  </div>
                </div>
              </div>



              <div *ngIf="feltoltes" class="mt-5 mb-3">
                <div class="row">
                  <div class="col-lg-12">
                    <h2>Aláírt flottaszaporító ajánlat feltöltése</h2>
                    <p>A feltötlthető fájlok formátuma, biztonsági okok miatt, korlátozott az alábbiakra: JPEG, JPG, PDF, PNG.</p>
                  </div>
                </div>
                <!-- feltöltés -->
                <div class="row">
                  <div class="col-lg-3">
                    <mat-form-field [style.width.%]="100">
                      <mat-label>Flotta azonosító</mat-label>
                      <input matInput formControlName="feltoltesFlotta" [readonly]="true">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field [style.width.%]="100">
                      <mat-label>Szerződő név</mat-label>
                      <input matInput formControlName="feltoltesSzerzodoNev" [readonly]="true">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field [style.width.%]="100">
                      <mat-label>Ügyszám</mat-label>
                      <input matInput formControlName="feltoltesUgyszam" [readonly]="true">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <granit-file-upload #fileUpload ngDefaultControl
                                         [formGroup]="ujFlottaAjanlatkeresekFormGroup"
                                         [formControlName]="'f_fajlok'"
                                         [labelText]="'Aláírt flotta ajánlat feltöltése'"
                                         [buttonText]="'Kiválasztás'"
                                         [allowMultipleFiles]="true"
                                         [acceptedFileExtensions]="['.doc','.docx','.xls','.xlsx','.jpg','.tif','.pdf','.png']">
                    </granit-file-upload>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-lg-3"><button mat-raised-button color="accent" (click)="feltoltescCancelOnClick()">Mégsem</button></div>
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3"><button mat-raised-button color="accent" (click)="onFajlokFeltolteseClick()">Fájl(ok) feltöltése</button></div>
                </div>
              </div>


            </mat-card-content>

          </mat-card>
        </div>


      </ng-container>
    </form>
  </ng-container>



</ng-template>
