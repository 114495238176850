import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { FelhasznaloService, PortalValidator } from '@granit/portal-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'regisztracio',
  templateUrl: './regisztracio.component.html',
  styleUrls: ['./regisztracio.component.scss']
})
export class RegisztracioComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private utilityService: UtilityService,
    public hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private route: ActivatedRoute,
    private router: Router) {

    // token querystring kiolvasása
    this.route.queryParams.subscribe(params => {
      // Ha a token querystringnek van értéke
      if (params['token']) {
        this.token = (params['token'] as string);
      }
    });

  }

  toltoKepernyo: boolean = true;
  token: string;
  profilAdatokFormGroup: FormGroup;
  jelszoElrejtese: boolean = true;
  jelszoIsmetElrejtese: boolean = true;

  ngOnInit(): void {

    // Ha nincs token querystring, akkor hiba és vissza navigáljuk a bejelentkezés oldalra
    if (!this.token) {
      const dialogRef = this.utilityService.dialogMegnyitasa({
        error: true,
        title: 'Hiba az oldal megnyitásakor',
        message: 'Az oldal hiányzó paraméterrel lett megnyitva.',
        confirmButtonText: 'Vissza a főoldalra'
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.router.navigate(['/bejelentkezes']);
        }
      });
    }
    else {
      // Ha van token querystring, ellenőrozzük, hogy valid-e
      this.utilityService.jwtTokenValidalas({ token: this.token }).subscribe(
        () => {
          this.profilAdatokFormGroupLetrehozasa();
          this.toltoKepernyo = false;
        },
        errorResult => {
          const dialogRef = this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba az oldal megnyitásakor',
            message: "A rendelkezésre álló időkeret lejárt, kérjük próbálkozzon újra!",
            confirmButtonText: 'Vissza a főoldalra'
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.router.navigate(['/bejelentkezes']);
            }
          });
        }
      );
    }
  }

  // Profil adatok formgroup létrehozása
  profilAdatokFormGroupLetrehozasa() {
    this.profilAdatokFormGroup = this.formBuilder.group({
      f_felhasznalo_nev: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(6), Validators.maxLength(30), PortalValidator.felhasznaloNevFoglalt(1, this.felhasznaloService)] }),
      f_jelszo: new FormControl(null, { updateOn: 'blur', validators: [Validators.required, PortalValidator.jelszoEllenorzes()] }),
      f_jelszo_ismet: new FormControl(null, [Validators.required])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_jelszo', 'f_jelszo_ismet')]
      }
    );
  }

  // Regisztráció végelgesítés gomb esemény
  onRegisztracioVeglegesitesClick() {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_regisztracio_onRegisztracioVeglegesitesClick').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          // Model, amit átadunk a controllernek
          let model = {
            alkalmazasEnum: 1,
            token: this.token,
            formGroupValue: this.profilAdatokFormGroup.value
          };
          this.felhasznaloService.regisztracioVeglegesites(model).subscribe(
            result => {
              if (result == 0) {
                const dialogRef = this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Sikeres mentés',
                  message: 'A regisztráció véglegesítés sikeresen megtörtént.',
                  confirmButtonText: 'Bejelentkezés'
                });
                dialogRef.afterClosed().subscribe((result: boolean) => {
                  if (result) {
                    this.router.navigate(['/bejelentkezes']);
                  }
                });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a regisztráció véglegesítése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        });
      }
    );
  }
}
