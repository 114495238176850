import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HibaService, UtilityService, GranitTableComponent } from '@granit/common-library';
import { FlottaService } from '../../../../services/flotta.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

@Component({
  selector: 'uj-flotta-dijkalkulaciok',
  templateUrl: './uj-flotta-dijkalkulaciok.component.html',
  styleUrls: ['./uj-flotta-dijkalkulaciok.component.scss']
})
export class UjFlottaDijkalkulaciokComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private flottaService: FlottaService,
    public hibaService: HibaService,
    private utilityService: UtilityService  ) {

  }

  ujFlottaDijkalkulaciokFormGroup: FormGroup;
  ujFlottaDijkalkulaciokTablaOszlopok: any[] = [
    { columnDef: 'f_ugyszam', header: 'Ügyszám', class: 'col-lg-1' },
    { columnDef: 'f_datum', header: 'Beérkezés dátum' },
    { columnDef: 'f_kockezd', header: 'Kockázatviselés kezdete' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot' }
  ];
  @ViewChild('ujFlottaDijkalkulaciokTabla', { static: false }) ujFlottaDijkalkulaciokTabla: GranitTableComponent;
  toltoKepernyoKereses: boolean = false;

  ngOnInit(): void {
    this.ujFlottaDijkalkulaciokFormGroupLetrehozas();
    this.ujFlottaDijkalkulaciokLekerese();
  }

  // Formgroup létrehozása
  ujFlottaDijkalkulaciokFormGroupLetrehozas() {
    this.ujFlottaDijkalkulaciokFormGroup = this.formBuilder.group({
      f_szerzodo_nev: new FormControl(null),
      f_allapot: new FormControl(''),
      tableDataSource: this.formBuilder.array([])
    });
  }

  // Új flotta díjkalkulációk lekérése
  ujFlottaDijkalkulaciokLekerese() {

    // Model létrehozása
    let model = {
      ugyfelnev: this.ujFlottaDijkalkulaciokFormGroup.controls['f_szerzodo_nev'].value,
      allapot: this.ujFlottaDijkalkulaciokFormGroup.controls['f_allapot'].value,
      modozat: 'GFB'
    };

    // Kontroller hívás
    this.flottaService.ujFlottaDijkalkulaciok(model).subscribe(
      data => {
        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.ujFlottaDijkalkulaciokFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
        this.ujFlottaDijkalkulaciokTabla.setDataSource();
      }
    );    
  }

  // Szűrés gomb esemény
  onSzures() {
    this.ujFlottaDijkalkulaciokLekerese();
  }

  // Táblázat letöltés gomb esemény
  onRowOperationClick(formGroup: FormGroup) {
    // Model létrehozása
    let model = {
      formGroupValue: formGroup.value,
      modozat: 'GFB',
      tipus: 'díjkalk'
    };

    this.toltoKepernyoKereses = true;
    this.flottaService.ujFlottaAjanlatVagyDijkalkulacioLetoltes(model).subscribe(
      data => {
        /*var fileURL = URL.createObjectURL(data.body);
        window.open(fileURL, "_blank");*/

        let contentType = 'application/pdf';
        let blob = base64StringToBlob(data.file, contentType);
        saveAs(blob, data.filename);
        this.toltoKepernyoKereses = false;

        this.ujFlottaDijkalkulaciokLekerese();
      }
    );        
  }

}
