import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { EgyenlegkozloService } from '../../services/_index';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'egyenlegkozlo',
  templateUrl: './egyenlegkozlo.component.html',
  styleUrls: ['./egyenlegkozlo.component.scss']
})
export class EgyenlegkozloComponent implements OnInit {

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  torzsszam: string;
  toltoKepernyo: boolean = false;
  egyenlegkozloFormGroup: FormGroup;
  uzenet: string;

  infoLathato: boolean = true;
  keresesLathato: boolean = true;


  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    private egyenlegkozloService: EgyenlegkozloService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.egyenlegkozloFormGroupInit();
  }

  /*FormGroup inicializálása*/

  egyenlegkozloFormGroupInit() {
    this.egyenlegkozloFormGroup = this.formBuilder.group({
      f_keresesi_mezo: new FormControl("0", [CommonValidator.defaultEllenorzo("0")]),
      f_keresesi_adat: new FormControl(null, [Validators.required])
    });
  }

  /*info ablak mutatása/elrejtése*/
  onInfoLathatoValt() {
    this.infoLathato = !this.infoLathato;
  }

  /*keresés ablak form mutatása/elrejtése*/
  onKeresesLathatoValt() {
    this.keresesLathato = !this.keresesLathato;
  }

  egyenlegkozlo(tipus: string) {

    this.toltoKepernyo = true;
    this.uzenet = "";

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_egyenlegkozlo_pdf').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            keresesiMezo: this.egyenlegkozloFormGroup.controls["f_keresesi_mezo"].value.toString(),
            azonosito: this.egyenlegkozloFormGroup.controls["f_keresesi_adat"].value.toString(),
            tipus: tipus
          }

          this.egyenlegkozloService.egyenlegkozlo(model).subscribe(
            data => {
              this.toltoKepernyo = false;
              this.uzenet = data.eredmeny;
              if (data.eredmeny == "") {

                let filename: string = this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam + "_egyenlegkozlo_" + this.egyenlegkozloFormGroup.controls["f_keresesi_adat"].value.toString() + "_" + this.datePipe.transform(new Date(), 'yyyyMMdd');

                if (data.pdf != null) {
                  let contentType = 'application/pdf';
                  let blob = base64StringToBlob(data.pdf, contentType);
                  saveAs(blob, filename + ".pdf");
                }

                if (data.excel != null) {
                  let contentType = 'application/vnd.ms-excel';
                  let blob = base64StringToBlob(data.excel, contentType);
                  saveAs(blob, filename + ".xlsx");
                }
              }
            },
            errorResult => {
              this.toltoKepernyo = false;
              this.uzenet = "Hiba történt a keresés közben!";
            }
          );
        })
      });

  }

  onPdf() {
    this.egyenlegkozlo("pdf");
  }

  onExcel() {
    this.egyenlegkozlo("excel");
  }

}
