<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'IPID'"
                                [subTitle]="''"
                                [templateOutlet]="ipid"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'IPID'"
                                [subTitle]="''"
                                [templateOutlet]="ipid">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #ipid>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">
        <form class="form">
          <ng-container>
            <div class="row">
              <div class="col-lg-12">

                <granit-alkalmazas-fajlok #alkFajlok
                                           [alkalmazasEnumInput]="1"
                                           [oldalInput]="'ipid'"
                                           [oldalCsoportInput]="'ipid'"
                                           [aktualisInput]="'I'">
                </granit-alkalmazas-fajlok>

              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</ng-template>
