import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FuvarozoiService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/fuvarozoi';

  /*
  // KÁF - KSZF díjszámoló
  kafKszfDijszamolo(model: any) {
          
    return this.httpClient.post<any>(this.url + '/KafKszfDijszamolo', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }*/ 

  // Fuvarozói szaporítás
  fuvarozoiSzaporitas(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiSzaporitas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói új szerződés
  fuvarozoiUj(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiUj', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Törzsámhoz rögzített fuvarozói folyamatbanlévő ügyek
  fuvarozoiFolyamatbanlevoUgyek(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiFolyamatbanlevoUgyek', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói adatok lekérdezése 
  fuvarozoiAdatokLekerdezes(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiAdatokLekerdezes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói ajánlat letöltés
  fuvarozoiAjanlat(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiAjanlat', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói szaporítási igény törlése
  fuvarozoiUgyTorles(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiUgyTorles', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói ajánlat betöltése
  fuvarozoiAjanlatFeltoltes(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiAjanlatFeltoltes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói élő szerződések listája
  fuvarozoiEloSzerzodesek(model: any) {
    return this.httpClient.post<any>(this.url + '/FuvarozoiEloSzerzodesek', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói törlés mentése
  fuvarozoiTorlesMentes(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiTorlesMentes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói törlés beküldése
  fuvarozoiTorlesBekuldes(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiTorlesBekuldes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói új szerződés
  fuvarozoiUgyMentes(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiUgyMentes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Fuvarozói fedezetigazolás
  fuvarozoiFedezetIgazolas(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiFedezetIgazolas', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Törzsszámhoz díjtételek lekérése
  fuvarozoiDijtetelekLekerese() {

    return this.httpClient.post<any>(this.url + '/FuvarozoiDijtetelekLekerese', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Törzsszámhoz díjtételek lekérése
  fuvarozoiSzerzodoKeresesEllenorzessel(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiSzerzodoKeresesEllenorzessel', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Törzsszámhoz díjtételek lekérése
  fuvarozoiSzerzodoKereses(model: any) {

    return this.httpClient.post<any>(this.url + '/FuvarozoiSzerzodoKereses', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
