import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kalkulator-hasznosinfo',
  templateUrl: './kalkulator-hasznosinfo.component.html',
  styleUrls: ['./kalkulator-hasznosinfo.component.scss']
})
export class KalkulatorHasznosinfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
