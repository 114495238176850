import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HibaService, UtilityService, GranitTableComponent } from '@granit/common-library';
import { FlottaService } from '../../../../services/flotta.service';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

@Component({
  selector: 'uj-flotta-ajanlatkeresek',
  templateUrl: './uj-flotta-ajanlatkeresek.component.html',
  styleUrls: ['./uj-flotta-ajanlatkeresek.component.scss']
})
export class UjFlottaAjanlatkeresekComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private flottaService: FlottaService,
    public hibaService: HibaService,
    private cdr: ChangeDetectorRef,
    private utilityService: UtilityService) {

  }

  ujFlottaAjanlatkeresekFormGroup: FormGroup;

  dataSource: MatTableDataSource<any>;
  matPaginator: MatPaginator;
  @ViewChild('matPaginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dataSource.paginator = this.matPaginator;
    }
  }
  getDisplayedColumns(): string[] {
    let displayedColumns = this.ujFlottaAjanlatkeresekTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  ujFlottaAjanlatkeresekTablaOszlopok: any[] = [
    { columnDef: 'f_ugyszam', header: 'Ügyszám', class: 'col-1' },
    { columnDef: 'f_datum', header: 'Beérkezés dátum', class: 'col-2', dateFormat: 'YYYY.MM.DD. HH:mm', adattipus: 'date' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot', class: 'col-2' },
    { columnDef: 'f_gj_azon', header: 'gjazon', visible: false },
    // Extra oszlopok
    { columnDef: 'f_letoltes', header: 'Ajánlat letöltés', adattipus: 'button', class: 'col-1'  },
    { columnDef: 'f_visszatoltes', header: 'Ajánlat visszatöltés', adattipus: 'button', class: 'col-1'  }/*,
    { columnDef: 'f_fedezetigazolas', header: 'Fedezet igazolás', adattipus: 'button', class: 'col-1'  }*/
  ];
  //@ViewChild('ujFlottaAjanlatkeresekTabla', { static: false }) ujFlottaAjanlatkeresekTabla: GranitTableComponent;

  ujFlottaAjanlatkeresGepjarmuTablaOszlopok: any[] = [
    { columnDef: 'f_vonalkod', header: 'Ajánlatszám' },
    { columnDef: 'f_kockezd', header: 'Kockázatviselés kezdete', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_jelleg_szoveg', header: 'Jelleg' },
    { columnDef: 'f_uzjelleg_szoveg', header: 'Üzemeltetés jelleg' },
    { columnDef: 'f_allomanydij', header: 'GFB díj' }
  ];
  @ViewChild('ujFlottaAjanlatkeresGepjarmuTabla', { static: false }) ujFlottaAjanlatkeresGepjarmuTabla: GranitTableComponent;

  formEnum: FormEnum = FormEnum.Ajanlatkeresek;
  ujFlottaKivalasztottAjanlatAdatok: any;
  ujFlottaAjanlatVisszatoltesFormGroup: FormGroup;
  toltoKepernyoKereses: boolean = false;
  fajlFeltoltesEredmeny: string = "";

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.ujFlottaAjanlatkeresekLetrehozas();
    this.ujFlottaAjanlatkeresekLekerese();
    this.dataSource = new MatTableDataSource();
  }

  // Új flotta ajánlatkérések szűrő formgroup létrehozása
  ujFlottaAjanlatkeresekLetrehozas() {
    this.ujFlottaAjanlatkeresekFormGroup = this.formBuilder.group({
      f_szerzodo_nev: new FormControl(null),
      f_allapot: new FormControl('')
    });
  }

  // Új flotta ajánlat visszatöltés formgroup létrehozása
  ujFlottaAjanlatVisszatoltesFormGroupLetrehozas() {
    this.ujFlottaAjanlatVisszatoltesFormGroup = this.formBuilder.group({
      tableDataSource: this.formBuilder.array([]),
      f_fajlok: new FormControl(null)
    });

    this.ujFlottaAjanlatVisszatoltesFormGroup.controls['f_fajlok'].valueChanges.subscribe(value => {
      if (value == null || value.length == 0) {
        this.fajlFeltoltesEredmeny = "";
      }
    });
  }

  // Új flotta ajánlatkérések lekérése
  ujFlottaAjanlatkeresekLekerese() {

    // Model létrehozása
    let model = {
      ugyfelnev: this.ujFlottaAjanlatkeresekFormGroup.controls['f_szerzodo_nev'].value,
      allapot: this.ujFlottaAjanlatkeresekFormGroup.controls['f_allapot'].value,
      modozat: 'GFB'
    };

    this.toltoKepernyoKereses = true;
    // Kontroller hívás
    this.flottaService.ujFlottaAjanlatkeresek(model).subscribe(
      data => {
        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.dataSource.data = this.formBuilder.array(fgs).controls;
        this.toltoKepernyoKereses = false;
      }
    );
  }

  // Szűrés gomb esemény
  onSzures() {
    this.ujFlottaAjanlatkeresekLekerese();
  }

  // Táblázat letöltés gomb esemény
  letoltesOnClick(formGroup: FormGroup) {
    // Model létrehozása
    let model = {
      formGroupValue: formGroup.value,
      modozat: 'GFB',
      tipus: 'új'
    };

    this.toltoKepernyoKereses = true;
    this.flottaService.ujFlottaAjanlatVagyDijkalkulacioLetoltes(model).subscribe(
      data => {
        /*var fileURL = URL.createObjectURL(data.body);
        window.open(fileURL, "_blank");*/

        let contentType = 'application/pdf';
        let blob = base64StringToBlob(data.file, contentType);
        saveAs(blob, data.filename);
        this.toltoKepernyoKereses = false;

        this.ujFlottaAjanlatkeresekLekerese();
      }
    );
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny += element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }

  // Táblázat feltöltése gomb
  feltoltesOnClick(formGroup: FormGroup) {

    // A formenumot átváltjuk az ajánlat visszatöltésre
    this.formEnum = FormEnum.AjanlatVisszatoltes;
    // A read only adatokat beletöltjük egy any objektumba
    this.ujFlottaKivalasztottAjanlatAdatok = formGroup.value;
    // Létrehozzük a feltöltéshez használt formgroupot
    this.ujFlottaAjanlatVisszatoltesFormGroupLetrehozas();

    // Model létrehozás
    let model = {
      ugyszam: formGroup.controls['f_ugyszam'].value,
      ajanlatGepjarmuAdatokSzukseges: true
    };

    this.toltoKepernyoKereses = true;
    // Új flotta ajánlat gépjármű adatok lekérdezése ügyszám alapján
    this.flottaService.ujFlottaAdatokLekerese(model).subscribe(
      data => {

               
        const fgs = data["ujFlottaAjanlatGepjarmuAdatok"].map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });

        this.toltoKepernyoKereses = false;
        this.cdr.detectChanges();

        this.ujFlottaAjanlatVisszatoltesFormGroup.setControl('tableDataSource', this.formBuilder.array(fgs));
        this.ujFlottaAjanlatkeresGepjarmuTabla.setDataSource();


      }
    );
  }

  onFajlokFeltolteseClick() {

    // Model létrehozás
    let model = {
      ugyszam: this.ujFlottaKivalasztottAjanlatAdatok.f_ugyszam,
      fajlok: this.ujFlottaAjanlatVisszatoltesFormGroup.controls['f_fajlok'].value,
      modozat: 'GFB'
    };

    this.toltoKepernyoKereses = true;
    this.flottaService.ujFlottaAjanlatMellekletekVisszatoltese(model).subscribe(
      () => {
        const dialogRef = this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres feltöltés',
          message: 'A feltöltés sikeresen megtörtént.',
          confirmButtonText: 'Rendben'
        });
        dialogRef.afterClosed().subscribe(
          () => {
            // A formenumot átváltjuk az ajánlat visszatöltésre
            this.formEnum = FormEnum.Ajanlatkeresek;
          }
        );
        this.ujFlottaAjanlatkeresekLekerese();
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hibás feltöltés',
          message: "Hiba a feltöltés közben",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      }
    );
  }

}


enum FormEnum {
  Ajanlatkeresek,
  AjanlatVisszatoltes
}
