import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DokumentumService {

  constructor(private httpClient: HttpClient) { }

  private url: string = '/api/dokumentum';

  

  // WS AlkuszDokumentumKereses
  dokumentumKereses(model: any) {
    
    return this.httpClient.post<any>(this.url + '/DokumentumKereses', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // WS AlkuszDokumentumIgenyles
  dokumentumIgenyles(model: any) {

    return this.httpClient.post<any>(this.url + '/DokumentumIgenyles', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // WS AlkuszDokumentumIgenyles
  szlaKeresesLetoltes(model: any) {

    return this.httpClient.post<any>(this.url + '/SzlaKeresesLetoltes', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Dokumentum igénylésen belüli jogosultság lekérés
  dokumentumJogosultsag() {

    return this.httpClient.post<any>(this.url + '/DokumentumJogosultsag', null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


}
