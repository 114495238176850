<!--
<mat-toolbar class="menu-fejlec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <button mat-icon-button color="accent" (click)="onMenuToggleClick()" style="font-weight: bold"><mat-icon>chevron_left</mat-icon>MENÜ
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>
  -->

<div class="menu-top-margin">
  <div *ngIf="aktualisFelhasznalo && aktualisFelhasznalo.f_torzsszam == '0000000001'" class="container-fluid pt-3">
    <div class="row">
      <div class="col-12">
        <mat-card class="content-wrapper content">
          <mat-card-header>
            <mat-card-title class="content-title">Megtekintés más nevében</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form [formGroup]="megtekintesMasNevebenFormGroup" class="form-group">
              <div class="row">
                <div class="col-lg-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Partner</mat-label>
                    <mat-select formControlName="f_partner" (selectionChange)="onPartnerSelectionChange()">
                      <mat-option *ngFor="let uzletkoto of uzletkotokLista" [value]="uzletkoto.f_torzsszam">{{uzletkoto.f_nev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Felhasználó</mat-label>
                    <mat-select formControlName="f_felhasznalo" [compareWith]="objectComparisonFunctionFelhasznalo" (selectionChange)="onFelhasznaloSelectionChange()">
                      <mat-option *ngFor="let felhasznalo of felhasznalokLista" [value]="felhasznalo">{{felhasznalo.f_felhasznalo_nev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <mat-nav-list>
    <mat-list-item *ngFor="let menupont of menupontokLista; let last = last">
      <!-- Ha van almenüpont -->
      <div *ngIf="menupont.f_menupontok && menupont.f_menupontok.length > 0">
        <button mat-button [matMenuTriggerFor]="menu.alMenu">
          <span>{{menupont.f_megnevezes}}</span>
          <span fxflex="" style="flex: 1 1 0%; box-sizing: border-box;"></span>
          <mat-icon class="dd-icon">arrow_drop_down</mat-icon>
        </button>
        <portal-almenu #menu [menupontok]="menupont.f_menupontok"></portal-almenu>
      </div>
      <!-- Ha nincs almenüpont -->
      <div *ngIf="!menupont.f_menupontok || menupont.f_menupontok.length === 0">
        <button mat-button (click)="onMenupontClick(menupont)">
          <span>{{menupont.f_megnevezes}}</span>
        </button>
      </div>
      <mat-divider *ngIf="last"></mat-divider>
    </mat-list-item>
  </mat-nav-list>
</div>
