<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Fuvarozói Felelősségbiztosítások'"
                                [subTitle]="''"
                                [templateOutlet]="fuvarozoi"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="'Fuvarozói Felelősségbiztosítások'"
                                [subTitle]="''"
                                [templateOutlet]="fuvarozoi">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #fuvarozoi>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">

        <!-- Szaporítás, törlés -->

        <form [formGroup]="eloSzerzodesekFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">

            <ng-container *ngIf="toltoElo">
              <div class="row">
                <div class="col-lg-12 bg-white">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!toltoElo">

              <div class="card-expand-div" (click)="onEloLathatoValt()" [class.disabledDiv]="toltoUj || toltoRogzitett">
                <mat-card-header>
                  <mat-card-title>Meglévő szerződésre szaporítási, törlési igény bejelentése</mat-card-title>
                  <button mat-icon-button *ngIf="eloLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!eloLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="eloLathato">
                <div class="row pb-4 pt-3 pl-3 pr-3">
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződés azonosító</mat-label>
                      <input type="number" matInput formControlName="f_szerzazon">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő</mat-label>
                      <input type="text" matInput formControlName="f_ugyfelnev">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Módozat</mat-label>
                      <mat-select matInput formControlName="f_modozat">
                        <mat-option value="">Mind</mat-option>
                        <mat-option value="KÁF">KÁF</mat-option>
                        <mat-option value="KSZF">KSZF</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 text-center">
                    <button mat-raised-button color="accent" (click)="elokLekerdezes()">Keresés</button>
                  </div>

                </div>
                <div class="row pb-4 pt-3 pl-3 pr-3">
                  <div class="col-lg-12">
                    <!-- táblázat helye -->
                    <mat-table [dataSource]="dataSourceElo" #matSortElo="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_modozat'" [matSortDirection]="matSortDirectionElo" matSort>
                      <!--class="w100 mr-5 table_border"-->
                      <ng-container *ngFor="let column of eloTablaOszlopok" [matColumnDef]="column.columnDef">
                        <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]="column.class" mat-sort-header>
                          <div>
                            <span [innerHTML]="column.header"></span>
                          </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class">
                          <div>
                            <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '')"></span>
                          </div>
                        </mat-cell>
                      </ng-container>

                      <!--Fejléc-->
                      <mat-header-row *matHeaderRowDef="getDisplayedEloColumns()"></mat-header-row>
                      <!--Sorok-->
                      <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedEloColumns();" (click)="highlightElo(row)" [style.background]="highlightedRowElo && highlightedRowElo.length > 0 && highlightedRowElo.indexOf(row) != -1 ? 'lightblue' : ''"></mat-row>

                      <div class="mat-row col-lg-12" *matNoDataRow>
                        <div class="mat-cell">Nincs megjeleníthető elem.</div>
                      </div>
                    </mat-table>
                    <mat-paginator #paginatorElo [pageSizeOptions]="[5, 10, 20]"
                                   showFirstLastButtons
                                   aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                    </mat-paginator>
                  </div>
                </div>
                <div class="row pb-4 pt-3 pl-3 pr-3">
                  <div class="col-lg-12">
                    <button mat-raised-button color="accent" (click)="szaporitasIndit()" [disabled]="(highlightedRowElo && highlightedRowElo.length == 0) || eloSzerzodesekFormGroup.get('f_ugyszam').value">Új szaporítás indítása a kiválasztott szerződésre</button>
                  </div>
                </div>
                <div class="row pb-4 pt-3 pl-3 pr-3">
                  <div class="col-lg-12">
                    <button mat-raised-button color="accent" (click)="torlesIndit()" [disabled]="(highlightedRowElo && highlightedRowElo.length == 0) || eloSzerzodesekFormGroup.get('f_ugyszam').value">Gépjármű(vek) törlése a kiválasztott szerződésből</button>
                  </div>
                </div>


                <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-12" *ngIf="eloSzerzodesekFormGroup.get('f_ugyszam').value != null">

                  <div>
                    <mat-card-header>
                      <mat-card-title>Adatok</mat-card-title>
                    </mat-card-header>
                  </div>
                  <mat-card-content>

                    <div *ngIf="eloSzerzodesekFormGroup.get('f_szerzodes_ok').value" id="divSzapFuvarozoi">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row pt-3">
                            <div class="col-lg-3">
                              <mat-label class="hx">Kockázat kezdet</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label class="hx">Évforduló</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label class="hx">Jelenlegi díj</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label class="hx">Díjrendezettség</mat-label>
                            </div>
                          </div>
                          <div class="row pb-2 pt-1">
                            <div class="col-lg-3">
                              <mat-label>{{eloSzerzodesekFormGroup.get("f_kockezd").value}}</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label>{{eloSzerzodesekFormGroup.get("f_evfordulo").value}}</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label>{{eloSzerzodesekFormGroup.get("f_alldijsum").value}}</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label>{{eloSzerzodesekFormGroup.get("f_dijrend").value}}</mat-label>
                            </div>
                          </div>

                          <div class="row pb-2 pt-3">
                            <div class="col-lg-12 hx">
                              Szerződésen lévő gépjárművek
                            </div>
                            <div class="col-lg-12">

                              <mat-table [dataSource]="dataSourceFuvarozoiSzerzodesAdatok" #matSortFuvarozoiSzerzodesAdatok="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_tipus'" [matSortDirection]="matSortDirectionFuvarozoiSzerzodesAdatok" matSort>

                                <ng-container *ngFor="let column of fuvarozoiSzerzodesAdatok" [matColumnDef]="column.columnDef">
                                  <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>
                                    <div>
                                      <span [innerHTML]="column.header"></span>
                                    </div>
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]=column.class>
                                    <div *ngIf="!(eloSzerzodesekFormGroup.controls['f_feladat_tipus'].value == 'törlés' && column.columnDef == 'f_torles_hataly')">
                                      <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                    </div>
                                    <div *ngIf="eloSzerzodesekFormGroup.controls['f_feladat_tipus'].value == 'törlés' && column.columnDef == 'f_torles_hataly'">
                                      <mat-form-field appearance="fill" style="margin-top: 10px" [style.width.%]="80">
                                        <input matInput [matDatepicker]="hatalyPicker" formControlName="f_torles_hataly" [min]="element.get(['f_hatalytol']).value < torlesMinDate ? torlesMinDate : element.get(['f_hatalytol']).value" [max]="torlesMaxDate" (dateChange)="torlesHatalyEllenorzes(element)" [disabled]="element.get(['f_torles_hataly_eredeti']).value != null && element.get(['f_torles_hataly_eredeti']).value != ''">
                                        <mat-datepicker-toggle matSuffix [for]="hatalyPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #hatalyPicker></mat-datepicker>
                                        <mat-error *ngIf="hibaService.hiba(element, 'f_torles_hataly', 'torlesNemDatum')">
                                          Hibás dátum!
                                        </mat-error>
                                        <mat-error *ngIf="hibaService.hiba(element, 'f_torles_hataly', 'torlesKorai')">
                                          A hatály nem lehet korábbi a Hatálytól dátumnál!
                                        </mat-error>
                                        <mat-error *ngIf="hibaService.hiba(element, 'f_torles_hataly', 'torlesKorai90')">
                                          A hatály nem lehet korábbi 90 napnál!
                                        </mat-error>
                                        <mat-error *ngIf="hibaService.hiba(element, 'f_torles_hataly', 'torlesKesoi')">
                                          A hatály nem lehet a mai nap plusz 1 hónapnál nagyobb!
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                  </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="getDisplayedFuvarozoiSzerzodesAdatokColumns()"></mat-header-row>

                                <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFuvarozoiSzerzodesAdatokColumns();"></mat-row>

                                <div class="mat-row col-lg-12" *matNoDataRow>
                                  <div class="mat-cell">Nincs megjeleníthető elem.</div>
                                </div>
                              </mat-table>
                              <mat-paginator #paginatorFuvarozoiSzerzodesAdatok [pageSizeOptions]="[5, 10, 20]"
                                             showFirstLastButtons
                                             aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                              </mat-paginator>

                            </div>

                          </div>

                          <div *ngIf="eloSzerzodesekFormGroup.controls['f_feladat_tipus'].value == 'szaporítás'">

                            <div class="row">
                              <div class="col-lg-3">
                                <mat-form-field appearance="fill">
                                  <mat-label>Módosítás hatálya</mat-label>
                                  <input matInput [matDatepicker]="modHatalyPicker" formControlName="f_modhataly_datum" [min]="modHatalyMinDate" [max]="modHatalyMaxDate" (dateChange)="modHatalyDatumValtozas()">
                                  <mat-datepicker-toggle matSuffix [for]="modHatalyPicker"></mat-datepicker-toggle>
                                  <mat-datepicker #modHatalyPicker></mat-datepicker>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_datum', 'required')">
                                    Hiányzó dátum!
                                  </mat-error>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_datum', 'matDatepickerParse')">
                                    Hibás dátum!
                                  </mat-error>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_datum', 'matDatepickerMin')">
                                    A dátum nem lehet kisebb a mai napnál!
                                  </mat-error>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_datum', 'matDatepickerMax')">
                                    A dátum nem lehet nagyobb a mai nap + 6 hónapnál!
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div class="col-lg-3" *ngIf="idoLathato">
                                <mat-form-field appearance="fill">
                                  <mat-label>Óra</mat-label>
                                  <mat-select formControlName="f_modhataly_ora" (selectionChange)="idoEllenorzes()">
                                    <mat-option *ngFor="let ora of oraLista" [value]="ora">{{ora}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_ora', 'required')">
                                    Hiányzó óra!
                                  </mat-error>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_ora', 'kisebbIdo')">
                                    Az időpont nem lehet kisebb az aktuális időpontnál!
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div class="col-lg-3" *ngIf="idoLathato">
                                <mat-form-field appearance="fill">
                                  <mat-label>Perc</mat-label>
                                  <mat-select formControlName="f_modhataly_perc" (selectionChange)="idoEllenorzes()">
                                    <mat-option *ngFor="let perc of percLista" [value]="perc">{{perc}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_modhataly_perc', 'required')">
                                    Hiányzó perc!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-12 mb-3">
                                <mat-label class="mt-2 font-weight-500">Díjtétel kiválasztása</mat-label>
                              </div>
                            </div>
                            <div class="row" *ngIf="eloSzerzodesekFormGroup.controls['f_modkod'].value == '32101'">

                              <div class="col-lg-12 mb-2">
                                <mat-label class="mt-2">Kérjük, a legördülő mezőből a KÁF díjtételeket kiválasztani! Díjtétel (A) legfeljebb 3,5 t össztömegű tgk., Díjtétel (B) 3,5 t össztömeg feletti tgk. és vontató. Mind a két díjtételt ki kell választani!</mat-label>
                              </div>

                              <div class="col-lg-4">
                                <mat-form-field appearance="fill">
                                  <mat-label>Díjtétel (A)</mat-label>
                                  <mat-select formControlName="f_dijtetel_a" required>
                                    <mat-option *ngFor="let dijtetel of dijtetelALista" [value]="dijtetel">{{dijtetel}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_dijtetel_a', 'required')">
                                    <mat-icon>error</mat-icon>
                                    Kérjük, adja meg az A tipusú díjtételt!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-lg-4">
                                <mat-form-field appearance="fill">
                                  <mat-label>Díjtétel (B)</mat-label>
                                  <mat-select formControlName="f_dijtetel_b" required>
                                    <mat-option *ngFor="let dijtetel of dijtetelBLista" [value]="dijtetel">{{dijtetel}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_dijtetel_b', 'required')">
                                    <mat-icon>error</mat-icon>
                                    Kérjük, adja meg a B tipusú díjtételt!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="row" *ngIf="eloSzerzodesekFormGroup.controls['f_modkod'].value == '32102'">

                              <div class="col-lg-12 mb-2">
                                <mat-label class="mt-2">Kérjük, a legördülő mezőből a KSZF díjtételt kiválasztani!</mat-label>
                              </div>

                              <div class="col-lg-4">
                                <mat-form-field appearance="fill">
                                  <mat-label>Díjtétel</mat-label>
                                  <mat-select formControlName="f_dijtetel_b" required>
                                    <mat-option *ngFor="let dijtetel of dijtetelKSZFLista" [value]="dijtetel">{{dijtetel}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="hibaService.hiba(eloSzerzodesekFormGroup, 'f_dijtetel_b', 'required')">
                                    <mat-icon>error</mat-icon>
                                    Kérjük, adja meg a díjtételt!
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-12" *ngIf="eloSzerzodesekFormGroup.controls['f_modkod'].value == '32101'">
                                <span>
                                  <a href="assets/documents/kaf_betolto.xlsx" style="color: blue;">KÁF bővítés excel sablon</a>
                                </span>
                              </div>
                              <div class="col-lg-12" *ngIf="eloSzerzodesekFormGroup.controls['f_modkod'].value == '32102'">
                                <span>
                                  <a href="assets/documents/kszf_betolto.xlsx" style="color: blue;">KSZF bővítés excel sablon</a>
                                </span>
                              </div>
                            </div>

                            <div class="row pt-3">
                              <div class="col-lg-12">
                                <mat-label class="hx">
                                  Bővítő excel feltöltése
                                </mat-label>
                              </div>
                            </div>

                            <div class="row pb-2 pt-1">
                              <div class="col-lg-12">

                                <granit-file-upload #fileUploadExcel ngDefaultControl [formGroup]="eloSzerzodesekFormGroup" [formControlName]="'f_dokumentum'" [labelText]="''"
                                                     [buttonText]="'Fájl kiválasztás'" [allowMultipleFiles]="false" [acceptedFileExtensions]="fajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>

                              </div>
                            </div>
                            <div class="row pb-3" *ngIf="eloSzerzodesekFormGroup.get('f_dokumentum').value">
                              <div class="col-lg-12">
                                <mat-label>
                                  Feltöltés eredménye: {{fajlFeltoltesEredmeny}}
                                </mat-label>
                              </div>
                            </div>
                            <div class="row pt-2 pb-3">
                              <div class="col-lg-12">
                                <p>
                                  <strong>Tisztelt Partnerünk,</strong><br /> felhívjuk figyelmét, hogy a
                                  szaporítás során az adatközlőben csak az újonnan szaporítani kívánt gépjárművek
                                  adatait legyen szíves megadni.<br />Köszönettel: Gránit Biztosító
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="uzenet != ''">
                      <div class="row pt-3 pb-3">
                        <div class="col-lg-12 hiba_szoveg">
                          <span [innerHtml]="uzenet"></span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="row pt-3 pb-3">
                        <div class="col-lg-6">
                          <button mat-raised-button *ngIf="eloSzerzodesekFormGroup.get('f_szerzodes_ok').value && eloSzerzodesekFormGroup.get('f_feladat_tipus').value == 'szaporítás'" color="accent" [disabled]="fajlFeltoltesEredmeny != 'Sikeres' || !eloSzerzodesekFormGroup.valid" (click)="onSzaporitas()">Szaporítási igény beküldése</button>
                          <button mat-raised-button *ngIf="eloSzerzodesekFormGroup.get('f_szerzodes_ok').value && eloSzerzodesekFormGroup.get('f_feladat_tipus').value == 'törlés'" [disabled]="szerzodesFormGroup.get('f_hiba').value || !torlesDb" color="accent" (click)="onTorlesMentes()">Törlési igény mentése</button>
                        </div>
                        <div class="col-lg-6 text-right">
                          <button mat-raised-button color="accent" (click)="onMegsemKezd()">Mégsem</button>
                        </div>
                      </div>
                    </div>

                  </mat-card-content>

                </mat-card>

              </mat-card-content>

            </ng-container>

          </mat-card>
        </form>

        <!-- Új -->
        <form [formGroup]="ujSzerzodesFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">

            <ng-container *ngIf="toltoUj">
              <div class="row">
                <div class="col-lg-12 bg-white">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!toltoUj">

              <div class="card-expand-div" (click)="onUjLathatoValt()" [class.disabledDiv]="toltoElo || toltoRogzitett">
                <mat-card-header>
                  <mat-card-title>Új szerződés</mat-card-title>
                  <button mat-icon-button *ngIf="ujLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!ujLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="ujLathato">
                <div class="row pb-4 pt-3 pl-3" id="divUjFuvarozoi">

                  <div class="col-lg-12 div-elvalaszto-alul mb-2">
                    <div class="row pb-2 pt-3">
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Módozat</mat-label>
                          <mat-select formControlName="f_modozat">
                            <mat-option value="32101">KÁF</mat-option>
                            <mat-option value="32102">KSZF</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Kockázatviselés kezdete</mat-label>
                          <input matInput [matDatepicker]="kockezdPicker" formControlName="f_kockezd_datum" [min]="kockezdMinDate" [max]="kockezdMaxDate" (dateChange)="kockezdDatumValtozas()">
                          <mat-datepicker-toggle matSuffix [for]="kockezdPicker"></mat-datepicker-toggle>
                          <mat-datepicker #kockezdPicker></mat-datepicker>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_datum', 'required')">
                            Hiányzó dátum!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_datum', 'matDatepickerParse')">
                            Hibás dátum!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_datum', 'matDatepickerMin')">
                            A dátum nem lehet kisebb a mai napnál!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_datum', 'matDatepickerMax')">
                            A dátum nem lehet nagyobb a mai nap + 6 hónapnál!
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-lg-3" *ngIf="idoLathato">
                        <mat-form-field appearance="fill">
                          <mat-label>Óra</mat-label>
                          <mat-select formControlName="f_kockezd_ora" (selectionChange)="idoEllenorzes()">
                            <mat-option *ngFor="let ora of oraLista" [value]="ora">{{ora}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_ora', 'required')">
                            Hiányzó óra!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_ora', 'kisebbIdo')">
                            Az időpont nem lehet kisebb az aktuális időpontnál!
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-lg-3" *ngIf="idoLathato">
                        <mat-form-field appearance="fill">
                          <mat-label>Perc</mat-label>
                          <mat-select formControlName="f_kockezd_perc" (selectionChange)="idoEllenorzes()">
                            <mat-option *ngFor="let perc of percLista" [value]="perc">{{perc}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_kockezd_perc', 'required')">
                            Hiányzó perc!
                          </mat-error>
                        </mat-form-field>
                      </div>



                    </div>
                  </div>

                  <div class="col-lg-12 div-elvalaszto-alul mb-2">

                    <mat-label class="cimke">Alkusz</mat-label>

                    <div class="row pb-2 pt-3">
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Név és kód</mat-label>
                          <input matInput formControlName="f_alkusz_neve_kodja" readonly>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Regisztrált email cím</mat-label>
                          <input matInput formControlName="f_alkusz_regisztralt_email" readonly>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-icon matSuffix matTooltip="Kérjük, hogy olyan telefonszámot adjon meg, amelyen az adott flottával kapcsolatos esetleges kérdések, problémák esetén kollégáink elérhetik Önt. Megadása nem kötelező.">info</mat-icon>
                          <mat-label>Telefon</mat-label>
                          <input matInput formControlName="f_alkusz_telefon">
                        </mat-form-field>
                      </div>

                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-icon matSuffix matTooltip="Kérjük, itt adja meg azt az e-mail címet, amelyre a szaporítással kapcsolatos értesítéseket küldhetjük, illetve amelyen keresztül probléma esetén elérhetjük.">info</mat-icon>
                          <mat-label>Regisztrált email cím kommunikációhoz</mat-label>
                          <mat-select formControlName="f_regisztralt_email_hasznalata">
                            <mat-option value="I">Igen</mat-option>
                            <mat-option value="N">Nem</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-6" *ngIf="ujSzerzodesFormGroup.controls['f_regisztralt_email_hasznalata'].value == 'N'">
                        <mat-form-field appearance="fill">
                          <mat-label>Email cím</mat-label>
                          <input matInput formControlName="f_alkusz_email" required>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_alkusz_email', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az e-mail címet!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_alkusz_email', 'email')">
                            <mat-icon>error</mat-icon>
                            A megadott e-mail cím nem megfelelő!
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-lg-6" *ngIf="ujSzerzodesFormGroup.controls['f_regisztralt_email_hasznalata'].value == 'N'">
                        <mat-form-field appearance="fill">
                          <mat-label>Email cím megerősítése</mat-label>
                          <input matInput formControlName="f_alkusz_email_ismet" required>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_alkusz_email_ismet', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az e-mail címét ismét!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_alkusz_email_ismet', 'email')">
                            <mat-icon>error</mat-icon>
                            A megadott e-mail cím nem megfelelő!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_alkusz_email_ismet', 'nemEgyezik')">
                            <mat-icon>error</mat-icon>
                            Kérjük, helyesen ismételje meg az e-mail címét!
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row pb-2" *ngIf="aktualisFelhasznalo.f_tobbes_ugynok == 'N'">
                      <div class="col-lg-12">
                        <mat-checkbox formControlName="f_alkuszi_megbizas">Kijelentem, hogy jelen szerződés megkötéséhez szükséges érvényes alkuszi megbízással rendelkezem.<br /> A Bit 398. § (1) pontja alapján az ügyféllel kötött szerződés alapján, az ügyfél megbízásából járok el.</mat-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 div-elvalaszto-alul mb-2 mt-2">

                    <mat-label class="cimke mt-2">Szerződő</mat-label>

                    <div class="row pb-2 pt-3">
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-icon matSuffix matTooltip="Kérjük az adószám első 8 számjegyét adja meg, szóközök és kötőjelek nélkül.">info</mat-icon>
                          <mat-label>Adószám</mat-label>
                          <input matInput formControlName="f_adoszam" required maxlength="8">
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_adoszam', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az adószámot!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_adoszam', 'minlength')">
                            <mat-icon>error</mat-icon>
                            A kereséshez az adószám első 8 karakterét kell megadni!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-3">
                        <button mat-raised-button type="button" [disabled]="(ujSzerzodesFormGroup && (ujSzerzodesFormGroup.controls['f_adoszam'].errors || ujSzerzodesFormGroup.controls['f_adoszam_talalt'].value != null)) || toltoUj" color="accent" (click)="onSzerzodoKeresesClick(false)">Keresés</button>
                      </div>
                      <div class="col-lg-3">
                        <button mat-raised-button type="button" [disabled]="(ujSzerzodesFormGroup && ujSzerzodesFormGroup.controls['f_adoszam_talalt'].value == null) || toltoUj" color="accent" (click)="onSzerzodoKeresesClick(true)">Szerződő adatainak frissítése</button>
                      </div>
                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Tipus</mat-label>
                          <input matInput formControlName="f_szerzodo_tipus" readonly>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        Figyelem! A szerződő adatait a Biztosító tölti fel adatbázisából. Amennyiben az ügyfél által megadott szerződő adataiban és a feltöltés adataiban eltérés van, pl. adószám, székhely címe stb., akkor kérünk, hogy nyomj rá a „Szerződő adatainak frissítésé” gombra!
                      </div>
                    </div>
                    <div class="row pb-2">
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Név</mat-label>
                          <input matInput formControlName="f_szerzodo_nev" readonly>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Cím</mat-label>
                          <input matInput formControlName="f_szerzodo_cime" readonly>
                        </mat-form-field>
                      </div>
                    </div>

                    <div>
                      <div class="row pb-2" *ngIf="!ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].value || ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].value == 'cég'">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Cégjegyzékszám</mat-label>
                            <input matInput formControlName="f_cegjegyzekszam" readonly>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Főtevékenység TEÁOR kód</mat-label>
                            <input matInput formControlName="f_teaor" readonly>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].value && ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].value != 'cég'">
                      <div class="row pb-2">
                        <div class="col-lg-12 font-weight-500">
                          Egyéni vállalkozó adatinál a születés helyét, idejét és anyja nevét kérjük kötelezően kitölteni!
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Születési hely</mat-label>
                            <input matInput formControlName="f_szerzodo_szulhely">
                          </mat-form-field>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szerzodo_szulhely', 'hianyzoSzulhely')">
                            <mat-icon>error</mat-icon>
                            Nem lett kitöltve a születési dátum
                          </mat-error>
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Születési dátum</mat-label>
                            <input matInput formControlName="f_szerzodo_szuldat" [matDatepicker]="szuldatDatePicker" [min]="minSzuldat" [max]="maxSzuldat" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="szuldatDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #szuldatDatePicker></mat-datepicker>
                          </mat-form-field>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szerzodo_szuldat', 'hianyzoSzuldat')">
                            <mat-icon>error</mat-icon>
                            Nem lett kitöltve a születési dátum
                          </mat-error>
                        </div>
                      </div>

                      <div class="row pb-2">
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Anyja neve</mat-label>
                            <input matInput formControlName="f_szerzodo_anyanev">
                          </mat-form-field>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szerzodo_anyanev', 'hianyzoAnyaNev')">
                            <mat-icon>error</mat-icon>
                            Nem lett kitöltve a születési dátum
                          </mat-error>
                        </div>
                        <div class="col-lg-6">
                          <mat-form-field appearance="fill">
                            <mat-label>Főtevékenység TEÁOR kód</mat-label>
                            <input matInput formControlName="f_teaor" readonly>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Eltérő szerződő levelezési cím</mat-label>
                          <mat-select formControlName="f_eltero_levcim">
                            <mat-option value="I">Igen</mat-option>
                            <mat-option value="N">Nem</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div *ngIf="ujSzerzodesFormGroup.controls['f_eltero_levcim'].value == 'I'">
                      <div class="row pb-2 mr-1">
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Irányítószám</mat-label>
                            <input matInput formControlName="f_lev_irsz" mask="0000" required autocomplete="off">
                            <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_lev_irsz', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adja meg az irányítószámot!
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Település</mat-label>
                            <mat-select formControlName="f_lev_telepules" required>
                              <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_lev_telepules', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adja meg a települést!
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Utcanév</mat-label>
                            <mat-select formControlName="f_lev_kt_nev" required>
                              <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_lev_kt_nev', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adja meg az utcanevet!
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row pb-2 mr-1">
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Úttípus</mat-label>
                            <mat-select formControlName="f_lev_kt_tipus" required [disabled]="lev_kt_tipus_readonly">
                              <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_lev_kt_tipus', 'required')">
                              <mat-icon>error</mat-icon>
                              Kérjük, adja meg az úttípust!
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Házszám</mat-label>
                            <input matInput formControlName="f_lev_hazszam1" autocomplete="off">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Házszám 2</mat-label>
                            <input matInput formControlName="f_lev_hazszam2" autocomplete="off">
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row pb-2 mr-1">
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Épület</mat-label>
                            <input matInput formControlName="f_lev_epulet" autocomplete="off">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Lépcsőház</mat-label>
                            <input matInput formControlName="f_lev_lepcsohaz" autocomplete="off">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Emelet</mat-label>
                            <mat-select formControlName="f_lev_emelet">
                              <mat-option value="FSZ">Földszint</mat-option>
                              <mat-option value="MFSZ">Magasföldszint</mat-option>
                              <mat-option value="FÉLEM">Félemelet</mat-option>
                              <mat-option value="TTÉR">Tetőtér</mat-option>
                              <mat-option value="SZUT">Szuterén</mat-option>
                              <mat-option value="GRZS">Garázs</mat-option>
                              <mat-option value="GARSOR">Garázssor</mat-option>
                              <mat-option value="ALGSOR">Alagsor</mat-option>
                              <mat-option value="ÜZLSOR">Üzletsor</mat-option>
                              <mat-option value="1. EM">1. Emelet</mat-option>
                              <mat-option value="2. EM">2. Emelet</mat-option>
                              <mat-option value="3. EM">3. Emelet</mat-option>
                              <mat-option value="4. EM">4. Emelet</mat-option>
                              <mat-option value="5. EM">5. Emelet</mat-option>
                              <mat-option value="6. EM">6. Emelet</mat-option>
                              <mat-option value="7. EM">7. Emelet</mat-option>
                              <mat-option value="8. EM">8. Emelet</mat-option>
                              <mat-option value="9. EM">9. Emelet</mat-option>
                              <mat-option value="10. EM">10. Emelet</mat-option>
                              <mat-option value="11. EM">11. Emelet</mat-option>
                              <mat-option value="12. EM">12. Emelet</mat-option>
                              <mat-option value="13. EM">13. Emelet</mat-option>
                              <mat-option value="14. EM">14. Emelet</mat-option>
                              <mat-option value="15. EM">15. Emelet</mat-option>
                              <mat-option value="16. EM">16. Emelet</mat-option>
                              <mat-option value="17. EM">17. Emelet</mat-option>
                              <mat-option value="18. EM">18. Emelet</mat-option>
                              <mat-option value="19. EM">19. Emelet</mat-option>
                              <mat-option value="20. EM">20. Emelet</mat-option>
                              <mat-option value="POSTALÁDA">Postaláda</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row pb-2 mr-1">
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Ajtó</mat-label>
                            <input matInput formControlName="f_lev_ajto" autocomplete="off">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-4">
                          <mat-form-field appearance="fill">
                            <mat-label>Helyrajzi szám</mat-label>
                            <input matInput formControlName="f_lev_hrsz" autocomplete="off">
                          </mat-form-field>
                        </div>

                      </div>

                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-icon matSuffix matTooltip="E-mail cím megadása esetén minden nyomtatványt elektronikus formában fog megkapni az ügyfél az itt megadott címre.">info</mat-icon>
                          <mat-label>Email cím elektronikus kommunikációhoz</mat-label>
                          <mat-select formControlName="f_email_ekom_van">
                            <mat-option value="I">Igen</mat-option>
                            <mat-option value="N">Nem</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row pb-2">
                      <div class="col-lg-6" *ngIf="ujSzerzodesFormGroup.controls['f_email_ekom_van'].value == 'I'">
                        <mat-form-field appearance="fill">
                          <mat-label>E-mail cím</mat-label>
                          <input matInput formControlName="f_email_ekom">
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_email_ekom', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az e-mail címet!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_email_ekom', 'email')">
                            <mat-icon>error</mat-icon>
                            A megadott e-mail cím nem megfelelő!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6" *ngIf="ujSzerzodesFormGroup.controls['f_email_ekom_van'].value == 'I'">
                        <mat-form-field appearance="fill">
                          <mat-label>E-mail cím ismét</mat-label>
                          <input matInput formControlName="f_email_ekom_ismet">
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_email_ekom_ismet', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az e-mail címét ismét!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_email_ekom_ismet', 'email')">
                            <mat-icon>error</mat-icon>
                            A megadott e-mail cím nem megfelelő!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_email_ekom_ismet', 'nemEgyezik')">
                            <mat-icon>error</mat-icon>
                            Kérjük, helyesen ismételje meg az e-mail címét!
                          </mat-error>
                        </mat-form-field>
                      </div>

                    </div>

                  </div>

                  <div class="col-lg-12 div-elvalaszto-alul mb-2 mt-2">

                    <mat-label class="cimke mt-2">Díjfizetés</mat-label>

                    <div class="row pb-2 pt-3">
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Díjfizetés módja</mat-label>
                          <mat-select formControlName="f_dijfizmod">
                            <mat-option value="B">Banki átutalás</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Díjfizetés gyakorisága</mat-label>
                          <mat-select formControlName="f_dijfizgyak">
                            <mat-option value="E">Éves</mat-option>
                            <mat-option value="F">Féléves</mat-option>
                            <mat-option value="N">Negyedéves</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Számlaszám</mat-label>
                          <input matInput formControlName="f_szamlaszam" maxlength="24">
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szamlaszam', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg a számlaszámot!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szamlaszam', 'hibasSzlaHossz')">
                            <mat-icon>error</mat-icon>
                            A számlaszámnak 16 vagy 24 számból kell állnia!
                          </mat-error>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_szamlaszam', 'hibasSzlaKotojel')">
                            <mat-icon>error</mat-icon>
                            A számlaszám megadásánál csak számokat használjon!
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 mb-3">
                        <mat-label class="mt-2 font-weight-500">Díjtétel kiválasztása</mat-label>
                      </div>
                    </div>

                    <div class="row" *ngIf="ujSzerzodesFormGroup.controls['f_modozat'].value == '32101'">
                      <div class="col-lg-12 mb-2">
                        <mat-label class="mt-2">Kérjük, a legördülő mezőből a KÁF díjtételeket kiválasztani! Díjtétel (A) legfeljebb 3,5 t össztömegű tgk., Díjtétel (B) 3,5 t össztömeg feletti tgk. és vontató. Mind a két díjtételt ki kell választani!</mat-label>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Díjtétel (A)</mat-label>
                          <mat-select formControlName="f_dijtetel_a" required>
                            <mat-option *ngFor="let dijtetel of dijtetelALista" [value]="dijtetel">{{dijtetel}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_dijtetel_a', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg az A tipusú díjtételt!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Díjtétel (B)</mat-label>
                          <mat-select formControlName="f_dijtetel_b" required>
                            <mat-option *ngFor="let dijtetel of dijtetelBLista" [value]="dijtetel">{{dijtetel}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_dijtetel_b', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg a B tipusú díjtételt!
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row" *ngIf="ujSzerzodesFormGroup.controls['f_modozat'].value == '32102'">

                      <div class="col-lg-12 mb-2">
                        <mat-label class="mt-2">Kérjük, a legördülő mezőből a KSZF díjtételt kiválasztani!</mat-label>
                      </div>


                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Díjtétel</mat-label>
                          <mat-select formControlName="f_dijtetel_b" required>
                            <mat-option *ngFor="let dijtetel of dijtetelKSZFLista" [value]="dijtetel">{{dijtetel}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="hibaService.hiba(ujSzerzodesFormGroup, 'f_dijtetel_b', 'required')">
                            <mat-icon>error</mat-icon>
                            Kérjük, adja meg a díjtételt!
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                  </div>

                  <div class="col-lg-12 div-elvalaszto-alul mb-2 mt-2">

                    <mat-label class="cimke mt-2">Excel fájl feltöltés</mat-label>

                    <div class="row pb-2 pt-3">
                      <div class="col-lg-12" *ngIf="ujSzerzodesFormGroup.controls['f_modozat'].value == '32101'">
                        <span>
                          <a href="assets/documents/kaf_betolto.xlsx" style="color: blue;">KÁF adatközlő excel sablon</a>
                        </span>
                      </div>
                      <div class="col-lg-12" *ngIf="ujSzerzodesFormGroup.controls['f_modozat'].value == '32102'">
                        <span>
                          <a href="assets/documents/kszf_betolto.xlsx" style="color: blue;">KSZF adatközlő excel sablon</a>
                        </span>
                      </div>
                    </div>


                    <div class="row pb-2 pt-3">
                      <div class="col-lg-6">
                        <granit-file-upload #fileUploadExcelUj ngDefaultControl
                                             [formGroup]="ujSzerzodesFormGroup"
                                             [formControlName]="'f_dokumentum'"
                                             [labelText]="'Kérjük töltse fel a kitöltött új flotta ajánlat sablont!'"
                                             [buttonText]="'Kiválasztás'"
                                             [acceptedFileExtensions]="['.xlsx']"
                                             (changeClick)="onFajlHozzaadas($event)">
                        </granit-file-upload>
                      </div>

                    </div>

                  </div>

                  <div *ngIf="uzenet != ''">
                    <div class="row pt-3 pb-3">
                      <div class="col-lg-12 hiba_szoveg">
                        <span [innerHtml]="uzenet"></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-2 mt-3">
                    <button mat-raised-button type="button" [disabled]="!ujSzerzodesFormGroup.valid" color="accent" (click)="onigenyBekuldese()">Igény beküldése</button>
                  </div>

                </div>
              </mat-card-content>
            </ng-container>
          </mat-card>
        </form>

        <!-- Rögzített igények -->
        <form [formGroup]="szerzodesFormGroup" class="form">


          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">

            <ng-container *ngIf="toltoRogzitett">
              <div class="row">
                <div class="col-lg-12 bg-white">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!toltoRogzitett">

              <div class="card-expand-div" (click)="onRogzitettLathatoValt()" [class.disabledDiv]="toltoUj || toltoElo">
                <mat-card-header>
                  <mat-card-title>Folyamatban lévő ügyek követése</mat-card-title>
                  <button mat-icon-button *ngIf="rogzitettLathato">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button *ngIf="!rogzitettLathato">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </mat-card-header>
              </div>
              <mat-card-content *ngIf="rogzitettLathato">

                <div class="row pt-3 pl-3 pr-3">
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Ügyszám</mat-label>
                      <input type="number" matInput formControlName="f_ugyszam_kereses">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződés azonosító</mat-label>
                      <input type="number" matInput formControlName="f_szerzazon_kereses">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row pl-3 pr-3">
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő</mat-label>
                      <input type="text" matInput formControlName="f_ugyfelnev_kereses">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Módozat</mat-label>
                      <mat-select matInput formControlName="f_modozat_kereses">
                        <mat-option value="">Mind</mat-option>
                        <mat-option value="KÁF">KÁF</mat-option>
                        <mat-option value="KSZF">KSZF</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 text-center">
                    <button mat-raised-button color="accent" (click)="folyamatbanlevoUgyekFeltolt()">Keresés</button>
                  </div>

                </div>
                <div class="row pb-4 pb-2 pl-3 pr-3">
                  <div class="col-lg-12 mt-2">


                    <mat-table [dataSource]="dataSourceRogzitett" #matSortRogzitett="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_inditas'" [matSortDirection]="matSortDirectionRogzitett" matSort>

                      <ng-container *ngFor="let column of rogzitettOszlopok" [matColumnDef]="column.columnDef">
                        <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border mr-1" [class]="column.class" mat-sort-header>
                          <div>
                            <span [innerHTML]="column.header"></span>
                          </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]="column.class" class="mr-1">
                          <div>
                            <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '')"></span>
                          </div>
                        </mat-cell>
                      </ng-container>


                      <mat-header-row *matHeaderRowDef="getDisplayedRogzitettColumns()"></mat-header-row>

                      <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedRogzitettColumns();" (click)="onAdatokClick(row, element.get('f_tipus').value == 'szaporítás' ? 'szapfolyt' : (element.get('f_tipus').value == 'törlés' ? 'törlésfolyt' : 'új'))" [style.background]="!toltoRogzitett && highlightedRowRogzitett && highlightedRowRogzitett.length > 0 && highlightedRowRogzitett.indexOf(row) != -1 ? 'lightblue' : ''"></mat-row>

                      <div class="mat-row col-lg-12" *matNoDataRow>
                        <div class="mat-cell">Nincs megjeleníthető elem.</div>
                      </div>
                    </mat-table>
                    <mat-paginator #paginatorRogzitett [pageSizeOptions]="[5, 10, 20]"
                                   showFirstLastButtons
                                   aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                    </mat-paginator>



                  </div>
                </div>

                <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-12" *ngIf="szerzodesFormGroup.get('f_ugyszam').value != 0">

                  <div>
                    <mat-card-header>
                      <mat-card-title>Adatok</mat-card-title>
                    </mat-card-header>
                  </div>
                  <mat-card-content>

                    <div *ngIf="szerzodesFormGroup.get('f_ugyszam').value > 0">

                      <div class="row pt-3 pb-3" *ngIf="szerzodesFormGroup.controls['f_allapot_bruno'].value >= '60'">
                        <div class="col-lg-6">
                          <span class="font-weight-500 font-color-red">Szerződés állapota törölt, kérjük a bővíteni kívánt szerződést kösse újra az Új szerződés menü pontban.</span>
                        </div>
                      </div>

                      <div class="row pt-3 pb-3">
                        <div class="col-lg-6">
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés'" [disabled]="!(szerzodesFormGroup.get('f_allapotkod').value >= 10 && szerzodesFormGroup.get('f_allapotkod').value <= 11) || szerzodesFormGroup.controls['f_allapot_bruno'].value >= '60'" color="accent" (click)="onAjanlatLetoltes(highlightedRowRogzitett[0].controls['f_tipus'].value)">Ajánlat letöltése</button>
                        </div>
                        <div class="col-lg-6 text-right">
                          <button mat-raised-button color="accent" (click)="onBezaras()">Bezárás</button>
                        </div>
                      </div>

                      <div class="row pt-3 pb-3">
                        <div class="col-lg-4">
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés'" [disabled]="szerzodesFormGroup.get('f_allapotkod').value != 11 || fajlFeltoltesEredmeny != 'Sikeres' || szerzodesFormGroup.controls['f_allapot_bruno'].value >= '60'" color="accent" (click)="onAjanlatBekuldes()">Aláírt ajánlat beküldése</button>
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés'" color="accent" [disabled]="szerzodesFormGroup.get('f_allapotkod').value >= 11" (click)="onTorlesBekuldes()">Törlési igény beküldése</button>
                        </div>
                        <div class="col-lg-4 text-center">
                          <button mat-raised-button *ngIf="fedezetigazolas && (szerzodesFormGroup.get('f_allapotkod').value == 12 || szerzodesFormGroup.get('f_allapotkod').value == 13) && highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés' && szerzodesFormGroup.controls['f_allapot_bruno'].value < '60'" color="accent" (click)="onFedIgLetoltes(highlightedRowRogzitett[0].controls['f_tipus'].value)">Fedezetigazolás letöltése</button>
                        </div>
                        <div class="col-lg-4 text-right">
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'szaporítás'" [disabled]="!(szerzodesFormGroup.get('f_allapotkod').value >= 10 &&  szerzodesFormGroup.get('f_allapotkod').value <= 11)" color="accent" (click)="onSzaporitasIgenyTorles()">Szaporítási igény törlése</button>
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés'" color="accent" [disabled]="szerzodesFormGroup.get('f_allapotkod').value >= 11" (click)="onSzaporitasIgenyTorles()">Törlési igény törlése</button>
                          <button mat-raised-button *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'új'" color="accent" [disabled]="!(szerzodesFormGroup.get('f_allapotkod').value >= 10 &&  szerzodesFormGroup.get('f_allapotkod').value <= 11)" (click)="onSzaporitasIgenyTorles()">Új igény törlése</button>
                        </div>
                      </div>

                      <div class="row pt-3" *ngIf="szerzodesFormGroup.get('f_allapotkod').value == 11 && highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés' && szerzodesFormGroup.controls['f_allapot_bruno'].value < '60'">
                        <div class="col-lg-4">
                          <mat-label class="hx">
                            Aláírt ajánlat
                          </mat-label>
                        </div>
                      </div>

                      <div class="row pt-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés' && szerzodesFormGroup.get('f_allapotkod').value <= 11">
                        <div class="col-lg-4">
                          <mat-label class="hx">
                            Kérjük a törlési kérelemhez csatolja az igazoló dokumentumot!
                          </mat-label>
                        </div>
                      </div>

                      <div class="row pb-2 pt-1" *ngIf="(highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés' && szerzodesFormGroup.get('f_allapotkod').value == 11) || (highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés' && szerzodesFormGroup.get('f_allapotkod').value <= 11)  && szerzodesFormGroup.controls['f_allapot_bruno'].value < '60'">
                        <div class="col-lg-4">

                          <granit-file-upload #fileUploadAjanlat ngDefaultControl [formGroup]="szerzodesFormGroup" [formControlName]="'f_dokumentum'" [labelText]="''"
                                               [buttonText]="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés' ? 'Törlési dokumentum beküldése' : 'Fájl kiválasztás'" [allowMultipleFiles]="true" [acceptedFileExtensions]="fajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>

                        </div>
                      </div>
                      <div class="row pb-3" *ngIf="szerzodesFormGroup.controls['f_dokumentum'].value && (highlightedRowRogzitett[0].controls['f_tipus'].value != 'törlés' && szerzodesFormGroup.get('f_allapotkod').value == 11) || (highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés' && szerzodesFormGroup.get('f_allapotkod').value < 12) && szerzodesFormGroup.controls['f_allapot_bruno'].value < '60'">
                        <div class="col-lg-4">
                          <mat-label>
                            Feltöltés eredménye: {{fajlFeltoltesEredmeny}}
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="szerzodesFormGroup.get('f_szerzodes_ok').value">


                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row pt-3">
                            <div class="col-lg-3">
                              <mat-label class="hx">Kockázat kezdet</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label class="hx">Évforduló</mat-label>
                            </div>
                            <div class="col-lg-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'új'">
                              <mat-label class="hx">Jelenlegi díj</mat-label>
                            </div>
                            <div class="col-lg-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'új'">
                              <mat-label class="hx">Díjrendezettség</mat-label>
                            </div>
                          </div>
                          <div class="row pb-2 pt-1">
                            <div class="col-lg-3">
                              <mat-label>{{szerzodesFormGroup.get("f_kockezd").value}}</mat-label>
                            </div>
                            <div class="col-lg-3">
                              <mat-label>{{szerzodesFormGroup.get("f_evfordulo").value}}</mat-label>
                            </div>
                            <div class="col-lg-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'új'">
                              <mat-label>{{szerzodesFormGroup.get("f_alldijsum").value}}</mat-label>
                            </div>
                            <div class="col-lg-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'új'">
                              <mat-label>{{szerzodesFormGroup.get("f_dijrend").value}}</mat-label>
                            </div>
                          </div>

                          <!-- div class="row pb-2 pt-3" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value != 'új'">
                            <div class="col-lg-12 hx">
                              Szerződésen lévő gépjárművek
                            </div>
                            <div class="col-lg-12">


                              <mat-table [dataSource]="dataSourceFuvarozoiSzerzodesAdatok" #matSortFuvarozoiSzerzodesAdatok="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_tipus'" [matSortDirection]="matSortDirectionFuvarozoiSzerzodesAdatok" matSort>

                                <ng-container *ngFor="let column of fuvarozoiSzerzodesAdatok" [matColumnDef]="column.columnDef">
                                  <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>
                                    <div>
                                      <span [innerHTML]="column.header"></span>
                                    </div>
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]=column.class>
                                    <div *ngIf="column.adattipus != 'button'">
                                      <span [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                    </div>
                                    <div *ngIf="column.adattipus == 'button' && column.columnDef == 'torles'">
                                      <button mat-icon-button (click)="torlesOnClick(element)" title="Gépjárművek törlése"><mat-icon>delete</mat-icon></button>
                                    </div>
                                  </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="getDisplayedFuvarozoiSzerzodesAdatokColumns()"></mat-header-row>

                                <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFuvarozoiSzerzodesAdatokColumns();"></mat-row>

                                <div class="mat-row col-lg-12" *matNoDataRow>
                                  <div class="mat-cell">Nincs megjeleníthető elem.</div>
                                </div>
                              </mat-table>
                              <mat-paginator #paginatorFuvarozoiSzerzodesAdatok [pageSizeOptions]="[5, 10, 20]"
                                             showFirstLastButtons
                                             aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                              </mat-paginator>

                            </div>

                          </div -->

                          <div class="row pb-2 pt-3" *ngIf="szerzodesFormGroup.get('f_ugyszam').value > 0">
                            <div class="col-lg-12 hx" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'szaporítás'">
                              Bővítésre beküldött gépjárművek
                            </div>
                            <div class="col-lg-12 hx" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés'">
                              Törlésre beküldött gépjárművek
                            </div>
                            <div class="col-lg-12 hx" *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'új'">
                              Beküldött gépjárművek
                            </div>
                            <div class="col-lg-12">

                              <mat-table *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'szaporítás' || highlightedRowRogzitett[0].controls['f_tipus'].value == 'új'" [dataSource]="dataSourceFuvarozoiBovitesGepjarmuvek" #matSortFuvarozoiBovitesGepjarmuvek="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_rendszam'" [matSortDirection]="matSortDirectionFuvarozoiBovitesGepjarmuvek" matSort>

                                <ng-container *ngFor="let column of fuvarozoiBovitesGepjarmuvekOszlopok" [matColumnDef]="column.columnDef">
                                  <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>
                                    <div>
                                      <span [innerHTML]="column.header"></span>
                                    </div>
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]=column.class>
                                    <div>
                                      <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                    </div>
                                  </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="getDisplayedFuvarozoiBovitesGepjarmuvekColumns()"></mat-header-row>

                                <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFuvarozoiBovitesGepjarmuvekColumns();"></mat-row>

                                <div class="mat-row col-lg-12" *matNoDataRow>
                                  <div class="mat-cell">Nincs megjeleníthető elem.</div>
                                </div>
                              </mat-table>

                              <mat-paginator #paginatorFuvarozoiBovitesGepjarmuvek [pageSizeOptions]="[5, 10, 20]"
                                             showFirstLastButtons
                                             aria-label="Válassza ki az oldalon megjelenő találatok számát.">

                              </mat-paginator>


                              <mat-table *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés'" [dataSource]="dataSourceFuvarozoiTorlesGepjarmuvek" #matSortFuvarozoiTorlesGepjarmuvek="matSort" class="w100 mr-5 table_border" [matSortActive]="'f_rendszam'" [matSortDirection]="matSortDirectionFuvarozoiTorlesGepjarmuvek" matSort>

                                <ng-container *ngFor="let column of fuvarozoiTorlesGepjarmuvekOszlopok" [matColumnDef]="column.columnDef">
                                  <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class mat-sort-header>
                                    <div>
                                      <span [innerHTML]="column.header"></span>
                                    </div>
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" [class]=column.class>
                                    <div>
                                      <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                                    </div>
                                  </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="getDisplayedFuvarozoiTorlesGepjarmuvekColumns()"></mat-header-row>

                                <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFuvarozoiTorlesGepjarmuvekColumns();"></mat-row>

                                <div class="mat-row col-lg-12" *matNoDataRow>
                                  <div class="mat-cell">Nincs megjeleníthető elem.</div>
                                </div>
                              </mat-table>
                              <mat-paginator #paginatorFuvarozoiTorlesGepjarmuvek [pageSizeOptions]="[5, 10, 20]"
                                             showFirstLastButtons
                                             aria-label="Válassza ki az oldalon megjelenő találatok számát."
                                             *ngIf="highlightedRowRogzitett[0].controls['f_tipus'].value == 'törlés'">
                              </mat-paginator>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>

                    <div *ngIf="uzenet != ''">
                      <div class="row pt-3 pb-3">
                        <div class="col-lg-12 hiba_szoveg">
                          <span [innerHtml]="uzenet"></span>
                        </div>
                      </div>
                    </div>

                  </mat-card-content>

                </mat-card>

              </mat-card-content>
            </ng-container>

          </mat-card>
        </form>
      </div>
    </div>
  </div>
</ng-template>
