import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonValidator, HibaService, TextTransformPipe, UtilityService, GranitFileUploadComponent, GranitTableComponent } from '@granit/common-library';
import { Felhasznalo, FelhasznaloService } from '@granit/portal-library';
import { base64StringToBlob } from 'blob-util';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DokumentumService, FuvarozoiService, ListakService, MenuService } from '../../services/_index';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FlottaService } from '../../services/flotta.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'fuvarozoi',
  templateUrl: './fuvarozoi.component.html',
  styleUrls: ['./fuvarozoi.component.scss']
})
export class FuvarozoiComponent implements OnInit {

  aktualisFelhasznalo: Felhasznalo = this.felhasznaloService.aktualisFelhasznaloValue;

  ujSzerzodesFormGroup: FormGroup;
  szerzodesFormGroup: FormGroup;

  lev_kt_tipus_readonly: boolean = false;

  /* Szerződésen lévő gépjármű adatok*/

  fuvarozoiSzerzodesAdatok: any[] = [
    { columnDef: 'f_tipus', header: 'Tipus' },
    { columnDef: 'f_hatalytol', header: 'Hatálytól', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_szallszemszam', header: 'Szállítható szem.' },
    { columnDef: 'f_torles_hataly', header: 'Törlés hatálya', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_torles_hataly_eredeti', header: 'Törlés hatálya', dateFormat: 'YYYY.MM.DD', adattipus: 'date', visible: false }
  ];
  getDisplayedFuvarozoiSzerzodesAdatokColumns(): string[] {
    let displayedColumns = this.fuvarozoiSzerzodesAdatok.filter(c => (c.visible != false) &&
      ((c.columnDef != 'f_tipus' && c.columnDef != 'f_szallszemszam') ||
        (c.columnDef == 'f_tipus' && ((this.rogzitettLathato && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KÁF') || (this.eloLathato && this.highlightedRowElo && this.highlightedRowElo[0].controls["f_modozat"].value == 'KÁF'))) ||
        (c.columnDef == 'f_szallszemszam' && ((this.rogzitettLathato && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KSZF') || (this.eloLathato && this.highlightedRowElo && this.highlightedRowElo[0].controls["f_modozat"].value == 'KSZF'))))).map(c => c.columnDef);

    return displayedColumns;
  }
  dataSourceFuvarozoiSzerzodesAdatok: MatTableDataSource<any>;
  paginatorFuvarozoiSzerzodesAdatok: MatPaginator;
  @ViewChild('paginatorFuvarozoiSzerzodesAdatok', { static: false }) set paginatorFuvarozoi(paginatorFuvarozoi: MatPaginator) {
    if (paginatorFuvarozoi) {
      this.paginatorFuvarozoiSzerzodesAdatok = paginatorFuvarozoi;
      this.dataSourceFuvarozoiSzerzodesAdatok.paginator = this.paginatorFuvarozoiSzerzodesAdatok;
    }
  }
  matSortFuvarozoiSzerzodesAdatok: MatSort;
  @ViewChild('matSortFuvarozoiSzerzodesAdatok', { static: false }) set sortFuvarozoiSzerzodesAdatok(sortFuvarozoiSzerzodesAdatok: MatSort) {
    if (sortFuvarozoiSzerzodesAdatok) {
      this.matSortFuvarozoiSzerzodesAdatok = sortFuvarozoiSzerzodesAdatok;
      this.dataSourceFuvarozoiSzerzodesAdatok.sort = this.matSortFuvarozoiSzerzodesAdatok;
    }
  }
  matSortDirectionFuvarozoiSzerzodesAdatok = 'asc';



  /* Szaporításhoz tartozó gépjármű adatok*/
  fuvarozoiBovitesGepjarmuvekOszlopok: any[] = [
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_jelleg', header: 'Jelleg' },
    { columnDef: 'f_szall_szem_szam', header: 'Száll.szem szám' }
  ];
  getDisplayedFuvarozoiBovitesGepjarmuvekColumns(): string[] {
    let displayedColumns = this.fuvarozoiBovitesGepjarmuvekOszlopok.filter(c => (c.visible != false) &&
      ((c.columnDef != 'f_jelleg' && c.columnDef != 'f_szall_szem_szam') ||
        (c.columnDef == 'f_jelleg' && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KÁF') ||
        (c.columnDef == 'f_szall_szem_szam' && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KSZF'))).map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceFuvarozoiBovitesGepjarmuvek: MatTableDataSource<any>;
  paginatorFuvarozoiBovitesGepjarmuvek: MatPaginator;
  @ViewChild('paginatorFuvarozoiBovitesGepjarmuvek', { static: false }) set paginatorBovites(paginatorBovites: MatPaginator) {
    if (paginatorBovites) {
      this.paginatorFuvarozoiBovitesGepjarmuvek = paginatorBovites;
      this.dataSourceFuvarozoiBovitesGepjarmuvek.paginator = this.paginatorFuvarozoiBovitesGepjarmuvek;
    }
  }
  matSortFuvarozoiBovitesGepjarmuvek: MatSort;
  @ViewChild('matSortFuvarozoiBovitesGepjarmuvek', { static: false }) set sortFuvarozoiBovitesGepjarmuvek(sortFuvarozoiBovitesGepjarmuvek: MatSort) {
    if (sortFuvarozoiBovitesGepjarmuvek) {
      this.matSortFuvarozoiBovitesGepjarmuvek = sortFuvarozoiBovitesGepjarmuvek;
      this.dataSourceFuvarozoiBovitesGepjarmuvek.sort = this.matSortFuvarozoiBovitesGepjarmuvek;
    }
  }
  matSortDirectionFuvarozoiBovitesGepjarmuvek = 'asc';

  @ViewChild('fileUploadExcel', { static: false }) fileUploadExcel: GranitFileUploadComponent;
  @ViewChild('fileUploadAjanlat', { static: false }) fileUploadAjanlat: GranitFileUploadComponent;
  @ViewChild('fileUploadExcelUj', { static: false }) fileUploadExcelUj: GranitFileUploadComponent;


  /* Törléshez tartozó gépjármű adatok*/
  fuvarozoiTorlesGepjarmuvekOszlopok: any[] = [
    { columnDef: 'f_tipus', header: 'Tipus' },
    { columnDef: 'f_hatalytol', header: 'Hatalytól', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_szall_szem_szam', header: 'Száll.szem szám' },
    { columnDef: 'f_torles_hataly', header: 'Törlés hatálya', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_torles_hataly_eredeti', header: 'Törlés hatálya', dateFormat: 'YYYY.MM.DD', adattipus: 'date', visible: false }
  ];
  getDisplayedFuvarozoiTorlesGepjarmuvekColumns(): string[] {
    let displayedColumns = this.fuvarozoiTorlesGepjarmuvekOszlopok.filter(c => (c.visible != false) &&
      ((c.columnDef != 'f_tipus' && c.columnDef != 'f_szall_szem_szam') ||
        (c.columnDef == 'f_tipus' && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KÁF') ||
        (c.columnDef == 'f_szall_szem_szam' && this.highlightedRowRogzitett && this.highlightedRowRogzitett[0].controls["f_modozat"].value == 'KSZF'))).map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceFuvarozoiTorlesGepjarmuvek: MatTableDataSource<any>;
  paginatorFuvarozoiTorlesGepjarmuvek: MatPaginator;
  @ViewChild('paginatorFuvarozoiTorlesGepjarmuvek', { static: false }) set paginatorTorles(paginatorTorles: MatPaginator) {
    if (paginatorTorles) {
      this.paginatorFuvarozoiTorlesGepjarmuvek = paginatorTorles;
      this.dataSourceFuvarozoiTorlesGepjarmuvek.paginator = this.paginatorFuvarozoiTorlesGepjarmuvek;
    }
  }
  matSortFuvarozoiTorlesGepjarmuvek: MatSort;
  @ViewChild('matSortFuvarozoiTorlesGepjarmuvek', { static: false }) set sortFuvarozoiTorlesGepjarmuvek(sortFuvarozoiTorlesGepjarmuvek: MatSort) {
    if (sortFuvarozoiTorlesGepjarmuvek) {
      this.matSortFuvarozoiTorlesGepjarmuvek = sortFuvarozoiTorlesGepjarmuvek;
      this.dataSourceFuvarozoiTorlesGepjarmuvek.sort = this.matSortFuvarozoiTorlesGepjarmuvek;
    }
  }
  matSortDirectionFuvarozoiTorlesGepjarmuvek = 'asc';
  torlesDb: boolean = false;

  /*Élő szerződés adatok*/
  eloSzerzodesekFormGroup: FormGroup;
  eloTablaOszlopok: any[] = [
    { columnDef: 'f_szerz_azon', header: 'Szerződésszám' },
    { columnDef: 'f_szerzodo_nev', header: 'Szerződő' },
    { columnDef: 'f_adoszam', header: 'Adószám' },
    { columnDef: 'f_modozat', header: 'Módozat' },
    { columnDef: 'f_cegjegyzekszam', header: '', visible: false },
    { columnDef: 'f_ev_nyilv_szam', header: '', visible: false },
    { columnDef: 'f_teaor', header: '', visible: false },
    { columnDef: 'f_szerzodo_szulhely', header: '', visible: false },
    { columnDef: 'f_szerzodo_szuldat', header: '', visible: false },
    { columnDef: 'f_szerzodo_anyanev', header: '', visible: false },
    { columnDef: 'f_szerzodo_tipus', header: '', visible: false },
    { columnDef: 'f_szerzodo_cime', header: '', visible: false },
    { columnDef: 'f_szerzodo_lev_cime', header: '', visible: false },
    { columnDef: 'f_ekom_email', header: '', visible: false },
    { columnDef: 'f_dijfizmod', header: '', visible: false },
    { columnDef: 'f_dijfizgyak', header: '', visible: false },
    { columnDef: 'f_szamlaszam', header: '', visible: false }

  ];
  getDisplayedEloColumns(): string[] {
    let displayedColumns = this.eloTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceElo: MatTableDataSource<any>;
  paginatorElo: MatPaginator;
  @ViewChild('paginatorElo', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.paginatorElo = paginator;
      this.dataSourceElo.paginator = this.paginatorElo;
    }
  }
  matSortElo: MatSort;
  @ViewChild('matSortElo', { static: false }) set sortElo(sortElo: MatSort) {
    if (sortElo) {
      this.matSortElo = sortElo;
      this.dataSourceElo.sort = this.matSortElo;
    }
  }
  matSortDirectionElo = 'asc';

  /*Rögzített ügy adatai*/
  rogzitettOszlopok: any[] = [
    { columnDef: 'f_ugyszam', header: 'Ügyszám', class: 'col-lg-1' },
    { columnDef: 'f_inditas', header: 'Ügy indítása' },
    { columnDef: 'f_modozat', header: 'Termék', class: 'col-lg-1' },
    { columnDef: 'f_szerzazon', header: 'Szerződésszám' },
    { columnDef: 'f_szerzodo_nev', header: 'Szerződő' },
    { columnDef: 'f_gj_db', header: 'Gjm darab', class: 'col-lg-1' },
    { columnDef: 'f_allapot_megnevezes', header: 'Állapot' },
    { columnDef: 'f_felelos', header: 'Felelős', class: 'col-lg-1' },
    { columnDef: 'f_tipus', header: 'Tipus' },
    { columnDef: 'f_allapotkod', header: 'f_allapotkod', visible: false }
  ];
  getDisplayedRogzitettColumns(): string[] {
    let displayedColumns = this.rogzitettOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceRogzitett: MatTableDataSource<any>;
  matPaginatorRogzitett: MatPaginator;
  @ViewChild('paginatorRogzitett', { static: false }) set paginatorRogzitett(paginatorRogzitett: MatPaginator) {
    if (paginatorRogzitett) {
      this.matPaginatorRogzitett = paginatorRogzitett;
      this.dataSourceRogzitett.paginator = this.matPaginatorRogzitett;
    }
  }
  matSortRogzitett: MatSort;
  @ViewChild('matSortRogzitett', { static: false }) set sortRogzitett(sortRogzitett: MatSort) {
    if (sortRogzitett) {
      this.matSortRogzitett = sortRogzitett;
      this.dataSourceRogzitett.sort = this.matSortRogzitett;
    }
  }
  matSortDirectionRogzitett: string = "desc";


  fajlKiterjesztesek: string[] = [];
  fajlFeltoltesEredmeny: string = "";

  uzenet: string;

  rogzitettLathato: boolean = false;
  eloLathato: boolean = false;
  ujLathato: boolean = false;

  toltoRogzitett: boolean = false;
  toltoElo: boolean = false;
  toltoUj: boolean = false;
  highlightedRowElo: any = [];
  highlightedRowRogzitett: any = [];

  torlesMinDate: string = moment(new Date()).add(-90, 'day').format('YYYY-MM-DD');
  torlesMaxDate: string = moment(new Date()).add(1, 'M').format('YYYY-MM-DD');
  kockezdMinDate: string = moment(new Date()).format('YYYY-MM-DD');
  kockezdMaxDate: string = moment(new Date()).add(6, 'M').format('YYYY-MM-DD');
  modHatalyMinDate: string = moment(new Date()).format('YYYY-MM-DD');
  modHatalyMaxDate: string = moment(new Date()).add(6, 'M').format('YYYY-MM-DD');

  oraLista: any[] = [];
  percLista: any[] = [];

  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];


  minSzuldat: Date = new Date("1901.01.01");
  maxSzuldat: Date = new Date(new Date().setMonth(new Date().getMonth() - 216));

  dijtetelALista: any[];
  dijtetelBLista: any[];
  dijtetelKSZFLista: any[];

  fedezetigazolas: boolean = false;
  idoLathato: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public hibaService: HibaService,
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    private fuvarozoiService: FuvarozoiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef,
    private flottaService: FlottaService,
    private menuService: MenuService) {

  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    this.oldalonBeluliJogosultsag();
    this.formGroupInit();
  }

  oldalonBeluliJogosultsag() {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('partnerportal_dokumentumigenyles_oldalonBeluliJogosultsag').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            oldal: "Fuvarozói Felelősségbiztosítás"
          }
          this.menuService.oldalonBeluliJogosultsag(model).subscribe(
            data => {
              let talalat = data.filter(x => x.f_megnevezes == 'Fuvarozói fedezetigazolás')
              this.fedezetigazolas = talalat.length > 0;
            },
            errorResult => {              
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt a jogosultságok lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  // Óra lista feltöltése
  oraListaFeltoltese() {

    let hour: number = new Date().getHours();

    for (var i = hour; i < 24; i++) {
      let oraString = i.toString();
      if (oraString.length == 1) {
        oraString = '0' + oraString;
      }
      this.oraLista.push(oraString)
    }
  }

  // Perc lista feltöltése
  percListaFeltoltese() {
    for (var i = 0; i < 60; i++) {
      if (i % 5 == 0) {
        let percLista = i.toString();
        if (percLista.length == 1) {
          percLista = '0' + percLista;
        }
        this.percLista.push(percLista);
      }
    }
  }

  kockezdDatumValtozas() {

    if (this.ujSzerzodesFormGroup.controls["f_kockezd_datum"].value != null && this.ujSzerzodesFormGroup.controls["f_kockezd_datum"].value != "") {
      if (moment(new Date()).format('YYYY-MM-DD') == moment(this.ujSzerzodesFormGroup.controls["f_kockezd_datum"].value).format('YYYY-MM-DD')) {
        this.idoLathato = true;
        this.oraListaFeltoltese();
        this.percListaFeltoltese();
        this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].setValue(null);
        this.ujSzerzodesFormGroup.controls["f_kockezd_perc"].setValue(null);
      }
      else {
        this.idoLathato = false;
        this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].setValue("00");
        this.ujSzerzodesFormGroup.controls["f_kockezd_perc"].setValue("00");
      }

      this.ujSzerzodesFormGroup.controls['f_dijtetel_b'].setValue(null);
      if (this.ujSzerzodesFormGroup.controls['f_modozat'].value == "32101") {
        this.ujSzerzodesFormGroup.controls['f_dijtetel_a'].setValue(null);
      }


    }
    else {
      this.idoLathato = false;
      this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].setValue(null);
      this.ujSzerzodesFormGroup.controls["f_kockezd_perc"].setValue(null);
    }
  }

  modHatalyDatumValtozas() {
    if (moment(new Date()).format('YYYY-MM-DD') == moment(this.eloSzerzodesekFormGroup.controls["f_modhataly_datum"].value).format('YYYY-MM-DD')) {
      this.idoLathato = true;
      this.oraListaFeltoltese();
      this.percListaFeltoltese();
      this.eloSzerzodesekFormGroup.controls["f_modhataly_ora"].setValue(null);
      this.eloSzerzodesekFormGroup.controls["f_modhataly_perc"].setValue(null);
    }
    else {
      this.idoLathato = false;
      this.eloSzerzodesekFormGroup.controls["f_modhataly_ora"].setValue("00");
      this.eloSzerzodesekFormGroup.controls["f_modhataly_perc"].setValue("00");
    }

    this.eloSzerzodesekFormGroup.controls['f_dijtetel_b'].setValue(null);
    if (this.ujSzerzodesFormGroup.controls['f_modkod'].value == "32101") {
      this.eloSzerzodesekFormGroup.controls['f_dijtetel_a'].setValue(null);
    }
  }

  idoEllenorzes() {

    let ora: number = this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].value;
    let perc: number = this.ujSzerzodesFormGroup.controls["f_kockezd_perc"].value;

    if (ora != null && perc != null) {

      this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].setErrors(null);

      let aktIdo = moment(new Date()).format('HH:mm')
      let valasztottIdo = ora + ":" + perc;
      if (valasztottIdo < aktIdo) {
        this.ujSzerzodesFormGroup.controls["f_kockezd_ora"].setErrors({ kisebbIdo: true });
      }
    }


  }

  /*FormGroup inicializálása*/

  formGroupInit() {

    this.eloSzerzodesekFormGroupInit();
    this.szerzodesFormGroupInit();
    this.ujSzerzodesFormGroupInit();

    this.dataSourceElo = new MatTableDataSource();
    this.dataSourceElo.sortingDataAccessor = (item: FormGroup, property) => {
      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

    this.dataSourceRogzitett = new MatTableDataSource();
    this.dataSourceRogzitett.sortingDataAccessor = (item: FormGroup, property) => {
      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

    this.dataSourceFuvarozoiSzerzodesAdatok = new MatTableDataSource();
    this.dataSourceFuvarozoiSzerzodesAdatok.sortingDataAccessor = (item: FormGroup, property) => {
      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

    this.dataSourceFuvarozoiBovitesGepjarmuvek = new MatTableDataSource();
    this.dataSourceFuvarozoiBovitesGepjarmuvek.sortingDataAccessor = (item: FormGroup, property) => {
      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

    this.dataSourceFuvarozoiTorlesGepjarmuvek = new MatTableDataSource();
    this.dataSourceFuvarozoiTorlesGepjarmuvek.sortingDataAccessor = (item: FormGroup, property) => {
      let control = item.get(property);
      let textTransformPipe = new TextTransformPipe();
      return textTransformPipe.transform(control.value, 'YYYY.MM.DD.');
    };

    this.dijtetelekLekerese();

    this.elokLekerdezes();

    this.folyamatbanlevoUgyekFeltolt(null);

  }

  eloSzerzodesekFormGroupInit() {
    this.eloSzerzodesekFormGroup = this.formBuilder.group({
      f_szerzazon: new FormControl(null),
      f_modozat: new FormControl(""),
      f_ugyfelnev: new FormControl(null),
      f_dokumentum: new FormControl(null),
      f_szerzodes_ok: new FormControl(false),
      f_kockezd: new FormControl(null),
      f_evfordulo: new FormControl(null),
      f_alldijsum: new FormControl(null),
      f_dijrend: new FormControl(null),
      f_hiba: new FormControl(false),
      f_ugyszam: new FormControl(null),
      f_modkod: new FormControl(""),
      f_feladat_tipus: new FormControl(null),
      f_dijtetel_a: new FormControl(null, [Validators.required]),
      f_dijtetel_b: new FormControl(null, [Validators.required]),
      f_modhataly_datum: new FormControl(null, [Validators.required]),
      f_modhataly_ora: new FormControl(null, [Validators.required]),
      f_modhataly_perc: new FormControl(null, [Validators.required])
    });

    this.eloSzerzodesekFormGroup.controls['f_dokumentum'].valueChanges.subscribe(value => {
      if (value == null) {
        this.fajlFeltoltesEredmeny = "";
      }
    });

    this.eloSzerzodesekFormGroup.controls['f_modkod'].valueChanges.subscribe(value => {
      if (value == "32102") {
        this.eloSzerzodesekFormGroup.controls['f_dijtetel_a'].setValue(0);
      }
    });
  }

  dijtetelekLekerese() {
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_dijtetelek').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

           this.fuvarozoiService.fuvarozoiDijtetelekLekerese().subscribe(
            data => {
              if (data != null) {

                this.dijtetelALista = data.dijtetel_a;
                this.dijtetelBLista = data.dijtetel_b;
                this.dijtetelKSZFLista = data.dijtetel_kszf;
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Nincs választható díjtétel",
                  confirmButtonText: 'Rendben'
                });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a választható díjtétel lekérésekor",
                confirmButtonText: 'Rendben'
              });
            }
          );
        });
      });
  }

  elokLekerdezes() {


    this.toltoElo = true;
    this.eloSzerzodesekFormGroup.controls["f_ugyszam"].setValue(null);
    this.highlightedRowElo = [];

    this.recaptchaV3Service.execute('parterportal_fuvarozoi_elo').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            f_ugyfelnev: this.eloSzerzodesekFormGroup.controls["f_ugyfelnev"].value,
            f_szerzazon: this.eloSzerzodesekFormGroup.controls["f_szerzazon"].value,
            f_modozat: this.eloSzerzodesekFormGroup.controls["f_modozat"].value,
          }
          this.fuvarozoiService.fuvarozoiEloSzerzodesek(model).subscribe(
            data => {
              if (data != null) {
                const fgs = data.map(item => {
                  return this.utilityService.formGroupObjektumbol(item);
                });
                this.dataSourceElo.data = this.formBuilder.array(fgs).controls;
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hibás kérés",
                  confirmButtonText: 'Rendben'
                });
              }
              this.toltoElo = false;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hibás kérés",
                confirmButtonText: 'Rendben'
              });
              this.toltoElo = false;
            }
          );
        });
      });
  }

  szerzodesFormGroupInit() {
    this.szerzodesFormGroup = this.formBuilder.group({
      f_szerzazon: new FormControl(false),
      f_szerzodes_ok: new FormControl(false),
      f_kockezd: new FormControl(null),
      f_mod_hataly: new FormControl(null),
      f_evfordulo: new FormControl(null),
      f_szerzodo: new FormControl(null),
      f_adoszam: new FormControl(null),
      f_adoszam_talalt: new FormControl(null),
      f_cegjegyzekszam: new FormControl(null),
      f_ev_nyilv_szam: new FormControl(null),
      f_dijfizgyak: new FormControl(null),
      f_dijfizmod: new FormControl(null),
      f_modkod: new FormControl(null),
      f_alldijsum: new FormControl(null),
      f_dijrend: new FormControl(null),
      f_ugyszam: new FormControl(0),
      f_hiba: new FormControl(false),
      f_allapot: new FormControl(null),
      f_allapotkod: new FormControl(null),
      f_dokumentum: new FormControl(null),
      f_szerzodo_tipus: new FormControl(null),
      f_teaor: new FormControl(null),
      f_szulhely: new FormControl(null),
      f_szuldatum: new FormControl(null),
      f_anyanev: new FormControl(null),
      f_szerzodo_cime: new FormControl(null),
      f_szerzodo_lev_cime: new FormControl(null),
      f_email_ekom: new FormControl(null),
      f_szamlaszam: new FormControl(null),
      f_vonalkod: new FormControl(""),
      f_allapot_bruno: new FormControl(null),
      f_ugyszam_kereses: new FormControl(null),
      f_szerzazon_kereses: new FormControl(null),
      f_ugyfelnev_kereses: new FormControl(null),
      f_modozat_kereses: new FormControl("")

    });

    this.szerzodesFormGroup.controls['f_dokumentum'].valueChanges.subscribe(value => {
      if (value == null) {
        this.fajlFeltoltesEredmeny = "";
      }
    });

  }

  ujSzerzodesFormGroupInit() {
    this.ujSzerzodesFormGroup = this.formBuilder.group({
      f_feladat_tipus: new FormControl("új"),
      f_modozat: new FormControl("32101"),
      f_alkusz_neve_kodja: new FormControl(this.aktualisFelhasznalo.f_valasztott_felhasznalo_partner_nev + ' - ' + this.aktualisFelhasznalo.f_valasztott_felhasznalo_torzsszam),
      f_alkusz_regisztralt_email: new FormControl(this.aktualisFelhasznalo.f_email),
      f_regisztralt_email_hasznalata: new FormControl('I'),
      f_alkusz_email: new FormControl(null),
      f_alkusz_email_ismet: new FormControl(null),
      f_alkusz_telefon: new FormControl(""),
      f_alkuszi_megbizas: new FormControl(this.aktualisFelhasznalo.f_tobbes_ugynok == "I", [Validators.requiredTrue]),
      f_adoszam: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      f_adoszam_talalt: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      f_szerzodo_tipus: new FormControl(null, [Validators.required]),
      f_szerzodo_nev: new FormControl(null),
      f_szerzodo_cime: new FormControl(null),
      f_szerzodo_szuldat: new FormControl(null),
      f_szerzodo_szulhely: new FormControl(null),
      f_szerzodo_anyanev: new FormControl(null),
      f_cegjegyzekszam: new FormControl(null),
      f_ev_nyilv_szam: new FormControl(null),
      f_teaor: new FormControl(null),
      f_eltero_levcim: new FormControl('N'),
      f_lev_irsz: new FormControl(null),
      f_lev_telepules: new FormControl(null),
      f_lev_kt_nev: new FormControl(null),
      f_lev_kt_tipus: new FormControl(null),
      f_lev_hrsz: new FormControl(null),
      f_lev_hazszam1: new FormControl(null),
      f_lev_hazszam2: new FormControl(null),
      f_lev_epulet: new FormControl(null),
      f_lev_lepcsohaz: new FormControl(null),
      f_lev_emelet: new FormControl(null),
      f_lev_ajto: new FormControl(null),
      f_lev_cim: new FormControl(null),
      f_email_ekom_van: new FormControl('I'),
      f_email_ekom: new FormControl(null, [Validators.required, Validators.email]),
      f_email_ekom_ismet: new FormControl(null, [Validators.required, Validators.email]),
      f_dijfizmod: new FormControl('B'),
      f_dijfizgyak: new FormControl('N'),
      f_szamlaszam: new FormControl(null),
      f_dokumentum: new FormControl(null, [Validators.required]),
      f_dijtetel_a: new FormControl(null, [Validators.required]),
      f_dijtetel_b: new FormControl(null, [Validators.required]),
      f_kockezd_datum: new FormControl(null, [Validators.required]),
      f_kockezd_ora: new FormControl(null, [Validators.required]),
      f_kockezd_perc: new FormControl(null, [Validators.required])
    },
      {
        validators: [CommonValidator.osszehasonlitas('f_alkusz_email', 'f_alkusz_email_ismet'),
        CommonValidator.osszehasonlitas('f_email_ekom', 'f_email_ekom_ismet'),
        AdatValidator.szuletesDatumEllenor(), AdatValidator.szuletesHelyEllenor(), AdatValidator.anyaNevEllenor(), AdatValidator.szlaEllenor()
        ]
      }
    );


    this.ujSzerzodesFormGroup.controls['f_regisztralt_email_hasznalata'].valueChanges.subscribe(
      value => {
        if (value == 'N') {
          this.ujSzerzodesFormGroup.controls['f_alkusz_email'].setValidators([Validators.required, Validators.email]);
          this.ujSzerzodesFormGroup.controls['f_alkusz_email_ismet'].setValidators([Validators.required, Validators.email]);
        }
        else {
          this.ujSzerzodesFormGroup.controls['f_alkusz_email'].reset();
          this.ujSzerzodesFormGroup.controls['f_alkusz_email_ismet'].reset();
          this.ujSzerzodesFormGroup.controls['f_alkusz_email'].setValidators([Validators.email]);
          this.ujSzerzodesFormGroup.controls['f_alkusz_email_ismet'].setValidators([Validators.email]);
        }

        this.ujSzerzodesFormGroup.controls['f_alkusz_email'].updateValueAndValidity();
        this.ujSzerzodesFormGroup.controls['f_alkusz_email_ismet'].updateValueAndValidity();
      }
    );

    this.ujSzerzodesFormGroup.controls['f_eltero_levcim'].valueChanges.subscribe(
      value => {
        if (value == 'I') {
          this.ujSzerzodesFormGroup.controls['f_lev_irsz'].setValidators([Validators.required]);
          this.ujSzerzodesFormGroup.controls['f_lev_telepules'].setValidators([Validators.required]);
          this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].setValidators([Validators.required]);
          this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].setValidators([Validators.required]);
        }
        else {

          this.ujSzerzodesFormGroup.controls['f_lev_irsz'].reset();
          this.ujSzerzodesFormGroup.controls['f_lev_telepules'].reset();
          this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].reset();
          this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].reset();

          this.ujSzerzodesFormGroup.controls['f_lev_irsz'].setValidators(null);
          this.ujSzerzodesFormGroup.controls['f_lev_telepules'].setValidators(null);
          this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].setValidators(null);
          this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].setValidators(null);
        }
        this.ujSzerzodesFormGroup.controls['f_lev_irsz'].updateValueAndValidity();
        this.ujSzerzodesFormGroup.controls['f_lev_telepules'].updateValueAndValidity();
        this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].updateValueAndValidity();
        this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].updateValueAndValidity();
      }
    );

    this.ujSzerzodesFormGroup.controls['f_lev_irsz'].valueChanges.subscribe(value => {
      if (value && (value as string).length == 4) {
        this.telepulesLekerdezese();
      }
      else {
        this.ujSzerzodesFormGroup.controls['f_lev_telepules'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_hazszam1'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_hazszam2'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_epulet'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_lepcsohaz'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_emelet'].setValue(null);
        this.ujSzerzodesFormGroup.controls['f_lev_ajto'].setValue(null);
        this.telepulesLista = [];
        this.utcanevLista = [];
        this.uttipusLista = [];
      }
    });

    // Település módosulás esetén lekérdezzük az utcaneveket
    this.ujSzerzodesFormGroup.controls['f_lev_telepules'].valueChanges.subscribe(value => {
      if (value) {
        this.utcanevekLekerdezese();
      }
    });
    // Utcanév módosulás esetén lekérdezzük az úttípusokat
    this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].valueChanges.subscribe(value => {
      if (value) {
        this.uttipusokLekerdezese();
      }
    });

    this.ujSzerzodesFormGroup.controls['f_email_ekom_van'].valueChanges.subscribe(value => {
      if (value == 'I') {
        this.ujSzerzodesFormGroup.controls['f_email_ekom'].setValidators([Validators.required, Validators.email]);
        this.ujSzerzodesFormGroup.controls['f_email_ekom_ismet'].setValidators([Validators.required, Validators.email]);
      }
      else {
        this.ujSzerzodesFormGroup.controls['f_email_ekom'].reset();
        this.ujSzerzodesFormGroup.controls['f_email_ekom_ismet'].reset();
        this.ujSzerzodesFormGroup.controls['f_email_ekom'].setValidators([Validators.email]);
        this.ujSzerzodesFormGroup.controls['f_email_ekom_ismet'].setValidators([Validators.email]);
      }

      this.ujSzerzodesFormGroup.controls['f_email_ekom'].updateValueAndValidity();
      this.ujSzerzodesFormGroup.controls['f_email_ekom_ismet'].updateValueAndValidity();
    });

    this.ujSzerzodesFormGroup.controls['f_adoszam'].valueChanges.subscribe(value => {
      this.ujSzerzodesFormGroup.controls['f_adoszam_talalt'].setValue(null);
    });

    this.ujSzerzodesFormGroup.controls['f_modozat'].valueChanges.subscribe(value => {
      if (value == "32102") {
        this.ujSzerzodesFormGroup.controls['f_dijtetel_a'].setValue(0);
      }
    });

  }

  // Szerződő keresése form gomb esemény
  onSzerzodoKeresesClick(uj_kereses: boolean) {

    this.toltoUj = true;
    this.ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].setValue(null);
    this.ujSzerzodesFormGroup.controls['f_szerzodo_nev'].setValue(null);
    this.ujSzerzodesFormGroup.controls['f_szerzodo_cime'].setValue(null);
    this.ujSzerzodesFormGroup.controls['f_cegjegyzekszam'].setValue(null);
    this.ujSzerzodesFormGroup.controls['f_ev_nyilv_szam'].setValue(null);
    this.ujSzerzodesFormGroup.controls['f_teaor'].setValue(null);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('fuvarozoi_szerzodo_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            f_adoszam: this.ujSzerzodesFormGroup.controls['f_adoszam'].value,
            f_uj_kereses: uj_kereses,
            f_modozat: this.ujSzerzodesFormGroup.controls['f_modozat'].value
          }

          // Lekérdezzük a további ügyfél adatokat az adószám alapján
          this.fuvarozoiService.fuvarozoiSzerzodoKeresesEllenorzessel(model).subscribe(
            data => {

              if (data["marSzerzodo"]) {
                this.onUjLathatoValt()
                this.utilityService.dialogMegnyitasa({
                  info: true,
                  title: 'Adószám keresése',
                  message: "A megadott adószámhoz tartozó ügyfél, már rendelkezik nálunk " + (this.ujSzerzodesFormGroup.controls['f_modozat'].value == '32101' ? "Közúti Árufuvarozói Felelősségbiztosítással" : "Közúti Személyszállítók Felelősségbiztosítással") + ". Javasoljuk a jelenleg élő szerződésének szaporítását.",
                  confirmButtonText: 'Rendben'
                });
              }
              else {
                if (data["adatok"] && data["adatok"].value != null) {

                  // További ügyfél adatok feltöltése
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_tipus'].setValue(data["adatok"].value.f_tipus == "J" ? "cég" : "egyéni vállalkozó");
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_nev'].setValue(data["adatok"].value.f_cegnev);
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_cime'].setValue(data["adatok"].value.f_telephely);
                  this.ujSzerzodesFormGroup.controls['f_cegjegyzekszam'].setValue(data["adatok"].value.f_cegjegyzekszam);
                  this.ujSzerzodesFormGroup.controls['f_ev_nyilv_szam'].setValue(data["adatok"].value.f_ev_nyilv_szam);
                  this.ujSzerzodesFormGroup.controls['f_teaor'].setValue(data["adatok"].value.f_teaor);
                  this.ujSzerzodesFormGroup.controls['f_adoszam_talalt'].setValue(data["adatok"].value.f_adoszam);
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_szuldat'].setValue(data["adatok"].value.f_szuldatum);
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_szulhely'].setValue(data["adatok"].value.f_szulhely);
                  this.ujSzerzodesFormGroup.controls['f_szerzodo_anyanev'].setValue(data["adatok"].value.f_anyanev);
                  this.ujSzerzodesFormGroup.controls['f_dokumentum'].setValue(null);
                }
                else {
                  this.utilityService.dialogMegnyitasa({
                    info: true,
                    title: 'Adószám keresése',
                    message: 'Az Ön által megadott adószámhoz tartozó céget nem találtunk. Kérjük ellenőrizze, hogy helyesen adta-e meg az adószámot.',
                    confirmButtonText: 'Rendben'
                  });
                }
              }
              this.toltoUj = false;
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Adószám keresése',
                message: 'Adatbázisunk jelenleg egy technikai hiba miatt nem érhető el. Kérjük térjen vissza később!',
                confirmButtonText: 'Rendben'
              });
              this.toltoUj = false;
            }
          );
        });
      });
  }

  // Település lekérdezése irányítószám alapján
  telepulesLekerdezese() {
    this.utilityService.telepulesek(this.ujSzerzodesFormGroup.controls['f_lev_irsz'].value).subscribe(
      data => {
        this.telepulesLista = data;
        // Ha egy darab település van, akkor automatikusan kiválasztjuk
        if (this.telepulesLista.length == 1) {
          this.ujSzerzodesFormGroup.controls['f_lev_telepules'].setValue(this.telepulesLista[0].f_helynev);
        }
      }
    );
  }

  // Utca nevek lekérdezése irányítószám és település alapján
  utcanevekLekerdezese() {
    this.utilityService.utcanevek(this.ujSzerzodesFormGroup.controls['f_lev_irsz'].value, this.ujSzerzodesFormGroup.controls['f_lev_telepules'].value).subscribe(
      data => {
        this.utcanevLista = data;
      }
    );
  }

  // Utca nevek lekérdezése irányítószám, település és utcanév alapján
  uttipusokLekerdezese() {
    this.utilityService.uttipusok(this.ujSzerzodesFormGroup.controls['f_lev_irsz'].value, this.ujSzerzodesFormGroup.controls['f_lev_telepules'].value, this.ujSzerzodesFormGroup.controls['f_lev_kt_nev'].value).subscribe(
      data => {
        if (data[0] == null) {
          data[0] = {"f_uttipus": " "};
          this.uttipusLista = data;
          this.lev_kt_tipus_readonly = true;
        }
        else {
          this.uttipusLista = data;
          this.lev_kt_tipus_readonly = false;
        }
        // Ha egy darab úttípus van, akkor automatikusan kiválasztjuk
        if (this.uttipusLista.length == 1) {
          this.ujSzerzodesFormGroup.controls['f_lev_kt_tipus'].setValue(this.uttipusLista[0].f_uttipus);
        }
      }
    );
  }

  // FileUpload kontrol (changeClick) output eseménye, lekezeljük, ha van hiba
  onFajlHozzaadas(hibaLista: string[]) {
    // Ha a fájl hozzáadása közben történt hiba
    if (hibaLista && hibaLista.length > 0) {
      this.ujSzerzodesFormGroup.controls['f_dokumentum'].reset();
    }
  }

  szaporitasIndit() {
    this.eloSzerzodesekFormGroup.controls["f_ugyszam"].setValue(-1);
    this.eloSzerzodesekFormGroup.controls["f_feladat_tipus"].setValue("szaporítás");

    this.onAdatokClick(this.highlightedRowElo[0].value, 'szapkezd')
  }

  torlesIndit() {
    this.eloSzerzodesekFormGroup.controls["f_ugyszam"].setValue(-1);
    this.eloSzerzodesekFormGroup.controls["f_feladat_tipus"].setValue("törlés");

    this.onAdatokClick(this.highlightedRowElo[0].value, 'törléskezd')
  }

  compareObjects(object1, object2, key, sortDirection) {
    const obj1 = object1.controls[key].value.toUpperCase();
    const obj2 = object2.controls[key].value.toUpperCase();

    if (sortDirection == "asc") {
      if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
    }
    else {
      if (obj1 < obj2) {
        return 1
      }
      if (obj1 > obj2) {
        return -1
      }
    }
    return 0
  }

  rogzitettUgyKeresese(tablaAdatok: any[], ugyszam: number): number {

    let sor: number = 0;
    let ok: boolean = false;

    /*setTimeout(() => {*/

    tablaAdatok.sort((a, b) => {
      return this.compareObjects(a, b, this.dataSourceRogzitett.sort.active, this.matSortDirectionRogzitett)
    })

    while (!ok && sor < tablaAdatok.length) {

      let row: FormGroup = tablaAdatok[sor];
      if (Number(row.controls["f_ugyszam"].value.toString()) == ugyszam) {
        ok = true;
      }
      else {
        sor++;
      }
    }

    /* });*/


    return sor;
  }

  folyamatbanlevoUgyekFeltolt(ugyszam: number) {

    this.szerzodesFormGroup.controls["f_ugyszam"].setValue(0);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_folyamatbanlevo_ugyek_feltolt').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.dataSourceRogzitett.data = [];

          let model = {
            f_ugyfelnev: this.szerzodesFormGroup.controls["f_ugyfelnev_kereses"].value,
            f_szerzazon: this.szerzodesFormGroup.controls["f_szerzazon_kereses"].value,
            f_modozat: this.szerzodesFormGroup.controls["f_modozat_kereses"].value,
            f_ugyszam: this.szerzodesFormGroup.controls["f_ugyszam_kereses"].value
          }

          this.fuvarozoiService.fuvarozoiFolyamatbanlevoUgyek(model).subscribe(
            data => {
              if (data && data.length > 0) {
                const fgs = data.map(item => {
                  return this.utilityService.formGroupObjektumbol(item);
                });
                this.dataSourceRogzitett.data = this.formBuilder.array(fgs).controls;
                /*this.matSortRogzitett.start = "desc";*
                this.dataSourceRogzitett.sort = this.matSortRogzitett;        */        
                
                if (ugyszam != null) {

                  let tablaAdatok: any[] = [];
                  tablaAdatok = this.dataSourceRogzitett.data;

                  let rowNum: number;
                  rowNum = this.rogzitettUgyKeresese(tablaAdatok, ugyszam);

                  this.dataSourceRogzitett.paginator.pageIndex = Math.trunc(rowNum / this.dataSourceRogzitett.paginator.pageSize);
                  this.dataSourceRogzitett.paginator.page.emit({
                    length: this.dataSourceRogzitett.paginator.getNumberOfPages(),
                    pageIndex: Math.trunc(rowNum / this.dataSourceRogzitett.paginator.pageSize),
                    pageSize: this.dataSourceRogzitett.paginator.pageSize
                  });

                  this.onAdatokClick(tablaAdatok[rowNum], tablaAdatok[rowNum].controls["f_tipus"].value == "szaporítás" ? "szapfolyt" : (tablaAdatok[rowNum].controls["f_tipus"].value == "törlés" ? "törlésfolyt" : tablaAdatok[rowNum].controls["f_tipus"].value));
                }
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az adatok betöltése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          )
        })
      });
  }

  highlightElo(row) {
    this.highlightedRowElo = [];
    this.highlightedRowElo.push(row);
    this.eloSzerzodesekFormGroup.controls["f_ugyszam"].setValue(null);
    this.eloSzerzodesekFormGroup.controls["f_dijtetel_a"].setValue(null);
    this.eloSzerzodesekFormGroup.controls["f_dijtetel_b"].setValue(null);
    this.fajlFeltoltesEredmeny = "";
  }

  highlightedRogzitett(row) {
    this.highlightedRowRogzitett = [];
    this.highlightedRowRogzitett.push(row);
  }

  onRogzitettLathatoValt() {
    this.szerzodesFormGroup.controls['f_ugyszam'].setValue(0);
    this.idoLathato = false;
    this.highlightedRowElo = [];
    this.highlightedRowRogzitett = [];
    this.rogzitettLathato = !this.rogzitettLathato;
    if (this.rogzitettLathato) {
      this.szerzodesFormGroupInit();
      this.eloLathato = false;
      this.ujLathato = false;
    }
    this.cdr.detectChanges();
  }

  onEloLathatoValt() {
    this.szerzodesFormGroup.controls['f_ugyszam'].setValue(0);
    this.idoLathato = false;
    this.highlightedRowElo = [];
    this.highlightedRowRogzitett = [];
    this.eloLathato = !this.eloLathato;
    if (this.eloLathato) {
      this.eloSzerzodesekFormGroupInit();
      this.dataSourceFuvarozoiSzerzodesAdatok.data = [];
      this.rogzitettLathato = false;
      this.ujLathato = false;
    }
    this.cdr.detectChanges();
  }

  onUjLathatoValt() {
    this.szerzodesFormGroup.controls['f_ugyszam'].setValue(0);
    this.idoLathato = false;
    this.highlightedRowElo = [];
    this.highlightedRowRogzitett = [];
    this.ujLathato = !this.ujLathato;
    if (this.ujLathato) {
      this.ujSzerzodesFormGroupInit();
      this.rogzitettLathato = false;
      this.eloLathato = false;
    }
    this.cdr.detectChanges();
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny = element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }

  onSzaporitas() {
    this.uzenet = "";
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_szaporitas').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzodesAdatok: this.highlightedRowElo[0].value,
            f_dokumentum: this.eloSzerzodesekFormGroup.controls["f_dokumentum"].value,
            f_feladat_tipus: this.eloSzerzodesekFormGroup.controls["f_feladat_tipus"].value,
            f_dijtetel_a: this.eloSzerzodesekFormGroup.controls["f_dijtetel_a"].value,
            f_dijtetel_b: this.eloSzerzodesekFormGroup.controls["f_dijtetel_b"].value,
            f_modhataly_datum: this.eloSzerzodesekFormGroup.controls["f_modhataly_datum"].value,
            f_modhataly_ora: this.eloSzerzodesekFormGroup.controls["f_modhataly_ora"].value,
            f_modhataly_perc: this.eloSzerzodesekFormGroup.controls["f_modhataly_perc"].value
          }

          this.fuvarozoiService.fuvarozoiUgyMentes(model).subscribe(
            data => {
              if (data["hibalista"] != null && data["hibalista"].length > 0) {
                this.uzenet = "<b>Tisztelt Partnerünk!</><br><br>Bővítési igényének betöltése közben az alábbi hibá(ka)t találtuk:<br>";
                this.uzenet += "<ul>";
                data["hibalista"].forEach(element => {
                  this.uzenet += "<li>" + element + "</li>";
                })
                this.uzenet += "</ul>";
                this.fileUploadExcel.clearFilesList();
                this.fileUploadExcel.clearInputElement();
                this.eloSzerzodesekFormGroup.controls["f_dokumentum"].setValue(null);
                this.fajlFeltoltesEredmeny = "";
              }
              else {

                if (data.ugyszam > 0) {

                  this.utilityService.dialogMegnyitasa({
                    success: true,
                    title: 'Sikeres mentés',
                    message: "<b>Tisztelt Partnerünk!</><br><br>Bővítési igényét fogadtuk.<br>Ügyszám: " + data.ugyszam.toString() + "<br>Az ajánlat pdf formátumban letölthető.<br>A kitöltött és ügyfél által is aláírt, olvasható dokumentumot kérjük, töltse vissza 5 napon belül!",
                    confirmButtonText: 'Rendben'
                  });

                  this.eloSzerzodesekFormGroupInit();

                  this.eloLathato = false;
                  this.rogzitettLathato = true;
                  this.folyamatbanlevoUgyekFeltolt(data.ugyszam);
                }
                else {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hibás mentés',
                    message: "Hiba történt a szaporítás mentése közben",
                    confirmButtonText: 'Rendben'
                  });
                }

              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hibás mentés',
                message: "Hiba történt a szaporítás feldolgozása közben",
                confirmButtonText: 'Rendben'
              });

            }
          );
        })
      });
  }

  onAdatokClick(event: any, tipus: string) {

    let strUgyszam: string = "";
    this.uzenet = "";
    if (this.fileUploadAjanlat != null) {
      this.fileUploadAjanlat.clearFilesList();
      this.fileUploadAjanlat.clearInputElement();
    }
    this.szerzodesFormGroup.controls["f_dokumentum"].setValue(null);
    this.fajlFeltoltesEredmeny = "";

    if (tipus == "szapfolyt" || tipus == "törlésfolyt" || tipus == "új") {

      this.highlightedRogzitett(event);
      strUgyszam = event.controls["f_ugyszam"].value;
      let ok: boolean = false
      let i: number = 0;
      while (!ok) {
        if (strUgyszam[0] == "0") {
          strUgyszam = strUgyszam.substring(1);
        }
        else {
          ok = true;
        }
      }
    }


    //this.toltoRogzitett = true;
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_adatok').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.dataSourceFuvarozoiSzerzodesAdatok.data = [];
          this.dataSourceFuvarozoiBovitesGepjarmuvek.data = [];
          this.dataSourceFuvarozoiTorlesGepjarmuvek.data = [];

          let model = {
            ugyszam: strUgyszam == "" ? null : Number(strUgyszam),
            szerzazon: tipus == "szapfolyt" || tipus == "törlésfolyt" ? Number(event.controls["f_szerzazon"].value) : Number(event.f_szerz_azon),
            tipus: tipus
          }

          this.fuvarozoiService.fuvarozoiAdatokLekerdezes(model).subscribe(
            data => {
              //this.toltoRogzitett = false;

              if (tipus == "szapfolyt" || tipus == "törlésfolyt") {

                this.szerzodesFormGroup.controls['f_dokumentum'].setValue(null);
                this.szerzodesFormGroup.controls['f_szerzazon'].setValue(Number(event.controls["f_szerzazon"].value));
                this.szerzodesFormGroup.controls['f_ugyszam'].setValue(Number(strUgyszam));
                this.szerzodesFormGroup.controls['f_allapot'].setValue(event.controls["f_allapot_megnevezes"].value);
                this.szerzodesFormGroup.controls['f_allapotkod'].setValue(event.controls["f_allapotkod"].value);
                this.szerzodesFormGroup.controls['f_szerzodes_ok'].setValue(true);

                this.szerzodesFormGroup.controls["f_kockezd"].setValue(moment(data["fuvarozoiUgyfelAdatBruno"].f_kockezd).format('YYYY.MM.DD HH:mm'));
                this.szerzodesFormGroup.controls["f_mod_hataly"].setValue(moment(data["fuvarozoiUgyfelAdatPP"].f_kockezd).format('YYYY.MM.DD HH:mm'));
                this.szerzodesFormGroup.controls["f_evfordulo"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_evfordulo);
                this.szerzodesFormGroup.controls["f_szerzodo"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szerzodo);
                this.szerzodesFormGroup.controls["f_adoszam"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_adoszam);
                this.szerzodesFormGroup.controls["f_cegjegyzekszam"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_cegjegyzekszam);
                this.szerzodesFormGroup.controls["f_ev_nyilv_szam"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_ev_nyilv_szam);
                this.szerzodesFormGroup.controls["f_dijfizgyak"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_dijfizgyak);
                this.szerzodesFormGroup.controls["f_dijfizmod"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_dijfizmod);
                this.szerzodesFormGroup.controls["f_modkod"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_modkod);
                this.szerzodesFormGroup.controls["f_alldijsum"].setValue((Math.round(data["fuvarozoiUgyfelAdatBruno"].f_alldijsum * 100) / 100).toLocaleString());
                this.szerzodesFormGroup.controls["f_dijrend"].setValue(moment(data["fuvarozoiUgyfelAdatBruno"].f_dijrend).format('YYYY.MM.DD'));
                this.szerzodesFormGroup.controls["f_szerzodo_tipus"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szerzodo_tipus);
                this.szerzodesFormGroup.controls["f_teaor"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_teaor);
                this.szerzodesFormGroup.controls["f_szulhely"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szulhely);
                this.szerzodesFormGroup.controls["f_szuldatum"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szuldatum);
                this.szerzodesFormGroup.controls["f_anyanev"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_anyanev);
                this.szerzodesFormGroup.controls["f_szerzodo_cime"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szerzodo_cime);
                this.szerzodesFormGroup.controls["f_szerzodo_lev_cime"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szerzodo_lev_cime);
                this.szerzodesFormGroup.controls["f_email_ekom"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_email_ekom);
                this.szerzodesFormGroup.controls["f_szamlaszam"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_szamlaszam);
                this.szerzodesFormGroup.controls["f_vonalkod"].setValue(data["fuvarozoiUgyfelAdatPP"].f_vonalkod);
                this.szerzodesFormGroup.controls["f_allapot_bruno"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_allapot);


                if (data["fuvarozoiSzerzodesAdatok"] != null && data["fuvarozoiSzerzodesAdatok"].length > 0) {
                  const fgsAdatok = data["fuvarozoiSzerzodesAdatok"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });
                  this.dataSourceFuvarozoiSzerzodesAdatok.data = this.formBuilder.array(fgsAdatok).controls;
                }

                if (data["fuvarozoiBovitesGepjarmuvek"].length > 0) {
                  const fgsBovitFuvarozoiGepjarmuvek = data["fuvarozoiBovitesGepjarmuvek"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });

                  this.dataSourceFuvarozoiBovitesGepjarmuvek.data = this.formBuilder.array(fgsBovitFuvarozoiGepjarmuvek).controls;
                }

                if (data["fuvarozoiTorlesGepjarmuvek"].length > 0) {
                  const fgsTorlesFuvarozoiGepjarmuvek = data["fuvarozoiTorlesGepjarmuvek"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });

                  this.dataSourceFuvarozoiTorlesGepjarmuvek.data = this.formBuilder.array(fgsTorlesFuvarozoiGepjarmuvek).controls;
                }


                this.fajlKiterjesztesek = [".jpg", ".jpeg", ".png", ".pdf"];
              }

              if (tipus == "új") {

                this.szerzodesFormGroup.controls['f_dokumentum'].setValue(null);
                this.szerzodesFormGroup.controls['f_szerzazon'].setValue(null);
                this.szerzodesFormGroup.controls['f_ugyszam'].setValue(Number(strUgyszam));
                this.szerzodesFormGroup.controls['f_allapot'].setValue(event.controls["f_allapot_megnevezes"].value);
                this.szerzodesFormGroup.controls['f_allapotkod'].setValue(event.controls["f_allapotkod"].value);
                this.szerzodesFormGroup.controls['f_szerzodes_ok'].setValue(true);

                this.szerzodesFormGroup.controls["f_kockezd"].setValue(moment(data["fuvarozoiUgyfelAdatPP"].f_kockezd).format('YYYY.MM.DD HH:mm'));
                this.szerzodesFormGroup.controls["f_mod_hataly"].setValue(null);
                this.szerzodesFormGroup.controls["f_evfordulo"].setValue(data["fuvarozoiUgyfelAdatPP"].f_evfordulo);
                this.szerzodesFormGroup.controls["f_szerzodo"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szerzodo);
                this.szerzodesFormGroup.controls["f_adoszam"].setValue(data["fuvarozoiUgyfelAdatPP"].f_adoszam);
                this.szerzodesFormGroup.controls["f_cegjegyzekszam"].setValue(data["fuvarozoiUgyfelAdatPP"].f_cegjegyzekszam);
                this.szerzodesFormGroup.controls["f_ev_nyilv_szam"].setValue(data["fuvarozoiUgyfelAdatPP"].f_ev_nyilv_szam);
                this.szerzodesFormGroup.controls["f_dijfizgyak"].setValue(data["fuvarozoiUgyfelAdatPP"].f_dijfizgyak);
                this.szerzodesFormGroup.controls["f_dijfizmod"].setValue(data["fuvarozoiUgyfelAdatPP"].f_dijfizmod);
                this.szerzodesFormGroup.controls["f_modkod"].setValue(data["fuvarozoiUgyfelAdatPP"].f_modkod);
                this.szerzodesFormGroup.controls["f_alldijsum"].setValue((Math.round(data["fuvarozoiUgyfelAdatPP"].f_alldijsum * 100) / 100).toLocaleString());
                this.szerzodesFormGroup.controls["f_dijrend"].setValue(data["fuvarozoiUgyfelAdatPP"].f_dijrend == null ? null : moment(data["fuvarozoiUgyfelAdatPP"].f_dijrend).format('YYYY.MM.DD'));
                this.szerzodesFormGroup.controls["f_szerzodo_tipus"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szerzodo_tipus);
                this.szerzodesFormGroup.controls["f_teaor"].setValue(data["fuvarozoiUgyfelAdatPP"].f_teaor);
                this.szerzodesFormGroup.controls["f_szulhely"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szulhely);
                this.szerzodesFormGroup.controls["f_szuldatum"].setValue(moment(data["fuvarozoiUgyfelAdatPP"].f_szuldatum).format('YYYY.MM.DD'));
                this.szerzodesFormGroup.controls["f_anyanev"].setValue(data["fuvarozoiUgyfelAdatPP"].f_anyanev);
                this.szerzodesFormGroup.controls["f_szerzodo_cime"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szerzodo_cime);
                this.szerzodesFormGroup.controls["f_szerzodo_lev_cime"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szerzodo_lev_cime);
                this.szerzodesFormGroup.controls["f_email_ekom"].setValue(data["fuvarozoiUgyfelAdatPP"].f_email_ekom);
                this.szerzodesFormGroup.controls["f_szamlaszam"].setValue(data["fuvarozoiUgyfelAdatPP"].f_szamlaszam);
                this.szerzodesFormGroup.controls["f_vonalkod"].setValue(data["fuvarozoiUgyfelAdatPP"].f_vonalkod);

                if (data["fuvarozoiSzerzodesAdatok"] != null && data["fuvarozoiSzerzodesAdatok"].length > 0) {
                  const fgsAdatok = data["fuvarozoiSzerzodesAdatok"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });
                  this.dataSourceFuvarozoiSzerzodesAdatok.data = this.formBuilder.array(fgsAdatok).controls;
                }

                if (data["fuvarozoiBovitesGepjarmuvek"].length > 0) {
                  const fgsBovitFuvarozoiGepjarmuvek = data["fuvarozoiBovitesGepjarmuvek"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });

                  this.dataSourceFuvarozoiBovitesGepjarmuvek.data = this.formBuilder.array(fgsBovitFuvarozoiGepjarmuvek).controls;
                }

                if (data["fuvarozoiTorlesGepjarmuvek"].length > 0) {
                  const fgsTorlesFuvarozoiGepjarmuvek = data["fuvarozoiTorlesGepjarmuvek"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });

                  this.dataSourceFuvarozoiTorlesGepjarmuvek.data = this.formBuilder.array(fgsTorlesFuvarozoiGepjarmuvek).controls;
                }


                this.fajlKiterjesztesek = [".jpg", ".jpeg", ".png", ".pdf"];
              }

              if (tipus == "szapkezd" || tipus == "törléskezd") {


                this.eloSzerzodesekFormGroup.controls["f_kockezd"].setValue(moment(data["fuvarozoiUgyfelAdatBruno"].f_kockezd).format('YYYY.MM.DD hh:mm'));
                this.eloSzerzodesekFormGroup.controls["f_modkod"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_modkod);
                this.eloSzerzodesekFormGroup.controls["f_evfordulo"].setValue(data["fuvarozoiUgyfelAdatBruno"].f_evfordulo);
                this.eloSzerzodesekFormGroup.controls["f_alldijsum"].setValue((Math.round(data["fuvarozoiUgyfelAdatBruno"].f_alldijsum * 100) / 100).toLocaleString());
                this.eloSzerzodesekFormGroup.controls["f_dijrend"].setValue(moment(data["fuvarozoiUgyfelAdatBruno"].f_dijrend).format('YYYY.MM.DD'));
                this.eloSzerzodesekFormGroup.controls["f_szerzodes_ok"].setValue(true);

                if (data["fuvarozoiSzerzodesAdatok"] != null && data["fuvarozoiSzerzodesAdatok"].length > 0) {
                  const fgsAdatok = data["fuvarozoiSzerzodesAdatok"].map(item => {
                    return this.utilityService.formGroupObjektumbol(item);
                  });
                  this.dataSourceFuvarozoiSzerzodesAdatok.data = this.formBuilder.array(fgsAdatok).controls;
                }

                this.fajlKiterjesztesek = [".xlsx"];
              }

              this.cdr.detectChanges();

            },
            errorResult => {
              //this.toltoRogzitett = false;
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az adatok betöltése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });

  }

  onMegsemKezd() {
    this.eloSzerzodesekFormGroup.get('f_ugyszam').setValue(null);
  }

  onMegsem() {
    this.szerzodesFormGroup.get('f_ugyszam').setValue(0);
    this.szerzodesFormGroup.get('f_szerzodes_ok').setValue(false);
    this.szerzodesFormGroup.get('f_allapot').setValue(null);
    this.onRogzitettLathatoValt();
  }

  onBezaras() {
    this.szerzodesFormGroup.get('f_ugyszam').setValue(0);
    this.szerzodesFormGroup.get('f_szerzodes_ok').setValue(false);
    this.szerzodesFormGroup.get('f_allapot').setValue(null);
    this.uzenet = "";
    this.highlightedRowRogzitett = [];
  }

  onAjanlatLetoltes(feladat: string) {

    this.toltoRogzitett = true;

    this.recaptchaV3Service.execute('parterportal_fuvarozoi_ajanlat').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzodesAdatok: this.szerzodesFormGroup.value,
            tipus: feladat
          }

          this.fuvarozoiService.fuvarozoiAjanlat(model).subscribe(
            data => {

              let contentType = 'application/pdf';
              //let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              let blob = base64StringToBlob(data, contentType);
              saveAs(blob, (this.szerzodesFormGroup.controls["f_modkod"].value == "32101" ? "kaf" : "kszf") + "_ajanlat_" + this.szerzodesFormGroup.controls["f_ugyszam"].value + ".pdf");
              this.folyamatbanlevoUgyekFeltolt(this.szerzodesFormGroup.controls["f_ugyszam"].value);
              this.toltoRogzitett = false;

            },
            errorResult => {
              this.toltoRogzitett = false;
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az ajánlat készítése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  onSzaporitasIgenyTorles() {

    let biztos = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: "",
      message: "Ügyszám: " + this.szerzodesFormGroup.controls["f_ugyszam"].value + "<br> Biztos törölni akarod?",
      confirmButtonText: "Biztos",
      cancelButtonText: "Mégsem"
    });

    biztos.afterClosed().subscribe(result => {
      if (result) {

        this.toltoRogzitett = true;

        this.recaptchaV3Service.execute('parterportal_igeny_torlese').subscribe(
          token => {
            let model = { encodedResponse: token }
            // Recaptcha szerver validálás
            this.utilityService.recaptchaValidalas(model).subscribe(result => {
              let model = {
                ugyszam: this.szerzodesFormGroup.controls["f_ugyszam"].value
              }

              this.fuvarozoiService.fuvarozoiUgyTorles(model).subscribe(
                data => {
                  this.toltoRogzitett = false;

                  if (data == 0) {
                    this.szerzodesFormGroup.get('f_ugyszam').setValue(0);
                    this.szerzodesFormGroup.get('f_szerzodes_ok').setValue(false);
                    this.szerzodesFormGroup.get('f_allapot').setValue(null);
                    this.folyamatbanlevoUgyekFeltolt(null);
                    this.utilityService.dialogMegnyitasa({
                      success: true,
                      title: 'Sikeres törlés',
                      message: "Az igény törlése sikeresen megtörtént",
                      confirmButtonText: 'Rendben'
                    });
                  }
                  else {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba történt az igény törlése közben!",
                      confirmButtonText: 'Rendben'
                    });
                  }
                },
                errorResult => {
                  this.toltoRogzitett = false;
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hiba',
                    message: "Hiba történt az igény törlése közben!",
                    confirmButtonText: 'Rendben'
                  });
                }
              );
            })
          });

      }
    });
  }

  onAjanlatBekuldes() {
    this.toltoRogzitett = true;
    this.uzenet = "";

    this.recaptchaV3Service.execute('parterportal_ajanlat_bekuldes').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzodesAdatok: this.szerzodesFormGroup.value,
            tipus: this.highlightedRowRogzitett[0].controls["f_tipus"].value
          }

          this.fuvarozoiService.fuvarozoiAjanlatFeltoltes(model).subscribe(
            data => {
              this.toltoRogzitett = false;
              this.szerzodesFormGroup.controls["f_dokumentum"].setValue(null);
              this.fajlFeltoltesEredmeny = "";
              this.folyamatbanlevoUgyekFeltolt(this.szerzodesFormGroup.controls["f_ugyszam"].value);

              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Sikeres feltöltés',
                message: "<b>Tisztelt Partnerünk!</><br><br>Az ajánlat felöltése sikeres volt, továbbítottuk kockázatelbírálásra.",
                confirmButtonText: 'Rendben'
              });
            },
            errorResult => {
              this.toltoRogzitett = false;
              this.szerzodesFormGroup.controls["f_dokumentum"].setValue(null);
              this.fajlFeltoltesEredmeny = "";
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az ajánlat beküldése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  torlesHatalyEllenorzes(event: any) {

    this.szerzodesFormGroup.controls['f_hiba'].setValue(false);

    if (event.controls["f_torles_hataly"].value == "" || event.controls["f_torles_hataly"].value == null) {
      event.controls["f_torles_hataly"].setErrors(null);
    }
    else {
      if (isNaN(new Date(event.controls["f_torles_hataly"].value).getTime())) {
        event.controls["f_torles_hataly"].setErrors(({ torlesNemDatum: true }));
        this.szerzodesFormGroup.controls['f_hiba'].setValue(true);
      }
      else {
        //this.torlesMinDate = moment(new Date(event.controls["f_hatalytol"].value)).format('YYYY-MM-DD');
        if (new Date(event.controls["f_torles_hataly"].value) < (new Date(event.controls["f_hatalytol"].value) < new Date(this.torlesMinDate) ? new Date(this.torlesMinDate) : new Date(event.controls["f_hatalytol"].value))) {
          new Date(event.controls["f_hatalytol"].value) < new Date(this.torlesMinDate) ? event.controls["f_torles_hataly"].setErrors(({ torlesKorai90: true })) : event.controls["f_torles_hataly"].setErrors(({ torlesKorai: true }));
          this.szerzodesFormGroup.controls['f_hiba'].setValue(true);
        }
        else if (new Date(event.controls["f_torles_hataly"].value) > new Date(this.torlesMaxDate)) {
          event.controls["f_torles_hataly"].setErrors(({ torlesKesoi: true }));
          this.szerzodesFormGroup.controls['f_hiba'].setValue(true);
        }
      }
    }

    this.torlesDb = false;
    this.dataSourceFuvarozoiSzerzodesAdatok.data.forEach(sor => {
      if (sor.controls["f_torles_hataly"].value != "" && sor.controls["f_torles_hataly"].value != null) {
        if (sor.controls["f_torles_hataly"].value != sor.controls["f_torles_hataly_eredeti"].value) {
          this.torlesDb = true;
        }
      }
    })
    
  }

  onTorlesMentes() {

    this.uzenet = "";
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_torles').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let valasztottak = this.dataSourceFuvarozoiSzerzodesAdatok.data.filter(row => row.controls["f_torles_hataly"].value != "" && row.controls["f_torles_hataly"].value != row.controls["f_torles_hataly_eredeti"].value);
          let gjmuAdatok: any[] = [];
          valasztottak.forEach(adat => { gjmuAdatok.push(adat.value) });

          let model = {
            szerzodesAdatok: this.highlightedRowElo[0].value,
            gepjarmuAdatok: gjmuAdatok,
            f_feladat_tipus: this.eloSzerzodesekFormGroup.controls["f_feladat_tipus"].value
          }

          this.fuvarozoiService.fuvarozoiTorlesMentes(model).subscribe(
            data => {
              if (data.ugyszam > 0) {

                this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Sikeres mentés',
                  message: "<b>Tisztelt Partnerünk!</><br><br>Törlési igényét sikeresen elmentettük.<br>Kérjük a következő lépésben a törléshez szükséges dokumentum feltöltését elvégezni!<br>Ügyszám: " + data.ugyszam.toString(),
                  confirmButtonText: 'Rendben'
                });

                this.eloSzerzodesekFormGroupInit();

                this.eloLathato = false;
                this.rogzitettLathato = true;
                this.folyamatbanlevoUgyekFeltolt(data.ugyszam);
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hibás mentés',
                  message: "Hiba történt a törlési igény mentése közben",
                  confirmButtonText: 'Rendben'
                });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hibás mentés',
                message: "Hiba történt a törlési igény mentése közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

  onTorlesBekuldes() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_torles').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let valasztottak = this.dataSourceFuvarozoiTorlesGepjarmuvek.data;
          let gjmuAdatok: any[] = [];
          valasztottak.forEach(adat => { gjmuAdatok.push(adat.value) });


          let strUgyszam: string = "";
          strUgyszam = this.highlightedRowRogzitett[0].controls["f_ugyszam"].value;
          let ok: boolean = false
          let i: number = 0;
          while (!ok) {
            if (strUgyszam[0] == "0") {
              strUgyszam = strUgyszam.substring(1);
            }
            else {
              ok = true;
            }
          }

          let model = {
            szerzodesAdatok: this.highlightedRowRogzitett[0].value,
            gepjarmuAdatok: gjmuAdatok,
            dokumentum: this.szerzodesFormGroup.controls["f_dokumentum"].value,
            ugyszam: strUgyszam
          }

          this.fuvarozoiService.fuvarozoiTorlesBekuldes(model).subscribe(
            data => {
              this.toltoRogzitett = false;
              this.fileUploadAjanlat.clearFilesList();
              this.fileUploadAjanlat.clearInputElement();
              this.szerzodesFormGroup.controls["f_dokumentum"].setValue(null);
              this.fajlFeltoltesEredmeny = "";
              this.folyamatbanlevoUgyekFeltolt(this.szerzodesFormGroup.controls["f_ugyszam"].value);

              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Sikeres mentés',
                message: "<b>Tisztelt Partnerünk!</><br><br>Törlési igényét sikeresen fogadtuk",
                confirmButtonText: 'Rendben'
              });
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hibás mentés',
                message: "Hiba történt a törlési igény fogadása közben",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });


  }

  onigenyBekuldese() {
    this.uzenet = "";
    this.toltoUj = true;
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('parterportal_fuvarozoi_uj').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzodesAdatok: this.ujSzerzodesFormGroup.value,
            f_feladat_tipus: this.ujSzerzodesFormGroup.controls["f_feladat_tipus"].value/*,
            f_dijtetel_a: this.ujSzerzodesFormGroup.controls["f_dijtetel_a"].value,
            f_dijtetel_b: this.ujSzerzodesFormGroup.controls["f_dijtetel_b"].value*/
          }

          this.fuvarozoiService.fuvarozoiUgyMentes(model).subscribe(
            data => {
              if (data["hibalista"] != null && data["hibalista"].length > 0) {
                this.uzenet = "<b>Tisztelt Partnerünk!</><br><br>Új kötési igényének betöltése közben az alábbi hibá(ka)t találtuk:<br>";
                this.uzenet += "<ul>";
                data["hibalista"].forEach(element => {
                  this.uzenet += "<li>" + element + "</li>";
                })
                this.uzenet += "</ul>";
              }
              else {

                if (data.ugyszam > 0) {

                  let dijfizgyakModSzoveg = "";
                  if (data.dijfizgyakMod == "I") {
                    dijfizgyakModSzoveg = "Tájékoztatjuk, hogy a szerződés díja kisebb mint 20.000 Ft, ezért csak éves díjfizetési ütem választható.";
                  }

                  let message = "<b>Tisztelt Partnerünk!</><br><br>Új kötési igényét fogadtuk.<br>" + (dijfizgyakModSzoveg != "" ? ("<b>" + dijfizgyakModSzoveg + "</b><br>") : "") + "Ügyszám: " + data.ugyszam.toString() + "<br>Az ajánlat pdf formátumban letölthető.<br>A kitöltött és ügyfél által is aláírt, olvasható dokumentumot kérjük, töltse vissza 5 napon belül!";

                  this.utilityService.dialogMegnyitasa({
                    success: true,
                    title: 'Sikeres mentés',
                    message: message,
                    confirmButtonText: 'Rendben'
                  });

                  this.onRogzitettLathatoValt();

                  this.folyamatbanlevoUgyekFeltolt(data.ugyszam);
                }
                else {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hibás mentés',
                    message: "Hiba történt az új szerződés mentése közben",
                    confirmButtonText: 'Rendben'
                  });
                }

              }
              this.toltoUj = false;
              this.cdr.detectChanges();
              if (this.ujSzerzodesFormGroup.controls["f_feladat_tipus"].value == "új") {
                this.fileUploadExcelUj.clearFilesList();
                this.fileUploadExcelUj.clearInputElement();
                this.scrollToElement('divUjFuvarozoi');
              }
              else {
                this.fileUploadExcel.clearFilesList();
                this.fileUploadExcel.clearInputElement();
                this.scrollToElement('divSzapFuvarozoi');
              }
              this.eloSzerzodesekFormGroup.controls["f_dokumentum"].setValue(null);
              this.fajlFeltoltesEredmeny = "";
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hibás mentés',
                message: "Hiba történt új szerződés mentése közben",
                confirmButtonText: 'Rendben'
              });
              this.toltoUj = false;
            }
          );
        })
      });
  }

  scrollToElement(elementName): void {
    var element = document.getElementById(elementName);
    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  onFedIgLetoltes(feladat: string) {

    this.toltoRogzitett = true;

    this.recaptchaV3Service.execute('parterportal_fuvarozoi_fedig').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            szerzodesAdatok: this.szerzodesFormGroup.value,
            tipus: feladat
          }

          this.fuvarozoiService.fuvarozoiFedezetIgazolas(model).subscribe(
            data => {

              let contentType = 'application/pdf';
              let blob = base64StringToBlob(data, contentType);
              saveAs(blob, (this.szerzodesFormGroup.controls["f_modkod"].value == "32101" ? "kaf" : "kszf") + "_igazolas_" + this.szerzodesFormGroup.controls["f_ugyszam"].value + ".pdf");

              this.folyamatbanlevoUgyekFeltolt(this.szerzodesFormGroup.controls["f_ugyszam"].value);
              this.toltoRogzitett = false;

            },
            errorResult => {
              this.toltoRogzitett = false;
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az igazolás készítése közben!",
                confirmButtonText: 'Rendben'
              });
            }
          );
        })
      });
  }

}

export class AdatValidator extends Validators {


  static szuletesDatumEllenor() {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls['f_szerzodo_tipus'];
      const control2 = formGroup.controls['f_szerzodo_szuldat'];


      if (control1.value && control1.value == "egyéni vállalkozó" && (!control2.value || control2.value == "")) {
        hasError = true;
      }
      if (hasError) {
        control2.setErrors({ hianyzoSzuldat: true });
      }
      else {
        control2.setErrors(null);
      }
    }
    return validator;
  }

  static szuletesHelyEllenor() {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls['f_szerzodo_tipus'];
      const control2 = formGroup.controls['f_szerzodo_szulhely'];


      if (control1.value && control1.value == "egyéni vállalkozó" && (!control2.value || control2.value == "")) {
        hasError = true;
      }
      if (hasError) {
        control2.setErrors({ hianyzoSzulhely: true });
      }
      else {
        control2.setErrors(null);
      }
    }
    return validator;
  }

  static anyaNevEllenor() {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls['f_szerzodo_tipus'];
      const control2 = formGroup.controls['f_szerzodo_anyanev'];


      if (control1.value && control1.value == "egyéni vállalkozó" && (!control2.value || control2.value == "")) {
        hasError = true;
      }
      if (hasError) {
        control2.setErrors({ hianyzoAnyaNev: true });
      }
      else {
        control2.setErrors(null);
      }
    }
    return validator;
  }

  static szlaEllenor() {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls['f_szamlaszam'];
      control1.setErrors(null);

      if (control1.value) {
        if (!(control1.value.toString().length == 16 || control1.value.toString().length == 24)) {
          control1.setErrors({ hibasSzlaHossz: true });
        }
        else {
          if (control1.value.toString().indexOf("-") > -1) {
            control1.setErrors({ hibasSzlaKotojel: true });
          }
        }
      }
    }
    return validator;
  }

}
