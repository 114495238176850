<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="'Egyenlegközlő'"
                                [subTitle]="''"
                                [templateOutlet]="egyenlegkozlo"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper!-->
        <portal-content-wrapper [title]="'Egyenlegközlő'"
                                [subTitle]="''"
                                [templateOutlet]="egyenlegkozlo">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #egyenlegkozlo>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">

        <form [formGroup]="egyenlegkozloFormGroup" class="form">

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onInfoLathatoValt()">
              <mat-card-header>
                <mat-card-title>Információ</mat-card-title>
                <button mat-icon-button *ngIf="infoLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!infoLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </mat-card-header>
            </div>
            <mat-card-content *ngIf="infoLathato">

              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <span>
                  Az alábbi űrlapon lehetősége van a szerződésekhez vagy flottákhoz tartozó egyenlegközlő letöltésérehez. A lekérdezés több időt vehet igénybe, kérjük addig az alkalmazást ne zárja be!
                </span>
              </div>
              <div class="col-lg-12 mt-2 mb-3 pr-5 info">
                <span>
                  Szolgáltatásunkkal kapcsolatos kérdését, speciális igényét kérjük e-mailen jelezze a broker@granitbiztosito.hu címen.
                </span>
              </div>

            </mat-card-content>
          </mat-card>

          <mat-card class="form-content-2 mb-3 pt-2 pb-0 col-lg-10">
            <div class="card-expand-div" (click)="onKeresesLathatoValt()">
              <mat-card-header>
                <mat-card-title>Keresés</mat-card-title>
                <button mat-icon-button *ngIf="keresesLathato">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!keresesLathato">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </mat-card-header>
            </div>
            <mat-card-content *ngIf="keresesLathato">

              <ng-container *ngIf="toltoKepernyo">
                <div class="row">
                  <div class="col-lg-12 bg-white">
                    <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!toltoKepernyo">

                <div class="row pb-2 pt-3">
                  <div class="col-lg-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Keresési mező</mat-label>
                      <mat-select matInput formControlName="f_keresesi_mezo">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option value="f_flotta_azon">Flotta azonosító</mat-option>
                        <mat-option value="f_szerz_azon">Szerződésszám</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(egyenlegkozloFormGroup, 'f_keresesi_mezo', 'Egyezik')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési mezőt!
                    </mat-error>
                  </div>

                  <div class="col-lg-6">
                    <mat-form-field appearance="fill">
                      <mat-label>Keresési adat</mat-label>
                      <input type="number" matInput formControlName="f_keresesi_adat">
                    </mat-form-field>
                    <mat-error *ngIf="hibaService.hiba(egyenlegkozloFormGroup, 'f_keresesi_adat', 'required')">
                      <mat-icon>error</mat-icon>
                      Kérjük, adjon meg egy keresési adatot!
                    </mat-error>

                  </div>
                </div>

                <div class="col-lg-12 mt-2 mb-3 pr-5 hibauzenet" *ngIf="uzenet && uzenet != ''">
                  <span>
                    {{uzenet}}
                  </span>
                </div>

                <div class="row pb-3">

                  <div class="col-lg-3">
                    <button mat-raised-button color="accent" [disabled]="!egyenlegkozloFormGroup.valid" (click)="onPdf()">PDF</button>
                  </div>

                  <div class="col-lg-3">
                    <button mat-raised-button color="accent" [disabled]="!egyenlegkozloFormGroup.valid" (click)="onExcel()">Excel</button>
                  </div>

                </div>

              </ng-container>

            </mat-card-content>
          </mat-card>


        </form>

      </div>
    </div>
  </div>
</ng-template>
