import { Component, OnInit, HostListener, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Event, Router } from '@angular/router';
import { HibaService, UtilityService } from '@granit/common-library';
import { FelhasznaloService, Felhasznalo } from '@granit/portal-library';
import { CountdownEvent, CountdownStatus, CountdownComponent } from 'ngx-countdown';
import { Observable } from 'rxjs';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'portal-fejlec',
  templateUrl: './portal-fejlec.component.html',
  styleUrls: ['./portal-fejlec.component.scss']
})
export class PortalFejlecComponent implements OnInit {

  
  menu_latszik: boolean = true;

  constructor(
    private felhasznaloService: FelhasznaloService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
    private router: Router,
    public hibaService: HibaService,
    private menuService: MenuService) {

    this.environmentName();
  }

  teszt: boolean = false;
  routerUrl: string;
  fooldalGombLathatosag: boolean;
  menuGombLathatosag: boolean = true;
  aktualisFelhasznalo: Felhasznalo;
  @ViewChild('countdownComponent', { static: false }) private countdownComponent: CountdownComponent;
  @ViewChild('countdownComponent2', { static: false }) private countdownComponent2: CountdownComponent;

  ngOnInit(): void {    

    // A főoldali gomb csak akkor látszik, ha nem a bejelentkezés vagy a főoldal url-en vagyunk
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/bejelentkezes' || event.url == '/fooldal') {
          this.fooldalGombLathatosag = false;
        }
        else {
          this.fooldalGombLathatosag = true;
        }
      }

      this.karbantartasAllapot();

    });

    // Felhasználó subscribe
    this.felhasznaloService.aktualisFelhasznalo.subscribe(
      felhasznalo => {
        this.aktualisFelhasznalo = felhasznalo;
      }
    );
  }

  environmentName() {
    this.utilityService.environmentName().subscribe(
      data => {
        this.teszt = data != "Production";
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  handleEvent(event: CountdownEvent) {
    if (event.action == 'notify') {
      this.utilityService.dialogMegnyitasa({
        info: true,
        title: 'Munkamenete 5 perc múlva lejár...',
        message: 'Inaktivitás miatt automatikusan kilépteti a rendszer. Kattintson a \'Rendben\' gombra, hogy a munkamenet újrakezdődjön.',
        confirmButtonText: 'Rendben'
      });
    }
    if (event.status == CountdownStatus.done) {
      this.felhasznaloService.kijelentkezes();
      this.dialog.closeAll();
      this.utilityService.dialogMegnyitasa({
        info: true,
        title: 'Automatikus kiléptetés',
        message: 'Tétlenség miatt a rendszer biztonsági okokból kiléptette.',
        confirmButtonText: 'Rendben'
      });
    }
  }

  handleEvent2(event: CountdownEvent) {    
    if (event.status == CountdownStatus.done) {            
      this.karbantartasAllapot();      
    }
  }

  karbantartasAllapot() {

    this.menuGombLathatosag = true;

    this.utilityService.karbantartasAllapot().subscribe(
      data => {
        if (data) {
          if (data["f_uzenet"] && data["f_uzenet"] != "") {
            this.felhasznaloService.kijelentkezes();
            this.dialog.closeAll();
            this.menuGombLathatosag = false;
          }
        }
      },
      errorResult => {
      }
    );
  }

  onMenuToggleClick() {
    this.menuService.portalMenuToggle();
    this.menu_latszik = !this.menu_latszik;
  }

  onKijelentkezesClick() {
    this.felhasznaloService.kijelentkezes();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (this.countdownComponent) {
      this.countdownComponent.restart();
    }
  }
}
