<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Flotta szaporítás'"
                                [subTitle]="'Meglévő flotta szaporítása, vagy díjnemfizetéssel megszűnt flotta szerződés újrakötése'"
                                [templateOutlet]="fedezetigazolas">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>
<ng-template #fedezetigazolas>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Előzetes fedezetigazolás letöltése</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="flottaFedigFormGroup">
            <ng-container *ngIf="toltoKepernyoKereses">
              <div class="row">
                <div class="col-lg-12 bg-white">
                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!toltoKepernyoKereses">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field [style.width.%]="100">
                      <mat-label>Alkusz törzsszáma</mat-label>
                      <input matInput formControlName="alkuszTorzsszam" [readonly]="true">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field [style.width.%]="100">
                      <mat-label>Alkusz neve</mat-label>
                      <input matInput formControlName="alkuszNev" [readonly]="true">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill" [style.width.%]="100">
                      <mat-label>Ügyszám</mat-label>
                      <input matInput formControlName="ugyszam">
                      <!--<mat-hint>Ügyszámra történő kereséshez, adja meg az ügyszámot!</mat-hint>-->
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill" [style.width.%]="100">
                      <mat-label>Flotta azonosító</mat-label>
                      <input matInput formControlName="flottaAzonosito">
                      <!--<mat-hint>Flotta azonosítóra történő kereséshez, adja meg a kívánt flotta azonosítót!</mat-hint>-->
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-6">
                    <mat-form-field appearance="fill" [style.width.%]="100">
                      <mat-label>Szerződő név</mat-label>
                      <input matInput formControlName="szerzodoNev">
                      <mat-hint>Szerződő névre történő kereséshez, adja meg a szerződő nevének részletét!</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill" [style.width.%]="100">
                      <mat-label>Ajánlatszám</mat-label>
                      <input matInput formControlName="ajanlatszam">
                      <!--<mat-hint>Ajánlatszámra történő kereséshez, adja meg az ajánlatszámot részletét!</mat-hint>-->
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="fill" [style.width.%]="100">
                      <mat-label>Rendszám</mat-label>
                      <input matInput formControlName="rendszam">
                      <!--<mat-hint>Rendszámra történő kereséshez, adja meg a kívánt rendszámot!</mat-hint>-->
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3"><button mat-raised-button color="accent" (click)="kereses()">Keresés</button></div>
                </div>
              </div>
              <div class="row"></div>
              <div class="row">
                <div class="col-lg-12">
                  <mat-table [dataSource]="dataSource">
                    <!--class="w100 mr-5 table_border"-->
                    <ng-container *ngFor="let column of fedigTablaOszlopok" [matColumnDef]="column.columnDef">
                      <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border">
                        <div>
                          <span [innerHTML]="column.header"></span>
                        </div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element">
                        <div *ngIf="column.adattipus != 'button'">
                          <span [innerHTML]="(element.get([column.columnDef]) ? element.get([column.columnDef]).value : '') | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                        </div>
                        <div *ngIf="column.adattipus == 'button' && column.columnDef == 'f_letoltes'">
                          <button mat-icon-button color="primary" (click)="letoltes(element)" title="Letöltés"><mat-icon>download</mat-icon></button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <!--Fejléc-->
                    <mat-header-row *matHeaderRowDef="getDisplayedFedigColumns()"></mat-header-row>
                    <!--Sorok-->
                    <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFedigColumns();"></mat-row>

                    <div class="mat-row col-lg-12" *matNoDataRow>
                      <div class="mat-cell">Nincs megjeleníthető elem.</div>
                    </div>
                  </mat-table>
                  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]"
                                 showFirstLastButtons
                                 aria-label="Válassza ki az oldalon megjelenő találatok számát.">
                  </mat-paginator>
                </div>
              </div>
            </ng-container>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
