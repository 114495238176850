<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Flotta CASCO szaporítás'"
                                [subTitle]="'Meglévő flotta CASCO szaporítása, vagy díjnemfizetéssel megszűnt flotta CASCO szerződés újrakötése'"
                                [templateOutlet]="szaporitas">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #szaporitas>
  <div class="row">
    <div class="col-lg-12">
      <mat-card class="form-content">
        <mat-card-header>
          <mat-card-title class="content-title">Flottaszaporítás / DNF újrakötés</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Alkusz adatai</mat-card-title>
              </mat-card-header>
              <mat-card-content class="mb-0">
                <div class="col-lg-12">
                  <div>
                    <form [formGroup]="alkuszFormGroup">
                      <!--<div><h4>Alkusz adatai</h4></div>-->
                      <div class="row">
                        <div class="col-lg-12 text-right">
                          <a mat-raised-button color="primary" href="assets/documents/casco_flotta_szaporito.xlsx"><mat-icon>download</mat-icon>Flottaszaporitó Excel sablon letöltése</a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <mat-form-field appearance="legacy">
                            <mat-label>Név</mat-label>
                            <input matInput formControlName="alkuszNev" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                          <mat-form-field appearance="legacy">
                            <mat-label>Törzsszám</mat-label>
                            <input matInput formControlName="alkuszTorzsszam" [readonly]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                          <mat-form-field appearance="legacy">
                            <mat-label>E-mail címe</mat-label>
                            <input matInput formControlName="alkuszEmail" [readonly]="true">
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </mat-card-content>
              <div *ngIf="szerzodoFormGroup.controls['szerzodoNev'].value">
                <mat-card-header>
                  <mat-card-title>Szerződő adatai</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mb-0">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-3">
                        {{szerzodoFormGroup.controls['szerzodoNev'].value}}
                      </div>
                      <div class="col-lg-3">
                        {{szerzodoFormGroup.controls['szerzodoCimEgyben'].value}}
                      </div>
                      <div class="col-lg-3">
                        {{currentStep}}
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>
              <mat-card-content class="mb-0">
                <div class="col-lg-12">
                  <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                      <!-- step 0-->
                      <mat-step [stepControl]="alkuszFormGroup" [editable]="currentFormGroup && currentFormGroup == alkuszFormGroup">
                        <div>
                          <form [formGroup]="alkuszElerhetosegFormGroup">
                            <ng-template matStepLabel>Alkusz elérhetősége</ng-template>
                            <div>
                              <section>
                                <mat-checkbox formControlName="chkElteroEmail">Eltérő email cím</mat-checkbox>
                              </section>
                            </div>
                            <div *ngIf="alkuszElerhetosegFormGroup.get('chkElteroEmail').value">
                              <mat-form-field appearance="fill">
                                <mat-label>Alkusz e-mail címe</mat-label>
                                <input matInput placeholder="pl.: minta@valami.hu" formControlName="alkuszElteroEmail">
                                <mat-error *ngIf="hibaService.hiba(alkuszElerhetosegFormGroup, 'alkuszElteroEmail', 'pattern')">
                                  <mat-icon>error</mat-icon>
                                  Nem valós e-mail cím!
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <div>
                              <mat-form-field appearance="fill">
                                <mat-label>Telefonszám</mat-label>
                                <input matInput placeholder="pl.: 06-1-999-9999" formControlName="alkuszTelefon">
                              </mat-form-field>
                            </div>
                            <div>
                              <button mat-raised-button color="accent" matStepperNext>Tovább</button>
                            </div>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 1 -->
                      <mat-step [stepControl]="szerzodoFormGroup" [editable]="currentFormGroup && currentFormGroup == szerzodoFormGroup">
                        <div>
                          <form [formGroup]="szerzodoFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Szerződő adatai</ng-template>
                              <div>
                                <mat-form-field appearance="fill">
                                  <mat-label>Flotta azonosító</mat-label>
                                  <input matInput placeholder="pl.: 123" formControlName="flottaAzonosito" (focusout)="szerzodoAdatokTorles()">
                                </mat-form-field>
                              </div>
                              <div>
                                <mat-form-field appearance="fill">
                                  <mat-label>Szerződő adószáma</mat-label>
                                  <input matInput placeholder="pl.: 123465678-12-1" formControlName="szerzodoAdoszam" (focusout)="szerzodoAdatokTorles()">
                                </mat-form-field>
                              </div>
                              <div>
                                <mat-form-field appearance="fill">
                                  <mat-label>A kötés oka</mat-label>
                                  <mat-select matInput formControlName="kotesOka">
                                    <mat-option value="01">Flotta szaporítás</mat-option>
                                    <mat-option value="06">Díjnemfizetéssel megszűnt szerződés újrakötése</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div *ngIf="szerzodoFormGroup.get('flottaAzonosito').value && szerzodoFormGroup.get('szerzodoAdoszam').value && szerzodoFormGroup.get('kotesOka').value">
                                <button mat-stroked-button (click)="szerzodoKereses()">Szerződő keresése</button>
                              </div>
                              <div class="row mt-3" [innerHtml]="szerzodoFormGroup.get('hibaSzoveg').value"></div>

                              <div *ngIf="szerzodoFormGroup.get('szerzodoNev').value">
                                <div>
                                  <div><h2><br />Keresés eredménye:</h2></div>
                                  <div>
                                    <mat-form-field appearance="fill">
                                      <mat-label>Szerződő neve</mat-label>
                                      <input matInput placeholder="" formControlName="szerzodoNev" [disabled]="disabled">
                                    </mat-form-field>
                                  </div>
                                  <div>
                                    <mat-form-field appearance="fill">
                                      <mat-label>Szerződő címe</mat-label>
                                      <input matInput placeholder="" formControlName="szerzodoCimEgyben" [disabled]="disabled">
                                    </mat-form-field>
                                  </div>
                                  <div>
                                    <button mat-raised-button color="accent" matStepperNext>Tovább</button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 2 -->
                      <mat-step [stepControl]="alkusziFeltoltesFormGroup" [editable]="currentFormGroup && currentFormGroup == alkusziFeltoltesFormGroup" *ngIf="alkusziSzukseges">
                        <div>
                          <form [formGroup]="alkusziFeltoltesFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Alkuszi megbízás feltöltése</ng-template>
                              <div class="row" *ngIf="!folyamatVege">
                                <div>
                                  <p>&nbsp;</p>
                                  <p><strong>Tisztelt Partnerünk,</strong></p>
                                  <p>mivel a szaporítani kívánt flottát más partnerünk kezeli, ezért kérjük, hogy alkuszi megbízását itt a felületen feltölteni szíveskedjék. Amennyiben a feltöltés megtörtént, 1 munkanapon belül jelezni fogjuk, hogy mikor folytathatja a flotta szaporítását. A gyorsabb ügyintézés érdekében a megadott e-mail címen, vagy telefonszámon fogjuk keresni.</p>
                                  <p>Kérjük szíves türelmét!</p>
                                  <p>üdvözlettel: Gránit Biztosító</p>
                                  <p>&nbsp;</p>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                  <div class="mt-4">
                                    <div class="row mt-5">
                                      <div class="col-lg-1">
                                        <mat-icon class="ml-3 info-icon" [matTooltip]="'Biztonsági okok miatt, csak jpg, jpeg, pdf kiterjesztés file-okat tud kárbejelentéséhez csatolni. Kérjük szíves megértését.'">info</mat-icon>
                                      </div>
                                      <div class="col-lg-4">
                                        <granit-file-upload #fileUpload ngDefaultControl [formGroup]="alkusziFeltoltesFormGroup" [formControlName]="'alkusziFeltoltesDokumentum'" [labelText]="'Kérjük válassza ki a feltölteni kívánt fájlt!'"
                                                             [buttonText]="'Fájl kiválasztás'" allowMultipleFiles="false" [acceptedFileExtensions]="alkusziFajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
                                      </div>
                                    </div>
                                    <div class="row pb-3" *ngIf="alkusziFeltoltesFormGroup.get('alkusziFeltoltesDokumentum').value">
                                      <div class="col-lg-12">
                                        <mat-label>
                                          Feltöltés eredménye: {{fajlFeltoltesEredmeny}}
                                        </mat-label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div [innerHTML]="alkusziFeltoltesFormGroup.get('hibaSzoveg').value"></div>
                              </div>
                              <div class="row">
                                <div>
                                  <button mat-raised-button color="accent" (click)="resetForm()" *ngIf="folyamatVege">Új szaporítás</button>
                                  <button mat-raised-button color="accent" (click)="alkusziFeldolgozas()" *ngIf="!folyamatVege && alkusziFeltoltesFormGroup.get('alkusziFeltoltesDokumentum').value">Feldolgozás</button>
                                </div>
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                      <!-- step 3 -->
                      <mat-step [stepControl]="szaporitoFeltoltesFormGroup" [editable]="currentFormGroup && currentFormGroup == szaporitoFeltoltesFormGroup" *ngIf="!alkusziSzukseges">
                        <div>
                          <form [formGroup]="szaporitoFeltoltesFormGroup">
                            <ng-container *ngIf="toltoKepernyo">
                              <div class="row">
                                <div class="col-lg-12 bg-white">
                                  <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                                  </ngx-skeleton-loader>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="!toltoKepernyo">
                              <ng-template matStepLabel>Flottaszaporítás</ng-template>
                              <div class="row mb-3" *ngIf="!folyamatVege">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                  <div class="mt-4">
                                    <div class="row mt-1">
                                      <p>Kérjük, hogy az adatközlőben a gépjármű értékénél az újkori értéket adják meg forintban. A pontosabb kalkuláció érdekében kérjük, hogy az elvárt díjat is adják meg.</p>
                                      <p><span>Amennyiben a kockázat kezdete mai nap, akkor kérjük az időpontot is adja meg az alábbi formátumban:<br />yyyy.mm.dd óó:pp</span></p>
                                      <p></p>
                                    </div>
                                    <div class="row mt-5">
                                      <div class="col-lg-4">
                                        <granit-file-upload #fileUpload ngDefaultControl [formGroup]="szaporitoFeltoltesFormGroup" [formControlName]="'szaporitoFeltoltesDokumentum'" [labelText]="'Kérjük válassza ki a feltölteni kívánt Excel fájlt!'"
                                                             [buttonText]="'Fájl kiválasztás'" [allowMultipleFiles]="false" [acceptedFileExtensions]="szaporitoFajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
                                      </div>
                                    </div>
                                    <div class="row pb-3" *ngIf="szaporitoFeltoltesFormGroup.get('szaporitoFeltoltesDokumentum').value">
                                      <div class="col-lg-12">
                                        <mat-label>
                                          Feltöltés eredménye: {{fajlFeltoltesEredmeny}}
                                        </mat-label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div [innerHTML]="szaporitoFeltoltesFormGroup.get('hibaSzoveg').value"></div>
                              </div>                             
                              <div class="row">
                                <div class="col-lg-4">
                                  <button mat-raised-button color="accent" (click)="resetForm()" *ngIf="folyamatVege">Új szaporítás</button>
                                  <button mat-raised-button color="accent" (click)="szaporitoFeldolgozas()" *ngIf="!folyamatVege" [disabled]="!szaporitoFeltoltesFormGroup.valid">Feldolgozás</button>
                                </div>                                
                              </div>
                            </ng-container>
                          </form>
                        </div>
                      </mat-step>
                    </div>
                  </mat-vertical-stepper>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>

