<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <!--portal-content-wrapper [title]="tipus == 'aktuális' ? 'Hírek' : 'Archív hírek'"
                                [subTitle]="''"
                                [templateOutlet]="hirek"
                                [titleColorClass]="'color-primary'">
        </portal-content-wrapper-->
        <portal-content-wrapper [title]="tipus == 'aktuális' ? 'Hírek' : 'Archív hírek'"
                                [subTitle]="''"
                                [templateOutlet]="hirek">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>

<ng-template #hirek>
  <div class="contain-lg">
    <div class="row mt-2 mb-5">
      <div class="col-lg-12">
        <form class="form">

          <div class="row">
            <div class="col-lg-10">
              <div [innerHTML]="info">
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-10">
              <div *ngIf="hirekList.length > 0">
                <mat-list *ngFor="let hir of hirekList">
                  <div class="pt-4 pb-4 mb-4 pl-5 pr-5 hir_doboz">
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="hir_targy" (click)="onTargyClick(hir.f_id)">{{hir.f_targy}}</label>
                      </div>
                      <div class="col-lg-6 text-right">
                        <label>{{hir.f_hatalytol | dateFormat: 'YYYY.MM.DD'}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <label>{{hir.f_bevezeto_szoveg}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="hir.f_id == hirTeljes && hir.f_szovegtorzs && hir.f_szovegtorzs != ''">
                      <div class="col-lg-12">
                        <label>{{hir.f_szovegtorzs}}</label>
                      </div>
                    </div>
                  </div>
                </mat-list>
              </div>
              <div *ngIf="hirekList.length == 0">
                Jelenleg nincs megjeleníthető hír
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
