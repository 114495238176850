<div class="container-fluid pb-3">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <portal-content-wrapper [title]="'Partnerportál'"
                                [subTitle]="'Egyszerűen intézheti biztosításaival kapcsolatos ügyeit!'"
                                [templateOutlet]="bejelentkezes">
        </portal-content-wrapper>
      </div>
    </div>
  </div>
</div>


<ng-template #bejelentkezes>
  <div class="row">
    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-12 mb-xl-0 mb-lg-0 mb-md-0 mb-4" *ngIf="karbantartas != 'I'">
      <portal-bejelentkezes [alkalmazasEnum]="1"></portal-bejelentkezes>
    </div>

    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-12 mb-xl-0 mb-lg-0 mb-md-0 mb-4">
      <portal-karbantartas [alkalmazasEnum]="1" (karbantartasEsemeny)="olvasas($event)"></portal-karbantartas>
    </div>
  </div>
</ng-template>
