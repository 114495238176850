import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { HibaService, TextTransformPipe, UtilityService, GranitTableComponent } from '@granit/common-library';
import { DokumentumService } from '../../../../services/dokumentum.service';
import { FlottaService } from '../../../../services/flotta.service';
import { base64StringToBlob } from 'blob-util';
import { MatSort } from '@angular/material/sort';
import { saveAs } from 'file-saver';

@Component({
  selector: 'uj-flotta-casco-ajanlatkeresek',
  templateUrl: './uj-flotta-casco-ajanlatkeresek.component.html',
  styleUrls: ['./uj-flotta-casco-ajanlatkeresek.component.scss']
})
export class UjFlottaCascoAjanlatkeresekComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private flottaService: FlottaService,
    public hibaService: HibaService,
    private utilityService: UtilityService,
    private dokumentumokService: DokumentumService,
    private cdr: ChangeDetectorRef  ) {

  }

  ujFlottaAjanlatkeresekFormGroup: FormGroup;

  dataSource: MatTableDataSource<any>;
  matPaginator: MatPaginator;
  @ViewChild('matPaginator', { static: false }) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.matPaginator = paginator;
      this.dataSource.paginator = this.matPaginator;
    }
  }
  getDisplayedColumns(): string[] {
    let displayedColumns = this.ujFlottaAjanlatkeresekTablaOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
 


  ujFlottaAjanlatkeresekTablaOszlopok: any[] = [
    { columnDef: 'f_ugyszam', header: 'Ügyszám', class: 'col-1' },
    { columnDef: 'f_datum', header: 'Beérkezés dátum', class: 'col-2', dateFormat: 'YYYY.MM.DD. HH:mm', adattipus: 'date' },
    { columnDef: 'f_ugyfelnev', header: 'Szerződő' },
    { columnDef: 'f_allapot_szoveg', header: 'Állapot' },
    { columnDef: 'f_szemle', header: '', visible: false },
    { columnDef: 'f_egyeni_megjegyzes', header: '', visible: false }
  ];
  @ViewChild('ujFlottaAjanlatkeresekTabla', { static: false }) ujFlottaAjanlatkeresekTabla: GranitTableComponent;
  ujFlottaAjanlatkeresGepjarmuTablaOszlopok: any[] = [
    { columnDef: 'f_vonalkod', header: 'Ajánlatszám' },
    { columnDef: 'f_kockezd', header: 'Kockázatviselés kezdete', dateFormat: 'YYYY.MM.DD', adattipus: 'date' },
    { columnDef: 'f_rendszam', header: 'Rendszám' },
    { columnDef: 'f_alvazszam', header: 'Alvázszám' },
    { columnDef: 'f_jelleg_szoveg', header: 'Jelleg' },
    { columnDef: 'f_uzjelleg_szoveg', header: 'Üzemeltetés jelleg' }
  ]; 

  /* Új flottához tartozó gépjármű azonosítók*/
  cascoGepjarmuvekOszlopok: any[] = [
    { columnDef: 'f_gj_azon', header: 'XXX' }
  ];
  getDisplayedCascoGepjarmuvekColumns(): string[] {
    let displayedColumns = this.cascoGepjarmuvekOszlopok.map(c => c.columnDef);
    return displayedColumns;
  }
  dataSourceCascoGepjarmuvek: MatTableDataSource<any>;

  formEnum: FormEnum = FormEnum.Ajanlatkeresek;
  //ujFlottaAjanlatVisszatoltesFormGroup: FormGroup;

  toltoKepernyoKereses: boolean = false;
  toltoKepernyoFajlFeltoltes: boolean = false;
  highlightedRow: any = [];
  feltoltes: boolean = false;
  fedezetIgazolas: boolean = false;
  fajlFeltoltesEredmeny: string = "";


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    
    this.dataSource = new MatTableDataSource();    
    this.dataSourceCascoGepjarmuvek = new MatTableDataSource();
    this.ujFlottaAjanlatkeresekLetrehozas();
    
  }

  highlighted(row) {
    this.highlightedRow = [];
    this.highlightedRow.push(row);
    this.feltoltes = false;
    this.ujFlottaAjanlatkeresekFormGroup.controls["f_allapot_szoveg"].setValue(this.highlightedRow[0].controls["f_allapot_szoveg"].value);
    if (this.highlightedRow[0].controls["f_szemle"].value == "I") {
      this.szemleAdatok();
    }    
    this.tarsbiztositottiAdatok();
    this.scrollToElement('divReszletesAdatok');
  }

  // Új flotta ajánlatkérések szűrő formgroup létrehozása
  ujFlottaAjanlatkeresekLetrehozas() {
    this.ujFlottaAjanlatkeresekFormGroup = this.formBuilder.group({
      f_szerzodo_nev: new FormControl(null),
      f_allapot: new FormControl(''),
      feltoltesFlotta: new FormControl(''),
      feltoltesSzerzodoNev: new FormControl(''),
      feltoltesUgyszam: new FormControl(''),
      feltoltesAlkuszTelefon: new FormControl(''),
      feltoltesAlkuszEmail: new FormControl(''),
      feltoltesFlottaNev: new FormControl(''),
      feltoltesVonalkodok: new FormControl(''),
      feltoltesUid: new FormControl(''),
      f_fajlok: new FormControl(''),
      f_allapot_szoveg: new FormControl(''),      
      f_kapcsolattarto_neve: new FormControl(''),      
      f_kapcsolattarto_telszam: new FormControl(''),
      f_kapcsolattarto_email: new FormControl('', [Validators.pattern("^[-_a-z0-9][-_.a-z0-9]*@[-_.a-z0-9]+(\.[-_.a-z0-9]+)*\.([a-z]{2,10})$")]),
      f_kapcsolattarto_fenykep: new FormControl('')
    },
      {
        validators: [AdatValidator.kapcsolattartoNevEllenor(), AdatValidator.kapcsolattartoTelszamEllenor(), AdatValidator.kapcsolattartoEmailEllenor(), AdatValidator.kapcsolattartoFenykepEllenor()]
      });

    this.ujFlottaAjanlatkeresekLekerese();
  }

  // Új flotta ajánlat visszatöltés formgroup létrehozása
  //ujFlottaAjanlatVisszatoltesFormGroupLetrehozas() {
  //  this.ujFlottaAjanlatVisszatoltesFormGroup = this.formBuilder.group({
  //    tableDataSource: this.formBuilder.array([]),
  //    f_fajlok: new FormControl(null, [Validators.required])
  //  });
  //}

  // Új flotta ajánlatkérések lekérése
  ujFlottaAjanlatkeresekLekerese() {

    let ugyszam: number = null;
    if (this.highlightedRow && this.highlightedRow.length > 0) {
      ugyszam = this.highlightedRow[0].controls["f_ugyszam"].value;
    }

    // Model létrehozása
    let model = {
      ugyfelnev: this.ujFlottaAjanlatkeresekFormGroup.controls['f_szerzodo_nev'].value,
      allapot: this.ujFlottaAjanlatkeresekFormGroup.controls['f_allapot'].value,
      modozat: 'CASCO'
    };

    this.toltoKepernyoKereses = true;
    // Kontroller hívás
    this.flottaService.ujFlottaAjanlatkeresek(model).subscribe(
      data => {
        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        this.dataSource.data = this.formBuilder.array(fgs).controls;
        
        if (ugyszam != null) {
          
          setTimeout(() => {
          let sor: number = 0;
          let ok: boolean = false;          

          while (!ok && sor < this.dataSource.data.length) {

            let row: FormGroup = this.dataSource.data[sor];
            if (Number(row.controls["f_ugyszam"].value.toString()) == ugyszam) {
              ok = true;
              this.highlightedRow = [];
              this.highlightedRow.push(row);              
            }
            else {
              sor++;
            }
          }

          let pageindex: number = Math.trunc(sor / this.dataSource.paginator.pageSize);          
          this.dataSource.paginator.pageIndex = pageindex;
          this.dataSource.paginator.page.emit({
            length: this.dataSource.paginator.getNumberOfPages(),
            pageIndex: pageindex,
            pageSize: this.dataSource.paginator.pageSize
          });
          }, 0);

        }

        this.toltoKepernyoKereses = false;
      }
    );
  }

  // Szűrés gomb esemény
  onSzures() {
    this.ujFlottaAjanlatkeresekLekerese();
  }

  // Táblázat letöltés gomb esemény
  letoltesOnClick() {    
    // Model létrehozása
    let model = {
      formGroupValue: this.highlightedRow[0].value,
      modozat: 'CASCO',
      tipus: 'új'
    };

    this.toltoKepernyoKereses = true;

    this.flottaService.ujFlottaAjanlatVagyDijkalkulacioLetoltes(model).subscribe(
      data => {
        /*var fileURL = URL.createObjectURL(data.body);
        window.open(fileURL, "_blank");*/

        let contentType = 'application/pdf';
        let blob = base64StringToBlob(data.file, contentType);
        saveAs(blob, data.filename);
        this.toltoKepernyoKereses = false;

        this.ujFlottaAjanlatkeresekLekerese();
      }
    );
  }

  fajlEllenorzes(event: any) {
    if (event.length > 0) {
      event.forEach(element => {
        this.fajlFeltoltesEredmeny = element.f_hiba
      })
    }
    else {
      this.fajlFeltoltesEredmeny = "Sikeres";
    };
  }

  // Táblázat feltöltése gomb
  feltoltesOnClick() {
    this.feltoltes = true;
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesFlotta"].setValue(this.highlightedRow[0].controls["f_flotta_azon"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesSzerzodoNev"].setValue(this.highlightedRow[0].controls["f_ugyfelnev"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesUgyszam"].setValue(this.highlightedRow[0].controls["f_ugyszam"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesAlkuszTelefon"].setValue(this.highlightedRow[0].controls["f_alkusz_telefon"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesAlkuszEmail"].setValue(this.highlightedRow[0].controls["f_alkusz_email"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesFlottaNev"].setValue(this.highlightedRow[0].controls["f_ugyfelnev"].value);
    this.ujFlottaAjanlatkeresekFormGroup.controls["feltoltesUid"].setValue(this.highlightedRow[0].controls["f_uid"].value);
  }

  feltoltescCancelOnClick() {
    this.feltoltes = false;
  }

  // Ajánlat törlése
  torlesOnClick() {

    const dialogRef = this.utilityService.dialogMegnyitasa({
      confirm: true,
      title: 'Törlés megerősítés',
      message: 'Biztosan törölni kívánja az ajánlatot?',
      cancelButtonText: 'Mégsem',
      confirmButtonText: 'Igen'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.toltoKepernyoKereses = true;
        // Model, amit a controllerbe küldünk
        let model = {
          f_ugyszam: this.highlightedRow[0].controls['f_ugyszam'].value,
          f_modozat: "CASCO"
        };

        this.flottaService.ujFlottaAjanlatTorles(model).subscribe(
          data => {
            this.onSzures();
            this.toltoKepernyoKereses = false;

            this.utilityService.dialogMegnyitasa({
              success: true,
              title: 'Sikeres törlés',
              message: 'Az ajánlat törlése sikeresen megtörtént. Ügyszám: ' + this.highlightedRow[0].controls['f_ugyszam'].value,
              confirmButtonText: 'Rendben'
            });
          },
          errorResult => {
            this.toltoKepernyoKereses = false;

            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Sikertelen törlés',
              message: 'Az ajánlat törlése közbren hiba történt. Ügyszám: ' + this.highlightedRow[0].controls['f_ugyszam'].value,
              confirmButtonText: 'Rendben'
            });

          }
        );
      }
    });

  }

  onFajlokFeltolteseClick() {
    this.toltoKepernyoKereses = true;
    // Model létrehozás
    let model = {
      ugyszam: this.highlightedRow[0].controls['f_ugyszam'].value,
      fajlok: this.ujFlottaAjanlatkeresekFormGroup.controls['f_fajlok'].value,
      modozat: 'CASCO'
    };

    this.flottaService.ujFlottaAjanlatMellekletekVisszatoltese(model).subscribe(
      () => {
        const dialogRef = this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres feltöltés',
          message: 'A feltöltés sikeresen megtörtént.',
          confirmButtonText: 'Rendben'
        });
        dialogRef.afterClosed().subscribe(
          () => {
            // A formenumot átváltjuk az ajánlat visszatöltésre
            this.formEnum = FormEnum.Ajanlatkeresek;
          }
        );
        this.ujFlottaAjanlatkeresekLekerese();
        this.toltoKepernyoKereses = false;
        this.feltoltes = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás feltöltés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      }
    );
  }


  fedezetIgazolasOnClick() {

    let model = {
      f_dokumentum: "F",
      f_szerzodesSzamok: "",
      f_kotegelt: false,
      f_flotta: null,
      f_ugyszam: this.highlightedRow[0].controls['f_ugyszam'].value,
      f_ugy_modozat: "CASCO",
      f_ugy_tipus: "új"
    }

    this.toltoKepernyoKereses = true;
    this.dokumentumokService.dokumentumIgenyles(model).subscribe(
      data => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres igénylés',
          message: data.eredmeny_szoveg,
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
        this.toltoKepernyoKereses = false;
      });
  }

  tarsbiztositottiAdatok() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value
    }
    this.flottaService.flottaCascoUjTarsbiztositottiAdatok(model).subscribe(
      data => {
        if (data != null) {
          const fgs = data.map(item => {
            return this.utilityService.formGroupObjektumbol(item);
          });
          this.dataSourceCascoGepjarmuvek.data = this.formBuilder.array(fgs).controls;
          this.dataSourceCascoGepjarmuvek.data.forEach(adat => {
            if (adat.controls["f_tarsbiztositotti_szukseges"].value == "I" &&
              adat.controls["f_hitelintezet_neve"].value != "" &&
              adat.controls["f_hitelintezet_cime"].value != "" &&
              adat.controls["f_hitelszerzodes_azonosito"].value != "" &&
              adat.controls["f_szerzodes_tipusa"].value != "" &&
              adat.controls["f_szerzodes_kezdete"].value != null &&
              adat.controls["f_szerzodes_osszege"].value != null &&
              adat.controls["f_szerzodes_devizanem"].value != "" &&
              adat.controls["f_szerzodes_lejarata"].value != null) {
              this.fedezetIgazolas = true;
            }
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  szemleAdatok() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_ugytipus: 'U'
    }
    this.flottaService.flottaCascoSzemleAdatok(model).subscribe(
      data => {
        if (data != null) {

          this.ujFlottaAjanlatkeresekFormGroup.controls["f_kapcsolattarto_neve"].setValue(data["f_nev"]);
          this.ujFlottaAjanlatkeresekFormGroup.controls["f_kapcsolattarto_telszam"].setValue(data["f_telefon"]);
          this.ujFlottaAjanlatkeresekFormGroup.controls["f_kapcsolattarto_email"].setValue(data["f_email"]);
          this.ujFlottaAjanlatkeresekFormGroup.controls["f_kapcsolattarto_fenykep"].setValue(data["f_fenykep"]);
          
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  compareObjects(object1, object2, key/*, direction*/) {
    const obj1 = object1.controls[key].value.toString().toUpperCase();
    const obj2 = object2.controls[key].value.toString().toUpperCase();

    if (obj1 < obj2) {      
        return /*direction == "asc" ?*/ -1/* : 1 */    
    }
    if (obj1 > obj2) {
      return /*direction == "asc" ?*/ 1/*: -1*/
    }
    return 0
  }

  szemleAdatokMentes() {
    let model = {
      f_ugyszam: this.highlightedRow[0].controls["f_ugyszam"].value,
      f_ugytipus: 'U',
      f_formgroup: this.ujFlottaAjanlatkeresekFormGroup.value
    }

    this.flottaService.flottaCascoSzemleAdatokMentes(model).subscribe(
      data => {
        this.ujFlottaAjanlatkeresekLekerese();        

        this.utilityService.dialogMegnyitasa({
            success: true,
            title: 'Sikeres mentés',
            message: "A kapocsolttartó adatainak mentése sikeresen megtörtént",
            confirmButtonText: 'Rendben'
          });
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hibás kérés",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }


  tarsbiztositottiMentes(row) {

    this.flottaService.flottaCascoUjTarsbiztositottiAdatokMentes(row.value).subscribe(
      data => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Sikeres mentés',
          message: "A társbiztosítotti adatokat sikeresen elmentettük",
          confirmButtonText: 'Rendben'
        });
        this.fedezetIgazolas = true;
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a mentés közben",
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  onBezaras() {
    this.highlightedRow = [];
  }

  scrollToElement(elementName): void {
    var element = document.getElementById(elementName);
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}


enum FormEnum {
  Ajanlatkeresek,
  AjanlatVisszatoltes
}


export class AdatValidator extends Validators {


  static kapcsolattartoNevEllenor() {

    let validator = (formGroup: FormGroup) => {

      const control2 = formGroup.controls['f_allapot_szoveg'];
      const control1 = formGroup.controls['f_kapcsolattarto_neve'];
      control1.setErrors(null);

      if (control2 && control2.value == "Új flotta szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoNevlHiany: true });
        }

      }
    }
    return validator;
  }

  static kapcsolattartoTelszamEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_telszam'];
      const control2 = formGroup.controls['f_allapot_szoveg'];
      control1.setErrors(null);

      if (control2 && control2.value == "Új flotta szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoTelszamlHiany: true });
        }

      }
    }
    return validator;
  }

  static kapcsolattartoEmailEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_email'];
      const control2 = formGroup.controls['f_allapot_szoveg'];

      if (!control1.errors || (control1.errors && !control1.errors.pattern)) {
        control1.setErrors(null);

        if (control2 && control2.value == "Új flotta szemleköteles") {

          if (!control1.value || control1.value.toString().length == 0) {
            control1.setErrors({ kapcsolattartoEmailHiany: true });
          }

        }
      }
    }
    return validator;
  }

  static kapcsolattartoFenykepEllenor() {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls['f_kapcsolattarto_fenykep'];
      const control2 = formGroup.controls['f_allapot_szoveg'];
      control1.setErrors(null);

      if (control2 && control2.value == "Új flotta szemleköteles") {

        if (!control1.value || control1.value.toString().length == 0) {
          control1.setErrors({ kapcsolattartoFenykepHiany: true });
        }

      }
    }
    return validator;
  }

}
