import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FelhasznaloService {

  constructor(
    private httpClient: HttpClient) {    
  }

  private url: string = '/api/felhasznalo';

  // Partnerportál felhasználók lekérdezése
  felhasznalok(model: any) {
    return this.httpClient.post<any>(this.url + '/Felhasznalok', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Admin - Megtekintés más nevében mód beállítása
  megtekintesMasNeveben(model : any) {
    return this.httpClient.post<any>(this.url + '/MegtekintesMasNeveben', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Felhasználónak van e jogosultsága az oldalhoz
  felhasznaloOldalJogosultsag(model: any) {
    return this.httpClient.post<any>(this.url + '/FelhasznaloOldalJogosultsag', model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
